/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const showModalForGoToFormAI = createSlice({
  name: 'showModalForGoToFormAI',
  initialState: {
    value: {
      show: "",
    },
  },
  reducers: {
    showModal: (state, action) => {
      state.value.show = true;
    },
    hideModal: (state, action) => {
      state.value.show = false;
    },
  }
});

export const {
  showModal, hideModal
} = showModalForGoToFormAI.actions;

export default showModalForGoToFormAI.reducer;
