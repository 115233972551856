import React from 'react';
import './style.scss';
import {
  useSelector
} from 'react-redux';
import Fade from "react-reveal/Fade";
import { Link } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import Text from '../../../share-components/text/text';
import { optionsOffer } from '../../../services/values';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function Subscription() {
  const statusUser = useSelector((state) => state.statusUser.value);
  const companyList = useSelector((state) => state.companyList.value);

  const currectPlan = [
    {
      id: 1,
      name: 'Name',
      value: optionsOffer.find((x) => x.uuid === statusUser.servicePlan).name
    },
    {
      id: 2,
      name: 'Max company profiles',
      value: statusUser.maxCompanyProfiles
    },
    {
      id: 3,
      name: 'company profiles',
      value: companyList.companyList.length
    },
    {
      id: 4,
      name: 'Max content generate',
      value: statusUser.maxGenerateContentCompany
    },
    {
      id: 5,
      name: 'Max generate specific post',
      value: statusUser.maxGenerateSpecificPost
    },
    {
      id: 8,
      name: 'Max generate content post',
      value: statusUser.maxGenerateContentPost
    },
    {
      id: 11,
      name: 'Max generate video post',
      value: statusUser.maxGenerateVideoPosts
    },
  ];

  const handlerBuildInfo = (list) => {
    return <div className="container-list container-cards-menu w-100">
      {list.map((menu, index, array) => (
        <div className={`item-list ${index === 0 ? 'firts-child' : ''} ${index === array.length - 1 ? 'last-child' : ''} ${index !== array.length - 1 ? 'column-divider' : ''}`}>
          <div className='left-container'>
            <Text type="body">{menu.name}</Text>
          </div>
          <div className='right-container'>
            <Text type="body" inyectClass="text-muted">{menu.value}</Text>
          </div>
        </div>
      ))}
    </div>;
  };

  const itemChangeYourPlan = [
    {
      id: 1,
      name: 'Change your plan',
      value: ''
    }
  ];

  const handlerBuildChangeYourPlan = (list) => {
    return <div className="container-list container-cards-menu w-100">
      {list.map((menu, index, array) => (
        <div
          className={`item-list ${index === 0 ? 'firts-child' : ''} ${index === array.length - 1 ? 'last-child' : ''} ${index !== array.length - 1 ? 'column-divider' : ''}`}
          onClick={() => {console.log('click');}}
        >
          <div className='left-container'>
            <Text type="body">{menu.name}</Text>
          </div>
          <div className='right-container'>
            <NavigateNextIcon />
          </div>
        </div>
      ))}
    </div>;
  };

  return (
    <div>
      <Fade duration={600} opposite>
        <div className="content-title-embed">
          <div>
            <Link to="/settings" className="link-back">
              <ArrowBackIosNewRoundedIcon className='color-text-primary'/>
              <Text type='body' inyectClass="color-text-primary">
                Settings
              </Text>
            </Link>
          </div>
          <div>
            <Text type="title">
              Subscription
            </Text>
          </div>
          <div></div>
        </div>
        <div className={`container-company-profile content-view pt-4`}>
          <div className="container-cards-menu mb-0">
            <Text type="small" inyectClass="text-muted">
              Current plan
            </Text>
          </div>
          {handlerBuildInfo(currectPlan)}

          <div className="container-cards-menu mb-0">
            <Text type="small" inyectClass="text-muted">
              Change your plan
            </Text>
          </div>
          {handlerBuildChangeYourPlan(itemChangeYourPlan)}
        </div>
      </Fade>
    </div>
  );
};
export default Subscription;
