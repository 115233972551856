import React, { useEffect, useState, useRef } from 'react';
import base64 from 'base-64';
import {
  HashRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import './style.scss';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import moment from 'moment';
import { postRequest } from '../services/requestLib';
import { detectDeviceType } from '../services/util';
import { setNavigator, setTheme } from '../../reducers/userAgent';
import { setLoginInfo } from '../../reducers/userData';
import Loading from '../share-components/loading/loading';
import Alert from '../share-components/alert/alert';
import Paper from '../share-components/paper/paper';

/* App pages */
import LogIn from '../security/login';
import SignUp from '../security/signup';
import FotgotPassword from '../security/forgotPassword';

/* Admin views */
import page404 from '../security/error404';
import Navbar from '../navbar/navbar';
import Form from '../user/home/form/form';
import CompanyProfile from '../user/home/companyProfile/companyProfile';
import BrandContent from '../user/home/brandContent/brandContent';
import ContentCalendar from'../user/home/contentCalendar/contentCalendar';
import SettingsIndex from '../settings/index';
import SettingsCompany from '../share-components/settingsCompany/settingsCompany';
import JoinToMeantto from '../joinToMeantto/joinToMeantto';

/* Collaborator views */
import LoadingAsCollaborator from '../share-components/loadingAsCollaborator/loading';
import BrandContentCollaborator from '../userCollaborator/home/brandContent/brandContent';
import CompanyProfileCollaborator from '../userCollaborator/home/companyProfile/companyProfile';
import ContentCalendarCollaborator from'../userCollaborator/home/contentCalendar/contentCalendar';

/* init request */
import RequestCompanyAdmin from './adminCompany/requestCompanyAdmin';
import RequestInfoAdmin from './adminCompany/requestInfoAdmin';
import RequestCompanyCollaborator from './colllaboratorLab/requestCompanyCollaborator';
import CleanCompanyCollab from './colllaboratorLab/cleanCompanyCollab';
import CollaboratorWithoutAccount from '../collaboratorWithoutAccount/collaboratorWithoutAccount';

/* Shared components */
import FirtsLogin from '../share-components/modalAlerts/firtsLogin/firtsLogin';
import ErrorInSignIn from '../share-components/modalAlerts/errorInSignIn/errorInSignIn';
import ModalChangeCompany from '../share-components/modalChangeCompany/modalChangeCompany';
import CreateFirtsCompany from '../share-components/modalAlerts/createFirtsCompany/createFirtsCompany';
import useTheme from '../services/theme/useTheme';
import ModalCompanyCollaborator from '../share-components/modalCompanyCollaborator/modalCompanyCollaborator';
import { setShowAlert } from '../../reducers/showAlert';
import UseUpdateSocket from '../services/webSocket/useUpdateSocket';
import { handlerGetLocalStorage } from '../services/localStorage/localStorage';
import { handlerGetSessionStorage } from '../services/sessionStorage/sessionStorage';
import { IsCollaboratorPlan } from '../services/isCollaboratorPlan/IsCollaboratorPlan';
import CollaboratorPlan from '../collaboratorPlan/collaboratorPlan';

import InfoAccount from '../settings/components/infoAccount/infoAccount';
import Security from '../settings/components/security/security';
import Subscription from '../settings/components/subscription/subscription';
import Paymets from '../settings/components/payments/payments';
import Notifications from '../settings/components/notifications/notifications';
import Language from '../settings/components/language/language';
import SendUsMessage from '../settings/components/sendUsMessage/sendUsMessage';

function Index() {
  const { userTheme } = useTheme();

  const userAgent = useSelector((state) => state.userAgent.value);
  const userData = useSelector((state) => state.userData.value);
  const activeCompanyProfile = useSelector((state) => state.activeCompanyProfile.value);
  const activeCollaboratorMode = useSelector((state) => state.activeCollaboratorMode.value);
  const collaboratorCompanySelected = useSelector((state) => state.collaboratorCompanySelected.value);  

  //#region SOCKET
    const { updateDataFromSocket } = UseUpdateSocket();
    const [ws, setWs] = useState(null);
    const wsRef = useRef(null);
    const reconnectRef = useRef(false);

    const sendSocket = (message) => {
      try {
        if (ws) {
          ws.send(JSON.stringify(message));
        } 
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      const companyId = activeCollaboratorMode.collaboratorMode ? collaboratorCompanySelected.idCompany : activeCompanyProfile.id;
      if (userData.user && companyId) {
        const connectWebSocket = () => {
          const url = `wss://hz3435zuw6.execute-api.us-east-1.amazonaws.com/test?user=${encodeURIComponent(userData.user)}&company=${encodeURIComponent(companyId)}`;
          const socket = new WebSocket(url);
          wsRef.current = socket;
          setWs(socket);
  
          socket.onopen = () => {
            console.log('Conexión establecida');
            reconnectRef.current = false;
          };
  
          socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            const {
              type,
              table,
              sendFromAdminView,
              sendFromCollaboratorView,
              companyOwner,
              idCompanyOwner,
              userSendUpdate, 
              isAdmin,
              data
            } = message;
  
            updateDataFromSocket(type, table, sendFromAdminView, sendFromCollaboratorView, companyOwner, idCompanyOwner, userSendUpdate, isAdmin, data);
          };
  
          socket.onerror = (error) => {
            console.error('Error en WebSocket:', error);
          };
  
          socket.onclose = (event) => {
            console.log('Conexión cerrada:', event);
            if (!reconnectRef.current) {
              reconnectRef.current = true;
              setTimeout(() => {
                console.log('Reconectando...');
                connectWebSocket();
              }, 2000);
            }
          };
        };
  
        connectWebSocket();
  
        return () => {
          if (wsRef.current) {
            wsRef.current.close();
          }
        };
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData, activeCompanyProfile, activeCollaboratorMode, collaboratorCompanySelected]);
  //#endregion

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const handlerSetLoginInfo = (infoLogin) => {
    dispatch(setLoginInfo(infoLogin));
  };

  const handlerSetNavigator = (infoNavigator) => {
    dispatch(setNavigator(infoNavigator));
  };

  const handlerSetTheme = (infoNavigator) => {
    dispatch(setTheme(infoNavigator));
  };

  const handlerSetShowAlert = (infoAlert) => {
    dispatch(setShowAlert(infoAlert));
  };

  const openAlert = (typeAlert, textAlert) => {
    const object = {
      typeAlert: typeAlert,
      textAlert: textAlert
    };

    handlerSetShowAlert(object);
  };

  const [errorInSignIn, setErrorInSignIn] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const requestNewCompanyInfo = async () => {
    setLoadingData(true);
    const requestCompanyInfo = await RequestCompanyAdmin(dispatch, userData.email, userData.password, userData.token, activeCompanyProfile.id);
    setLoadingData(false);
    return requestCompanyInfo;
  };

  useEffect(() => {
    if (activeCompanyProfile.id) {
      requestNewCompanyInfo();
    }
    // eslint-disable-next-line
  }, [activeCompanyProfile]);

  const handlerCheckIfLoginLocalStorage = () => {
    const data = localStorage.getItem('CognitoAttrdlu');
    return {
      status: (data !== null),
      data: data === null ? null : JSON.parse(base64.decode(data)),
    }
  };

  const handlerInsertDataLoginToLocalStorage = (data) => {
    localStorage.setItem('CognitoAttrdlu', data);
  };

  const getAccessLogin = handlerGetLocalStorage('CognitoIdentityServiceProvider.dcLS');

  const handlerClearCacheData = () => {
    sessionStorage.clear();
    localStorage.clear();
  };

  const requestLoginUser = async () => {
    setLoadingData(true);
    const check = handlerCheckIfLoginLocalStorage();

    if (check.status === false) {
      try {
        const userInfo = {
          email: handlerGetSessionStorage('CognitoIdentityServiceProvider.MeanttoE'),
          password: handlerGetSessionStorage('CognitoIdentityServiceProvider.MeanttoP'),
          token: handlerGetSessionStorage('CognitoIdentityServiceProvider.MeanttoT'),
          dateLogin: moment().format('YYYY-MM-DDTHH:mm:ss.SSS')
        };
    
        const loginResponse = await postRequest('login', userInfo);
        if (loginResponse.status === 200 && loginResponse.data.status === true) {
          const dataUser = loginResponse.data.data.user;
          const dataSave = {
            email: handlerGetSessionStorage('CognitoIdentityServiceProvider.MeanttoE'),
            password: handlerGetSessionStorage('CognitoIdentityServiceProvider.MeanttoP'),
            active: dataUser.active,
            lastLogged: dataUser.lastLogged,
            token: handlerGetSessionStorage('CognitoIdentityServiceProvider.MeanttoT'),
            user: dataUser.user,
            firtsLogin: dataUser.firtsLogin,
            servicePlan: dataUser.servicePlan
          };
          handlerSetLoginInfo(dataSave);

          const transformLoginData = base64.encode(JSON.stringify(dataSave));
          handlerInsertDataLoginToLocalStorage(transformLoginData);

          const response = await RequestInfoAdmin(dispatch, dataSave.email, dataSave.password, dataSave.token);
          if (response.status) {
            if (!response.collaboratorPlan) {
              await RequestCompanyAdmin(dispatch, dataSave.email, dataSave.password, dataSave.token, response.id);
            }
          } else {
            console.log(response.msm);
          }
        }

        setLoadingData(false);
      } catch (error) {
        handlerClearCacheData();
        setErrorInSignIn(true);
      }
    }

    if (check.status === true) {
      try {
        const dataUser = check.data;

        const dataSave = {
          email: dataUser.email,
          password: dataUser.password,
          active: dataUser.active,
          lastLogged: dataUser.lastLogged,
          token: dataUser.token,
          user: dataUser.user,
          firtsLogin: dataUser.firtsLogin,
          servicePlan: dataUser.servicePlan
        };
        handlerSetLoginInfo(dataSave);

        const response = await RequestInfoAdmin(dispatch, dataSave.email, dataSave.password, dataSave.token);
        if (response.status) {
          if (!response.collaboratorPlan) {
            await RequestCompanyAdmin(dispatch, dataSave.email, dataSave.password, dataSave.token, response.id);
          }
        } else {
          console.log(response.msm);
        }

        setLoadingData(false);
      } catch (error) {
        handlerClearCacheData();
        setErrorInSignIn(true);
      }
    }
  };

  const handlerGetDevice = () => {
    const detectedDevice = detectDeviceType();
    handlerSetNavigator({ device: detectedDevice });
  };

  useEffect(() => {
    handlerGetDevice();
    requestLoginUser();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userTheme !== undefined) {
      handlerSetTheme({ theme: `${userTheme}-mode` });
    }
    // eslint-disable-next-line
  }, [userTheme]);

  const handlerRequestCompanyAsCollaborator = async () => {
    setLoadingData(true);
    const response = await RequestCompanyCollaborator(dispatch, userData, collaboratorCompanySelected);
    if (response === true) {
      setLoadingData(false);
    } else {
      openAlert("danger", "We're sorry, we couldn't access the Company profile, please try again.");
    }
  };

  const handlerClearCompanyCollabortor = async () => {
    await CleanCompanyCollab(dispatch);
  };

  useEffect(() => {
    if (collaboratorCompanySelected.idCompany !== "") {
      handlerRequestCompanyAsCollaborator();
    } else {
      handlerClearCompanyCollabortor();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collaboratorCompanySelected, dispatch]);

  return (
    <Router>
      {getAccessLogin !== null ? (
        <Paper type="bg-app" inyectClass={`h-100 ${activeCollaboratorMode.collaboratorMode === true && 'collaborator-mode'}`} >
          <div className="index-content clearfix">
            <Paper type="navbar" inyectClass={`sidebar ${userAgent.device === 'iOS' ? 'ios-sidebar' : ''}`}>
              <Navbar />
            </Paper>
            <div className={`content ${userAgent.device === 'iOS' ? 'ios-content' : ''}`}>

              {activeCollaboratorMode.collaboratorMode === false && (
                IsCollaboratorPlan(userData.servicePlan) ? (
                  <Switch>
                    <Route exact path="/">
                      {loadingData ? <Loading /> : <CollaboratorPlan />}
                    </Route>
                    <Route path="/content-calendar">
                      {loadingData ? <Loading /> : <CollaboratorPlan />}
                    </Route>
                    <Route path="/join-to-meantto">
                      {<JoinToMeantto />}
                    </Route>
                    <Route exact path="/settings">
                      {loadingData ? <LoadingAsCollaborator /> : <SettingsIndex />}
                    </Route>
                    <Route path="/settings/account">
                      {loadingData ? <LoadingAsCollaborator /> : <InfoAccount /> }
                    </Route>
                    <Route path="/settings/security">
                      {loadingData ? <LoadingAsCollaborator /> : <Security /> }
                    </Route>
                    <Route path="/settings/subscription">
                      {loadingData ? <LoadingAsCollaborator /> : <Subscription /> }
                    </Route>
                    <Route path="/settings/payments">
                      {loadingData ? <LoadingAsCollaborator /> : <Paymets /> }
                    </Route>
                    <Route path="/settings/notifications">
                      {loadingData ? <LoadingAsCollaborator /> : <Notifications /> }
                    </Route>
                    <Route path="/settings/language">
                      {loadingData ? <LoadingAsCollaborator /> : <Language /> }
                    </Route>
                    <Route path="/settings/send-us-message">
                      {loadingData ? <LoadingAsCollaborator /> : <SendUsMessage /> }
                    </Route>
                    <Route path="*">
                      {loadingData ? <Loading /> : <CollaboratorPlan />}
                    </Route>
                  </Switch>
                ) : (
                  <Switch>
                    <Route exact path="/">
                      {loadingData ? <Loading /> : <ContentCalendar SendSocket={sendSocket} />}
                    </Route>
                    <Route path="/content-calendar">
                      {loadingData ? <Loading /> : <ContentCalendar SendSocket={sendSocket} />}
                    </Route>
                    <Route path="/form-ai">
                      {loadingData ? <Loading /> : <Form />}
                    </Route>
                    <Route path="/company-profile">
                      {loadingData ? <Loading /> : <CompanyProfile SendSocket={sendSocket} />}
                    </Route>
                    <Route path="/brand-information">
                      {loadingData ? <Loading /> : <BrandContent SendSocket={sendSocket} />}
                    </Route>
                    <Route exact path="/settings">
                      {loadingData ? <Loading /> : <SettingsIndex />}
                    </Route>
                    <Route path="/settings/account">
                      {loadingData ? <Loading /> : <InfoAccount /> }
                    </Route>
                    <Route path="/settings/security">
                      {loadingData ? <Loading /> : <Security SendSocket={sendSocket} /> }
                    </Route>
                    <Route path="/settings/subscription">
                      {loadingData ? <Loading /> : <Subscription /> }
                    </Route>
                    <Route path="/settings/payments">
                      {loadingData ? <Loading /> : <Paymets /> }
                    </Route>
                    <Route path="/settings/notifications">
                      {loadingData ? <Loading /> : <Notifications /> }
                    </Route>
                    <Route path="/settings/language">
                      {loadingData ? <Loading /> : <Language /> }
                    </Route>
                    <Route path="/settings/send-us-message">
                      {loadingData ? <Loading /> : <SendUsMessage /> }
                    </Route>
                    <Route path="/join-to-meantto">
                      {<JoinToMeantto />}
                    </Route>
                    <Route path="*">
                      {loadingData ? <Loading /> : <ContentCalendar SendSocket={sendSocket} />}
                    </Route>
                  </Switch>
                )
              )}

              {activeCollaboratorMode.collaboratorMode === true && (
                <Switch>
                  <Route exact path="/">
                    {loadingData ? <LoadingAsCollaborator /> : <ContentCalendarCollaborator SendSocket={sendSocket} />}
                  </Route>
                  <Route path="/content-calendar">
                    {loadingData ? <LoadingAsCollaborator /> : <ContentCalendarCollaborator SendSocket={sendSocket} />}
                  </Route>
                  <Route path="/company-profile">
                    {loadingData ? <LoadingAsCollaborator /> : <CompanyProfileCollaborator />}
                  </Route>
                  <Route path="/brand-information">
                    {loadingData ? <LoadingAsCollaborator /> : <BrandContentCollaborator />}
                  </Route>
                  <Route exact path="/settings">
                    {loadingData ? <LoadingAsCollaborator /> : <SettingsIndex />}
                  </Route>
                  <Route path="*">
                    {loadingData ? <LoadingAsCollaborator /> : <ContentCalendarCollaborator SendSocket={sendSocket} />}
                  </Route>
                </Switch>
              )}
            </div>

            <Alert />
            <FirtsLogin />
            <CreateFirtsCompany />
            <ModalChangeCompany />
            <ModalCompanyCollaborator />
            <SettingsCompany />
            <ErrorInSignIn show={errorInSignIn} />
          </div>
        </Paper>
      ) : (
        <Switch>
          <Route exact path="/" component={LogIn} />
          <Route path="/sign-in" component={LogIn} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/forgot-password">
            <FotgotPassword from={"outside"} />
          </Route>
          <Route path="/join-to-meantto" component={CollaboratorWithoutAccount} />
          <Route path="*" component={page404} />
        </Switch>
      )}
    </Router>
  );
}

export default Index;
