import React, { useState, useEffect } from 'react';
import '../../../../../styles/contentCalendar/components/deleteFile/style.scss';
import PropTypes from 'prop-types';
import { Fade } from "react-reveal";
import { PulseLoader } from 'react-spinners';
import { Modal } from 'react-responsive-modal';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { S3Client, DeleteObjectsCommand } from "@aws-sdk/client-s3";
import generateCredentialsProvider from '../../../../../security/cognitoPool';
import { bucketNameS3, regionAWS } from '../../../../../services/values';
import { updateFileList, updateCounterFiles } from '../../../../../../reducers/companyFiles';
import { setShowAlert } from '../../../../../../reducers/showAlert';
import { patchRequestCollaborator } from '../../../../../services/requestLib';
import Text from '../../../../../share-components/text/text';

function DeteleFile({ file, thumbnail, SendSocket }) {
  const userData = useSelector((state) => state.userData.value);
  const activeCompanyProfile = useSelector((state) => state.activeCompanyProfile.value);
  const companyFiles = useSelector((state) => state.companyFiles.value);
  const [tempFileList, setTempFileList] = useState([...companyFiles.fileList]);
  const [tempCounterFiles, setTempCounterFiles] = useState(0);
  const activeCollaboratorMode = useSelector((state) => state.activeCollaboratorMode.value);

  useEffect(() => {
    setTempFileList([...companyFiles.fileList]);
  }, [companyFiles]);

  const [stateOfDelete, setStateOfDelete] = useState('confirm-delete');
  const [openModalForDelete, setOpenModalForDelete] = useState(false);
  const [fileForDelete, setFileForDelete] = useState({
    thumbnail: "",
    file: ""
  });

  const dispatch = useDispatch();

  const handlerUpdateCounterFiles = (infoCompany) => {
    dispatch(updateCounterFiles(infoCompany));
  };

  const handlerUpdateFileList = (infoCompany) => {
    dispatch(updateFileList(infoCompany));
  };

  const handlerSetShowAlert = (infoAlert) => {
    dispatch(setShowAlert(infoAlert));
  };

  const openAlert = (typeAlert, textAlert) => {
    const object = {
      typeAlert: typeAlert,
      textAlert: textAlert
    };
  
    handlerSetShowAlert(object);
  };

  const handlerSelectFileForDelete = (e, thumbnail, file) => {
    e.preventDefault();

    setFileForDelete((prevState) => ({
      ...prevState,
      thumbnail: thumbnail,
      file: file
    }));

    setOpenModalForDelete(true);
  };

  const handlerCloseModalFileForDelete = () => {
    setFileForDelete((prevState) => ({
      ...prevState,
      thumbnail: "",
      file: ""
    }));

    setOpenModalForDelete(false);
  };

  const handlerUpdateCompanyFiles = async () => {
      // Realiza las operaciones dentro del método
      const updatedFileList = tempFileList.filter((x) => `${x.uuid}.${x.format}` !== file);
      const updatedCounterFiles = parseInt(tempCounterFiles, 10) + 1;

      const user = {
        email: userData.email,
        password: userData.password,
        token: userData.token,
        typeAccess: 1
      };

      const sendData = {
        id: activeCompanyProfile.id,
        fileList: updatedFileList,
        counterFiles: updatedCounterFiles,
      };

      const responseUpdateCompanyFiles = await patchRequestCollaborator('company-file', user, sendData);
      if (responseUpdateCompanyFiles.status === 200 && responseUpdateCompanyFiles.data.status === true) {

        SendSocket({
          action: 'send-message',
          data: {
            id: activeCompanyProfile.id,
            user: userData.user,
            message: {
              type: 'UPDATE',
              table: 'company-file',
              sendFromAdminView: true,
              sendFromCollaboratorView: false,
              companyOwner: userData.user,
              idCompanyOwner: activeCompanyProfile.id,
              userSendUpdate: userData.user,
              isAdmin: !activeCollaboratorMode.collaboratorMode,
              data: { 
                user: companyFiles.user,
                id: activeCompanyProfile.id,
                email: companyFiles.email,
                fileList: updatedFileList,
                counterFiles: updatedCounterFiles,
                storageForFiles: companyFiles.storageForFiles,
              }
            }
          }
        });

        handlerUpdateFileList(sendData);
        handlerUpdateCounterFiles(sendData);

        // Utiliza los resultados actualizados con useState
        setTempFileList(updatedFileList);
        setTempCounterFiles(updatedCounterFiles);

        return { status: true };
      } else {
        return { status: false };
      }
  };

  const handlerRequestToS3ForDelete = async () => {
    try {
      const credentialProvider = generateCredentialsProvider();
  
      const s3Client = new S3Client({
        region: regionAWS,
        credentials: credentialProvider,
      });
  
      const objectKeys = [fileForDelete.file, fileForDelete.thumbnail];

      const deleteList = objectKeys.map(Key => ({ Key }));
  
      const putObjectCommand = new DeleteObjectsCommand({
        Bucket: bucketNameS3,
        Delete: { Objects: deleteList },
      });
  
      const response = await s3Client.send(putObjectCommand);
  
      if (response.$metadata.httpStatusCode) {
        return { status: true };
      } else {
        return { status: false };
      }
    } catch (error) {
      console.log(error);
      return { status: false };
    }
  };

  const handlerDeleteFile = async () => {
    try {
      setStateOfDelete('loading');
      const responseDeleteFileS3 = await handlerRequestToS3ForDelete();
      if (responseDeleteFileS3.status === true) {
        const responseUpdateCompanyFiles = await handlerUpdateCompanyFiles();
        if (responseUpdateCompanyFiles.status === true) {
          handlerCloseModalFileForDelete();
          openAlert("success", "Success");
        } else {
          setStateOfDelete('error');
          openAlert("danger", "Error, try delete your file again");
        }
      } else {
        setStateOfDelete('error');
        openAlert("danger", "Error, try delete your file again");
      }
    } catch (error) {
      setStateOfDelete('error');
      openAlert("danger", "Error, try delete your file again");
    }
  };

  return (
    <>
      <button
        className='btn-delete-file'
        onClick={(e) => handlerSelectFileForDelete(e, thumbnail, file)}
      >
        <DeleteIcon />
      </button>

      <Modal
        open={openModalForDelete}
        showCloseIcon={false}
        blockScroll={true}
        onClose={() => handlerCloseModalFileForDelete()}
        classNames={{
          overlay: 'customOverlay',
          modal: 'customModal',
        }}
        center
      >
        <div className="modal-body modal-alert-persistent">
          <div className="modal-content">
              {stateOfDelete === 'confirm-delete' && (
                <Fade duration={600} opposite>
                  <div className='d-flex flex-column' style={{ height: '90%' }}>
                  <Text type="subtitle">
                    Delete file
                  </Text>
                  <Text type="text" inyectClass="mt-2">
                    Are you sure that u want delete this file?
                  </Text>
                  </div>
                </Fade>
              )}

              {stateOfDelete === 'loading' && (
                <Fade duration={600} opposite>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100 flex-1 pt-5 pb-1 pl-2 pr-2">
                    <PulseLoader color="#6001d0" />
                    <Text type="text" inyectClass="mt-3">
                      Loading data
                    </Text>
                  </div>
                </Fade>
              )}

              {stateOfDelete === 'error' && (
                <Fade duration={600} opposite>
                  <Text type="text" inyectClass="mt-3">
                    Something went wrong. Please try again
                  </Text>
                </Fade>
              )}

          </div>
          {stateOfDelete === 'confirm-delete' && (
            <div className="action-btn-form">
              <button
                className="two-btn-modal"
                onClick={() => handlerCloseModalFileForDelete()}>
                  Close
              </button>
              <button
                className="two-btn-modal"
                onClick={() => handlerDeleteFile()}>
                  Delete
              </button>
            </div>
          )}

          {stateOfDelete === 'error' && (
            <div className="action-btn-form">
              <button
                className="one-btn-modal"
                onClick={() => handlerDeleteFile()}>
                  Try again
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

DeteleFile.propTypes = {
  file: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  SendSocket: PropTypes.func.isRequired,
};

export default DeteleFile;
