/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const answersForm = createSlice({
  name: 'answersForm',
  initialState: {
    value: {
      question1: "",
      question2: "",
      question3: "",
      question4: "",
      question5: "",
      question6: "",
      question7: "",
      question8: "",
      question9: "",
      question10: "",
      question11: "",
      question12: "",
      question13: "",
      question14: "",
    },
  },
  reducers: {
    setAnswersForm: (state, action) => {
      state.value.question1 = action.payload.question1;
      state.value.question2 = action.payload.question2;
      state.value.question3 = action.payload.question3;
      state.value.question4 = action.payload.question4;
      state.value.question5 = action.payload.question5;
      state.value.question6 = action.payload.question6;
      state.value.question7 = action.payload.question7;
      state.value.question8 = action.payload.question8;
      state.value.question9 = action.payload.question9;
      state.value.question10 = action.payload.question10;
      state.value.question11 = action.payload.question11;
      state.value.question12 = action.payload.question12;
      state.value.question13 = action.payload.question13;
      state.value.question14 = action.payload.question14;
    },
  }
});

export const {
  setAnswersForm
} = answersForm.actions;

export default answersForm.reducer;
