import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { hideModal } from '../../../../reducers/showModalForGoToFormAI';

function FirtsLogin() {
  const history = useHistory();
  const showModalForGoToFormAI = useSelector((state) => state.showModalForGoToFormAI.value);

  const [modalFirtsSteps, setModalFirtsSteps] = useState(false);
  
  const dispatch = useDispatch();

  const handlerHideModal = () => {
    dispatch(hideModal());
  };
  
  const handlerShowFirtsStepsToUser = () => {
    setModalFirtsSteps(true);
  };

  const handlerHideFirtsStepsToUser = () => {
    handlerHideModal();
    setModalFirtsSteps(false);
    history.push('/form-ai');
  };

  useEffect(() => {
    if (showModalForGoToFormAI.show === true) {
      handlerShowFirtsStepsToUser();
    }
    // eslint-disable-next-line
  }, [showModalForGoToFormAI]);

  return (
    <Modal
      open={modalFirtsSteps}
      showCloseIcon={false}
      blockScroll={true}
      onClose={() => handlerHideFirtsStepsToUser()}
      classNames={{
        overlay: 'customOverlay',
        modal: 'customModal',
      }}
      center
      >
      <div className="modal-body">
        <div className="modal-content">
          <div className='d-flex align-items-center justify-content-center' style={{ height: '90%' }}>
            <span className="text-muted">
              Image that explains to the user what they should do first.
            </span>
          </div>
        </div>
        <div className="action-btn-form">
          <button
            className="one-btn-modal"
            onClick={() => handlerHideFirtsStepsToUser()}>
              Proceed to AI form
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default FirtsLogin;
