import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { formatMoment, formatDateMoment, formatHourMoment } from './values';

export const generatePost = (activeCompanyProfile, getGeneratedPost, dateSelected, groupSelected) => {
  const fullDate = moment.utc(dateSelected).format(formatDateMoment);
  const fullHour = moment.utc(dateSelected).format(formatHourMoment);

  const newPost = {
    createdate: moment().format(formatMoment),
    publishDate: fullDate,
    publishHour: fullHour,
    alertNotification: false,
    alertNotificationDate: fullDate,
    alertNotificationHour: fullHour,
    id: uuidv4(),
    companyProfileId: activeCompanyProfile.id,
    contentIdea: getGeneratedPost.contentIdea,
    postText: getGeneratedPost.postText,
    socialNetwork: "",
    typePost: "Weekly Post",
    groupPost: groupSelected,
    urlFile: "",
    urlThumbnailFile: "",
    haveFile: false
  };

  return newPost;
};