import { formatObjectForSpecificPrompt } from "./utilPrompts";

export const generatePromptSpecificPost = (dataForm, location, specificEvent, specificProduct, socialNetworks) => {
  const WhatIsYourBusinessType = dataForm[`question${1}`];
  const WhatIsYourBusinessName = dataForm[`question${2}`];
  const ChooseYourIndustryType = dataForm[`question${3}`];
  const DescriptionOfProductsAndServices = dataForm[`question${4}`];
  const SelectYourBrandPersonality = dataForm[`question${5}`];
  const WhoIsYourGenderTarget = dataForm[`question${6}`];
  const WhoIsYourTargetAge = dataForm[`question${7}`];
  const GiveABriefDescriptionOfTargetAudience = dataForm[`question${8}`];

  return `I run a ${WhatIsYourBusinessType} called ${WhatIsYourBusinessName} in the ${ChooseYourIndustryType} industry, that specializes in ${DescriptionOfProductsAndServices}, with an ${SelectYourBrandPersonality} brand tone. My target audience are ${WhoIsYourGenderTarget} aged between ${WhoIsYourTargetAge} who ${GiveABriefDescriptionOfTargetAudience}, located in ${location}. Generate 1 post focused around ${specificEvent} for ${socialNetworks}. This post will focus around ${specificProduct}. Include the type of post, idea, caption and multiple hashtags for this post. Ensure that the Captions have storytelling elements, and be sure to integrate SEO-optimized keywords and hashtags for ${ChooseYourIndustryType} to enhance target audience reach.
    Assign each generated content to the following JSON as a value and return this same JSON with the generated information and dont modify this JSON, i want key - value, DONT'T INCLUDE SPECIAL CHARACTERS at the beginning and at the end of the JSON object and stringify format without backslash.
    ${formatObjectForSpecificPrompt}`;
};
