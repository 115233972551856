import base64 from 'base-64';

export const handlerGetSessionStorage = (name) => {
  const data = sessionStorage.getItem(name);
  if (data === null) {
    return null;
  }
  if (data !== null) {
    return base64.decode(data);
  }
};