import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useMediaQuery } from '@react-hook/media-query';
import Skeleton from 'react-loading-skeleton';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import 'react-loading-skeleton/dist/skeleton.css';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import { Tooltip } from 'react-tooltip';
import { Fade } from "react-reveal";
import { useSelector, useDispatch } from 'react-redux';
import { setTextContentCompany, setReGenerateContent } from '../../../../../reducers/generatedContent';
import { putRequestMeantto } from '../../../../services/requestLib';
import { generateContentMeantto } from '../../../../services/generatePrompt';
import { setShowAlert } from '../../../../../reducers/showAlert';
import { reGenerateContent } from '../../../../services/generatePrompt2';
import { setRestCounterGenerateContent } from '../../../../../reducers/companyInfo';
import GeneratingContentCard from '../../../../share-components/lottie/generatingContentCard/generatingContentCard';
import { valuesForTooltip } from './values';

const ReturnSkeleton = () => {
  return (
    <Fade duration={600} opposite>
      <Skeleton count={5} />
    </Fade>
  );
};

function Information({ SendSocket }) {
  const esPantallaMediana = useMediaQuery('(min-width: 1px) and (max-width: 991px)');
  const activeCollaboratorMode = useSelector((state) => state.activeCollaboratorMode.value); 
  const userData = useSelector((state) => state.userData.value);
  const activeCompanyProfile = useSelector((state) => state.activeCompanyProfile.value);
  const answersForm = useSelector((state) => state.answersForm.value);
  const companyInfo = useSelector((state) => state.companyInfo.value);
  const generatedContentCompany = useSelector((state) => state.generatedContent.value);
  const [tempCompanyInfo, setTempCompanyInfo] = useState({...generatedContentCompany});

  useEffect(() => {
    setTempCompanyInfo({...generatedContentCompany});
  }, [generatedContentCompany]);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  //#region
  const handlerSetTextContentCompany = (answersFormData) => {
    dispatch(setTextContentCompany(answersFormData));
  };
  
  const handlerSetReGenerateContent = (answersFormData) => {
    dispatch(setReGenerateContent(answersFormData));
  };

  const handlerSetRestCounterGenerateContent = (titleCard) => {
    dispatch(setRestCounterGenerateContent(titleCard));
  };

  const handlerSetShowAlert = (infoAlert) => {
    dispatch(setShowAlert(infoAlert));
  };
  
  const openAlert = (typeAlert, textAlert) => {
    const object = {
      typeAlert: typeAlert,
      textAlert: textAlert
    };
  
    handlerSetShowAlert(object);
  };

  const handlerSendMsm = (newData) => {
    SendSocket({
      action: 'send-message',
      data: {
        id: activeCompanyProfile.id,
        user: userData.user,
        message: {
          type: 'UPDATE',
          table: 'company-info',
          sendFromAdminView: true,
          sendFromCollaboratorView: false,
          companyOwner: userData.user,
          idCompanyOwner: activeCompanyProfile.id,
          userSendUpdate: userData.user,
          isAdmin: !activeCollaboratorMode.collaboratorMode,
          data: {
            user: companyInfo.user,
            email: companyInfo.email,
            answerForm: companyInfo.answerForm,
            generatedContent: newData,
            counterUseForm: companyInfo.counterUseForm,
            counterUseGenerateContent: companyInfo.counterUseGenerateContent,
            logo: companyInfo.logo,
          }
        }
      }
    });
  };

  const [editViewInfo, setEditViewInfo] = useState({
    title: '',
    content: ''
  });

  const updateCompanyInfo = async (oldData, newData, updateCounter, updateCounterForm) => {
    openAlert("loading", "Making changes");
    const dataUser = {
      email: userData.email,
      password: userData.password,
      token: userData.token,
    };
  
    const sendData = {
      id: activeCompanyProfile.id,
      generatedContent: newData,
      counterUseGenerateContent: updateCounter,
      counterUseForm: updateCounterForm
    };

    const responseUpdateCompanyInfo = await putRequestMeantto('company-info', dataUser, sendData);
    if (responseUpdateCompanyInfo.status === 200){
      if (responseUpdateCompanyInfo.data.status === true) {
        openAlert("success", "Success");

        handlerSendMsm(newData);
      } else {
        handlerSetTextContentCompany(oldData);
        openAlert("danger", "Error, try again");
      }
    } else {
      handlerSetTextContentCompany(oldData);
      openAlert("danger", "Error, try again");
    }
  }

  const updateDataInfo = () => {
    handlerSetTextContentCompany(editViewInfo);

    const updatingData = tempCompanyInfo;
    updatingData[editViewInfo.title] = editViewInfo.content;

    closeModal();
    updateCompanyInfo(generatedContentCompany, updatingData, companyInfo.counterUseGenerateContent, companyInfo.counterUseForm);
  };

  const setDataInfo = (e) => {
    setEditViewInfo((prevInfo) => ({
      ...prevInfo,
      content: e
    }));
  };

  const viewEdit = (e) => {
    const editData = generatedContentCompany[e];
    setEditViewInfo({
      title: e,
      content: editData
    });

    openModal();
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditViewInfo({
      title: '',
      content: ''
    });
  };
  //#endregion

  const [reGeneratedContent, setReGeneratedContent] = useState(false);
  const [titleReGeneratedContent, setTitleReGeneratedContent] = useState('');

  const returnAnswersFormUpdated = (title, newDataGenerated) => {
    let companyInfo_UpdateNewData = tempCompanyInfo;
    companyInfo_UpdateNewData[title] = newDataGenerated;

    return companyInfo_UpdateNewData;
  };

  const handlerUpdateCounterGenerateContent = (titleCard) => {
    const newJson = {
      ...companyInfo.counterUseGenerateContent,
      [titleCard]: companyInfo.counterUseGenerateContent[titleCard] - 1
    };

    return newJson;
  };

  const requestoToReGenerateContent = async (prompt, titleCard) => {
    const responseMeanttoGenerateNewPaletteColor = await generateContentMeantto(userData.email, activeCompanyProfile.id, "company profile",prompt);

    if (responseMeanttoGenerateNewPaletteColor.status === 200) {
      const data = responseMeanttoGenerateNewPaletteColor.data;

      if (data.body !== "") {
        const replaceQuotes = JSON.parse(data.body);

        const newData = returnAnswersFormUpdated(titleCard, replaceQuotes[titleCard]);
        let newDataJSON = {
          titleCard: titleCard, 
          content: replaceQuotes[titleCard]
        };
        handlerSetReGenerateContent(newDataJSON);

        const updateCounter = handlerUpdateCounterGenerateContent(titleCard);
        updateCompanyInfo(tempCompanyInfo, newData, updateCounter, companyInfo.counterUseForm);
        handlerSetRestCounterGenerateContent(titleCard);

        handlerSendMsm();

        setReGeneratedContent(false);
        setTitleReGeneratedContent('');
      } else {
        openAlert("danger", "Error, try again");
      }
    } else {
      openAlert("danger", "Error, try again");
    }
  };
  
  const handlerRegenerateContent = (titleCard) => {
    setReGeneratedContent(true);
    setTitleReGeneratedContent(titleCard);
    openAlert("loading", "Generating content");

    const getPromptReGenerateContent = reGenerateContent(answersForm, titleCard);
    requestoToReGenerateContent(getPromptReGenerateContent, titleCard);
  };

  const hanlderBuildBodyCard = (name) => {
    const descriptionTooltip = valuesForTooltip.find((x) => x.name === name);

    const content = typeof generatedContentCompany[name] === 'object'
    ? JSON.stringify(generatedContentCompany[name])
    : generatedContentCompany[name];

    return (<>
      <div className="title-card mb-1">
        <span
          className="title-card-company"
        >
          {name}
          <InfoOutlinedIcon
            className="text-muted btn-tooltip"
            data-tooltip-id="tooltip"
            data-tooltip-html={`${descriptionTooltip.description}<br/><br/>Remaining attempts: ${companyInfo?.counterUseGenerateContent?.[name]}`}
          />
        </span>
        {content !== "" && (
          <>
            {!reGeneratedContent && (
              <div className="action-buttons-container-card">
                {companyInfo?.counterUseGenerateContent?.[name] > 0 && (
                  <button
                    type="button"
                    onClick={() => handlerRegenerateContent(name)}
                    disabled={companyInfo?.counterUseGenerateContent?.[name] <= 0}
                    >
                    <CachedRoundedIcon className="icon-primary"/>
                  </button>
                )}
                <button
                  type="button"
                  onClick={() => viewEdit(name)}
                >
                  <EditRoundedIcon className="icon-primary"/>
                </button>
              </div>
            )}
          </>
        )}
      </div>
      <span>
        {titleReGeneratedContent === name ? (
          <GeneratingContentCard text={name} />
          ) : (
            <>
            {content !== "" ? (
              <Fade duration={600} opposite>
                {content}
              </Fade>
            ) : (
              <ReturnSkeleton />
            )}
          </>
        )}
      </span>
    </>);
  };

  return (
    <>
      <div className="firts-container">
        <div className="mission-vision-container">
          <div className="mission-container">
            {hanlderBuildBodyCard('Brand Mission')}
          </div>
          <div className="vision-container">
            {hanlderBuildBodyCard('Brand Vision')}
          </div>
          <div className="value-statement-container">
            {hanlderBuildBodyCard('Value Statement')}
          </div>
        </div>
      </div>
        <br />
      <div className="second-container">
        <div className="mb-1 w-100">
          <h3>Audience</h3>
        </div>
        <div className="market-audience-container">
          <div className="target-audience-container">
            {hanlderBuildBodyCard('Target Audience')}
          </div>
          <div className="martket-gap-container">
            {hanlderBuildBodyCard('Market Gap')}
          </div>
        </div>
      </div>
        <br />
      <div className="third-container">
        <div className="mb-1 w-100">
          <h3>Company</h3>
        </div>
        <div className="benefit-proposition-statement-container">
          <div className="ultimate-benefit-container">
            {hanlderBuildBodyCard('Unique Value Proposition (UVP)')}
          </div>
          <div className="unique-value-proposition-container">
            {hanlderBuildBodyCard('Ultimate Benefit')}
          </div>
        </div>
      </div>

      <Tooltip id="tooltip" place={esPantallaMediana ? 'bottom-start' : 'right'} className="custom-tooltip"/>

      <Modal
        open={isModalOpen}
        showCloseIcon={false}
        blockScroll={true}
        onClose={() => closeModal()}
        classNames={{
          overlay: 'customOverlay',
          modal: 'customModal',
        }}
        center
      >
        <div className="modal-body">
          <div className="modal-content">
            <h2 className="title-modal">
              Edit
            </h2>
            <textarea
              id={editViewInfo.title}
              rows={10}
              type="text"
              style={{ width: '100%', resize: 'none' }}
              name={editViewInfo.title}
              className={`input-field field-form ${editViewInfo.content === "" && 'invalid-answer'}`}
              defaultValue={editViewInfo.content}
              onChange={(e) => setDataInfo(e.target.value)}
            />

          </div>
          <div className="action-btn-form">
            <button
              className="two-btn-modal"
              onClick={() => closeModal()}>
              Close
            </button>
            <button
              className="two-btn-modal"
              onClick={() => updateDataInfo()}>
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

Information.propTypes = {
  SendSocket: PropTypes.func.isRequired,
};

export default memo(Information);
