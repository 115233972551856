import React from 'react';
import './App.scss';
import Web from './components/web/index/index';

function App() {
  return (
    <Web />
  );
}

export default App;
