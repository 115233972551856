import { setAnswersForm } from '../../../reducers/answersForm';
import { setGeneratedContent } from '../../../reducers/generatedContent';
import { setCompanyInfo, resetCompanyInfo } from '../../../reducers/companyInfo';
import { setPostsCompany, resetPostsCompany } from '../../../reducers/postsCompany';
import { setCompanyFiles, resetCompanyFiles } from '../../../reducers/companyFiles';
import { postRequest } from '../../services/requestLib';
// import { setLoadingCompanyInformation, hideLoadingCompanyInformation } from "../../../reducers/loadingCompanyInformation";

const RequestCompanyAdmin = async (dispatch, email, password, token, idCompany) => {
  const userInfo = {
    email: email,
    password: password,
    token: token,
    id: idCompany,
    typeAccess: 1,
  };

  dispatch(resetCompanyInfo());
  dispatch(resetPostsCompany());
  dispatch(resetCompanyFiles());

  // dispatch(hideLoadingCompanyInformation());

  try {
    const requestCompanyInfo = await postRequest('company-info', userInfo);
    if (requestCompanyInfo.status === 200) {

      dispatch(setCompanyInfo(requestCompanyInfo.data.data));
      dispatch(setGeneratedContent(requestCompanyInfo.data.data.generatedContent));
      dispatch(setAnswersForm(requestCompanyInfo.data.data.answerForm));

      const requestPostsCompany = await postRequest('company-posts', userInfo);
      if (requestPostsCompany.status === 200) {
        dispatch(setPostsCompany(requestPostsCompany.data.data));
      }

      const requestCompanyFiles = await postRequest('company-file', userInfo);
      if (requestCompanyFiles.status === 200) {
        dispatch(setCompanyFiles(requestCompanyFiles.data.data));
      }
    }

    // dispatch(setLoadingCompanyInformation());
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export default RequestCompanyAdmin;
