import React from 'react'
import Lottie from "lottie-react";
import Team from './team.json';
import Text from '../share-components/text/text';

const styleLottieAnim = {
  height: 250,
};

function CollaboratorPlan() {
  return (
    <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
      <Lottie animationData={Team} loop={true} autoPlay={true} style={styleLottieAnim} />
      <Text type="title" inyectClass="mt-4">
        Select any company
      </Text>
    </div>
  )
}

export default CollaboratorPlan;
