import React, { memo, useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Skeleton from 'react-loading-skeleton';
import 'react-responsive-modal/styles.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { Fade } from "react-reveal";
import { useSelector } from 'react-redux';
import { valuesForTooltip } from '../../../../user/home/companyProfile/information/values';

const ReturnSkeleton = () => {
  return (
    <Fade duration={600} opposite>
      <Skeleton count={5} />
    </Fade>
  );
};

function Information() {
  const companyInfo = useSelector((state) => state.companyInfo.value);
  const generatedContentCompanySlice = useSelector((state) => state.generatedContentAsCollaborator.value);
  const [generatedContentCompany, setGeneratedContentCompany] = useState({...generatedContentCompanySlice});

  useEffect(() => {
    setGeneratedContentCompany({...generatedContentCompanySlice});
  }, [generatedContentCompanySlice]);

  const hanlderBuildBodyCard = (name) => {
    const descriptionTooltip = valuesForTooltip.find((x) => x.name === name);

    const content = typeof generatedContentCompany[name] === 'object'
    ? JSON.stringify(generatedContentCompany[name])
    : generatedContentCompany[name];

    return (<>
      <div className="title-card mb-1">
        <span
          className="title-card-company"
        >
          {name}
          <InfoOutlinedIcon
            className="text-muted btn-tooltip"
            data-tooltip-id="tooltip"
            data-tooltip-html={`${descriptionTooltip.description}<br/><br/>Remaining attempts: ${companyInfo?.counterUseGenerateContent?.[name]}`}
          />
        </span>
      </div>
      <span>
        {content !== "" ? (
          <Fade duration={600} opposite>
            {content}
          </Fade>
        ) : (
          <ReturnSkeleton />
        )}
      </span>
    </>);
  };

  return (
    <>
      <div className="firts-container">
        <div className="mission-vision-container">
          <div className="mission-container">
            {hanlderBuildBodyCard('Brand Mission')}
          </div>
          <div className="vision-container">
            {hanlderBuildBodyCard('Brand Vision')}
          </div>
          <div className="value-statement-container">
            {hanlderBuildBodyCard('Ultimate Benefit')}
          </div>
        </div>
      </div>
        <br />
      <div className="second-container">
        <div className="mb-1 w-100">
          <h3>
            Audience
          </h3>
        </div>
        <div className="market-audience-container">
          <div className="target-audience-container">
            {hanlderBuildBodyCard('Target Audience')}
          </div>
          <div className="martket-gap-container">
            {hanlderBuildBodyCard('Market Gap')}
          </div>
        </div>
      </div>
        <br />
      <div className="third-container">
        <div className="mb-1 w-100">
          <h3>
            Company
          </h3>
        </div>
        <div className="benefit-proposition-statement-container">
          <div className="ultimate-benefit-container">
            {hanlderBuildBodyCard('Unique Value Proposition (UVP)')}
          </div>
          <div className="unique-value-proposition-container">
            {hanlderBuildBodyCard('Value Statement')}
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(Information);
