export const questionsCompany = {
  id: 1,
  nameSection: 'Company details',
  questions: [
    {
      id: 1,
      question: "Please select your business type",
      description: 'Choose the category that best represents the type of business you have.',
      type: 'select',
      options: ['Start Up', 'Brick and Mortar', 'Influencer', 'Blogger', 'Freelancer', 'Ecommerce Business'],
      active: true,
    },
    /*
    {
      id: 2,
      question: 'What is your Business Name?',
      description: '',
      example: 'Nike, Coca-Cola, Toyota, Microsoft, Apple etc',
      type: 'text',
      active: true,
    },
    */
    {
      id: 3,
      question: "Choose your industry type",
      description: 'Select your industry category to indicate the type of business.',
      type: 'select',
      options: ['Tech', 'Media & PR', 'E-commerce and Retail', 'Real Estate', 'Finance and Fin Tech', 'Accounting', 'Food & Beverage', 'Fitness & Wellness', 'Non-profit', 'Medicine & Psychology', 'Law', 'Fashion & Apparel', 'Cosmetic', 'Haircare', 'Beauty salon', 'Health and Wellness', 'Teaching', 'Coaching', 'Manufacturing & Construction', 'Tourism & Travel'],
      active: true,
    },
    {
      id: 4,
      question: "What product or service do you offer and what makes it unique?",
      description: 'Describe what sets your product or service apart from competitors. Consider innovation, technology, customer service, price, or any other factors that make you unique.',
      example: 'QuickClean - Ultrasonic Cleaning Device uses ultrasonic technology for deep, chemical-free cleaning. We are an eco-friendly company that eliminates the need for harsh chemicals, supporting a healthier environment. We created a compact design for easy use anywhere, anytime. QuickClean is Affordable, and priced for accessibility, ensuring advanced cleaning is within reach for all households.',
      type: 'text',
      active: true,
    },
    {
      id: 5,
      question: "Select your brand personality",
      description: 'Select the personality that best represents your brand’s characteristics and values.',
      type: 'select',
      options: ['Sincerity', 'Excitement', 'Competence', 'Sophistication', 'Ruggedness'],
      active: true,
    },
    {
      id: 6,
      question: "Who is your gender target?",
      description: 'Who is your product or service primarily designed for in terms of gender?',
      type: 'select',
      options: ['Female', 'Male', 'All'],
      active: true,
    },
    {
      id: 7,
      question: "Who is your target age?",
      description: 'Please indicate the age group you are targeting for your product or service.',
      type: 'range',
      options: ['0 to 9', '10 to 19', '20 to 29', '30 to 39', '40 to 49'],
      keyword: 'Age',
      active: true,
    },
    {
      id: 8,
      question: 'Give a brief description of your target audience',
      description: 'Describe your ideal customer. Indicate attributes related to personality traits, interests, attitudes or beliefs, and lifestyle.',
      example: 'Zenith Active Wear is designed for active individuals aged 18 to 45 who value comfort, style, and sustainability in their fitness gear. Our products cater to both men and women who are committed to a healthy lifestyle, offering high-performance wear that meets the demands of any workout or outdoor activity. Emphasizing eco-friendly practices, Zenith ensures inclusivity and quality for all, making it the go-to brand for modern athletes.',
      type: 'text',
      active: true,
    },
    {
      id: 10,
      question: 'Describe your brand objective',
      description: 'Summarize the main goal or purpose of your brand in a brief description.',
      example: 'Our goal is to connect coffee lovers with the rich stories behind each blend, fostering a community of informed consumers who value sustainability, quality, and the art of coffee making. We are committed to environmental stewardship and social responsibility, ensuring that every cup not only tastes good but also does good.',
      type: 'text',
      active: true,
    },
    {
      id: 11,
      question: "Please select the preferred typography style",
      description: 'Choose the typography style that best represents your brand’s visual identity and design preferences.',
      type: 'select',
      options: ['Minimalist', 'retro', 'upscale'],
      active: true,
    },
    /*
    {
      id: 12,
      question: "Which social media platforms would you like to focus on?",
      description: 'Select the social media platforms on which you want to prioritize your brand presence and engagement. Choose your preferred platforms.',
      type: 'multi-select',
      options: ['Facebook', 'Instagram', 'Linkedin', 'Tiktok', 'Twitter', 'YouTube'],
      classCards: 'social_cards',
      active: false,
    },
    {
      id: 13,
      question: 'Describe your product/service and what make it’s unique',
      description: 'Provide a brief description of your product or service, highlighting its unique features and qualities that set it apart from others in the market.',
      example: 'I create at home workout videos for working women that make working out easy and accessible.',
      type: 'text',
      active: true,
    },
    */
    {
      id: 14,
      question: 'Describe your brand tone',
      description: '',
      type: 'checkbox',
      options: ['Professional', 'Friendly', 'Inspirational', 'Authoritative', 'Witty_Humorous', 'Innovative', 'Casual', 'Passionate', 'Educational', 'Sophisticated'],
      classCards: 'objetive_cards',
      active: true,
    },
  ]
};

export const generatePrompt = (dataForm) => {
  const WhatIsYourBusinessType = dataForm[`question${1}`];
  const WhatIsYourBusinessName = dataForm[`question${2}`];
  const ChooseYourIndustryType = dataForm[`question${3}`];
  const DescriptionOfProductsAndServices = dataForm[`question${4}`];
  const SelectYourBrandPersonality = dataForm[`question${5}`];
  const WhoIsYourGenderTarget = dataForm[`question${6}`];
  const WhoIsYourTargetAge = dataForm[`question${7}`];
  const GiveABriefDescriptionOfTargetAudience = dataForm[`question${8}`];
  const DescribeYourBrandObjective = dataForm[`question${10}`];
  const SelectThePreferredTypographyStyle = dataForm[`question${11}`];

  const NumberPaletteColorAboutPlanService = 2;
  const NumberTaglinesAboutPlanService = 2;

  return `I run a ${WhatIsYourBusinessType} called ${WhatIsYourBusinessName} that specializes in ${ChooseYourIndustryType}, including ${DescriptionOfProductsAndServices} with an ${SelectYourBrandPersonality} brand personality and my target audience is ${WhoIsYourGenderTarget} genders between ${WhoIsYourTargetAge} who ${GiveABriefDescriptionOfTargetAudience}.
    Your mission is to assist me in marketing and branding my business, amplifying my brand awareness, and boosting engagement, and sales by creating the following items in this exact order that I will give to you.
    * Target Audience: I want you to build a profile of my target audience, and explain in detail why and how my product or service serves them.
    * Market Gap: Identify the void in the ${ChooseYourIndustryType} that ${WhatIsYourBusinessName}  can effectively fill.
    * Ultimate Benefit: Explain the maximum benefit a customer derives from ${WhatIsYourBusinessName} products.
    * Brand vision: Develop a brand vision statement. 
    * Brand mission: Develop a brand mission statement. 
    * Value Statement: Develop a value statement for ${WhatIsYourBusinessName}.
    * Unique Value Proposition (UVP): Develop a detailed Unique value proposition for ${WhatIsYourBusinessName}.
    * Taglines: For brand aesthetics, generate ${NumberTaglinesAboutPlanService} tagline suggestions that resonate with ${WhatIsYourBusinessName} ethos of ${DescribeYourBrandObjective}.
    * Color Palette: Suggest ${NumberPaletteColorAboutPlanService} color palette, each one with 3 color suggestions with corresponding hex# suitable for ${WhatIsYourBusinessName}.
    * Typography: Suggest style ${SelectThePreferredTypographyStyle} typography styles that match our brand.
    For Target Audience, Market Gap, Ultimate Benefit, Brand vision, Brand mission, Value Statement, Unique Value Proposition (UVP) generate ONLY TEXT and for Taglines, Color Palette, Typography are array, generate content type object with the next format {"id": 1, content: "", active: false} and insert the object into its corresponding attribute, id as autonumeric, content as generated content and active as false, for Typography content should be contain only name the font suggested and Color Palette content content should be contain a array with colors #HEX.
    Assign each generated content without special characters or single quote, following JSON as a value and return this same JSON with the generated information and dont modify this JSON, i want key - values, DONT'T INCLUDE SPECIAL CHARACTERS at the beginning and at the end of the JSON object. {"Target Audience": "","Market Gap": "","Ultimate Benefit": "","Brand Vision": "","Brand Mission": "","Value Statement": "","Unique Value Proposition (UVP)": "","Taglines": [],"Color Palette": [],"Typography": []}`;
};

/* Re generate content - Company profile */
const returnDescription = (dataForm, specificValue) => {
  const WhatIsYourBusinessName = dataForm[`question${2}`];
  const ChooseYourIndustryType = dataForm[`question${3}`];
  const DescribeYourBrandObjective = dataForm[`question${10}`];
  const SelectThePreferredTypographyStyle = dataForm[`question${11}`];

  const NumberPaletteColorAboutPlanService = 2;
  const NumberTaglinesAboutPlanService = 2;

  switch (specificValue) {
    case 'Target Audience':
      return `* Target Audience: I want you to build a profile of my target audience, and explain in detail why and how my product or service serves them.`
    case 'Market Gap':
      return `* Market Gap: Identify the void in the ${ChooseYourIndustryType} that ${WhatIsYourBusinessName}  can effectively fill.`
    case 'Ultimate Benefit':
      return `* Ultimate Benefit: Explain the maximum benefit a customer derives from ${WhatIsYourBusinessName} products.`
    case 'Brand Vision':
      return `* Brand Vision: Develop a brand vision statement.`
    case 'Brand Mission':
      return `* Brand Mission: Develop a brand mission statement.`
    case 'Value Statement':
      return `* Value Statement: Develop a value statement for ${WhatIsYourBusinessName}.`
    case 'Unique Value Proposition (UVP)':
      return `* Unique Value Proposition (UVP): Develop a detailed Unique value proposition for ${WhatIsYourBusinessName}.`
    case 'Taglines':
      return `* Taglines: For brand aesthetics, generate ${NumberTaglinesAboutPlanService} tagline suggestions that resonate with ${WhatIsYourBusinessName} ethos of ${DescribeYourBrandObjective}.`
    case 'Color Palette':
      return `* Color Palette: Suggest ${NumberPaletteColorAboutPlanService} color palette, each one with 3 color suggestions with corresponding hex# suitable for ${WhatIsYourBusinessName}.`
    case 'Typography':
      return `* Typography: Suggest style ${SelectThePreferredTypographyStyle} typography styles that match our brand.`
    default:
      break;
  }
};

const returnAttrJSON = (specificValue) => {
  switch (specificValue) {
    case 'Target Audience':
      return `"Target Audience": ""`
    case 'Market Gap':
      return `"Market Gap": ""`
    case 'Ultimate Benefit':
      return `"Ultimate Benefit": ""`
    case 'Brand Vision':
      return `"Brand Vision": ""`
    case 'Brand Mission':
      return `"Brand Mission": ""`
    case 'Value Statement':
      return `"Value Statement": ""`
    case 'Unique Value Proposition (UVP)':
      return `"Unique Value Proposition (UVP)": ""`
    case 'Taglines':
      return `"Taglines": []`
    case 'Color Palette':
      return `"Color Palette": []`
    case 'Typography':
      return `"Typography" : []`
    default:
      break;
  }
};

export const reGenerateContent = (dataForm, especificContent) => {
  const WhatIsYourBusinessType = dataForm[`question${1}`];
  const WhatIsYourBusinessName = dataForm[`question${2}`];
  const ChooseYourIndustryType = dataForm[`question${3}`];
  const DescriptionOfProductsAndServices = dataForm[`question${4}`];
  const SelectYourBrandPersonality = dataForm[`question${5}`];
  const WhoIsYourGenderTarget = dataForm[`question${6}`];
  const WhoIsYourTargetAge = dataForm[`question${7}`];
  const GiveABriefDescriptionOfTargetAudience = dataForm[`question${8}`];

  const buildPrompt = `I run a ${WhatIsYourBusinessType} called ${WhatIsYourBusinessName} that specializes in ${ChooseYourIndustryType}, including ${DescriptionOfProductsAndServices} with an ${SelectYourBrandPersonality} brand personality and my target audience is ${WhoIsYourGenderTarget} genders between ${WhoIsYourTargetAge} who ${GiveABriefDescriptionOfTargetAudience}.
    Your mission is to assist me in marketing and branding my business, amplifying my brand awareness, and boosting engagement, and sales by creating the following items in this exact order that I will give to you.
    ${returnDescription(dataForm, especificContent)}
    ${(especificContent === 'Typography' || especificContent === 'Color Palette' || especificContent === 'Taglines') ? `${especificContent} is array, generate content type object with the next format {'id': 1, content: '', active: false} and insert the object into its corresponding attribute, id as autonumeric, content as generated content and active as false, for Typography content should be contain only name the font suggested and Color Palette content content should be contain a array with colors #HEX.` : ''}
    Assign each generated content to the following JSON as a value and return this same JSON with the generated information and dont modify this JSON, i want key - value, DONT'T INCLUDE SPECIAL CHARACTERS at the beginning and at the end of the JSON object and stringify format without backslash.
    {
      ${returnAttrJSON(especificContent)}
    }`;

    return buildPrompt;
};

/* Re generate content - Brand kit information */
export const generateNewColorPalette = (dataForm) => {
  const WhatIsYourBusinessType = dataForm[`question${1}`];
  const WhatIsYourBusinessName = dataForm[`question${2}`];
  const ChooseYourIndustryType = dataForm[`question${3}`];
  const DescriptionOfProductsAndServices = dataForm[`question${4}`];
  const SelectYourBrandPersonality = dataForm[`question${5}`];
  const WhoIsYourGenderTarget = dataForm[`question${6}`];
  const WhoIsYourTargetAge = dataForm[`question${7}`];
  const GiveABriefDescriptionOfTargetAudience = dataForm[`question${8}`];

  const NumberPaletteColorAboutPlanService = 2;

  return `I run a ${WhatIsYourBusinessType} called ${WhatIsYourBusinessName} that specializes in ${ChooseYourIndustryType}, including ${DescriptionOfProductsAndServices} with an ${SelectYourBrandPersonality} brand personality and my target audience is ${WhoIsYourGenderTarget} genders between ${WhoIsYourTargetAge} who ${GiveABriefDescriptionOfTargetAudience}.
    Your mission is to assist me in marketing and branding my business, amplifying my brand awareness, and boosting engagement, and sales by creating the following items in this exact order that I will give to you.
    * Color Palette: Suggest ${NumberPaletteColorAboutPlanService} color palette, each one with 3 color suggestions with corresponding hex# suitable for ${WhatIsYourBusinessName}.
    For Color Palette is array, generate content type object with the next format {'id': 1, content: '', active: false} and insert the object into its corresponding attribute, id as autonumeric, content as generated content and active as false, for Color Palette content content should be contain a array with colors #HEX.
    Assign each generated content to the following JSON as a value and return this same JSON with the generated information and dont modify this JSON, i want key - value, DONT'T INCLUDE SPECIAL CHARACTERS at the beginning and at the end of the JSON object and stringify format without backslash.
    {
      "Color Palette": [],
    }`;
};

export const generateNewTaglines = (dataForm) => {
  const WhatIsYourBusinessType = dataForm[`question${1}`];
  const WhatIsYourBusinessName = dataForm[`question${2}`];
  const ChooseYourIndustryType = dataForm[`question${3}`];
  const DescriptionOfProductsAndServices = dataForm[`question${4}`];
  const SelectYourBrandPersonality = dataForm[`question${5}`];
  const WhoIsYourGenderTarget = dataForm[`question${6}`];
  const WhoIsYourTargetAge = dataForm[`question${7}`];
  const GiveABriefDescriptionOfTargetAudience = dataForm[`question${8}`];
  const DescribeYourBrandObjective = dataForm[`question${10}`];
  const NumberTaglinesAboutPlanService = 2;

  return `I run a ${WhatIsYourBusinessType} called ${WhatIsYourBusinessName} that specializes in ${ChooseYourIndustryType}, including ${DescriptionOfProductsAndServices} with an ${SelectYourBrandPersonality} brand personality and my target audience is ${WhoIsYourGenderTarget} genders between ${WhoIsYourTargetAge} who ${GiveABriefDescriptionOfTargetAudience}.
    Your mission is to assist me in marketing and branding my business, amplifying my brand awareness, and boosting engagement, and sales by creating the following items in this exact order that I will give to you.
    * Taglines: For brand aesthetics, generate ${NumberTaglinesAboutPlanService} tagline suggestions that resonate with ${WhatIsYourBusinessName} ethos of ${DescribeYourBrandObjective}.
    For Taglines is array, generate content type object with the next format {'id': 1, content: '', active: false} and insert the object into its corresponding attribute, id as autonumeric, content as generated content and active as false, for Color Palette content content should be contain a array with colors #HEX.
    Assign each generated content to the following JSON as a value and return this same JSON with the generated information and dont modify this JSON, i want key - value, DONT'T INCLUDE SPECIAL CHARACTERS at the beginning and at the end of the JSON object and stringify format without backslash.
    {
      "Taglines": [],
    }`;
};

export const generateNewTypography = (dataForm) => {
  const WhatIsYourBusinessType = dataForm[`question${1}`];
  const WhatIsYourBusinessName = dataForm[`question${2}`];
  const ChooseYourIndustryType = dataForm[`question${3}`];
  const DescriptionOfProductsAndServices = dataForm[`question${4}`];
  const SelectYourBrandPersonality = dataForm[`question${5}`];
  const WhoIsYourGenderTarget = dataForm[`question${6}`];
  const WhoIsYourTargetAge = dataForm[`question${7}`];
  const GiveABriefDescriptionOfTargetAudience = dataForm[`question${8}`];
  const SelectThePreferredTypographyStyle = dataForm[`question${11}`];

  const NumberPaletteColorAboutPlanService = 2;

  return `I run a ${WhatIsYourBusinessType} called ${WhatIsYourBusinessName} that specializes in ${ChooseYourIndustryType}, including ${DescriptionOfProductsAndServices} with an ${SelectYourBrandPersonality} brand personality and my target audience is ${WhoIsYourGenderTarget} genders between ${WhoIsYourTargetAge} who ${GiveABriefDescriptionOfTargetAudience}.
    Your mission is to assist me in marketing and branding my business, amplifying my brand awareness, and boosting engagement, and sales by creating the following items in this exact order that I will give to you.
    * Typography: Suggest ${NumberPaletteColorAboutPlanService} ${SelectThePreferredTypographyStyle} typography styles that match our brand.
    For Typography is array, generate content type object with the next format {'id': 1, content: '', active: false} and insert the object into its corresponding attribute, id as autonumeric, content as generated content and active as false, for Typography content should be contain only name the font suggested.
    Assign each generated content to the following JSON as a value and return this same JSON with the generated information and dont modify this JSON, i want key - value, DONT'T INCLUDE SPECIAL CHARACTERS at the beginning and at the end of the JSON object and stringify format without backslash.
    {
      "Typography": []
    }`;
};
