/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const showAlert = createSlice({
  name: 'showAlert',
  initialState: {
    value: {
      show: false,
      typeAlert: "",
      textAlert: ""
    },
  },
  reducers: {
    setShowAlert: (state, action) => {
      state.value.show = true;
      state.value.typeAlert = action.payload.typeAlert;
      state.value.textAlert = action.payload.textAlert;
    },
    setHideAlert: (state, action) => {
      state.value.show = false;
      state.value.typeAlert = "";
      state.value.textAlert = "";
    },
  }
});

export const {
  setShowAlert, setHideAlert
} = showAlert.actions;

export default showAlert.reducer;
