/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const activeCompanyProfileAsCollaborator = createSlice({
  name: 'activeCompanyProfileAsCollaborator',
  initialState: {
    value: {
      id: "",
      name: "",
      location: "",
      infoCompanyGenerate: false,
      order: 0,
      active: false
    },
  },
  reducers: {
    setActiveCompanyProfile: (state, action) => {
      state.value.id = action.payload.id;
      state.value.name = action.payload.name;
      state.value.location = action.payload.location;
      state.value.infoCompanyGenerate = action.payload.infoCompanyGenerate;
      state.value.order = action.payload.order;
      state.value.active = action.payload.active;
    },
  }
});

export const {
    setActiveCompanyProfile
} = activeCompanyProfileAsCollaborator.actions;

export default activeCompanyProfileAsCollaborator.reducer;
