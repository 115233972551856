/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const newPost = createSlice({
  name: 'newPost',
  initialState: {
    value: {
      createdate: "",
      publishDate: "",
      publishHour: "",
      alertNotification: false,
      alertNotificationDate: "",
      alertNotificationHour: "",
      id: "",
      companyProfileId: "",
      contentIdea: "",
      hashtags: "",
      postText: "",
      socialNetwork: "",
      imgSocialNetwork: "",
      typePost: "",
      groupPost: "",
      urlFile: "",
      urlThumbnailFile: "",
      haveFile: false,
    },
  },
  reducers: {
    setNewPost: (state, action) => {
      state.value.createdate = action.payload.createdate;
      state.value.id = action.payload.id;
      state.value.companyProfileId = action.payload.companyProfileId;
      state.value.hashtags = action.payload.hashtags;
      state.value.contentIdea = action.payload.contentIdea;
      state.value.postText = action.payload.postText;
      state.value.socialNetwork = action.payload.socialNetwork;
      state.value.imgSocialNetwork = action.payload.imgSocialNetwork;
      state.value.typePost = action.payload.typePost;
      if (state.value.typePost !== "Specific Post") {
        state.value.groupPost = action.payload.groupPost;
      }
    },
    setHahstags: (state, action) => {
      state.value.hashtags = action.payload;
    },
    setDescription: (state, action) => {
      state.value.postText = action.payload;
    },
    setPublishDate: (state, action) => {
      state.value.publishDate = action.payload;
    },
    setPublishHour: (state, action) => {
      state.value.publishHour = action.payload;
    },
    setAlertNotification: (state, action) => {
      state.value.alertNotification = action.payload;
    },
    setAlertNotificationDate: (state, action) => {
      state.value.alertNotificationDate = action.payload;
    },
    setAlertNotificationHour: (state, action) => {
      state.value.alertNotificationHour = action.payload;
    },
    setImageData: (state, action) => {
      state.value.urlFile = action.payload.url;
      state.value.urlThumbnailFile = action.payload.urlThumbnail;
      state.value.haveFile = true;
    },
    setPostFromCalendar: (state, action) => {
      state.value.createdate = action.payload.createdate;
      state.value.publishDate = action.payload.publishDate;
      state.value.publishHour = action.payload.publishHour;
      state.value.alertNotification = action.payload.alertNotification;
      state.value.alertNotificationDate = action.payload.alertNotificationDate;
      state.value.alertNotificationHour = action.payload.alertNotificationHour;
      state.value.id = action.payload.id;
      state.value.companyProfileId = action.payload.companyProfileId;
      state.value.contentIdea = action.payload.contentIdea;
      state.value.hashtags = action.payload.hashtags;
      state.value.postText = action.payload.postText;
      state.value.socialNetwork = action.payload.socialNetwork;
      state.value.imgSocialNetwork = action.payload.imgSocialNetwork;
      state.value.typePost = action.payload.typePost;
      state.value.groupPost = action.payload.groupPost;
      state.value.urlFile = action.payload.urlFile;
      state.value.urlThumbnailFile = action.payload.urlThumbnailFile;
      state.value.haveFile = action.payload.haveFile;
    },
    resetPost: (state, action) => {
      state.value.createdate = "";
      state.value.publishDate = "";
      state.value.publishHour = "";
      state.value.alertNotification = false;
      state.value.alertNotificationDate = "";
      state.value.alertNotificationHour = "";
      state.value.id = "";
      state.value.companyProfileId = "";
      state.value.contentIdea = "";
      state.value.hashtags = "";
      state.value.postText = "";
      state.value.socialNetwork = "";
      state.value.imgSocialNetwork = "";
      state.value.typePost = "";
      state.value.groupPost = "";
      state.value.urlFile = "";
      state.value.urlThumbnailFile = "";
      state.value.haveFile = false;
    },
  }
});

export const {
  setNewPost, setPublishDate, setPublishHour,
  setAlertNotification, setAlertNotificationDate, setAlertNotificationHour,
  setImageData, setPostFromCalendar, setDescription, setHahstags, resetPost
} = newPost.actions;

export default newPost.reducer;
