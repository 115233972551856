import React, { memo } from 'react';
import '../../../styles/style.scss';
import '../../../styles/compranyProfile/style.scss';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Fade } from "react-reveal";
import Information from './information/information';
import '../../../share-components/modal.scss';
import Text from '../../../share-components/text/text';

function CompanyProfile({ SendSocket }) {
  const activeCompanyProfile = useSelector((state) => state.activeCompanyProfile.value);
  return (
    <div>
      <div className="content-title">
        <Text type="title">
          Company profile
        </Text>
        <img src="./assets/logos/symbol/Simbolo/PNG/morado.png" alt="" />
      </div>
      <div className="content-view">
        <div className="container-company-profile">
          <Text type='title' inyectClass={"color-text-primary"}>{activeCompanyProfile.name}</Text>
          <Fade duration={600} opposite>
            <Information SendSocket={SendSocket} />
          </Fade>
        </div>
      </div>
    </div>
  )
};

CompanyProfile.propTypes = {
  SendSocket: PropTypes.func.isRequired,
};

export default memo(CompanyProfile);
