import React, { memo, useEffect, useState } from 'react';
import Text from '../../../share-components/text/text';
import { useHistory } from 'react-router-dom';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import RangeSlider from 'react-range-slider-input';
import { Fade } from "react-reveal";
import 'react-range-slider-input/dist/style.css';
import { generateContentMeantto } from '../../../services/generatePrompt';
import GenerateContentSuccess from '../../../share-components/modalAlerts/generateContentSuccess/generateContentSuccess';
import { questionsCompany, generatePrompt } from '../../../services/generatePrompt2';
import { setAnswersForm } from "../../../../reducers/answersForm";
import { setGeneratedContent, setNameCompany } from "../../../../reducers/generatedContent";
import { setRestMaxContentGenerateCompany } from "../../../../reducers/statusUser";
import { setFirtsLoginHide } from '../../../../reducers/userData';
import { postRequest, postRequestMeantto, patchRequestMeantto } from '../../../services/requestLib';
import { setShowModal } from '../../../../reducers/alertUserToGenerateContentSuccess';
import { setNewCompany } from '../../../../reducers/companyList';
import { setShowAlert } from '../../../../reducers/showAlert';
import { setActiveCompanyProfile } from '../../../../reducers/activeCompanyProfile';
import { setCompanyInfo, updateCounterUseForm, updateCounterUseGenerateContent } from '../../../../reducers/companyInfo';
import GeneratingContent from '../../../share-components/lottie/generatingContent/generatingContent';
import UseCheckIsFreeUser from '../../../services/checkIsFreeUser/useCheckIsFreeUser';

function Form() {
  const history = useHistory();
  // const answerFormCompany = useSelector((state) => state.answersForm.value);
  const userData = useSelector((state) => state.userData.value);
  const statusUser = useSelector((state) => state.statusUser.value);
  const activeCompanyProfile = useSelector((state) => state.activeCompanyProfile.value);
  const companyList = useSelector((state) => state.companyList.value);
  const companyInfo = useSelector((state) => state.companyInfo.value);
  const [valueRange, setValueRange] = useState([]);
  /* eslint-disable no-unused-vars */
  const [tempCompanyList, setTempCompanyList] = useState([...companyList.companyList]);
  const dataCompanyProfile = companyList.companyList.filter((x) => x.id === activeCompanyProfile.id)[0];
  const isFreePlanUser = UseCheckIsFreeUser(statusUser.servicePlan);

  useEffect(() => {
    setTempCompanyList([...companyList.companyList]);
  }, [companyList]);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const handlerSetAnswersForm = (answersFormData) => {
    dispatch(setAnswersForm(answersFormData));
  };

  const handlerSetNameCompany = (nameCompanyInfo) => {
    dispatch(setNameCompany(nameCompanyInfo));
  };

  const handlerSetGeneratedContent = (infoForm) => {
    dispatch(setGeneratedContent(infoForm));
  };

  const handlersetRestMaxContentGenerateCompany = () => {
    dispatch(setRestMaxContentGenerateCompany());
  }

  const handlerSetFirtsLoginHide = () => {
    dispatch(setFirtsLoginHide());
  };

  const handlerSetShowModalGenerateContentSuccess = () => {
    dispatch(setShowModal());
  };

  const handlerSetNewCompany = (infoCompanyList) => {
    dispatch(setNewCompany(infoCompanyList));
  };

  const handlerSetShowAlert = (infoAlert) => {
    dispatch(setShowAlert(infoAlert));
  };

  const handlerSetActiveCompanyProfile = (infoCompany) => {
    dispatch(setActiveCompanyProfile(infoCompany));
  };

  const handlerSetCompanyInfo = (stateCompanyInfo) => {
    dispatch(setCompanyInfo(stateCompanyInfo));
  };
  
  const hanlderUpdateCounterUseGenerateContent = (updateInfo) => {
    dispatch(updateCounterUseGenerateContent(updateInfo));
  };

  const hanlderUpdateCounterUseForm = (updateInfo) => {
    dispatch(updateCounterUseForm(updateInfo));
  };

  const [validAnswer, setValidAnswer] = useState([]);
  const [formData, setFormData] = useState({
    'question2': activeCompanyProfile.name
  });

  useEffect(() => {
    if (activeCompanyProfile.name !== "") {
      setFormData((prevData) => ({
        ...prevData,
        'question2': activeCompanyProfile.name,
      }));
    }
  }, [activeCompanyProfile]);

  const removeId = (idToRemove) => {
    const updatedIds = validAnswer.filter(id => id !== idToRemove);
    setValidAnswer(updatedIds);
  };

  const openAlert = (typeAlert, textAlert) => {
    const object = {
      typeAlert: typeAlert,
      textAlert: textAlert
    };

    handlerSetShowAlert(object);
  }; 

  const handleInputChangeRange = (event) => {
    const { name, value } = event;
    setValueRange(value);

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const getValueFromForm = (e) => {
    return formData[e];
  };

  const handleInputChange = (event, questionId) => {
    const { value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [`question${questionId}`]: value,
    }));

    removeId(questionId);
  };

  const handleCheckboxChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => {
      const updatedData = { ...prevData };
  
      if (!updatedData[name]) {
        updatedData[name] = [value];
      } else {
        const valueIndex = updatedData[name].indexOf(value);
        if (valueIndex !== -1) {
          updatedData[name].splice(valueIndex, 1);
        } else {
          updatedData[name].push(value);
        }
      }
      return updatedData;
    });

    if (!formData[name] || (Array.isArray(formData[name]) && formData[name].length === 0)) {
      removeId(parseInt(name.replace('question', ''), 10));
    }
  };

  const validateAnswers = () => {
    const unansweredQuestions = questionsCompany.questions
      .filter(question => !formData[`question${question.id}`])
      .map(question => question.id);

    setValidAnswer(unansweredQuestions);
    return unansweredQuestions;
  };  

  const [viewGeneratingContent, setViewGeneratingContent] = useState(false);

  const requestCompanyInfo = async (idCompany) => {
    const userInfo = {
      email: userData.email,
      password: userData.password,
      token: userData.token,
      id: idCompany,
      typeAccess: 1,
    };

    const requestCompanyInfo = await postRequest('company-info', userInfo);
    if (requestCompanyInfo.status === 200) {
      handlerSetCompanyInfo(requestCompanyInfo.data.data);
      handlerSetGeneratedContent(requestCompanyInfo.data.data.generatedContent);
      handlerSetAnswersForm(requestCompanyInfo.data.data.answerForm);
    }
  };

  const updateInfoCompanyGenerate = async () => {
    const idCompanyActive = activeCompanyProfile.id;
    let updatedCompanyList = tempCompanyList.map((company) => {
      if (company.id === idCompanyActive) {
        return { ...company, infoCompanyGenerate: true };
      }
      return company;
    });

    const user = {
      email: userData.email,
      password: userData.password,
      token: userData.token
    };

    const sendData = {
      companyList: updatedCompanyList
    };

    const responseUpdateCompanyList = await patchRequestMeantto('company-list', user, sendData);
    if (responseUpdateCompanyList.status === 200){
      if (responseUpdateCompanyList.data.status === true) {
        handlerSetNewCompany(sendData.companyList);
        handlerSetActiveCompanyProfile(updatedCompanyList.filter((x) => x.id === activeCompanyProfile.id)[0]);
        requestCompanyInfo(activeCompanyProfile.id);
      } else {        
        openAlert("danger", "Error, try again");
      }
    } else {
      openAlert("danger", "Error, try again");
    }
  };

  const handlerUpdateCounterGenerateContent = () => {
    if (activeCompanyProfile.infoCompanyGenerate) {
      const counterFormat = parseInt(companyInfo.counterUseForm, 10);
      if (counterFormat !== 0) {
        if (activeCompanyProfile.infoCompanyGenerate === true) {
          const calculateCounterForm = counterFormat - 1;
          return calculateCounterForm;
        }
      } else {
        return 0;
      }
    } else {
      return parseInt(companyInfo.counterUseForm, 10);
    }
  };

  const handlerUpdateContentCompany = (formData, updateCounterForm) => {
    handlerSetAnswersForm(formData);
    hanlderUpdateCounterUseForm(updateCounterForm);
  };

  const setDataCompany = async (replaceQuotes, updateCounterForm) => {
    try {
      const user = {
        email: userData.email,
        password: userData.password,
        token: userData.token
      };

      const dataCompany = {
        id: activeCompanyProfile.id,
        isFreePlan: isFreePlanUser,
        answerForm: formData,	
        generatedContent: replaceQuotes,
        counterUseForm: updateCounterForm,
        firstCompanyInformation: activeCompanyProfile.infoCompanyGenerate
      };

      const responseCompleteForm = await postRequestMeantto('complete-form-ai', user, dataCompany);
      if (responseCompleteForm.status === 200) {
        handlerSetFirtsLoginHide();
        handlerSetAnswersForm(formData);
        hanlderUpdateCounterUseForm(updateCounterForm);
        handlerUpdateContentCompany(formData, updateCounterForm);
      } else {
        openAlert("danger", "Error, try again");
      }
    } catch (error) {
      openAlert("danger", "Error, try again");
    }
  };

  const buildCounterCompany = (maxCounter, jsonData) => {
    const limitGeneratedContent = {};

    for (const key in jsonData) {
      limitGeneratedContent[`${key}`] = maxCounter;
    }
    hanlderUpdateCounterUseGenerateContent(limitGeneratedContent);
  };

  const handlerProcessAnswer = async () => {
    if (!isFreePlanUser) {
      if (statusUser.maxGenerateContentCompany > 0) {
        const howQuestionsWithoutAnswers = validateAnswers();
        if (howQuestionsWithoutAnswers.length === 0) {
          setViewGeneratingContent(true);  
          const getPromptWithAnswers = generatePrompt(formData);
  
          try {
            const responseMeanttoGenerateContent = await generateContentMeantto(userData.email, activeCompanyProfile.id, "form", getPromptWithAnswers);
            if (responseMeanttoGenerateContent.status === 200) {
              const getBody = responseMeanttoGenerateContent.data.body;
              const parseResponse = JSON.parse(getBody);
              
              if (parseResponse !== "") {              
                if (userData.firtsLogin === false) {
                  handlersetRestMaxContentGenerateCompany();
                }
                
                handlerSetNameCompany({ "Brand Name": activeCompanyProfile.name });
                const replaceQuotes = parseResponse;
                handlerSetGeneratedContent(replaceQuotes);
  
                const updateCounterForm = handlerUpdateCounterGenerateContent();
                setDataCompany(replaceQuotes, updateCounterForm);
                
                if (dataCompanyProfile.infoCompanyGenerate === false) {
                  updateInfoCompanyGenerate();
                  buildCounterCompany(statusUser.maxGenerateContentCompany, replaceQuotes);
                }
  
                setViewGeneratingContent(false);
                handlerSetShowModalGenerateContentSuccess();
              } else {
                setViewGeneratingContent(false);
                
                openAlert("danger", "Error, try again");
              }
            } else {
              setViewGeneratingContent(false);
              openAlert("danger", "Error, try again");
            }
          } catch (error) {
            console.log(error);
            setViewGeneratingContent(false);
            openAlert("danger", "Error, try again");
          }
        }
      } else {
        openAlert("danger", "Error, try again");
      }
    } else {
      if (dataCompanyProfile.infoCompanyGenerate === false) {
        updateInfoCompanyGenerate();
        setDataCompany({}, 0);
        history.push('/');
      }
    }
  };

  return (
    <div>
      <div className="content-title">
        <Text type="title">
          Form AI
        </Text>
        <img src="./assets/logos/symbol/Simbolo/PNG/morado.png" alt="" />
      </div>

      <div className={`container-company-profile content-view ${viewGeneratingContent === true ? 'justify-content-center align-items-center' : ''}`}>
        <Text type='title' inyectClass={"color-text-primary"}>{activeCompanyProfile.name}</Text>
        <div className={`question-list mt-3`}>
          {viewGeneratingContent === false && (
            <Fade duration={600} opposite>
              {questionsCompany.questions.map((question) => (
                question.active === true && (
                  <div key={question.id} className="container-question">
                    <div className="question-div">
                      <span className="text-question">
                        {question.question}
                      </span>
                      <span className="text-description">
                        {question.description}
                      </span>
                      {question.example && (
                        <span className="text-example">
                          <b>Example:</b> {question.example}
                        </span>
                      )}
                    </div>
                    <div className="input-div">
                      {question.type === "text" && (
                        <textarea
                          id={`question${question.id}`}
                          rows={5}
                          style={{ resize: 'none' }}
                          className={`input-field field-form ${validAnswer.includes(question.id) && 'invalid-answer'} ${question.id === 2 && 'input-disabled'}`}
                          onChange={(e) => handleInputChange(e, question.id)}
                          disabled={question.id === 2}
                          value={question.id === 2 ? activeCompanyProfile.name : formData[`question${question.id}`] || ''}
                        />
                      )}
                      {question.type === "select" && (
                        <select
                          id={`question${question.id}`}
                          className={`input-field field-form ${validAnswer.includes(question.id) && 'invalid-answer'}`}
                          onChange={(e) => handleInputChange(e, question.id)}
                          value={formData[`question${question.id}`] || ''}
                        >
                          <option value="null">Select an option</option>
                          {question.options.map((option) => (
                            <option value={option} key={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      )}
                      {question.type === "checkbox" && (
                        question.classCards === 'objetive_cards' && (
                          <div id={`question${question.id}`} className="grid-cards">
                            {question.options.map((option) => (
                              <div
                                className={`card_objetive card-objetive-${option} d-flex justify-content-center align-items-center ml-2 mr-2 ${validAnswer.includes(question.id) && 'invalid-answer'}`}
                                key={option}
                              >
                                <input
                                  type="checkbox"
                                  id={`card-checkbox-${option}`}
                                  name={`question${question.id}`}
                                  className={`card-checkbox-${option}`}
                                  checked={formData[`question${question.id}`]?.includes(option) || false}
                                  value={option}
                                  onChange={(e) => handleInputChange(e, question.id)}
                                />
                                <label
                                  htmlFor={`card-checkbox-${option}`}
                                  className="card-label card-content-label"
                                >
                                  <div className="card-content">
                                    <h4 className="title-objetive">
                                      {option.replace('_', ' ')}
                                    </h4>
                                  </div>
                                </label>
                              </div>
                            ))}
                          </div>  
                        )
                      )}
                      {question.type === "multi-select" && (
                        <div id={`question${question.id}`} className="grid-cards">
                          {question.classCards === 'social_cards' && (
                            question.options.map((option) => (
                              <div
                                className={`card_social card-multicheckbox-${option} ${validAnswer.includes(question.id) && 'invalid-answer'}`}
                                key={option}
                              >
                                <input
                                  type="checkbox"
                                  id={`card-multicheckbox-${option}`}
                                  className={`card-multicheckbox-${option}`}
                                  name={`question${question.id}`}
                                  checked={formData[`question${question.id}`]?.includes(option) || false}
                                  onChange={(e) => handleCheckboxChange(e)}
                                  value={option}
                                />
                                <label
                                  htmlFor={`card-multicheckbox-${option}`}
                                  className="card-label card-content-label"
                                >
                                  <div className="card-content">
                                    <h4 className="title-objetive">
                                      {option.replace('_', ' ')}
                                    </h4>
                                  </div>
                                </label>
                              </div>
                            )
                          ))}
                        </div>
                      )}
                      {(question.type === 'range' && question.active === true) && (
                        <div className="input_container">
                          <div className={`mt-2 ${validAnswer.includes(question.id) && 'invalid-answer'}`}>
                            <RangeSlider
                              name={`question${question.id}`}
                              onInput={(e) => handleInputChangeRange({ name: `question${question.id}`, value: e })}
                              value={getValueFromForm(`question${question.id}`)}
                              min={0}
                              max={100}
                              data-cy="ageRangeSlider"
                              range
                            />
                          </div>  
                          <div className="range_description mt-2">
                            {valueRange.length === 0 ? (
                              <span>{question.keyword} between 25 to 75</span>
                            ) : (
                              <span>{question.keyword} between {valueRange[0]} to {valueRange[1]}</span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              ))}

              {isFreePlanUser ? (
                <div className="footer-process-form" style={{ width: '100%', paddingBottom: '2rem' }}>
                  <button
                    id="btn-process-awnswer-company"
                    className="btn-process-answer"
                    onClick={() => handlerProcessAnswer()}
                    type="button"
                    >
                      Generate your content
                  </button>
                </div>
              ) : (
                <div className="footer-process-form" style={{ width: '100%', paddingBottom: '2rem' }}>
                  {companyInfo.counterUseForm <= 0 ? (
                    <span>
                      {/*
                        <button
                          id="btn-process-awnswer-company"
                          className="btn-process-answer"
                          onClick={() => console.log('upgrade your plan')}
                          type="button"
                          >
                          Upgrade your plan
                        </button>
                      */}
                    &nbsp;
                    </span>
                  ) : (
                    <button
                      id="btn-process-awnswer-company"
                      className="btn-process-answer"
                      onClick={() => handlerProcessAnswer()}
                      type="button"
                      >
                      {activeCompanyProfile.infoCompanyGenerate === false ? (
                        <span>
                          Generate your first content!
                        </span>
                      ) : (
                        <span>
                          Generate your content&nbsp;
                          &#40;{parseInt(companyInfo.counterUseForm)}&#41;
                        </span>
                      )}
                    </button>
                  )}
                </div>
              )}
            </Fade>
          )}
          <GenerateContentSuccess />
        </div>
        {viewGeneratingContent === true && (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <GeneratingContent />
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(Form);