import React from 'react';
import { Link } from 'react-router-dom';
import {
  useSelector
} from 'react-redux';
import './style.css';
import './login.css';
import { version_app } from '../services/util';
import FormLogin from './formLogin';

function Login() {
  const userAgent = useSelector((state) => state.userAgent.value);
  const externalURL = useSelector((state) => state.externalURL.value);

  return (
    <div className={`sign-in-container ${userAgent.device === 'iOS' ? 'ios-signin-container' : ''}`}>
      <div className="sign-in-img-container" style={{ backgroundImage: "url('./assets/images/signin-background.webp')" }} />
      <div className="sign-in-form-container d-flex flex-column justify-content-center pl-2 pr-2">
        <div className="signin-p-form">
          <div className="pb-5 d-flex flex-row" title={`version ${version_app}`}>
            <img
              src="./assets/logos/imagotipo/Imagotipo/Horizontal/PNG/dark.png"
              alt="Meantto - logo"
              className="img-logo-sign-in"
            />
            <small className='text-muted font-size-10'>
              v.{' '}{version_app}
            </small>
          </div>
          <span className="signin-title">Sign In</span>
            <FormLogin url={externalURL.url} />
          <br />
          <div className="d-flex justify-content-center">
            <Link
              id="forgot-password"
              to={`/forgot-password`}
            >
              Forgot your password?
            </Link>
          </div>
          <div className="d-flex justify-content-center mt-3 mb-5">
            <Link
              className="create-account-btn w-100 text-align-center"
              to={`/sign-up`}
            >
              Create account
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login;
