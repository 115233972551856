import React from 'react';
import './style.css';
import './signUp.css';
import FormSignUp from './formSignUp';

function SignUp() {
  return (
    <div className="sign-up-container">
      <div className="sign-up-form-container d-flex flex-column justify-content-center pl-2 pr-2">
        <div className="signup-p-form">
          <div>
            <img
              src="./assets/logos/imagotipo/Imagotipo/Horizontal/PNG/dark.png"
              alt="Meantto - logo"
              className="img-logo-sign-up"
            />
          </div>
          <div className="d-flex flex-column">
            <span className="signin-title mb-4">Sign Up</span>
          </div>
          <FormSignUp viewFromLogin={true} />
        </div>
      </div>
      <div className="sign-up-img-container" style={{ backgroundImage: "url('./assets/images/signup-background.webp')" }} />
    </div>
  )
}

export default SignUp;
