/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const collaboratorCompanySelected = createSlice({
  name: 'collaboratorCompanySelected',
  initialState: {
    value: {
      emailCompanyOwner: "",
      idCompany: "",
      keyAccess: "",
      name: "",
      userCompanyOwner: "",
      servicePlan: "",
      location: "",
    },
  },
  reducers: {
    setCompanyProfileSelected: (state, action) => {
      state.value.emailCompanyOwner = action.payload.emailCompanyOwner;
      state.value.idCompany = action.payload.idCompany;
      state.value.keyAccess = action.payload.keyAccess;
      state.value.name = action.payload.name;
      state.value.userCompanyOwner = action.payload.userCompanyOwner;
      state.value.servicePlan = action.payload.servicePlanOwner;
      state.value.location = action.payload.locationCompany;
    },
    cleanCompanyProfileSelected: (state, action) => {
      state.value.emailCompanyOwner = "";
      state.value.idCompany = "";
      state.value.keyAccess = "";
      state.value.name = "";
      state.value.userCompanyOwner = "";
      state.value.servicePlan = "";
      state.value.location = "";
    },
  }
});

export const {
  setCompanyProfileSelected, cleanCompanyProfileSelected
} = collaboratorCompanySelected.actions;

export default collaboratorCompanySelected.reducer;
