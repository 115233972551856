import React from 'react';
import { useHistory } from 'react-router-dom';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useMediaQuery } from '@react-hook/media-query';
import './style.scss';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { setHideModal } from '../../../reducers/modalCompanyCollaborator';
import { setShowAlert } from '../../../reducers/showAlert';
import { setEnableMode } from '../../../reducers/activeCollaboratorMode';
import { setCompanyProfileSelected } from '../../../reducers/collaboratorCompanySelected';
import { postRequest } from '../../services/requestLib';

function ModalCompanyCollaborator() {
  const history = useHistory();
  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');
  const userData = useSelector((state) => state.userData.value);
  const collaboratorCompanySelected = useSelector((state) => state.collaboratorCompanySelected.value);
  const companyCollaborator = useSelector((state) => state.companyCollaborator.value);
  const showCompanyCollaborator = useSelector((state) => state.showCompanyCollaborator.value);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();
  
  const handlerSetHideModal = () => {
    dispatch(setHideModal());
  };

  const handlerSetShowAlert = (infoAlert) => {
    dispatch(setShowAlert(infoAlert));
  };

  const openAlert = (typeAlert, textAlert) => {
    const object = {
      typeAlert: typeAlert,
      textAlert: textAlert
    };

    handlerSetShowAlert(object);
  };

  const handlerSetEnableMode = () => {
    dispatch(setEnableMode());
  };

  const handlerSetCompanyProfileSelected = (infoCompany) => {
    dispatch(setCompanyProfileSelected(infoCompany));
  };

  const handlerChangeCompanyProfileAsCollaborator = async (id) => {
    const filterCompanySelected = companyCollaborator.companyList.find((x) => x.idCompany === id);

    if (filterCompanySelected.idCompany !== collaboratorCompanySelected.idCompany) {
      const dataRequest = {
        email: userData.email,
        iC: filterCompanySelected.idCompany,
        iU: filterCompanySelected.userCompanyOwner,
        ka: filterCompanySelected.keyAccess,
      };
  
      openAlert("info", "Validating access.");
      handlerSetHideModal();

      const responseAccess = await postRequest('check-collaborator-access', dataRequest);
      if (responseAccess.status === 200) {
        if (responseAccess.data.status === true) {
          openAlert("success", "Success.");
          handlerSetCompanyProfileSelected(filterCompanySelected);
          handlerSetEnableMode();
          history.push('/');
        } else {
          openAlert("danger", "Sorry, you can not access.");
        }
      }
    } else {
      handlerSetHideModal();
    }
  };
  
  return (
    <Modal
      open={showCompanyCollaborator.show}
      showCloseIcon={false}
      blockScroll={true}
      onClose={() => handlerSetHideModal()}
      classNames={{
        overlay: 'customOverlay',
        modal: isMobileDevice ? 'fullscreen-modal' : 'customModal',
      }}
      center
    >
      <div className="modal-body modal-sm">
        <div className="modal-content">
          <div className='d-flex flex-column' style={{ height: '90%' }}>
            <h3 className="w-100 text-center">
              Select company profile as collaborator
            </h3>
            <div className="container-list mt-3 mb-1">
              {companyCollaborator.companyList.map((companyItem, index, array) => (
                <button
                  key={companyItem.idCompany}
                  className={`item-list w-100 ${index === 0 ? 'firts-child' : ''} ${index === array.length - 1 ? 'last-child' : ''} ${index !== array.length - 1 ? 'column-divider' : ''}`}
                  onClick={() => handlerChangeCompanyProfileAsCollaborator(companyItem.idCompany)}
                >
                  <div
                    className="w-100 btn-company-profile"
                  >
                    <span>
                      {companyItem.name}
                    </span>
                    {collaboratorCompanySelected.idCompany === companyItem.idCompany && (
                      <CheckRoundedIcon className="text-success" />
                    )}
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="action-btn-form">
          <button
            className="one-btn-modal"
            onClick={() => handlerSetHideModal()}>
              Close
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalCompanyCollaborator;