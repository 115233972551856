import React, { useState, useEffect } from 'react';
import './style.scss';
import { Fade } from "react-reveal";
import {
  useSelector
} from 'react-redux';
import Text from '../../../text/text';
import ImageIcon from '@mui/icons-material/Image';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { IsCollaboratorPlan } from '../../../../services/isCollaboratorPlan/IsCollaboratorPlan';

function WidgetManageFiles() {
  const userData = useSelector((state) => state.userData.value);
  const companyFiles = useSelector((state) => state.companyFiles.value);
  const maxStorageForCompany = companyFiles.storageForFiles;

  const [allFilesBytes, setAllFilesBytes] = useState(0);
  const [imageFilesBytes, setImageFilesBytes] = useState(0);
  const [videoFilesBytes, setVideoFilesBytes] = useState(0);

  const bytesToGigabytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

  const calculateTotalStorage = () => {
    const total = companyFiles.fileList.reduce((acc, file) => {
      return acc + file.size;
    }, 0);
    setAllFilesBytes(total);
  };

  // Función para calcular la suma total de archivos de tipo video
  const calculateTotalVideoStorage = () => {
    const totalVideo = companyFiles.fileList.reduce((acc, file) => {
      if (file.type.startsWith('video/')) {
        return acc + file.size;
      }
      return acc;
    }, 0);
    setVideoFilesBytes(totalVideo);
  };

  // Función para calcular la suma total de archivos de tipo imagen
  const calculateTotalImageStorage = () => {
    const totalImage = companyFiles.fileList.reduce((acc, file) => {
      if (file.type.startsWith('image/')) {
        return acc + file.size;
      }
      return acc;
    }, 0);
    setImageFilesBytes(totalImage);
  };

  const updateCalcFuncition = () => {
    calculateTotalStorage();
    calculateTotalImageStorage();
    calculateTotalVideoStorage();
  };

  useEffect(() => {
    updateCalcFuncition();
  // eslint-disable-next-line
  }, [companyFiles]);

  const calculateStoragePercentage = (allFilesBytes, maxStorageForCompany) => {
    /* if (typeof allFilesBytes !== 'number' || isNaN(allFilesBytes) || allFilesBytes < 0 ||
        typeof maxStorageForCompany !== 'number' || isNaN(maxStorageForCompany) || maxStorageForCompany <= 0) {
    } */

    const percentage = (allFilesBytes / maxStorageForCompany) * 100;
    const roundedPercentage = percentage.toFixed(2);
  
    return roundedPercentage;
  };

  const handlerGetPorcentClass = (porcent) => {
    if (porcent < 50) {
      return "cero-usage";
    }

    if (porcent > 50) {
      return "fifty-usage";
    }

    if (porcent > 80) {
      return "eighty-usage";
    }

    if (porcent > 90) {
      return "ninety-usage";
    }
  };

  const handlerGenerateWidget = (showWidget) => {
    return (
        <Fade duration={600} opposite>
          <div className="container-widget-storage d-flex flex-column">
            <Text type="small" inyectClass="pb-1 pl-2 pr-2">
              Storage
            </Text>
            <div className='w-100 pl-2 pr-2'>
              {showWidget ? (
                <>
                  <div className="indicator-all-storage">
                    <div className={`bar-indicator-storage ${handlerGetPorcentClass(calculateStoragePercentage(allFilesBytes, maxStorageForCompany))}`} style={{ width: `${calculateStoragePercentage(allFilesBytes, maxStorageForCompany)}%` }}></div>
                  </div>
                  <Text type="text" inyectClass="float-right">
                    {bytesToGigabytes(allFilesBytes)} de {bytesToGigabytes(maxStorageForCompany)} in use
                  </Text>
                </>
              ) : (
                <Text type="text" inyectClass="float-right">
                  Your plan have {bytesToGigabytes(maxStorageForCompany)} for storage
                </Text>
              )}
            </div>
            {showWidget === true && (
              <div className='table-description-storage'>
                <div className='item-description-storage'>
                  <ImageIcon />
                  <div className="text-description-storage">
                    <Text type="small">
                      Images
                    </Text>
                    <Text type="small">
                      {bytesToGigabytes(imageFilesBytes)}
                    </Text>
                  </div>
                </div>
                <div className='item-description-storage'>
                  <OndemandVideoIcon />
                  <div className="text-description-storage">
                    <Text type="small">
                      Videos
                    </Text>
                    <Text type="small">
                      {bytesToGigabytes(videoFilesBytes)}
                    </Text>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Fade>
    );
  };

  return (
    IsCollaboratorPlan(userData.servicePlan) ? (
      handlerGenerateWidget(false)
    ) : (
      <>
        {companyFiles.id !== "" ? (
          handlerGenerateWidget(true)
        ) : (
          <Fade duration={600} opposite>
            <div className='w-100 d-flex align-items-center justify-content-center'>
              <Text type="text">
                Calculating storage
              </Text>
            </div>
          </Fade>
        )}
      </>
    )
  );
}

export default WidgetManageFiles;