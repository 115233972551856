import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function Password({ onPasswordChange, isEmpty, placeholder }) {
  const [showPassword, setShowPassword] = useState(false);
  // eslint-disable-next-line
  const [password, setPassword] = useState("");

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handlerSetPassword = (e) => {
    const value = e.target.value;
    setPassword(value);
    onPasswordChange(value);
  };

  return (
    <>
      <div className={`input-field custom-password-input ${isEmpty && "invalid-answer"}`}>
        <input
          id="password"
          autoComplete='false'
          placeholder={placeholder}
          className={`password-input`}
          type={showPassword ? "text" : "password"}
          onChange={(e) => handlerSetPassword(e)}
        />
        <button className="eye-btn" onClick={(e) => handleShowPassword(e)}>
          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </button>
      </div>
    </>
  )
};

Password.propTypes = {
  onPasswordChange: PropTypes.func.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default Password;
