import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import PaletteRoundedIcon from '@mui/icons-material/PaletteRounded';
// import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import { useMediaQuery } from '@react-hook/media-query';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import './style.scss';
import { setShowAlert } from '../../reducers/showAlert';
import PillCompany from '../share-components/pillCompany/pillCompany';
import { setDisableMode } from '../../reducers/activeCollaboratorMode';
import UseCheckIsFreeUser from '../services/checkIsFreeUser/useCheckIsFreeUser';
import { IsCollaboratorPlan } from '../services/isCollaboratorPlan/IsCollaboratorPlan';
import UpgradeYourPlan from '../share-components/upgradeYourPlan/upgradeYourPlan';

function Navbar() {
  const location = useLocation();
  const history = useHistory();
  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');
  const statusUser = useSelector((state) => state.statusUser.value);
  const isFreePlanUser = UseCheckIsFreeUser(statusUser.servicePlan);
  const userData = useSelector((state) => state.userData.value);
  // eslint-disable-next-line no-unused-vars
  const [enableMenu, setEnableMenu] = useState(true);
  const userAgent = useSelector((state) => state.userAgent.value);
  const activeCollaboratorMode = useSelector((state) => state.activeCollaboratorMode.value);
  const accessStatusUser = useSelector((state) => state.statusUser.value.access);
  const activeCompanyProfile = useSelector((state) => state.activeCompanyProfile.value);
  

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const handlerSetShowAlert = (infoAlert) => {
    dispatch(setShowAlert(infoAlert));
  };

  const openAlert = (typeAlert, textAlert) => {
    const object = {
      typeAlert: typeAlert,
      textAlert: textAlert
    };

    handlerSetShowAlert(object);
  };

  const [pathNameMenu, setPathNameMenu] = useState('');

  useEffect(() => {
    if (userData.firtsLogin === true) {
      setEnableMenu(false);
    }
    if (userData.firtsLogin === false) {
      setEnableMenu(true);
    }
  }, [userData]);

  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    history.push('/');
    window.location.reload();
  };

  const handlerExitCollaboratorMode = () => {
    dispatch(setDisableMode());
  };

  const filterAccess = (name) => {
    if (activeCollaboratorMode.collaboratorMode) {
      return true;
    }

    if (accessStatusUser.length === 0) {
      return false;
    }

    switch (name) {
      case 'form-ai':
        if (isFreePlanUser) {
          return !activeCompanyProfile.infoCompanyGenerate;
        }
        return activeCompanyProfile.infoCompanyGenerate;
      case 'content-calendar':
        if (activeCompanyProfile.infoCompanyGenerate) {
          return true;
        } else {
          return false;
        }
      case 'company-profile':
      case 'brand-information':
        if (isFreePlanUser) {
          return false;
        }
        return activeCompanyProfile.infoCompanyGenerate;
      default:
        break;
    }
  };

  useEffect(() => {
    const urlName = location.pathname.split('/')[1];
    if (urlName === "") {
      setPathNameMenu("content-calendar");
    } else {
      setPathNameMenu(urlName);
    }
    // eslint-disable-next-line
  }, [location]);

  const handlerSetClassIcon = (link) => {
    if (isMobileDevice) {
      return `icon-menu icon-mobile ${pathNameMenu === link && 'icon-menu-mobile-active'} ${userAgent.device === 'iOS' ? 'icon-ios' : ''}`;
    } else {
      return `icon-menu ${pathNameMenu === link && 'icon-menu-active'} ${userAgent.device === 'iOS' ? 'icon-ios' : ''}`;
    }
  };
  
  const handlerSetClassMenu = () => {
    if (isMobileDevice) {
      return `menu-active-mobile`;
    } else {
      return `menu-active`;
    }
  };

  const menuDashboard = [
    {
      id: 1,
      name: 'Form ai',
      shortName: 'Form',
      iconComponent: <AutoFixHighRoundedIcon className={handlerSetClassIcon("form-ai")}/>,
      urlImg: './assets/icons/magic.png',
      url: 'form-ai',
      active: filterAccess('form-ai'),
      showInCollaboratorMode: false
    },
    {
      id: 3,
      name: 'Company profile',
      shortName: 'Company',
      iconComponent: <ApartmentRoundedIcon className={handlerSetClassIcon("company-profile")}/>,
      urlImg: './assets/icons/office.png',
      url: 'company-profile',
      active: filterAccess('company-profile'),
      showInCollaboratorMode: true
    },
    {
      id: 4,
      name: 'Brand kit',
      shortName: 'Brand kit',
      iconComponent: <PaletteRoundedIcon className={handlerSetClassIcon("brand-information")}/>,
      urlImg: './assets/icons/brand.png',
      url: 'brand-information',
      active: filterAccess('brand-information'),
      showInCollaboratorMode: true
    },
    {
      id: 2,
      name: 'Content calendar',
      shortName: 'Calendar',
      iconComponent: <CalendarMonthRoundedIcon className={handlerSetClassIcon("content-calendar")}/>,
      urlImg: './assets/icons/calendar.png',
      url: 'content-calendar',
      active: filterAccess('content-calendar'),
      showInCollaboratorMode: true
    },
  ];

  const handlerAlert = () => {
    if (activeCompanyProfile.infoCompanyGenerate === false) {
      openAlert("warning", "Complete Form ai");
    } else {
      let msmAlert = "";
      
      if (isFreePlanUser) {
        msmAlert = "In free plan, only can access to Content Calendar";
        openAlert("warning", msmAlert);
      }
    }
  };

  const validatorCollaboratorMode = (show) => {
    if (activeCollaboratorMode.collaboratorMode === false) {
      return true;
    }

    if (activeCollaboratorMode.collaboratorMode === true) {
      return show;
    }
  };

  const handlerGenerateMenu = () => {
    return menuDashboard.map((itemMenu) => (
      (itemMenu.active === true) ? (
        validatorCollaboratorMode(itemMenu.showInCollaboratorMode) && (
          <Link
            to={`/${itemMenu.url}`}
            key={itemMenu.id}
            className={`a-link`}
            onClick={() => setPathNameMenu(itemMenu.url)}
          >
            <div className={`menu-link unselectable ${userAgent.device === 'iOS' ? 'ios-menu-link' : ''} ${pathNameMenu === itemMenu.url && handlerSetClassMenu()}`}>
              {itemMenu.iconComponent}
              <span>
                {isMobileDevice ? itemMenu.shortName : itemMenu.name}
              </span>
            </div>
          </Link>
        )
      ) : (
        <button
          type='button'
          key={itemMenu.id}
          className="a-link border-none"
          onClick={() => handlerAlert()}
        >
          <div className="menu-link unselectable">
            {itemMenu.iconComponent}
            <span className="text-muted">
              {isMobileDevice ? itemMenu.shortName : itemMenu.name}
            </span>
          </div>
        </button>
      )
    ));
  };

  return (
    <div className="sidebar-content">
      <a href="/" className="navbar-title unselectable">
        <img
          src="./assets/logos/imagotipo/Imagotipo/Horizontal/PNG/morado.png"
          alt="Meantto - logo"
          className="img-logo-navbar"
        />
      </a>
      <div className="sidebar-actions">
        <div className={`menu-content`}>
          {activeCollaboratorMode.collaboratorMode === true ? (
            handlerGenerateMenu()
          ) : (            
            IsCollaboratorPlan(userData.servicePlan) ? (
              <UpgradeYourPlan />
            ) : (
              handlerGenerateMenu()
            )
          )}

          <Link
            to={`/settings`}
            className="a-link"
            onClick={() => setPathNameMenu('settings')}
          >
            <div className={`menu-link settings-menu unselectable ${userAgent.device === 'iOS' ? 'ios-menu-link' : ''} ${pathNameMenu === 'settings' && handlerSetClassMenu()}`}>
              <SettingsRoundedIcon
                fontSize={`${userAgent.device === 'iOS' ? 'small' : ''}`}
                className={handlerSetClassIcon("settings")}
              />
              <span>
                Settings
              </span>
            </div>
          </Link>
          {(isMobileDevice && activeCollaboratorMode.collaboratorMode === true) && (
            <button
              type='button'
              className='menu-link btn-primary border-none'
              onClick={() => handlerExitCollaboratorMode()}
            >
              <ArrowBackIcon />
              {isMobileDevice && (
                <span>
                  Exit
                </span>
              )}
            </button>
          )}
        </div>

        {!isMobileDevice && (
          <PillCompany />
        )}

        <div className="divider" />

        <div className="account-content">
          {/*
          <div
            className={`settings-container unselectable ${userAgent.device === 'iOS' ? 'ios-menu-link' : ''} ${pathNameMenu === 'help' && handlerSetClassMenu()}`}
          >
            <HelpOutlineRoundedIcon className="icon-menu"/>
            <div className="content-account-sidebar">
              <span>
                Help
              </span>
            </div>
          </div>
          */}
          <Link
            to={`/settings`}
            onClick={() => setPathNameMenu('settings')}
          >
            <div
              className={`settings-container ${userAgent.device === 'iOS' ? 'ios-menu-link' : ''} ${pathNameMenu === 'settings' && handlerSetClassMenu()}`}
            >
              <SettingsRoundedIcon  className={handlerSetClassIcon("settings")} />
              <div className="content-account-sidebar">
                <span>
                  Settings
                </span>
              </div>
            </div>
          </Link>

          <button
            type="button"
            className="help-container logout-btn unselectable border-none"
            onClick={() => logout()}
          >
            <ExitToAppRoundedIcon className="img-account-content-sidebar" />
            <div className="content-account-sidebar flex-start">
              <span>
                Logout
              </span>
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Navbar;
