import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from "react-reveal";
import Lottie from "lottie-react";
import Stars from './stars.json';
import Text from '../../text/text';
import { useMediaQuery } from '@react-hook/media-query';

function GeneratingContent({ text }) {
  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');

  const styleLottieAnim = {
    height: isMobileDevice ? 280 : 330,
  };

  return (
    <Fade duration={600} opposite>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Lottie animationData={Stars} loop={true} autoPlay={true} style={styleLottieAnim} />
        <Text type="subtitle">
          {text || "Generating content"}
        </Text>
      </div>
    </Fade>
  )
}

GeneratingContent.propTypes = {
  text: PropTypes.string
};

export default GeneratingContent;
