import React, { memo } from 'react';
import '../../../styles/brandContent/style.scss';
import '../../../styles/style.scss';
import PropTypes from 'prop-types';
import { Fade } from "react-reveal";
import Information from './information/information';
import Text from '../../../share-components/text/text';

function BrandContent({ SendSocket }) {
  return (
    <div>
      <div className="content-title">
        <Text type="title">
          Brand content
        </Text>
        <img src="./assets/logos/symbol/Simbolo/PNG/morado.png" alt="" />
      </div>
      <div className="content-view">
        <div className="container-brand-profile">
          <Fade duration={600} opposite>
            <Information SendSocket={SendSocket} />
          </Fade>
        </div>
      </div>
    </div>
  )
};

BrandContent.propTypes = {
  SendSocket: PropTypes.func.isRequired,
};

export default memo(BrandContent);
