import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import {
  useSelector
} from 'react-redux';

function Paper({ type, children, inyectClass }) {
  const userAgent = useSelector((state) => state.userAgent.value);
  const getTheme = userAgent.theme;

  return (
    <div className={`paper ${type} ${getTheme} ${inyectClass}`}>
      {children}
    </div>
  )
}

Paper.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  inyectClass: PropTypes.node.isRequired
};

export default Paper;
