import axios from 'axios';
import { urlBackend } from './requestLib';
export const urlGeneratePrompt = `${urlBackend}/test-meantto`;

export const generateContentMeantto = async (user, company, type, prompt) => {
  let bodyContent = {
    "user": user,
    "company": company,
    "type": type,
    "prompt": prompt
  };

  const data = await axios.post(urlGeneratePrompt, bodyContent);
  return data;
};
