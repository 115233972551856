import React, { useState, useEffect } from 'react';
import '../../../../../../styles/contentCalendar/components/viewsCreatePost/specificPost/style.scss';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Text from '../../../../../../share-components/text/text';
import { formatMoment, socialNetworksConst } from '../../../../../../services/values';
import '../../../../../../share-components/multiSelect.scss';
import { generatePromptSpecificPost } from '../../../../../../services/generatePromptSpecificPost';
import { generateContentMeantto } from '../../../../../../services/generatePrompt';
import { setShowAlert } from '../../../../../../../reducers/showAlert';
import { setNewPost } from '../../../../../../../reducers/newPost';
import DetailPost from '../../detailPost/detailPost';
import GeneratingContent from '../../../../../../share-components/lottie/generatingContent/generatingContent';
import { validatorPostCounter } from '../../../../../../services/validatePostRemaining';
import { patchRequestCollaborator } from '../../../../../../services/requestLib';
import { updateCounter } from '../../../../../../../reducers/postsCompany';
import { useMediaQuery } from '@react-hook/media-query';

function SpecificPost({ closeModal, updateStatusView, SendSocket }) {
  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');
  const userData = useSelector((state) => state.userData.value);

  // get answerForm from 'companyInfoAsCollaborator' slice
  const answersForm = useSelector((state) => state.companyInfoAsCollaborator.value.answerForm);
  // get data from company seleted as collaborator for get id and location
  const activeCompanyProfile = useSelector((state) => state.collaboratorCompanySelected.value);
  // get post company from postsCompanyAsCollaborator slice
  const postsCompany = useSelector((state) => state.postsCompanyAsCollaborator.value);
  const limitCounterSpecificPost = postsCompany.maxGenerateSpecificPost;
  const activeCollaboratorMode = useSelector((state) => state.activeCollaboratorMode.value);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const handlerSetShowAlert = (infoAlert) => {
    dispatch(setShowAlert(infoAlert));
  };

  const handlerSetNewPost = (infoPost) => {
    dispatch(setNewPost(infoPost));
  };

  const handlerUpdateCounter = (infoCompany) => {
    dispatch(updateCounter(infoCompany))
  };

  const openAlert = (typeAlert, textAlert) => {
    const object = {
      typeAlert: typeAlert,
      textAlert: textAlert
    };

    handlerSetShowAlert(object);
  }; 

  const [selectedSocialNetworks, setSelectedSocialNetworks] = useState({
    id: 0,
    name: ""
  });

  const handlerAddSocialNetworktoArray = (id) => {
    
    if (selectedSocialNetworks.id === id) {
      setSelectedSocialNetworks((prevInfo) => ({
        ...prevInfo,
        id: 0,
        name: '',
      }));
    } else {
      const selectedNetworksData = socialNetworksConst.find((x) => x.id === id);

      const newItem = {
        id: selectedNetworksData.id,
        name: selectedNetworksData.name,
      };
  
      setSelectedSocialNetworks((prevInfo) => ({
        ...prevInfo,
        id: newItem.id,
        name: newItem.name,
      }));
    }

  };
  const handlerReturnIcon = (id) => {
    if (selectedSocialNetworks.id === id) {
      return <CheckCircleRoundedIcon className="color-text-primary" />;
    } else {
      return <RadioButtonUncheckedRoundedIcon className="text-muted" />;
    }
  };

  const handlerFindCounterRemaining = () => {
    const counterPosts = postsCompany.postList.filter((x) => x.typePost === "Specific Post").length;

    return limitCounterSpecificPost - counterPosts;
  };

  const [specificEvent, setSpecificEvent] = useState("");
  const [specificEventEmpty, setSpecificEventEmpty] = useState(true);
  const [specificProduct, setSpecificProduct] = useState("");
  const [specificProductEmpty, setSpecificProductEmpty] = useState(true);

  const handlerChangeValueForSpaceficPost = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "specificEvent":
        setSpecificEvent(value);

        if (value === "") {
          setSpecificEventEmpty(true);
        } else {
          setSpecificEventEmpty(false);
        }

        break;
      case "specificProduct":
        setSpecificProduct(value);

        if (value === "") {
          setSpecificProductEmpty(true);
        } else {
          setSpecificProductEmpty(false);
        }

        break;
      default:
        break;
    }
  };

  const [viewSpecificPost, setViewSpecificPost] = useState("create");
  updateStatusView("create");

  const handlerUpdatePostCompany = async () => {
    const user = {
      email: userData.email,
      password: userData.password,
      token: userData.token,
      typeAccess: 2
    };
  
    const updateCompanyCounterPost = validatorPostCounter(3, postsCompany, 1);

    const sendData = {
      id: activeCompanyProfile.idCompany,
      user: activeCompanyProfile.userCompanyOwner,
      // postList: postsCompany.postList,
      keyToUpdate: updateCompanyCounterPost.key,
      updatedCounter: updateCompanyCounterPost.value
    };

    const responseUpdatePostCompany = await patchRequestCollaborator('company-posts', user, sendData);
    if (responseUpdatePostCompany.status === 200) {
      handlerUpdateCounter(updateCompanyCounterPost);

      SendSocket({
        action: 'send-message',
        data: {
          id: activeCompanyProfile.idCompany,
          user: userData.user,
          message: {
            type: 'UPDATE',
            table: 'company-posts',
            sendFromAdminView: false,
            sendFromCollaboratorView: true,
            companyOwner: activeCompanyProfile.userCompanyOwner,
            idCompanyOwner: activeCompanyProfile.idCompany,
            userSendUpdate: userData.user,
            isAdmin: !activeCollaboratorMode.collaboratorMode,
            data: { 
              user: postsCompany.user,
              id: postsCompany.id,
              email: postsCompany.email,
              maxGenerateContentPost: postsCompany.maxGenerateContentPost,
              maxGenerateSpecificPost: updateCompanyCounterPost.value,
              maxGenerateVideoPosts: postsCompany.maxGenerateVideoPosts,
              postList: postsCompany.postList
            }
          }
        }
      });

    } else {
      openAlert("danger", "Error, try again");
      setViewSpecificPost("error-on-update");
    }
  };

  const handlerGenerateSpecificPostContent = async (prompt) => {
    try {
      setViewSpecificPost("loading");
      const responseSpecificPostContent = await generateContentMeantto(activeCompanyProfile.emailCompanyOwner, activeCompanyProfile.idCompany, "specific post", prompt);
      if (responseSpecificPostContent.status === 200) {
        const getBody = responseSpecificPostContent.data.body;
        const parseResponse = JSON.parse(getBody);

        if (parseResponse !== "") {
          const getGeneratedPost = parseResponse;

          const newPost = {
            createdate: moment().format(formatMoment),
            id: uuidv4(),
            companyProfileId: activeCompanyProfile.id,
            hashtags: getGeneratedPost.hashtags,
            contentIdea: getGeneratedPost.contentIdea,
            postText: getGeneratedPost.postText,
            socialNetwork: getGeneratedPost.socialNetWork,
            imgSocialNetwork: socialNetworksConst.find((x) => x.id === selectedSocialNetworks.id).urlIcon,
            typePost: "Specific Post",
          };

          handlerUpdatePostCompany();

          handlerSetNewPost(newPost);
          setViewSpecificPost("success-generate");
        } else {
          setViewSpecificPost("error");
          openAlert("danger", "Error, try again");
        }
      }
    } catch (error) {
      setViewSpecificPost("error");
      openAlert("danger", "Error, try again");
    }
  };

  const handlerGeneratePrompt = () => {
    const getSpecificPrompt = generatePromptSpecificPost(answersForm, activeCompanyProfile.location, specificEvent, specificProduct, selectedSocialNetworks.name);
    handlerGenerateSpecificPostContent(getSpecificPrompt);
  };

  useEffect(() => {
    updateStatusView(viewSpecificPost);
  }, [viewSpecificPost, updateStatusView]);

  return (
    <>
      {(viewSpecificPost !== "success-generate") && (
        <>
          <div className="modal-content d-block">
            <div className="container-view-specific-post">
              {viewSpecificPost === "create" && (
                <>
                  <div>
                    <div className="d-flex flex-row justify-content-between">
                      <Text type="text">
                        Select your social network
                      </Text>

                      <Text type={isMobileDevice ? 'small' : 'text'} inyectClass="bold">
                        {handlerFindCounterRemaining()} posts remaining
                      </Text>
                    </div>
                    <div className="multi-select-option-container">
                      {socialNetworksConst.map((itemSN) => (
                        <button key={itemSN.id} onClick={() => handlerAddSocialNetworktoArray(itemSN.id)}>
                          {handlerReturnIcon(itemSN.id)}
                          <div className="container-data-multi-select-option">
                            <img src={itemSN.urlIcon} alt="" />
                            {itemSN.name}
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>
                  <div>
                    <Text type="text">
                      Describe the event, sale, or holiday you want to focus on in this post
                    </Text>
                    <div className="text-area-container">
                      <textarea
                        className="text-area-input"
                        rows={3}
                        name="specificEvent"
                        value={specificEvent}
                        onChange={(e) => handlerChangeValueForSpaceficPost(e)}
                      ></textarea>
                    </div>
                  </div>
                  <div>
                    <Text type="text">
                      Describe the product/service you want to focus on in this post
                    </Text>
                    <div className="text-area-container">
                      <textarea
                        className="text-area-input"
                        rows={3}
                        name="specificProduct"
                        value={specificProduct}
                        onChange={(e) => handlerChangeValueForSpaceficPost(e)}
                      ></textarea>
                    </div>
                  </div>
                </>            
              )}

              {viewSpecificPost === "loading" && (
                <GeneratingContent />
              )}

              {viewSpecificPost === "error" && (
                <>
                  <Text type="title">
                    Something went wrong
                  </Text>
                  <div className="mb-3">
                    <Text type="text">
                      Please try again
                    </Text>
                  </div>
                </>
              )}

              {handlerFindCounterRemaining() === 0 && (
                <Text type="text" inyectClass="text-danger">
                  You no longer have AI credits. Upgrade your plan for more credits!
                </Text>
              )}
            </div>
          </div>
          <div>
            <div className="action-btn-form">
              {(viewSpecificPost === "create" && handlerFindCounterRemaining() > 0) && (
                <>
                  <button
                    className="two-btn-modal"
                    onClick={() => closeModal()}>
                    Close
                  </button>
                  <button
                    className={`two-btn-modal ${selectedSocialNetworks.id === 0 || specificEventEmpty || specificProductEmpty ? 'btn-disabled' : ''}`}
                    onClick={() => handlerGeneratePrompt()}
                    disabled={selectedSocialNetworks.id === 0 || specificEventEmpty || specificProductEmpty}
                  >
                    Generate content
                  </button>
                </>
              )}

              {viewSpecificPost === "error" && (
                <button
                  className={`one-btn-modal ${selectedSocialNetworks.id === 0 || specificEventEmpty || specificProductEmpty ? 'btn-disabled' : ''}`}
                  onClick={() => handlerGeneratePrompt()}
                  disabled={selectedSocialNetworks.id === 0 || specificEventEmpty || specificProductEmpty}
                >
                  Generate content
                </button>
              )}

              {handlerFindCounterRemaining() === 0 && (
                <button
                  className="two-btn-modal"
                  onClick={() => closeModal()}>
                  Close
                </button>
              )}
            </div>
          </div>
        </>
      )}

      {viewSpecificPost === "success-generate" &&  (
        <DetailPost closeModal={closeModal} SendSocket={SendSocket} />
      )}
    </>
  )
};

SpecificPost.propTypes = {
  closeModal: PropTypes.func.isRequired,
  updateStatusView: PropTypes.func.isRequired,
  SendSocket: PropTypes.func.isRequired,
};

export default SpecificPost;
