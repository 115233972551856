import React, { useEffect, useState } from 'react';
import '../../../../../styles/contentCalendar/components/uploadFiles/style.scss';
import PropTypes from 'prop-types';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import { useMediaQuery } from '@react-hook/media-query';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { v4 as uuidv4 } from 'uuid';
import { S3Client, PutObjectCommand, DeleteObjectsCommand } from "@aws-sdk/client-s3";
import { Fade } from 'react-reveal';
import { SyncLoader } from 'react-spinners';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { setShowAlert } from '../../../../../../reducers/showAlert';
import { bucketNameS3, maxWidthThumbnail, maxHeightThumbnail, maxQualityThumbnail, regionAWS } from '../../../../../services/values';
import generateCredentialsProvider from '../../../../../security/cognitoPool';
import { patchRequestMeantto, patchRequestCollaborator } from '../../../../../services/requestLib';
import { updateCounterFiles, updateFileList } from '../../../../../../reducers/companyFiles';
import { updateCompanyLogo } from '../../../../../../reducers/companyInfo';
import { validateStorage } from '../../../../../services/validateStorage';
import Text from '../../../../../share-components/text/text';

function UploadFiles({ importButton, SendSocket, logoFile, logoThumbnail }) {
  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');

  const userData = useSelector((state) => state.userData.value);
  const activeCompanyProfile = useSelector((state) => state.activeCompanyProfile.value);
  const companyInfo = useSelector((state) => state.companyInfo.value);
  const companyFiles = useSelector((state) => state.companyFiles.value);
  const [tempFileList, setTempFileList] = useState([...companyFiles.fileList]);
  const [tempCounterFiles, setTempCounterFiles] = useState(0);
  const activeCollaboratorMode = useSelector((state) => state.activeCollaboratorMode.value);

  useEffect(() => {
    setTempFileList([...companyFiles.fileList]);
  }, [companyFiles]);

  const dispatch = useDispatch();

  const handlerUpdateCounterFiles = (infoCompany) => {
    dispatch(updateCounterFiles(infoCompany));
  };

  const handlerUpdateFileList = (infoCompany) => {
    dispatch(updateFileList(infoCompany));
  };

  const handlerSetShowAlert = (infoAlert) => {
    dispatch(setShowAlert(infoAlert));
  };

  const handlerUpdateCompanyLogo = (infoLogo) => {
    dispatch(updateCompanyLogo(infoLogo));
  };

  const openAlert = (typeAlert, textAlert) => {
    const object = {
      typeAlert: typeAlert,
      textAlert: textAlert
    };
  
    handlerSetShowAlert(object);
  };

  //#region PROCESS AND UPLOAD FILE TO S3
    /* Upload files */
    const allowedVideoFormats = ['video/mp4'];
    const allowedImageFormats = ['image/jpeg', 'image/png'];
    const [uploadFileProcess, setUploadFileProcess] = useState("create");
    const [modalUploadFiles, setModalUploadFiles] = useState(false);
    const [videoBlodFile, setVideoBlodFile] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState("");
    const [error, setError] = useState("");
    const [dataFile, setDataFile] = useState({
      name: '',
      completeName: '',
      type: '',
      format: '',
      size: '',
      uuid: '',
      uuidThumbnail: '',
      url: '',
      urlThumbnail: '',
      formatThumbnail: '',
      typeThumbnail: ''
    });
    const openModalUploadFiles = () => {
      setModalUploadFiles(true);
    };
    const closeModalUploadFiles = () => {
      setError("");
      setPreviewUrl("");
      setSelectedFile(null);
      setModalUploadFiles(false);
      setDataFile((prevDataFile) => ({
        ...prevDataFile,
        name: '',
        completeName: '',
        type: '',
        format: '',
        size: '',
        uuid: '',
        uuidThumbnail: '',
        url: '',
        urlThumbnail: '',
        formatThumbnail: '',
        typeThumbnail: ''
      }));
      setUploadFileProcess("create");
    };
    const validateFile = async (file) => {
      if (file.type && allowedVideoFormats.includes(file.type)) {
        // Validaciones para archivos de video
        const maxSizeInBytes = Infinity;
    
        if (file.size > maxSizeInBytes) {
          setError(`The size of the video must be smaller than${maxSizeInBytes / (1024 * 1024)} MB`);
          return false;
        }
    
        // Validar aspect ratio para videos
        /*
        const video = document.createElement('video');
        video.src = URL.createObjectURL(file);
    
        try {
          await new Promise((resolve, reject) => {
            video.onloadedmetadata = () => {
              const aspectRatio = video.videoWidth / video.videoHeight;
              const validRatios = [1, 4 / 5, 9 / 16];
              if (validRatios.includes(aspectRatio)) {
                resolve();
              } else {
                setError('El video debe tener un aspecto de 1:1, 4:5 o 9:16');
                reject();
              }
            };
          });
        } catch (error) {
          return false;
        }
        */
    
        // Otras validaciones para videos (duración, resolución)...
      } else if (file.type && allowedImageFormats.includes(file.type)) {
        // Validaciones para archivos de imagen
        const maxSizeInBytes = 10 * 1024 * 1024;
    
        if (file.size > maxSizeInBytes) {
          setError(`The size of the image must be smaller than ${maxSizeInBytes / (1024 * 1024)} MB`);
          return false;
        }
    
        // Validar aspect ratio para imágenes
        const image = new Image();
        image.src = URL.createObjectURL(file);
    
        try {
          await new Promise((resolve, reject) => {
            image.onload = () => {
              const aspectRatio = image.width / image.height;
              const minRatio = 16 / 9;
              const maxRatio = 2 / 3;

              if (aspectRatio >= maxRatio && aspectRatio <= minRatio) {
                resolve();
              } else {
                setError('The image must have an aspect ratio between 16:9 and 2:3.');
                reject();
              }
            };
          });
        } catch (error) {
          return false;
        }
    
        // Otras validaciones para imágenes (resolución)...
      } else {
        setError('Unsupported file format. Please select an MP4 video file or an image in JPG or PNG format.');
        return false;
      }
    
      return true;
    };
    const handleImageChange = async (event) => {
      setError("");
      const file = event.target.files[0];
      const { name, type: format, size } = file;

      if (file) {
        if (await validateFile(file)) {
          setSelectedFile(file);

          const reader = new FileReader();
          const fileExtension = name.split('.').pop();
          const typeExtensionThumbnail = 'image/jpeg';
          const formatExtensionThumbnail = 'jpeg';

          const nameFile = uuidv4();
          const nameThumbnail = uuidv4();
          
          setDataFile((prevDataFile) => ({
            ...prevDataFile,
            name: name,
            completeName: `${name}`,
            type: format,
            format: fileExtension,
            size: size,
            uuid: nameFile,
            uuidThumbnail: `${nameThumbnail}-thumbnail`,
            url: `https://s3.amazonaws.com/${bucketNameS3}/${nameFile}.${fileExtension}`,
            urlThumbnail: `https://s3.amazonaws.com/${bucketNameS3}/${nameThumbnail}-thumbnail.${formatExtensionThumbnail}`,
            typeThumbnail: typeExtensionThumbnail,
            formatThumbnail: formatExtensionThumbnail
          }));

          reader.onloadend = () => {
            setPreviewUrl(reader.result);

            if (file.type.startsWith('video/')) {
              const videoBlob = new Blob([reader.result], { type: file.type });
              setVideoBlodFile(videoBlob);
              const url = URL.createObjectURL(videoBlob);
              setSelectedFile(url);
            }
          };

          if (file.type.startsWith('image/')) {
            reader.readAsDataURL(file);
          } else if (file.type.startsWith('video/')) {
            reader.readAsArrayBuffer(file);
          }
        } else {
          setSelectedFile(null);
          setPreviewUrl('');
          setDataFile((prevDataFile) => ({
            ...prevDataFile,
            name: '',
            completeName: '',
            type: '',
            format: '',
            size: '',
            uuid: '',
            uuidThumbnail: '',
            url: '',
            urlThumbnail: '',
            formatThumbnail: '',
            typeThumbnail: ''
          }));
          setUploadFileProcess("create");
        }
      }
    };
    // transform base64 image to image blob file
    const dataURItoBlob = (dataURI, type) => {
      const byteString = atob(dataURI);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      
      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }

      return new Blob([arrayBuffer], { type: type });
    };
    // transform base64 image to thumbnail blob file
    const createThumbnail = async (file, maxWidth, maxHeight, quality) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
    
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
    
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
    
            // Calculate the new dimensions while maintaining aspect ratio
            let newWidth, newHeight;
            if (img.width > img.height) {
              newWidth = maxWidth;
              newHeight = (maxWidth / img.width) * img.height;
            } else {
              newHeight = maxHeight;
              newWidth = (maxHeight / img.height) * img.width;
            }
    
            canvas.width = newWidth;
            canvas.height = newHeight;
    
            // Enable image smoothing
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';
    
            // Draw the image on the canvas
            ctx.drawImage(img, 0, 0, newWidth, newHeight);
    
            // Obtiene el blob de la imagen en el formato especificado (quality)
            canvas.toBlob((blob) => {
              resolve(blob);
            }, 'image/jpeg', quality);
          };
    
          img.onerror = (error) => {
            reject(error);
          };
        };
    
        reader.onerror = (error) => {
          reject(error);
        };
    
        reader.readAsDataURL(file);
      });
    };
    // Función para obtener un fotograma específico del video
    const getVideoFrame = (video, frameNumber) => {
      return new Promise(async (resolve, reject) => {
        try {
          video.currentTime = 0; // Establecer el tiempo actual a cero

          video.addEventListener('seeked', async () => {
            const bitmap = await createImageBitmap(video);
            resolve(bitmap);
          });
        } catch (error) {
          reject(error);
        }
      });
    };
    // transfor video to thumbnail blob file
    const createVideoThumbnail = (file, maxWidth, maxHeight, quality) => {
      return new Promise(async (resolve, reject) => {
        const video = document.createElement('video');
        video.src = URL.createObjectURL(file);
    
        video.addEventListener('loadeddata', async () => {
          try {
            const frame = await getVideoFrame(video, 1); // Obtener el fotograma número 1
    
            // Calcular las dimensiones ajustadas de la miniatura
            const aspectRatio = frame.width / frame.height;
            let newWidth, newHeight;
    
            if (aspectRatio > 1) {
              newWidth = Math.min(maxWidth, frame.width);
              newHeight = newWidth / aspectRatio;
            } else {
              newHeight = Math.min(maxHeight, frame.height);
              newWidth = newHeight * aspectRatio;
            }
    
            // Configurar el canvas para crear la miniatura
            const canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext('2d');
    
            // Dibujar el fotograma en el canvas
            ctx.drawImage(frame, 0, 0, canvas.width, canvas.height);
    
            // Crear una URL de la miniatura
            const thumbnailUrl = canvas.toDataURL('image/jpeg', quality);
    
            // Liberar la URL del objeto blob cuando ya no sea necesaria
            URL.revokeObjectURL(video.src);
    
            resolve(thumbnailUrl);
          } catch (error) {
            reject(error);
          }
        });
    
        video.addEventListener('error', (error) => {
          reject(error);
        });
      });
    };
    const uploadToS3 = async (file, name, type, format) => {
      try {
        const credentialProvider = generateCredentialsProvider();
    
        const s3Client = new S3Client({
          region: regionAWS,
          credentials: credentialProvider,
        });
    
        const putObjectCommand = new PutObjectCommand({
          Bucket: bucketNameS3,
          Key: `${name}.${format}`,
          Body: file,
          ContentType: type,
          ContentEncoding: 'base64',
        });
        const response = await s3Client.send(putObjectCommand);

        if (response.$metadata.httpStatusCode) {
          return { status: true };
        } else {
          return { status: false };
        }
      } catch (error) {
        return { status: false };
      }
    };
    const uploadVideoToS3 = async (file, name, type, format) => {
      try {
        const credentialProvider = generateCredentialsProvider();

        const s3Client = new S3Client({
          region: regionAWS,
          credentials: credentialProvider,
        });
    
        const putObjectCommand = new PutObjectCommand({
          Bucket: bucketNameS3,
          Key: `${name}.${format}`,
          Body: file,
          ContentType: type,
        });

        const response = await s3Client.send(putObjectCommand);

        if (response.$metadata.httpStatusCode) {
          return { status: true };
        } else {
          return { status: false };
        }
      } catch (error) {
        return { status: false };
      }
    };
    const requestVideoToS3 =  async () => {
      // uplaod original video to s3
      const videoResponse = await uploadVideoToS3(videoBlodFile, dataFile.uuid, dataFile.type, dataFile.format);

      // generate base64 image thumbnail from video and create thumbnail image for upload to s3
      const generateVideoThumbnailImage = await createVideoThumbnail(videoBlodFile, maxWidthThumbnail, maxHeightThumbnail, maxQualityThumbnail);
      const base64WithOutHeader = generateVideoThumbnailImage.replace(/^data:image\/\w+;base64,/, "");
      const arrayBuffer = Uint8Array.from(atob(base64WithOutHeader), (c) => c.charCodeAt(0)).buffer;
      const blob = new Blob([arrayBuffer], { type: dataFile.type });
      const videoThumbnail = await createThumbnail(blob, maxWidthThumbnail, maxHeightThumbnail, maxQualityThumbnail);
      const videoThumbResponse = await uploadToS3(videoThumbnail, dataFile.uuidThumbnail, dataFile.typeThumbnail, dataFile.formatThumbnail);
      if (videoResponse.status === true && videoThumbResponse.status === true) {
        return { status: true };
      } else {
        return { status: false };
      }
    };
    const requestImageToS3 = async () => {
      // upload original image
      const base64WithOutHeader = previewUrl.replace(/^data:image\/\w+;base64,/, "");
      const blobImage = dataURItoBlob(base64WithOutHeader, dataFile.type);
      const imageResponse = await uploadToS3(blobImage, dataFile.uuid, dataFile.type, dataFile.format);

      // generate and upload thumbnail image
      const arrayBuffer = Uint8Array.from(atob(base64WithOutHeader), (c) => c.charCodeAt(0)).buffer;
      const blob = new Blob([arrayBuffer], { type: dataFile.type });
      const thumbnail = await createThumbnail(blob, maxWidthThumbnail, maxHeightThumbnail, maxQualityThumbnail);
      const thumbResponse = await uploadToS3(thumbnail, dataFile.uuidThumbnail, dataFile.typeThumbnail, dataFile.formatThumbnail);

      if (imageResponse.status === true && thumbResponse.status === true) {
        return { status: true };
      } else {
        return { status: false };
      }
    };

    const handlerRequestToS3ForDelete = async () => {
      try {
        const credentialProvider = generateCredentialsProvider();
    
        const s3Client = new S3Client({
          region: regionAWS,
          credentials: credentialProvider,
        });
        
        const file = `${companyInfo.logo.uuid}.${companyInfo.logo.format}`;
        const fileThumbnail = `${companyInfo.logo.uuidThumbnail}.${companyInfo.logo.formatThumbnail}`;
        
        const objectKeys = [file, fileThumbnail];
  
        const deleteList = objectKeys.map(Key => ({ Key }));
    
        const putObjectCommand = new DeleteObjectsCommand({
          Bucket: bucketNameS3,
          Delete: { Objects: deleteList },
        });
    
        const response = await s3Client.send(putObjectCommand);
    
        if (response.$metadata.httpStatusCode) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    };

    const updateFileListCompanyProfile = async () => {
      if (importButton === "logo") {
        const deletingLastLogo = await handlerRequestToS3ForDelete();

        if (deletingLastLogo) {
          const user = {
            email: userData.email,
            password: userData.password,
            token: userData.token
          };
    
          const sendData = {
            id: activeCompanyProfile.id,
            logo: dataFile,
          };
    
          const responseUpdateCompanyFiles = await patchRequestMeantto('company-logo', user, sendData);
          if (responseUpdateCompanyFiles.status === 200 && responseUpdateCompanyFiles.data.status === true) {
            handlerUpdateCompanyLogo(dataFile);

            SendSocket({
              action: 'send-message',
              data: {
                id: activeCompanyProfile.id,
                user: userData.user,
                message: {
                  type: 'UPDATE',
                  table: 'company-info',
                  sendFromAdminView: true,
                  sendFromCollaboratorView: false,
                  companyOwner: userData.user,
                  idCompanyOwner: activeCompanyProfile.id,
                  userSendUpdate: userData.user,
                  isAdmin: !activeCollaboratorMode.collaboratorMode,
                  data: {
                    user: companyInfo.user,
                    email: companyInfo.email,
                    answerForm: companyInfo.answerForm,
                    generatedContent: companyInfo.generatedContent,
                    counterUseForm: companyInfo.counterUseForm,
                    counterUseGenerateContent: companyInfo.counterUseGenerateContent,
                    logo: dataFile,
                  }
                }
              }
            });

            return { status: true };
          } else {
            return { status: false };
          }
        }

      } else {
        // Realiza las operaciones dentro del método
        const updatedFileList = [...tempFileList, dataFile];
        const updatedCounterFiles = parseInt(tempCounterFiles, 10) - 1;
  
        const user = {
          email: userData.email,
          password: userData.password,
          token: userData.token,
          typeAccess: 1
        };
  
        const sendData = {
          id: activeCompanyProfile.id,
          fileList: updatedFileList,
          counterFiles: updatedCounterFiles,
        };
  
        const responseUpdateCompanyFiles = await patchRequestCollaborator('company-file', user, sendData);
        if (responseUpdateCompanyFiles.status === 200 && responseUpdateCompanyFiles.data.status === true) {
  
          SendSocket({
            action: 'send-message',
            data: {
              id: activeCompanyProfile.id,
              user: userData.user,
              message: {
                type: 'UPDATE',
                table: 'company-file',
                sendFromAdminView: true,
                sendFromCollaboratorView: false,
                companyOwner: userData.user,
                idCompanyOwner: activeCompanyProfile.id,
                userSendUpdate: userData.user,
                isAdmin: !activeCollaboratorMode.collaboratorMode,
                data: { 
                  user: companyFiles.user,
                  id: activeCompanyProfile.id,
                  email: companyFiles.email,
                  fileList: updatedFileList,
                  counterFiles: updatedCounterFiles,
                  storageForFiles: companyFiles.storageForFiles,
                }
              }
            }
          });

          handlerUpdateFileList(sendData);
          handlerUpdateCounterFiles(sendData);
  
          // Utiliza los resultados actualizados con useState
          setTempFileList(updatedFileList);
          setTempCounterFiles(updatedCounterFiles);
  
          return { status: true };
        } else {
          return { status: false };
        }
      }
    };
    const handlerProcessVideo = async () => {
      try {
        const requestUploadVideo = await requestVideoToS3();
        if (requestUploadVideo.status === true) {
          const requestUpdateFileListCompany = await updateFileListCompanyProfile();
          if (requestUpdateFileListCompany.status === true) {
            closeModalUploadFiles();
            openAlert("success", "Success");
          } else {
            setUploadFileProcess("error");
            openAlert("danger", "Error, try upload your video again");
          }
        } else {
          setUploadFileProcess("error");
          openAlert("danger", "Error, try upload your video again");
        }
      } catch (error) {
        openAlert("danger", "Error, try upload your video again");
      }
    };
    const handlerProcessImage = async () => {
      try {
        const requestUploadImage = await requestImageToS3();
        if (requestUploadImage.status === true) {
          const requestUpdateFileListCompany = await updateFileListCompanyProfile();
          if (requestUpdateFileListCompany.status === true) {
            closeModalUploadFiles();
            openAlert("success", "Success");
          } else {
            setUploadFileProcess("error");
            openAlert("danger", "Error, try upload your image again");
          }
        } else {
          setUploadFileProcess("error");
          openAlert("danger", "Error, try upload your image again");
        }
      } catch (error) {
        openAlert("danger", "Error, try upload your image again");
      }
    };
    const handleUpload = async () => {
      const validateIfFreeStorageToNewFile = validateStorage(companyFiles.fileList, companyFiles.storageForFiles, dataFile.size);

      if (validateIfFreeStorageToNewFile === false) {
        setUploadFileProcess("loading");
        if (selectedFile) {
          try {
            if (dataFile.type.startsWith('video/')) {
              await handlerProcessVideo();
            }
  
            if (dataFile.type.startsWith('image/')) {
              await handlerProcessImage();
            }
          } catch (error) {
            openAlert("danger", "Error");
          }
        }
      } else {
        openAlert("danger", "The storage is not sufficient");
      }
    };
    const handlerValidateIfExistFile = () => {
      if (previewUrl || selectedFile) {
        return true;
      } else {
        return false;
      }
    };
  //#endregion

  const viewModalUploadFiles = () => {
    return (<Fade duration={1000} opposite>
        <div className='container-upload-file'>
          {previewUrl ? (
            <>
              {allowedImageFormats.includes(dataFile.type) && (
                <Fade duration={600} opposite>
                  <img src={previewUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '400px' }} />
                </Fade>
              )}

              {allowedVideoFormats.includes(dataFile.type) && (
                <Fade duration={600} opposite>
                  <video controls width="100%" height="100%">
                    <source src={selectedFile} type={dataFile.type} />
                    Tu navegador no soporta el tag de video.
                  </video>
                </Fade>
              )}
            </>
          ) : (
            <Fade duration={600} opposite>
              <div className="file-container">
                <span className='text-muted unselectable'>
                  Upload your {`${importButton === "logo" ? "logo" : "file"}`}
                </span>
              </div>
            </Fade>
          )}
        </div>
        <input
          id="input-upload-file"
          className="d-none"
          type="file"
          accept={`${importButton === "logo" ? "image/jpeg,image/png" : "video/mp4,image/jpeg,image/png"}`}
          onChange={handleImageChange}
        />
        <label className="btn-upload-file" htmlFor='input-upload-file'>
          Select {`${importButton === "logo" ? "logo" : "file"}`}
        </label>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </Fade>
    );
  };

  return (
    <>  
      {importButton === "gallery modal" && (
        <button
          type='button'
          className="btn btn-upload-file-gallery"
          onClick={() => openModalUploadFiles()}
        >
          <CameraAltRoundedIcon />
          Upload files
        </button>
      )}

      {importButton === "content calendar" && (
        <button
          type='button'
          className="btn-upload-filea-calendar-action mr-2"
          onClick={() => openModalUploadFiles()}
          >
          <CameraAltRoundedIcon />
          Upload files
        </button>
      )}

      {importButton === "detail post" && (
        <button
          type='button'
          className="btn-select-file btn-primary"
          onClick={() => openModalUploadFiles()}
        >
          Upload files
        </button>
      )}
      
      {importButton === "logo" && (
        <button
          type='button'
          className="border-none"
          onClick={() => openModalUploadFiles()}
        >
          <FileUploadIcon className="icon-primary"/>
        </button>
      )}

      <Modal
        open={modalUploadFiles}
        showCloseIcon={false}
        blockScroll={true}
        onClose={() => closeModalUploadFiles()}
        classNames={{
          overlay: 'customOverlay',
          modal: isMobileDevice ? 'fullscreen-modal' : 'customModal',
        }}
        center
      >
        <div className="modal-body">
          <div className="modal-content">
            <div className='w-100 d-flex justify-content-between'>
              <Text type="subtitle">
                Upload file
              </Text>
            </div>
            
            <div className="d-flex flex-column flex-1">
              {(uploadFileProcess === "create" || uploadFileProcess === "error") && (
                viewModalUploadFiles()
              )}
            </div>

            {uploadFileProcess === "loading" && (
              <Fade duration={1000} opposite>
                <div className="d-flex flex-column justify-content-center align-items-center pt-4 pb-4 pl-4 pr-4 w-100">
                  <div style={{ padding: '50px 0px 20px 0px' }}>
                    <SyncLoader color="#6001d0" />
                  </div>
                  <span>
                    Uploading {`${importButton === "logo" ? "logo" : "file"}`}
                  </span>
                </div>
              </Fade>
            )}
          </div>

          <div className="action-btn-form">
            {uploadFileProcess === "create" && (
              <Fade duration={1000} opposite>
                <button
                  className="two-btn-modal"
                  onClick={() => closeModalUploadFiles()}>
                  Close
                </button>
                <button
                  className={`two-btn-modal ${!handlerValidateIfExistFile() && 'btn-disabled'}`}
                  onClick={() => handleUpload()}
                  disabled={!handlerValidateIfExistFile()}
                >
                  Upload
                </button>
              </Fade>
            )}

            {uploadFileProcess === "error" && (
              <Fade duration={1000} opposite>
                <button
                  className="two-btn-modal"
                  onClick={() => closeModalUploadFiles()}>
                  Close
                </button>
                <button
                  className={`two-btn-modal ${!handlerValidateIfExistFile() && 'btn-disabled'}`}
                  onClick={() => handleUpload()}
                  disabled={!handlerValidateIfExistFile()}
                >
                  Try again
                </button>
              </Fade>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

UploadFiles.propTypes = {
  importButton: PropTypes.string.isRequired,
  SendSocket: PropTypes.func.isRequired,
  logoFile: PropTypes.string,
  logoThumbnail: PropTypes.string
};

export default UploadFiles;
