import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

function ErrorInSignIn({ show }) {
  const history = useHistory();

  const handlerHideFirtsStepsToUser = () => {
    sessionStorage.clear();
    history.push('/');
    window.location.reload();
  };

  return (
    <Modal
      open={show}
      showCloseIcon={false}
      blockScroll={true}
      onClose={() => handlerHideFirtsStepsToUser()}
      classNames={{
        overlay: 'customOverlay',
        modal: 'customModal',
      }}
      center
      >
      <div className="modal-body">
        <div className="modal-content">
          <div className='d-flex align-items-center justify-content-center' style={{ height: '90%' }}>
            <span className="text-muted">
              Image that explains to the user what happens &#40;Error in request data user&#41;.
            </span>
          </div>
        </div>
        <div className="action-btn-form">
          <button
            className="one-btn-modal"
            onClick={() => handlerHideFirtsStepsToUser()}>
              Close
          </button>
        </div>
      </div>
    </Modal>
  )
}

ErrorInSignIn.propTypes = {
  show: PropTypes.bool.isRequired
};

export default ErrorInSignIn;
