import { createSlice } from '@reduxjs/toolkit';

export const userData = createSlice({
  name: 'userData',
  initialState: {
    value: {
      email: "",
      password: "",
      user: "",
      active: false,
      lastLogged: "",
      token: "",
      firtsLogin: false,
      servicePlan: ""
    },
  },
  reducers: {
    setLoginInfo: (state, action) => {
      state.value.email = action.payload.email;
      state.value.password = action.payload.password;
      state.value.user = action.payload.user;
      state.value.active = action.payload.active;
      state.value.lastLogged = action.payload.lastLogged;
      state.value.token = action.payload.token;
      state.value.firtsLogin = action.payload.firtsLogin;
      state.value.servicePlan = action.payload.servicePlan;
    },
    setFirtsLoginHide: (state, action) => {
      state.value.firtsLogin = false;
    },
  }
});

export const { setLoginInfo, setFirtsLoginHide } = userData.actions;

export default userData.reducer;
