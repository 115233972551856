/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const postsCompany = createSlice({
  name: 'postsCompany',
  initialState: {
    value: {
      user: "",
      id: "",
      email: "",
      maxGenerateContentPost: 0,
      maxGenerateSpecificPost: 0,
      maxGenerateVideoPosts: 0,
      postList: []
    },
  },
  reducers: {
    setPostsCompany: (state, action) => {
      state.value.user = action.payload.user;
      state.value.id = action.payload.id;
      state.value.email = action.payload.email;
      state.value.maxGenerateContentPost = parseInt(action.payload.maxGenerateContentPost, 10);
      state.value.maxGenerateSpecificPost = parseInt(action.payload.maxGenerateSpecificPost, 10);
      state.value.maxGenerateVideoPosts = parseInt(action.payload.maxGenerateVideoPosts, 10);
      state.value.postList = action.payload.postList;
    },
    updatePostList: (state, action) => {
      const updatedPost = action.payload;
      const existingPostIndex = state.value.postList.findIndex(post => post.id === updatedPost.id);

      if (existingPostIndex !== -1) {
        state.value.postList[existingPostIndex] = updatedPost;
      } else {
        state.value.postList.push(updatedPost);
      }
    },
    updatePostListArray: (state, action) => {
      const postList = action.payload;

      for (let newPost of postList) {
        state.value.postList.push(newPost);
      }
    },
    updateCounter: (state, action) => {
      state.value[action.payload.key] = action.payload.value;
    },
    resetPostsCompany: (state, action) => {
      state.value.user = "";
      state.value.id = "";
      state.value.email = "";
      state.value.maxGenerateContentPost = 0;
      state.value.maxGenerateSpecificPost = 0;
      state.value.maxGenerateVideoPosts = 0;
      state.value.postList = [];
    },
  }
});

export const {
  setPostsCompany, updatePostList, updatePostListArray, updateCounter, resetPostsCompany
} = postsCompany.actions;

export default postsCompany.reducer;
