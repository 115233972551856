import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCompanyFiles } from '../../../reducers/companyFiles';
import { setPostsCompany } from '../../../reducers/postsCompany';
import { setPostsCompany as setPostsCompanyAsCollaborator } from "../../../reducers/postsCompanyAsCollaborator";
import { setCompanyFiles as setCompanyFilesAsCollaborator } from "../../../reducers/companyFilesAsCollaborator";
import { setCompanyInfoAsCollaborator } from '../../../reducers/companyInfoAsCollaborator';
import { setGeneratedContentAsCollaborator } from '../../../reducers/generatedContentAsCollaborator';


const UseUpdateSocket = (type, table, sendFromAdminView, sendFromCollaboratorView, companyOwner, idCompanyOwner, userSendUpdate, isAdmin, data) => {
  const activeCollaboratorMode = useSelector((state) => state.activeCollaboratorMode.value);
  // eslint-disable-next-line no-unused-vars
  const userData = useSelector((state) => state.userData.value);
  const activeCompanyProfile = useSelector((state) => state.activeCompanyProfile.value);
  const collaboratorCompanySelected = useSelector((state) => state.collaboratorCompanySelected.value);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const handlerSetPostList = (infoCompany) => {
    dispatch(setPostsCompany(infoCompany));
  };
  
  const handlerSetPostListAsCollaborator = (infoCompany) => {
    dispatch(setPostsCompanyAsCollaborator(infoCompany));
  };

  const handlerSetCompanyFiles = (infoCompany) => {
    dispatch(setCompanyFiles(infoCompany));
  };

  const handlerSetCompanyFilesAsCollaborator = (infoCompany) => {
    dispatch(setCompanyFilesAsCollaborator(infoCompany));
  };

  const handlerSetCompanyInfoAsCollaborator = (infoCompany) => {
    dispatch(setCompanyInfoAsCollaborator(infoCompany));
  };

  const handlerSetGeneratedContentAsCollaborator = (infoCompany) => {
    dispatch(setGeneratedContentAsCollaborator(infoCompany));
  };

  const updateDataFromSocket = (type, table, sendFromAdminView, sendFromCollaboratorView, companyOwner, idCompanyOwner, userSendUpdate, isAdmin, data) => {
    try {
      switch (table) {
        case 'company-posts':
  
          if (sendFromAdminView === true && sendFromCollaboratorView === false) {
            handlerSetPostListAsCollaborator(data);
          } 
  
          if (sendFromAdminView === false && sendFromCollaboratorView === true) {
            if (activeCollaboratorMode.collaboratorMode === true) {
              if (collaboratorCompanySelected.userCompanyOwner === companyOwner && collaboratorCompanySelected.idCompany === idCompanyOwner) {
                handlerSetPostListAsCollaborator(data);
              }
            }
  
            if (activeCollaboratorMode.collaboratorMode === false) {
              if (userData.user === companyOwner && activeCompanyProfile.id === idCompanyOwner) {
                handlerSetPostList(data);
              }
            }
          }
  
          break;
        case 'company-file':
          if (sendFromAdminView === true && sendFromCollaboratorView === false) {
            handlerSetCompanyFilesAsCollaborator(data);
          } 
  
          if (sendFromAdminView === false && sendFromCollaboratorView === true) {
            if (activeCollaboratorMode.collaboratorMode === true) {
              if (collaboratorCompanySelected.userCompanyOwner === companyOwner && collaboratorCompanySelected.idCompany === idCompanyOwner) {
                handlerSetCompanyFilesAsCollaborator(data);
              }
            }
  
            if (activeCollaboratorMode.collaboratorMode === false) {
              if (userData.user === companyOwner && activeCompanyProfile.id === idCompanyOwner) {
                handlerSetCompanyFiles(data);
              }
            }
          }
          break;
        case 'company-info':
          if (sendFromAdminView === true && sendFromCollaboratorView === false) {
            handlerSetCompanyInfoAsCollaborator(data);
            handlerSetGeneratedContentAsCollaborator(data.generatedContent);
          } 
  
          if (sendFromAdminView === false && sendFromCollaboratorView === true) {
            if (activeCollaboratorMode.collaboratorMode === true) {
              if (collaboratorCompanySelected.userCompanyOwner === companyOwner && collaboratorCompanySelected.idCompany === idCompanyOwner) {
                handlerSetCompanyInfoAsCollaborator(data);
                handlerSetGeneratedContentAsCollaborator(data.generatedContent);
              }
            }
            
            /*
            if (activeCollaboratorMode.collaboratorMode === false) {
              if (userData.user === companyOwner && activeCompanyProfile.id === idCompanyOwner) {
                handlerSetCompanyFiles(data);
              }
            }
            */
          }
          break;
        default:
          break;
      }
  
      return { status: 200, message: "updated" }
    } catch (error) {
      return { status: 500, message: error }
    }
  };

  useEffect(() => {
    updateDataFromSocket(type, table, sendFromAdminView, sendFromCollaboratorView, companyOwner, idCompanyOwner, userSendUpdate, isAdmin, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, table, sendFromAdminView, sendFromCollaboratorView, companyOwner, idCompanyOwner, userSendUpdate, isAdmin, data]);
  
  return { updateDataFromSocket };
};

export default UseUpdateSocket;