import React, { memo } from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import Fade from "react-reveal/Fade";
import Text from '../share-components/text/text';
import { useMediaQuery } from '@react-hook/media-query';
import PillCompany from '../share-components/pillCompany/pillCompany';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import SellRoundedIcon from '@mui/icons-material/SellRounded';
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import TranslateRoundedIcon from '@mui/icons-material/TranslateRounded';
// import LocalPostOfficeRoundedIcon from '@mui/icons-material/LocalPostOfficeRounded';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function Index() {
  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');

  const accountList = [
    {
      id: 1,
      name: 'Account',
      path: '/settings/account',
      icon: <AccountCircleRoundedIcon className='color-text-primary' />
    },
    {
      id: 2,
      name: 'Security',
      path: '/settings/security',
      icon: <SecurityRoundedIcon className='color-text-primary' />
    },
  ];

  const paymentsList = [
    {
      id: 1,
      name: 'Subscription',
      path: '/settings/subscription',
      icon: <SellRoundedIcon className='color-text-primary' />
    },
    {
      id: 2,
      name: 'Payments',
      path: '/settings/payments',
      icon: <PaymentRoundedIcon className='color-text-primary' />
    },
  ];

  const appList = [
    {
      id: 1,
      name: 'Notifications',
      path: '/settings/notifications',
      icon: <NotificationsRoundedIcon className='color-text-primary' />
    },
    {
      id: 2,
      name: 'Language',
      path: '/settings/language',
      icon: <TranslateRoundedIcon className='color-text-primary' />
    },
    /*{
      id: 3,
      name: 'Send us message',
      path: '/settings/send-us-message',
      icon: <LocalPostOfficeRoundedIcon className='color-text-primary' />
    },*/
  ];

  const handlerBuildMenu = (list) => {
    return <div className="container-list container-cards-menu w-100">
      {list.map((menu, index, array) => (
        <Link to={`${menu.path}`} className={`item-list ${index === 0 ? 'firts-child' : ''} ${index === array.length - 1 ? 'last-child' : ''} ${index !== array.length - 1 ? 'column-divider' : ''}`}>
          <div className='left-container'>
            {menu.icon}
            <a href={menu.path}>
              <Text type="body">{menu.name}</Text>
            </a>
          </div>
          <div className='right-container'>
            <NavigateNextIcon />
          </div>
        </Link>
      ))}
    </div>;
  };

  return (
    <div>
      <Fade duration={600} opposite>
        <div className="content-title">
          <Text type="title">
            Settings
          </Text>
          {isMobileDevice && <img src="./assets/logos/symbol/Simbolo/PNG/morado.png" alt="" />}
        </div>
        <>
          {isMobileDevice && (
            <PillCompany />
          )}
        </>
        {handlerBuildMenu(accountList)}
        {handlerBuildMenu(paymentsList)}
        {handlerBuildMenu(appList)}
      </Fade>
    </div>
  )
}

export default memo(Index);
