import React from 'react';
import { useHistory } from 'react-router-dom';
import './style.scss';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { setShowCompanyList } from '../../../reducers/showCompanyList';
import { setShowModal } from '../../../reducers/settingsCompany';
import { setShowModalCollaboratorCompanyList } from '../../../reducers/modalCompanyCollaborator';
import { setDisableMode } from '../../../reducers/activeCollaboratorMode';
import CleanCompanyCollab from '../../index/colllaboratorLab/cleanCompanyCollab';
import { IsCollaboratorPlan } from '../../services/isCollaboratorPlan/IsCollaboratorPlan';
import { useMediaQuery } from '@react-hook/media-query';

function PillCompany() {
  const history = useHistory();

  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');

  const activeCompanyProfile = useSelector((state) => state.activeCompanyProfile.value);
  const userData = useSelector((state) => state.userData.value);
  const companyInfo = useSelector((state) => state.companyInfo.value);
  const companyCollaborator = useSelector((state) => state.companyCollaborator.value);
  const activeCollaboratorMode = useSelector((state) => state.activeCollaboratorMode.value);
  const collaboratorCompanySelected = useSelector((state) => state.collaboratorCompanySelected.value);
  const companyInfoAsCollaborator = useSelector((state) => state.companyInfoAsCollaborator.value);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const handlerSetShowCompanyList = () => {
    dispatch(setShowCompanyList());
  };
    
  const handlerSetShowModal = () => {
    dispatch(setShowModal());
  };

  const handlerSetShowModalCollaboratorCompanyList = () => {
    dispatch(setShowModalCollaboratorCompanyList());
  };
  
  const handlerExitCollaboratorMode = async () => {
    history.push('/');
    await CleanCompanyCollab(dispatch);
    dispatch(setDisableMode());
  };

  const handlerBuildLogoCompany = (logo) => {
    if (logo) {
      return <img
        src={logo}
        alt=""
        style={{ height: '28px', width: '28px', borderRadius: '6px' }}
      />;
    } else {
      return <AccountBoxRoundedIcon className='text-muted' />;
    }
  };

  return (
    <div
      className={`pill-company ${isMobileDevice && 'mobile-pill-company'}`}
    >
      {activeCompanyProfile.name && (
        activeCollaboratorMode.collaboratorMode === false ? (
          <button
            className='company-profile-div bg-transparent border-none w-100'
            key={activeCompanyProfile.id}
            onClick={() => handlerSetShowCompanyList()}
          >
            {handlerBuildLogoCompany(companyInfo?.logo?.urlThumbnail)}
            <span className={`name-company-pill ${companyCollaborator.companyList.length > 0 && 'max-width-name-company-pill-1'}`}>
              {activeCompanyProfile.name}
            </span>
          </button>
        ) : (
          <button
            className='company-profile-div bg-transparent border-none w-100'
            key={activeCompanyProfile.id}
          >
            {handlerBuildLogoCompany(companyInfoAsCollaborator?.logo?.urlThumbnail)}
            <span className={`name-company-pill ${companyCollaborator.companyList.length > 0 && 'max-width-name-company-pill-1'}`}>
              {collaboratorCompanySelected.name}
            </span>
          </button>
        )
      )}

      {IsCollaboratorPlan(userData.servicePlan) && (
        <button
          className='company-profile-div bg-transparent border-none w-100'
          key={activeCompanyProfile.id}
          onClick={() => handlerSetShowCompanyList()}
        >
          {handlerBuildLogoCompany(companyInfo?.logo?.urlThumbnail)}
          <span className={`name-company-pill ${companyCollaborator.companyList.length > 0 && 'max-width-name-company-pill-1'}`}>
            My companies
          </span>
        </button>
      )}

      {companyCollaborator.companyList.length > 0 && (
        <button
          className='btn-change-admin-to-collaborator bg-transparent border-none'
          onClick={() => handlerSetShowModalCollaboratorCompanyList()}
        >
          <ImportExportIcon className='icon-change-admin-to-collaborator' />
        </button>
      )}

      {activeCollaboratorMode.collaboratorMode === false ? (
        <button
          type='button'
          className='settings-profile-div bg-transparent border-none'
          onClick={() => handlerSetShowModal()}
        >
          <SettingsRoundedIcon />
        </button>
      ) : (
        <button
          type='button'
          className='settings-profile-div btn-primary border-none'
          onClick={() => handlerExitCollaboratorMode()}
        >
          <ArrowBackIcon />
        </button>
      )}
    </div>
  )
};

export default PillCompany;