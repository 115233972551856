/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const showCompanyList = createSlice({
  name: 'showCompanyList',
  initialState: {
    value: {
      show: false
    },
  },
  reducers: {
    setShowCompanyList: (state, action) => {
      state.value.show = true;
    },
    setHideCompanyList: (state, action) => {
      state.value.show = false;
    },
  }
});

export const {
  setShowCompanyList, setHideCompanyList
} = showCompanyList.actions;

export default showCompanyList.reducer;
