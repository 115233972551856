import React, { useState } from 'react';
import './style.scss';
import { useMediaQuery } from '@react-hook/media-query';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Fade from "react-reveal/Fade";
import { Link } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import Text from '../../../share-components/text/text';

function Language() {
  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');
  const [languageSelected, setLanguageSelected] = useState(1);

  const languageList = [
    {
      id: 1,
      "language": "English",
      "code": "en"
    },
    { 
      id: 2,
      "language": "Spanish",
      "code": "es"
    },
    {
      id: 3,
      "language": "French",
      "code": "fr"
    },
    {
      id: 4,
      "language": "German",
      "code": "de"
    },
    {
      id: 5,
      "language": "Chinese",
      "code": "zh"
    },
    {
      id: 6,
      "language": "Japanese",
      "code": "ja"
    },
    {
      id: 7,
      "language": "Russian",
      "code": "ru"
    },
    {
      id: 8,
      "language": "Arabic",
      "code": "ar"
    },
    {
      id: 9,
      "language": "Portuguese",
      "code": "pt"
    },
    {
      id: 10,
      "language": "Hindi",
      "code": "hi"
    }
  ];

  const languageInfo = [
    {
      id: 1,
      name: 'Language',
      value: languageList.find((x) => x.id === languageSelected).language
    },
  ];

  const [openModalLanguage, setOpenModalLanguage] = useState(false);

  const handlerOpenModalLanguage = () => {
    setOpenModalLanguage(true);
  };

  const handlerCloseModalLanguage = () => {
    setOpenModalLanguage(false);
  };

  const handleLanguageSelect = (id) => {
    setLanguageSelected(id);
    handlerCloseModalLanguage();
  };

  const handlerBuildInfo = (list) => {
    return <div className="container-list container-cards-menu w-100">
      {list.map((menu, index, array) => (
        <div
          className={`item-list ${index === 0 ? 'firts-child' : ''} ${index === array.length - 1 ? 'last-child' : ''} ${index !== array.length - 1 ? 'column-divider' : ''}`}
          onClick={() => handlerOpenModalLanguage()}
        >
          <div className='left-container'>
            <Text type="body">{menu.name}</Text>
          </div>
          <div className='right-container'>
            <Text type="body" inyectClass="text-muted">{menu.value}</Text>
          </div>
        </div>
      ))}
    </div>;
  };

  return (
    <div>
      <Fade duration={600} opposite>
        <div className="content-title-embed">
          <div>
            <Link to="/settings" className="link-back">
              <ArrowBackIosNewRoundedIcon className='color-text-primary'/>
              <Text type='body' inyectClass="color-text-primary">
                Settings
              </Text>
            </Link>
          </div>
          <div>
            <Text type="title">
              Language
            </Text>
          </div>
          <div></div>
        </div>
        <div className={`container-company-profile content-view pt-4`}>

          {handlerBuildInfo(languageInfo)}
        </div>
      </Fade>

      <Modal
        open={openModalLanguage}
        showCloseIcon={false}
        onClose={() => handlerCloseModalLanguage()}
        closeOnEsc={false}
        blockScroll={false}
        closeOnOverlayClick={false}
        classNames={{
          overlay: 'customOverlay',
          modal: isMobileDevice ? 'fullscreen-modal' : 'customModal',
        }}
        center
      >
        <div className="modal-body modal-md">
          <div className="modal-content">
            <div className="content-title">
              <Text type="subtitle" inyectClass="color-text-primary">
                Select Language
              </Text>
            </div>
            <div className='container-list container-cards-menu w-100'>
              {languageList.map((menu, index, array) => (
                <div
                  key={index}
                  onClick={() => handleLanguageSelect(menu.id)}
                  className={`item-list ${index === 0? 'firts-child' : ''} ${index === array.length - 1? 'last-child' : ''} ${index!== array.length - 1? 'column-divider' : ''}`}
                >
                  <div className='left-container'>
                    <Text type="body">{menu.language}</Text>
                  </div>
                  <div className='right-container'>
                    <input type="radio" name="language" checked={menu.id === languageSelected} />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="action-btn-form">
            <button
              className={`one-btn-modal`}
              onClick={() => handlerCloseModalLanguage()}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Language;
