import React, { useState } from 'react';
import './style.scss';
import Fade from "react-reveal/Fade";
import { Link } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import Text from '../../../share-components/text/text';

function Notifications() {

  const [enableNotifications, setEnableNotification] = useState(false);

  const notificationInfo = [
    {
      id: 1,
      name: 'Enable notifications',
      value: enableNotifications
    },
  ];

  const handleToggleNotifications = () => {
    setEnableNotification(!enableNotifications);
  };

  const handlerBuildInfo = (list) => {
    return <div className="container-list container-cards-menu w-100">
      {list.map((menu, index, array) => (
        <div
          className={`item-list ${index === 0 ? 'firts-child' : ''} ${index === array.length - 1 ? 'last-child' : ''} ${index !== array.length - 1 ? 'column-divider' : ''}`}
          onClick={() => handleToggleNotifications()}
        >
          <div className='left-container'>
            <Text type="body">{menu.name}</Text>
          </div>
          <div className='right-container'>
            <div
              className={`btn-toogle ${menu.value ? 'active' : ''}`} 
            >
              <div className="circle-toogle">
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>;
  };

  return (
    <div>
      <Fade duration={600} opposite>
        <div className="content-title-embed">
          <div>
            <Link to="/settings" className="link-back">
              <ArrowBackIosNewRoundedIcon className='color-text-primary'/>
              <Text type='body' inyectClass="color-text-primary">
                Settings
              </Text>
            </Link>
          </div>
          <div>
            <Text type="title">
              Notifications
            </Text>
          </div>
          <div>

          </div>
        </div>
        <div className={`container-company-profile content-view pt-4`}>
          <div className="container-cards-menu mb-0">
            <Text type="small" inyectClass="text-muted">
              Enable notifications
            </Text>
          </div>
          {handlerBuildInfo(notificationInfo)}
        </div>
      </Fade>
    </div>
  );
};

export default Notifications;
