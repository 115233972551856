
import { cleanCompanyInfoAsCollaborator } from "../../../reducers/companyInfoAsCollaborator";
import { cleanGeneratedContentAsCollaborator } from "../../../reducers/generatedContentAsCollaborator";
import { cleanPostsCompany } from "../../../reducers/postsCompanyAsCollaborator";
import { cleanCompanyFiles } from "../../../reducers/companyFilesAsCollaborator";
import { cleanCompanyProfileSelected } from "../../../reducers/collaboratorCompanySelected";
import { resetImageSelectedForPost } from "../../../reducers/imageSelectedForPost";

const CleanCompanyCollab = async (dispatch) => {
  dispatch(resetImageSelectedForPost());
  dispatch(cleanCompanyProfileSelected());
  dispatch(cleanCompanyInfoAsCollaborator());
  dispatch(cleanGeneratedContentAsCollaborator());
  dispatch(cleanPostsCompany());
  dispatch(cleanCompanyFiles());

  return true;
};

export default CleanCompanyCollab;