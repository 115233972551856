/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const userAgent = createSlice({
  name: 'userAgent',
  initialState: {
    value: {
      device: "",
      theme: "",
    },
  },
  reducers: {
    setNavigator: (state, action) => {
      state.value.device = action.payload.device;
    },
    setTheme: (state, action) => {
      state.value.theme = "light-mode";
      // state.value.theme = action.payload.theme;
    },
  }
});

export const {
    setNavigator, setTheme
} = userAgent.actions;

export default userAgent.reducer;
