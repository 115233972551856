// En algún archivo de utilidades o donde sea apropiado en tu proyecto
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";

const generateCredentialsProvider = () => {
  const cognitoClient = new CognitoIdentityClient({ region: "us-east-1" });
  const identityPoolId = "us-east-1:3513fd7a-08b8-4ab9-bf15-8d0639e9807c";

  return fromCognitoIdentityPool({
    client: cognitoClient,
    identityPoolId: identityPoolId,
  });
};

export default generateCredentialsProvider;
