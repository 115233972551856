/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const companyInfoAsCollaborator = createSlice({
  name: 'companyInfoAsCollaborator',
  initialState: {
    value: {
      user: "",
      email: "",
      answerForm: {},
      generatedContent: {},
      counterUseForm: 0,
      counterUseGenerateContent: {},
      logo: {}
    },
  },
  reducers: {
    setCompanyInfoAsCollaborator: (state, action) => {
      state.value.user = action.payload.user;
      state.value.email = action.payload.email;
      state.value.answerForm = action.payload.answerForm;
      state.value.generatedContent = action.payload.generatedContent;
      state.value.counterUseForm = action.payload.counterUseForm;
      state.value.counterUseGenerateContent = action.payload.counterUseGenerateContent;
      state.value.logo = action.payload.logo;
    },
    cleanCompanyInfoAsCollaborator: (state, action) => {
      state.value.user = "";
      state.value.email = "";
      state.value.answerForm = {};
      state.value.generatedContent = {};
      state.value.counterUseForm = 0;
      state.value.counterUseGenerateContent = {};
      state.value.logo = {};
    },
  }
});

export const {
  setCompanyInfoAsCollaborator, cleanCompanyInfoAsCollaborator
} = companyInfoAsCollaborator.actions;

export default companyInfoAsCollaborator.reducer;
