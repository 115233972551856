/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const settingsCompany = createSlice({
  name: 'settingsCompany',
  initialState: {
    value: {
      show: false,
    },
  },
  reducers: {
    setShowModal: (state, action) => {
      state.value.show = true;
    },
    setHideModal: (state, action) => {
      state.value.show = false;
    },
  }
});

export const {
  setShowModal, setHideModal
} = settingsCompany.actions;

export default settingsCompany.reducer;
