/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const companyCollaborator = createSlice({
  name: 'companyCollaborator',
  initialState: {
    value: {
      user: "",
      email: "",
      companyList: []
    },
  },
  reducers: {
    setCompanyCollaborator: (state, action) => {
      state.value.user = action.payload.user;
      state.value.email = action.payload.email;
      state.value.companyList = action.payload.companyList;
    }
  },
});

export const {
  setCompanyCollaborator
} = companyCollaborator.actions;

export default companyCollaborator.reducer;
