import React, { useState } from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@react-hook/media-query';
import Fade from "react-reveal/Fade";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import Text from '../../../share-components/text/text';
import ForgotPassword from '../../../security/forgotPassword';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function Security({ SendSocket }) {
  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');
  const securityInfo = [
    {
      id: 1,
      name: 'Change Password',
      value: ''
    },
  ];

  const [openModalChangePassword, setOpenModalChangePassword] = useState(false);

  const handlerOpenModalChangePassword = () => {
    setOpenModalChangePassword(true);
  };

  const handlerCloseModalChangePassword = () => {
    setOpenModalChangePassword(false);
  };

  const handlerBuildChangePassword = (list) => {
    return <div className="container-list container-cards-menu w-100">
      {list.map((menu, index, array) => (
        <div
          className={`item-list ${index === 0 ? 'firts-child' : ''} ${index === array.length - 1 ? 'last-child' : ''} ${index !== array.length - 1 ? 'column-divider' : ''}`}
          onClick={() => handlerOpenModalChangePassword()}
        >
          <div className='left-container'>
            <Text type="body">{menu.name}</Text>
          </div>
          <div className='right-container'>
            <NavigateNextIcon />
          </div>
        </div>
      ))}
    </div>;
  };

  return (
    <div>
      <Fade duration={600} opposite>
        <div className="content-title-embed">
          <div>
            <Link to="/settings" className="link-back">
              <ArrowBackIosNewRoundedIcon className='color-text-primary'/>
              <Text type='body' inyectClass="color-text-primary">
                Settings
              </Text>
            </Link>
          </div>
          <div>
            <Text type="title">
              Security
            </Text>
          </div>
          <div></div>
        </div>
        <div className={`container-company-profile content-view pt-4`}>
          <div className="container-cards-menu mb-0">
            <Text type="small" inyectClass="text-muted">
              Change Password
            </Text>
          </div>
          {handlerBuildChangePassword(securityInfo)}
        </div>
      </Fade>

      <Modal
        open={openModalChangePassword}
        showCloseIcon={false}
        onClose={() => handlerCloseModalChangePassword()}
        closeOnEsc={false}
        blockScroll={false}
        closeOnOverlayClick={false}
        classNames={{
          overlay: 'customOverlay',
          modal: isMobileDevice ? 'fullscreen-modal' : 'customModal',
        }}
        center
      >
        <div className="modal-body modal-sm">
          <div className="modal-content">
            <div className="content-title">
              <Text type="subtitle" inyectClass="color-text-primary">
                Change Password
              </Text>
            </div>
            <div className='w-100'>
              <ForgotPassword from={'modal'} SendSocket={SendSocket} />
            </div>

          </div>
          <div className="action-btn-form">
            <button
              className={`one-btn-modal`}
              onClick={() => handlerCloseModalChangePassword()}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

Security.propTypes = {
  SendSocket: PropTypes.func.isRequired,
};

export default Security;
