/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const viewCreateCompanyProfile = createSlice({
  name: 'viewCreateCompanyProfile',
  initialState: {
    value: {
      show: false
    },
  },
  reducers: {
    setShowViewCreateCompanyProfile: (state, action) => {
      state.value.show = true;
    },
    setHideViewCreateCompanyProfile: (state, action) => {
      state.value.show = false;
    },
  }
});

export const {
  setShowViewCreateCompanyProfile, setHideViewCreateCompanyProfile
} = viewCreateCompanyProfile.actions;

export default viewCreateCompanyProfile.reducer;
