import { setShowViewCreateCompanyProfile } from "../../../reducers/viewCreateCompanyProfile";
import { showModal } from '../../../reducers/showModalForGoToFormAI';
import { setActiveCompanyProfile } from "../../../reducers/activeCompanyProfile";
import { postRequest } from "../../services/requestLib";
import { setStateUser } from "../../../reducers/statusUser";
import { setCompanyCollaborator } from "../../../reducers/companyCollaborator";
import { setCompanyList } from "../../../reducers/companyList";
import { IsCollaboratorPlan } from "../../services/isCollaboratorPlan/IsCollaboratorPlan";

const RequestInfoAdmin = async (dispatch, email, password, token) => {
  const userInfo = {
    email: email,
    password: password,
    token: token
  };

  let planServiceUser = "";

  try {
    const requestStateUser = await postRequest('get-state-user', userInfo);
    if (requestStateUser.status === 200) {
      dispatch(setStateUser(requestStateUser.data.data));
      planServiceUser = requestStateUser.data.data.servicePlan;
    }

    const responseCompanyCollaborator = await postRequest('company-collaborator', userInfo);
    if (responseCompanyCollaborator.status === 200) {
      dispatch(setCompanyCollaborator(responseCompanyCollaborator.data.data));
    }

    if (IsCollaboratorPlan(planServiceUser)) {
      return { status: true, id: 0, collaboratorPlan: true };
    }

    const responseCompanyList = await postRequest('company-list', userInfo);
    if (responseCompanyList.status === 200) {

      if (responseCompanyList.data.status) {
        dispatch(setCompanyList(responseCompanyList.data.data));
        const companyListRequested = responseCompanyList.data.data.companyList;

        if (companyListRequested.length > 0) {
          const firtsCompanyOnList = companyListRequested.filter((x) => x.order === 1)[0];
          if (firtsCompanyOnList) {
            dispatch(setActiveCompanyProfile(firtsCompanyOnList)); 
  
            if (firtsCompanyOnList.infoCompanyGenerate === true) {
              return { status: true, id: firtsCompanyOnList.id, collaboratorPlan: false };
            } else {
              dispatch(showModal());
              return { status: false, id: 0, collaboratorPlan: false };
            }
          }
        } else {
          dispatch(setShowViewCreateCompanyProfile());
          return { status: false, id: 0, collaboratorPlan: false };
        }
      } else {
        return { status: false, id: 0, collaboratorPlan: false };
      }
    }
  } catch (error) {
    console.log(error);
    return { status: false, id: 0, msm: JSON.stringify(error), collaboratorPlan: false };
  }
}

export default RequestInfoAdmin;