/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const companyFilesAsCollaborator = createSlice({
  name: 'companyFilesAsCollaborator',
  initialState: {
    value: {
      user: "",
      id: "",
      email: "",
      fileList: [],
      counterFiles: 0,
      storageForFiles: 0,
    },
  },
  reducers: {
    setCompanyFiles: (state, action) => {
      state.value.user = action.payload.user;
      state.value.id = action.payload.id;
      state.value.email = action.payload.email;
      state.value.fileList = action.payload.fileList;
      state.value.counterFiles = action.payload.counterFiles;
      state.value.storageForFiles = parseInt(action.payload.storageForFiles);
    },
    updateFileList: (state, action) => {
      state.value.fileList = action.payload.fileList;
    },
    updateCounterFiles: (state, action) => {
      state.value.counterFiles = action.payload.counterFiles;
    },
    cleanCompanyFiles: (state, action) => {
      state.value.user = "",
      state.value.id = "",
      state.value.email = "",
      state.value.fileList = [],
      state.value.counterFiles = 0,
      state.value.storageForFiles = 0
    },
  }
});

export const {
  setCompanyFiles, updateFileList, updateCounterFiles, cleanCompanyFiles
} = companyFilesAsCollaborator.actions;

export default companyFilesAsCollaborator.reducer;