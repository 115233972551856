/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const imageSelectedForPost = createSlice({
  name: 'imageSelectedForPost',
  initialState: {
    value: {
      uuid: '',
      uuidThumbnail: '',
      url: '',
      urlThumbnail: '',
      type: ''
    },
  },
  reducers: {
    setImageSelectedForPost: (state, action) => {
      state.value.uuid = action.payload.uuid;
      state.value.uuidThumbnail = action.payload.uuidThumbnail;
      state.value.url = action.payload.url;
      state.value.urlThumbnail = action.payload.urlThumbnail;
      state.value.type = action.payload.type;
    },
    resetImageSelectedForPost:  (state, action) => {
      state.value.uuid = '';
      state.value.uuidThumbnail = '';
      state.value.url = '';
      state.value.urlThumbnail = '';
      state.value.type = '';
    },
  }
});

export const {
  setImageSelectedForPost, resetImageSelectedForPost
} = imageSelectedForPost.actions;

export default imageSelectedForPost.reducer;
