export const validatorPostCounter = (typeDiscount, companyPost, numberDiscount) => {
  let calc;

  switch (typeDiscount) {
    case 1:
      calc = companyPost.maxGenerateContentPost - numberDiscount;
      if (calc <= 0) {
        return {
          key: "maxGenerateContentPost",
          value: 0
        };
      } else {
        return {
          key: "maxGenerateContentPost",
          value: calc
        };
      }
    case 2:
      calc = companyPost.maxGenerateVideoPosts - numberDiscount;
      if (calc <= 0) {
        return {
          key: "maxGenerateVideoPosts",
          value: 0
        };
      } else {
        return {
          key: "maxGenerateVideoPosts",
          value: calc
        };
      }
    case 3:
      calc = companyPost.maxGenerateSpecificPost - numberDiscount;
      if (calc <= 0) {
        return {
          key: "maxGenerateSpecificPost",
          value: 0
        };
      } else {
        return {
          key: "maxGenerateSpecificPost",
          value: calc
        };
      }
    default:
      break;
  }
};