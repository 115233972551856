import React, { useEffect, useState } from 'react';
import '../../../../../../styles/contentCalendar/components/viewsCreatePost/monthlyPost/style.scss';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Fade } from "react-reveal";
import { useDispatch, useSelector } from 'react-redux';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Text from '../../../../../../share-components/text/text';
import { formatMoment, socialNetworksConst } from '../../../../../../services/values';
import '../../../../../../share-components/multiSelect.scss';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { generatePromptMonthlyPost_v2 } from '../../../../../../services/generatePromptMonthlyPost';
import { generateContentMeantto } from '../../../../../../services/generatePrompt';
import { generatePost } from '../../../../../../services/generatePost';
import { updateCounter, updatePostList } from '../../../../../../../reducers/postsCompany';
import { patchRequestCollaborator } from '../../../../../../services/requestLib';
import { setShowAlert } from '../../../../../../../reducers/showAlert';
import Lottie from "lottie-react";
import VideoAnim from './lottieFiles/publish-video.json';
import ContentAnim from './lottieFiles/publish-content.json';
import SuccessAnim from './lottieFiles/success.json';
import GeneratingContent from '../../../../../../share-components/lottie/generatingContent/generatingContent';
import ErrorView from '../../../../../../share-components/lottie/errorView/errorView';
import { validatorPostCounter } from '../../../../../../services/validatePostRemaining';
import { handlerGetLimitSelectPost } from '../../../../../../services/utilPrompts';
import UseCheckIsFreeUser from '../../../../../../services/checkIsFreeUser/useCheckIsFreeUser';
import { useMediaQuery } from '@react-hook/media-query';

function MonthlyPost({ closeModal, updateStatusView, SendSocket }) {
  const localizer = momentLocalizer(moment);
  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');
  const styleLottieAnim = {
    height: isMobileDevice ? 180 : 220,
  };

  const userData = useSelector((state) => state.userData.value);
  const answersForm = useSelector((state) => state.answersForm.value);
  const activeCompanyProfile = useSelector((state) => state.activeCompanyProfile.value);
  const activeCollaboratorMode = useSelector((state) => state.activeCollaboratorMode.value);
  const postsCompany = useSelector((state) => state.postsCompany.value);
  const counterContentPost = postsCompany.maxGenerateContentPost;
  const counterVideoPost = postsCompany.maxGenerateVideoPosts;
  const statusUser = useSelector((state) => state.statusUser.value);
  const isFreePlanUser = UseCheckIsFreeUser(statusUser.servicePlan);
  const [selectedSocialNetworks, setSelectedSocialNetworks] = useState([]);
  const [selectAllSocialNetworks, setSelectAllSocialNetworks] = useState(false);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const handlerUpdatePostList = (infoPostList) => {
    dispatch(updatePostList(infoPostList));
  };

  const handlerSetShowAlert = (infoAlert) => {
    dispatch(setShowAlert(infoAlert));
  };

  const openAlert = (typeAlert, textAlert) => {
    const object = {
      typeAlert: typeAlert,
      textAlert: textAlert
    };

    handlerSetShowAlert(object);
  };

  const handlerUpdateCounter = (infoCompany) => {
    dispatch(updateCounter(infoCompany));
  } 

  const handlerAddSocialNetworktoArray = (id) => {  
    if (id === 0) {
      setSelectedSocialNetworks([]);
      if (selectAllSocialNetworks) {
        setSelectAllSocialNetworks(false);
      } else {
        const allNetworksData = socialNetworksConst.map((x) => ({
          id: x.id,
          name: x.name,
        }));
  
        setSelectedSocialNetworks(allNetworksData);
        setSelectAllSocialNetworks(true);
      }
    } else {
      const addedBeforeToArray = selectedSocialNetworks.filter((x) => x.id === id);
  
      if (addedBeforeToArray.length > 0) {
        const filteredArraySocialNetworks = selectedSocialNetworks.filter((x) => x.id !== id);
  
        setSelectedSocialNetworks(filteredArraySocialNetworks);
      } else {
        const selectedNetworksData = socialNetworksConst.find((x) => x.id === id);
    
        const newItem = {
          id: selectedNetworksData.id,
          name: selectedNetworksData.name,
        };
  
        setSelectedSocialNetworks((prevData) => ([...prevData, newItem]));
      }
    }
  };

  const handlerReturnIcon = (id) => {
    if (id === 0) {
      if (selectAllSocialNetworks) {
        return <CheckCircleRoundedIcon className="color-text-primary" />;
      } else {
        return <RadioButtonUncheckedRoundedIcon className="text-muted" />;
      }
    } else {
      const selectedNetworksData = selectedSocialNetworks.filter((x) => x.id === id);
  
      if (selectedNetworksData.length > 0) {
        return <CheckCircleRoundedIcon className="color-text-primary" />;
      } else {
        return <RadioButtonUncheckedRoundedIcon className="text-muted" />;
      }
    }
  };

  const chekckIfSelectedAllSocialNetworks = () => {
    const allIds = socialNetworksConst.map((x) => x.id);

    const allIdsSelected = allIds.every((id) =>
      selectedSocialNetworks.some((selected) => selected.id === id)
    );

    if (allIdsSelected) {
      setSelectAllSocialNetworks(true);
    } else {
      setSelectAllSocialNetworks(false);
    }
  };

  useEffect(() => {
    chekckIfSelectedAllSocialNetworks();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSocialNetworks]);

  const handlerCloseModal = () => {
    closeModal();
  };

  // eslint-disable-next-line no-unused-vars
  const [viewSpecificPost, setViewSpecificPost] = useState("create");
  updateStatusView("create");

  useEffect(() => {
    updateStatusView(viewSpecificPost);
  }, [viewSpecificPost, updateStatusView]);

  /* select group for create posts */
  const [viewSocialGroup, setViewSocialGroup] = useState(0);

  const handlerChangeView = (idView) => {
    if (idView !== viewSocialGroup) {
      setViewSocialGroup(idView);
    }
  };

  const handlerDetectGroupSelected = () => {
    if (viewSocialGroup === 1) {
      return 'Content post';
    }

    if (viewSocialGroup === 2) {
      return 'Video content post';
    }
  };

  const handlerFindCounterRemaining = () => {
    if (viewSocialGroup === 1) {
      return counterContentPost;
    }

    if (viewSocialGroup === 2) {
      return counterVideoPost;
    }
  };

  const maxPostMustSelectUser = () => {
    const maxValueForSelect = handlerGetLimitSelectPost(userData.servicePlan);

    if (UseCheckIsFreeUser(userData.servicePlan)) {
      if (viewSocialGroup === 1) {
        return maxValueForSelect !== counterContentPost ? handlerFindCounterRemaining() : maxValueForSelect;
      }
  
      if (viewSocialGroup === 2) {
        return maxValueForSelect !== counterVideoPost ? handlerFindCounterRemaining() : maxValueForSelect;
      }
    }

    if (userData.servicePlan === "74a8b99a-e92c-4d60-8966-53d288966d52") {
      if (viewSocialGroup === 1) {
        return maxValueForSelect !== counterContentPost ? handlerFindCounterRemaining() : maxValueForSelect;
      }
  
      if (viewSocialGroup === 2) {
        return maxValueForSelect !== counterVideoPost ? handlerFindCounterRemaining() : maxValueForSelect;
      }
    }

    if (userData.servicePlan === "d370579d-dbec-4fd6-9c26-d219fe00da1d") {
      if (viewSocialGroup === 1) {
        return maxValueForSelect > counterContentPost ? handlerFindCounterRemaining() : maxValueForSelect;
      }
  
      if (viewSocialGroup === 2) {
        return maxValueForSelect > counterVideoPost ? handlerFindCounterRemaining() : maxValueForSelect;
      }
    }
  };

  /* Mini calendar */
  const [dateArrayCalendar, setDateArrayCalendar] = useState([]);

  const handlerGoBackMonthlyPost = () => {
    setViewSocialGroup(0);
    setSelectedSocialNetworks([]);
    setDateArrayCalendar([]);
  };

  const handlerCountDateList = (e) => {
    try {
      console.log(e);
      /* if (e.start) {
        const currentDate = moment.utc().format(formatMoment)
        const getDate = moment.utc(e.start).format(formatMoment);
        
        const maxCounterSelectedPost = maxPostMustSelectUser();
        const maxCounter = handlerFindCounterRemaining() >= maxCounterSelectedPost ? maxCounterSelectedPost : handlerFindCounterRemaining();

        if (getDate >= currentDate) {
          if (dateArrayCalendar.length < maxCounter) {
            if (dateArrayCalendar.includes(getDate)) {
              const deleteDateClickTwice = dateArrayCalendar.filter((x) => x !== getDate);
              setDateArrayCalendar(deleteDateClickTwice);
            } else {
              const counterValidartor = viewSocialGroup === 1 ? counterContentPost : counterVideoPost;
              if (dateArrayCalendar.length < counterValidartor) {
                setDateArrayCalendar((prevData) => ([...prevData, getDate]));
              }
            }
          } else {
            if (dateArrayCalendar.includes(getDate)) {
              const deleteDateClickTwice = dateArrayCalendar.filter((x) => x !== getDate);
              setDateArrayCalendar(deleteDateClickTwice);
            } else {
              openAlert("danger", `Only can select ${maxCounter} day(s)`);
            }
          }
        } else {
          openAlert("danger", "You cannot create posts on days prior to today");
        }
      } */

      if (e.start) {
        const maxCounterSelectedPost = maxPostMustSelectUser();
        const remainingCounter = handlerFindCounterRemaining();
        const maxCounter = Math.min(remainingCounter, maxCounterSelectedPost);
        const selectedDates = e.slots;
        const currentDate = moment.utc().format(formatMoment);

        const formattedSelectedDates = selectedDates.map(date => moment.utc(date).format(formatMoment));
        const validDates = formattedSelectedDates.filter(getDate => getDate >= currentDate);

        let updatedDatesArray = [...dateArrayCalendar];

        validDates.forEach(getDate => {
          if (updatedDatesArray.includes(getDate)) {
            updatedDatesArray = updatedDatesArray.filter(date => date !== getDate);
          } else if (updatedDatesArray.length < maxCounter) {
            updatedDatesArray.push(getDate);
          } else {
            openAlert("danger", `Only ${maxCounter} day(s) can be selected`);
          }
        });

        setDateArrayCalendar(updatedDatesArray);

        if (validDates.length < formattedSelectedDates.length) {
          openAlert("danger", "You cannot create posts on days prior to today");
        }
      }        
    } catch (error) {
      console.log(error);
    }
  };

  const calendarStyleSelectedDate = (date) => {
    const formattedDate = moment.utc(date).format(formatMoment);
    const currentDate =  moment.utc().format(formatMoment);

    if (dateArrayCalendar.includes(formattedDate)) {
      return {
        className: "day-selected-minicalendar",
      };
    }
    
    if (formattedDate < currentDate) {
      return {
        className: "rbc-off-range-bg",
      };
    }
  
    return {};
  }

  const handlerUpdatePostCompany = async () => {
    // setViewSpecificPost("updating-post-list");
    const user = {
      email: userData.email,
      password: userData.password,
      token: userData.token,
      typeAccess: 1
    };
  
    const updateCompanyCounterPost = validatorPostCounter(viewSocialGroup, postsCompany, dateArrayCalendar.length);

    const sendData = {
      id: activeCompanyProfile.id,
      postList: postsCompany.postList,
      keyToUpdate: updateCompanyCounterPost.key,
      updatedCounter: updateCompanyCounterPost.value
    };

    const responseUpdatePostCompany = await patchRequestCollaborator('company-posts', user, sendData);
    if (responseUpdatePostCompany.status === 200 && responseUpdatePostCompany.data.status === true) {
      handlerUpdateCounter(updateCompanyCounterPost);
      handlerCloseModal();

      SendSocket({
        action: 'send-message',
        data: {
          id: activeCompanyProfile.id,
          user: userData.user,
          message: {
            type: 'UPDATE',
            table: 'company-posts',
            sendFromAdminView: true,
            sendFromCollaboratorView: false,
            companyOwner: userData.user,
            idCompanyOwner: activeCompanyProfile.id,
            userSendUpdate: userData.user,
            isAdmin: !activeCollaboratorMode.collaboratorMode,
            data: { 
              user: postsCompany.user,
              id: postsCompany.id,
              email: postsCompany.email,
              maxGenerateContentPost: updateCompanyCounterPost.key === 'maxGenerateContentPost' ? updateCompanyCounterPost.value : postsCompany.maxGenerateContentPost,
              maxGenerateSpecificPost: postsCompany.maxGenerateSpecificPost,
              maxGenerateVideoPosts: updateCompanyCounterPost.key === 'maxGenerateVideoPosts' ? updateCompanyCounterPost.value : postsCompany.maxGenerateVideoPosts,
              postList: postsCompany.postList
            }
          }
        }
      });
      
      openAlert("success", "Success");
    } else {
      openAlert("danger", "Error, try again");
      setViewSpecificPost("error-on-update");
    }
  };

  const handlerGenerateMonthlyPost = async (prompt) => {
    try {
      setViewSpecificPost("loading");
      const requestGeneratePost = await generateContentMeantto(userData.email, activeCompanyProfile.id, "monthly post",prompt);

      if (requestGeneratePost.status === 200) {
        const getBody = requestGeneratePost.data.body;
        const parseResponse = JSON.parse(getBody);
        if (parseResponse.socialMediaPost !== undefined) {
          const getListGeneratedPost = parseResponse.socialMediaPost;
  
          if (Array.isArray(getListGeneratedPost)) {
            for (let i = 0; i < getListGeneratedPost.length; i++) {
              const elementPost = getListGeneratedPost[i];
              const getSelectedDay = dateArrayCalendar[i];
              const groupSelected = handlerDetectGroupSelected();

              const postObject = generatePost(activeCompanyProfile, elementPost, getSelectedDay, groupSelected);
              handlerUpdatePostList(postObject);
            }
            // handlerUpdatePostCompany();
            setViewSpecificPost("success");
          }
        } else {
          setViewSpecificPost("error");
        }
      }
    } catch (error) {
      console.log(error);
      setViewSpecificPost("error");
    }
  };

  const [categoriesForVideoContent, setCategoriesForVideoContent] = useState([]);

  const dataCategoriesForVideoContent = [
    {
      id: 1,
      title: "Educational and Informative",
      text: "How-To Guides, Tutorials, Educational Content, Tech Reviews"
    },
    {
      id: 2,
      title: "Entertainment",
      text: "Comedy Sketches, Dance and Music Challenges, Comedy and Skits, Gaming Content"
    },
    {
      id: 3,
      title: "Lifestyle",
      text: "Vlogs, Fashion and Beauty, Fitness and Health, Food and Cooking, Personal Stories"
    },
    {
      id: 4,
      title: "Creative and DIY",
      text: "DIY Projects, Crafts, Life Hacks, DIY Tips"
    },
    {
      id: 5,
      title: "Inspirational and Motivational",
      text: "Personal Stories, Inspirational Content"
    },
  ];

  const handlerReturnIconCategories = (id) => {
    if (id === 0) {
      if (categoriesForVideoContent) {
        return <CheckCircleRoundedIcon className="color-text-primary" />;
      } else {
        return <RadioButtonUncheckedRoundedIcon className="text-muted" />;
      }
    } else {
      const selectedCategoryData = categoriesForVideoContent.filter((x) => x === id);
  
      if (selectedCategoryData.length > 0) {
        return <CheckCircleRoundedIcon className="color-text-primary" />;
      } else {
        return <RadioButtonUncheckedRoundedIcon className="text-muted" />;
      }
    }
  };

  const handlerSelectCategories = (id) => {
    if (!categoriesForVideoContent.includes(id)) {
      const maxCategoriesCanSelectec = isFreePlanUser ? 1 : 2;

      if (categoriesForVideoContent.length === maxCategoriesCanSelectec) {
        const newCategories = categoriesForVideoContent.slice(1);
        newCategories.push(id);
        setCategoriesForVideoContent(newCategories);
      } else {
        setCategoriesForVideoContent((prevData) => ([...prevData, id]));
      }


    } else {
      const filteredArray = categoriesForVideoContent.filter((x) => x !== id);
      setCategoriesForVideoContent(filteredArray);
    }
  };

  const handlerGeneratePrompt = () => {
    if (selectedSocialNetworks.length !== 0) {
      if (viewSocialGroup === 1) {
        const socialNetworksArray = socialNetworksConst.filter((x) => x.group === viewSocialGroup);

        const arrayCategories = {
          category_1: '',
          category_2: '',
        };

        const getMonthlyPrompt_v2 = generatePromptMonthlyPost_v2(statusUser.servicePlan, viewSocialGroup, answersForm, activeCompanyProfile.location, socialNetworksArray, arrayCategories, dateArrayCalendar.length);
        handlerGenerateMonthlyPost(getMonthlyPrompt_v2);
      }

      /* Remover cuando ya tenga el prompt de video contenido */
      if (viewSocialGroup === 2) {
        const socialNetworksArray = socialNetworksConst.filter((x) => x.group === viewSocialGroup);

        let arrayCategories = {};

        if (isFreePlanUser) {
          const category_1 = viewSocialGroup === 2 ? dataCategoriesForVideoContent.find((x) => x.id === categoriesForVideoContent[0]) : '';
  
          arrayCategories = {
            category_1: viewSocialGroup === 2 ? `${category_1.title}: ${category_1.text}` : '',
          };
        } else {
          const category_1 = viewSocialGroup === 2 ? dataCategoriesForVideoContent.find((x) => x.id === categoriesForVideoContent[0]) : '';
          const category_2 = viewSocialGroup === 2 ? dataCategoriesForVideoContent.find((x) => x.id === categoriesForVideoContent[1]) : '';
  
          arrayCategories = {
            category_1: viewSocialGroup === 2 ? `${category_1.title}: ${category_1.text}` : '',
            category_2: viewSocialGroup === 2 ? `${category_2.title}: ${category_2.text}` : '',
          };
        }
  
        const getMonthlyPrompt_v2 = generatePromptMonthlyPost_v2(statusUser.servicePlan, viewSocialGroup, answersForm, activeCompanyProfile.location, socialNetworksArray, arrayCategories, dateArrayCalendar.length);
        handlerGenerateMonthlyPost(getMonthlyPrompt_v2);
      }

    }
  };

  const handlerTryAgainGeneratingPost = () => {
    handlerGeneratePrompt();
  };

  const handlerTryAgainUpdatePostList = () => {
    handlerUpdatePostCompany();
  };

  const validatorButtonGenerateContent = () => {
    if (viewSocialGroup === 1) {
      return selectedSocialNetworks.length === 0 || dateArrayCalendar.length !== maxPostMustSelectUser();
    }

    if (viewSocialGroup === 2) {
      const counterCategoriesIfIsFreePlan = isFreePlanUser ? 1 : 2;
      return selectedSocialNetworks.length === 0 || dateArrayCalendar.length !== maxPostMustSelectUser() || (categoriesForVideoContent.length !== counterCategoriesIfIsFreePlan);
    }
  };

  return (
    <>
      <div className="modal-content">
        <div className="container-view-monthly-post">
          {viewSpecificPost === "create" && (
            <>
              {viewSocialGroup === 0 && (
                <Fade duration={600} opposite>
                  <div className="container-group-social-media mb-2">
                    <button
                      className="card-group-social-media"
                      onClick={() => handlerChangeView(1)}
                    >
                      <Text type="subtitle">
                        Curated content ideas and captions
                      </Text>
                      <Lottie animationData={ContentAnim} loop={true} autoPlay={true} style={styleLottieAnim} />
                      <Text type='small' inyectClass="mt-2 text-muted">{counterContentPost} posts remaining</Text>
                    </button>
                    <button
                      className="card-group-social-media"
                      onClick={() => handlerChangeView(2)}
                    >
                      <Text type="subtitle">
                        Curated vlog content
                      </Text>
                      <Lottie animationData={VideoAnim} loop={true} autoPlay={true} style={styleLottieAnim} />
                      <Text type='small' inyectClass="mt-2 text-muted">{counterVideoPost} posts remaining</Text>
                    </button>
                  </div>
                </Fade>
              )}

              {viewSocialGroup !== 0 && (
                <Fade duration={600} opposite>
                  <div>
                    <Text type="text">
                      Select your social network
                    </Text>
                    <div className="multi-select-option-container">
                      {socialNetworksConst.filter((x) => x.group === viewSocialGroup).map((itemSN) => (
                        <button key={itemSN.id} onClick={() => handlerAddSocialNetworktoArray(itemSN.id)}>
                          {handlerReturnIcon(itemSN.id)}
                          <div className="container-data-multi-select-option">
                            <img src={itemSN.urlIcon} alt="" />
                            {itemSN.name}
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>
                  <>
                    {viewSocialGroup === 2 && (
                      <>
                        <Text type="text">
                          Select {isFreePlanUser ? '1 category' : '2 categories'} for generate video content
                        </Text>
                        <div className="mt-2">
                          <div className="multi-select-option-container">
                            {dataCategoriesForVideoContent.map((itemVC) => (
                              <button key={itemVC.id} onClick={() => handlerSelectCategories(itemVC.id)}>
                                {handlerReturnIconCategories(itemVC.id)}
                                <div className="container-data-multi-select-option text-left">
                                  <div>
                                    <p>
                                      <strong>
                                        {itemVC.title}
                                      </strong>
                                    </p>
                                    <p>
                                      <small>
                                        {itemVC.text}
                                      </small>
                                    </p>
                                  </div>
                                </div>
                              </button>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                  <div className="mt-2">
                    <div className="d-flex flex-row justify-content-between">
                      {handlerFindCounterRemaining() !== 0 ? (
                        <Text type="text">
                          Please select {maxPostMustSelectUser()} days to generate posts.
                        </Text>
                      ) : (
                        <span>{' '}</span>
                      )}

                      <Text type="text" inyectClass="bold">
                        {handlerFindCounterRemaining()} posts remaining
                      </Text>
                    </div>
                    <div className="container-calendar-monthly-post">
                      <Calendar
                        localizer={localizer}
                        events={[]}
                        startAccessor="start"
                        endAccessor="end"
                        selectable
                        onSelectSlot={handlerCountDateList}
                        views={['month']}
                        dayPropGetter={calendarStyleSelectedDate}
                      />
                    </div>
                    {dateArrayCalendar.length >= handlerFindCounterRemaining() && (
                      <>
                        {/*
                          <span className="text-danger mt-2">
                            You selected the maximum number of dates to generate posts.
                          </span>
                        */}
                      </>
                    )}
                  </div>
                </Fade>
              )}
            </>
          )}

          {viewSpecificPost === "loading" && (
            <GeneratingContent />
          )}

          {viewSpecificPost === "success" && (
            <Fade duration={600} opposite>
              <div className="d-flex flex-column align-item-center justify-content-center">
                <Lottie animationData={SuccessAnim} loop={true} autoPlay={true} style={styleLottieAnim} />
                <Text type="subtitle" inyectClass="text-center">
                  Your content is ready!
                </Text>
              </div>
            </Fade>
          )}

          {viewSpecificPost ===  "updating-post-list" && (
            <GeneratingContent text="Saving posts" />
          )}

          {(viewSpecificPost === "error" || viewSpecificPost === "error-on-update") && (
            <ErrorView />
          )}

          {(handlerFindCounterRemaining() === 0 && viewSpecificPost !== "success") && (
            <Text type="text" inyectClass="text-danger">
              You no longer have AI credits. Upgrade your plan for more credits!
            </Text>
          )}
        </div>
      </div>
      <div className="action-btn-form">
        {viewSocialGroup !== 0 && (
          (viewSpecificPost === "create" && handlerFindCounterRemaining() > 0) && (
            <>
              <button
                className="two-btn-modal"
                onClick={() => handlerGoBackMonthlyPost()}>
                  Back
              </button>

              {viewSocialGroup === 1 && (
                <button
                  className={`two-btn-modal ${validatorButtonGenerateContent() ? 'btn-disabled' : ''}`}
                  onClick={() => handlerGeneratePrompt()}
                  disabled={validatorButtonGenerateContent()}
                >
                  Generate content
                </button>
              )}

              {viewSocialGroup === 2 && (
                <button
                  className={`two-btn-modal ${validatorButtonGenerateContent() ? 'btn-disabled' : ''}`}
                  onClick={() => handlerGeneratePrompt()}
                  disabled={validatorButtonGenerateContent()}
                >
                  Generate content
                </button>
              )}
            </>
          )
        )}

        {(viewSocialGroup === 0 && viewSpecificPost === "create") && (
          <button
            className={`one-btn-modal`}
            onClick={() => handlerCloseModal()}>
              Close
          </button>
        )}

        {viewSpecificPost === "success" && (
          <button
            className={`one-btn-modal`}
            onClick={() => handlerUpdatePostCompany()}
          >
            Show me
          </button>
        )}
        
        {viewSpecificPost === "error" && (
          <button
            className={`one-btn-modal`}
            onClick={() => handlerTryAgainGeneratingPost()}
          >
            Try again
          </button>
        )}
        
        {viewSpecificPost === "error-on-update" && (
          <button
            className={`one-btn-modal`}
            onClick={() => handlerTryAgainUpdatePostList()}
          >
            Try again
          </button>
        )}

        {(handlerFindCounterRemaining() === 0 && viewSpecificPost === "create") && (
          <button
            className="two-btn-modal"
            onClick={() => handlerGoBackMonthlyPost()}>
            Back
          </button>
        )}
      </div>
    </>
  )
};

MonthlyPost.propTypes = {
  closeModal: PropTypes.func.isRequired,
  updateStatusView: PropTypes.func.isRequired,
  SendSocket: PropTypes.func.isRequired,
};

export default MonthlyPost;
