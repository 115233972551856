/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const alertUserToGenerateContentSuccess = createSlice({
  name: 'alertUserToGenerateContentSuccess',
  initialState: {
    value: {
      show: false
    },
  },
  reducers: {
    setShowModal: (state, action) => {
      state.value.show = true;
    },
    setHideModal: (state, action) => {
      state.value.show = false;
    },
  }
});

export const {
    setShowModal, setHideModal
} = alertUserToGenerateContentSuccess.actions;

export default alertUserToGenerateContentSuccess.reducer;
