import React, { useState, useEffect } from 'react';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import './style.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { Fade } from "react-reveal";
import Text from '../../../text/text';
import { postRequest } from '../../../../services/requestLib';
import { emailValidator } from '../../../../services/util';
import { setShowAlert } from '../../../../../reducers/showAlert';
import { setCompanyGuestUsers } from '../../../../../reducers/guestUsers';

function GuestUsers({ backView }) {
  const userData = useSelector((state) => state.userData.value);
  const activeCompanyProfile = useSelector((state) => state.activeCompanyProfile.value);
  const statusUser = useSelector((state) => state.statusUser.value);
  const guestUsers = useSelector((state) => state.guestUsers.value);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const handlerSetShowAlert = (infoAlert) => {
    dispatch(setShowAlert(infoAlert));
  };

  const handlerSetCompanyGuestUsers = (infoCompany) => {
    dispatch(setCompanyGuestUsers(infoCompany));
  };

  const openAlert = (typeAlert, textAlert) => {
    const object = {
      typeAlert: typeAlert,
      textAlert: textAlert
    };

    handlerSetShowAlert(object);
  };

  const requestCompanyGuestUsers = async (email, password, token, idCompany) => {
    const userInfo = {
      email: email,
      password: password,
      token: token,
      id: idCompany
    };

    const requestCompanyFiles = await postRequest('company-guest-users', userInfo);
    if (requestCompanyFiles.status === 200) {
      handlerSetCompanyGuestUsers(requestCompanyFiles.data.data);
    }
  };

  const handlerBackView = () => {
    backView();
  };

  const [emailToInvitate, setEmailToInvitate] = useState("");

  const handlerWriteEmail = (email) => {
    setEmailToInvitate(email);
  };

  const handlerSendInvitation = async () => {
    const emailValid = emailValidator(emailToInvitate);

    if (emailValid && emailToInvitate !== userData.email) {
      const alreadyExistEmailInvitation = guestUsers.guestUsers.filter((x) => x.email === emailToInvitate);

      if (alreadyExistEmailInvitation.length < 1) {
        if (guestUsers.guestUsers.filter((x) => x.validateUser === true).length < 2) {
          const userInfo = {
            data: {
              userIdFromInvitation: userData.user,
              emailfromInvitation: userData.email,
              idCompanyFromInvitation: activeCompanyProfile.id,
              emailToInvitation: emailToInvitate,
              keyAccess: uuidv4(),
              nameCompany: activeCompanyProfile.name,
              servicePlanOwner: statusUser.servicePlan,
              locationCompany: activeCompanyProfile.location
            }
          };
      
          const requestSendInvitation = await postRequest('send-invitation', userInfo);
          if (requestSendInvitation.status === 200) {
            if (requestSendInvitation.data.status === true) {
              openAlert("success", "Invitation sent");
              setEmailToInvitate("");
              handlerWriteEmail("");
              requestCompanyGuestUsers(userData.email, userData.password, userData.token, activeCompanyProfile.id);
            } else {
              openAlert("danger", "Try again");
            }
          }
        } else {
          openAlert("danger", "Max users active");
        }
      } else {
        openAlert("danger", "You already sent an invitation to this email.");
        setEmailToInvitate("");
      }
    }

    if (!emailValid) {
      openAlert("danger", "invalid email");
    }
    
    if (emailToInvitate === userData.email) {
      openAlert("danger", "You can not send invitation to this email");
    }
  };

  useEffect(() => {
    if (guestUsers.user === "") {
      requestCompanyGuestUsers(userData.email, userData.password, userData.token, activeCompanyProfile.id);
    } 
    // eslint-disable-next-line
  }, []);
  

  return (
    <Fade  duration={600} opposite>
      <div className="pl-2 pr-2">
        <Text type="text">
          Guest users
        </Text>
        <div className="div-request-guest-invitation d-flex flex-column justify-content-center align-items-center pt-2 pb-2">
          <input
            className="input-field mb-0"
            placeholder="email"
            onChange={(e) => handlerWriteEmail(e.target.value)}
            value={emailToInvitate}
          />
          <button
            className="btn-for-send-invitation-guest-user"
            onClick={() => handlerSendInvitation()}
          >
            Send invitation
          </button>
        </div>

        {(guestUsers.user !== "" && guestUsers.guestUsers.length > 0) && (
          <div className="container-guested-user">
            <div className="d-flex flex-row justify-content-between">
              <Text type="small" inyectClass="mb-2">User list</Text>
              <Text type="small" inyectClass="mb-2">limit {statusUser.guestUsersPerCompanyProfile}</Text>
            </div>
            {guestUsers.guestUsers.map((user) => (
              <div className="item-guested-user" key={user.email}>
                <div className='user-container-guest-user'>
                  <Text type="body">{user.email}</Text>
                </div>
                <div className="div-actions-guest-users">
                  <button className="btn-delete-guest-user"> 
                    <DeleteIcon className={`${user.validateUser === true ? 'text-danger' : 'text-muted'}`}/>
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        {(guestUsers.user === "" && guestUsers.guestUsers.length === 0) && (
          <div className="container-guested-user">
            <div className="d-flex flex-row justify-content-between">
              <Text type="small" inyectClass="mb-2">User list</Text>
              <Text type="small" inyectClass="mb-2">limit {statusUser.guestUsersPerCompanyProfile}</Text>
            </div>
            <div className='d-flex flex-row align-items-center justify-content-center'>
              <Text type="body" inyectClass="mb-2">Loading</Text>
            </div>
          </div>
        )}

        {(guestUsers.user !== "" && guestUsers.guestUsers.length === 0) && (
          <div className="container-guested-user">
            <div className="d-flex flex-row justify-content-between">
              <Text type="small" inyectClass="mb-2">User list</Text>
              <Text type="small" inyectClass="mb-2">limit {statusUser.guestUsersPerCompanyProfile}</Text>
            </div>
            <div className='d-flex flex-row align-items-center justify-content-center'>
              <Text type="small" inyectClass="mb-2 text-muted unselectable">Add users for you workteam</Text>
            </div>
          </div>
        )}

      </div>
      <div className="action-btn-form">
        <button
          className={`one-btn-modal`}
          onClick={() => handlerBackView()}
        >
          Back
        </button>
      </div>
    </Fade>
  )
}

GuestUsers.propTypes = {
  backView: PropTypes.func.isRequired,
};

export default GuestUsers; 