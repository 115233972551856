import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import base64 from 'base-64';
import userPool from '../../security/userPool';

const handlerSetSessionStorage = (name, data) => {
  sessionStorage.setItem(name, base64.encode(data));
};

const handlerInsertDataLoginToLocalStorage = (name, data) => {
  localStorage.setItem(name, base64.encode(data));
};

const login = async (credentials) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: credentials.email,
      Pool: userPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: credentials.email,
      Password: credentials.password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: async (data) => {
        const token = data.idToken.jwtToken;
        const emailVerified = data.idToken.payload.email;

        handlerSetSessionStorage('CognitoIdentityServiceProvider.MeanttoT', token);
        handlerSetSessionStorage('CognitoIdentityServiceProvider.MeanttoU', emailVerified);
        handlerSetSessionStorage('CognitoIdentityServiceProvider.MeanttoE', credentials.email);
        handlerSetSessionStorage('CognitoIdentityServiceProvider.MeanttoP', credentials.password);

        const dataCredentials = {
          email: credentials.email,
          password: credentials.password,
          token: token,
        };

        handlerInsertDataLoginToLocalStorage("CognitoIdentityServiceProvider.dcLS", JSON.stringify(dataCredentials));

        resolve({ success: true, data: data, msm: "" });
      },
      onFailure: (err) => {
        resolve({ success: false, data: err, msm: err.message });
      },
      onPasswordRequired: (data) => {
        resolve({ success: false, data: data, msm: "Password required." });
      },
    });
  });
};

export default login;