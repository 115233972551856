import React, { useState } from 'react';
import '../../../../../styles/contentCalendar/components/viewsCreatePost/style.scss';
import PropTypes from 'prop-types';
import Text from '../../../../../share-components/text/text';
import { Fade } from 'react-reveal';
import MonthlyPost from './monthlyPost/monthlyPost';
import SpecificPost from './specificPost/specificPost';
import { useMediaQuery } from '@react-hook/media-query';

function ViewsCreatePost({ closeModal, SendSocket }) {
  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');

  const [viewForCreatePost, setViewForCreatePost] = useState({
    id: 1,
    name: "Monthly posts"
  });

  const handlerChangeViewCreatePost = (e) => {
    const { id, name } = e;

    setViewForCreatePost((prevInfo) => ({
      ...prevInfo,
      id: id,
      name: name,
    }));
  };

  const [statusViews, setStatusViews] = useState("create");

  const handlerStatusView = (status) => {
    setStatusViews(status);
  };

  return (
    <>
      {(statusViews === "create") && (
        <div className="header-modal-create-post">
          <Text type="subtitle">
            Create post
          </Text>

          <div className='d-flex flex-row gap-5'>
            <button
              className={`btn-switch-type-post-generate ${viewForCreatePost.id === 1 && 'active'}`}
              type="button"
              onClick={() => handlerChangeViewCreatePost({ id: 1, name: "Monthly posts" })}
            >
              <Text type={isMobileDevice ? 'small' : 'text'}>
                Monthly post
              </Text>
            </button>          
            <button
              className={`btn-switch-type-post-generate ${viewForCreatePost.id === 2 && 'active'}`}
              type="button"
              onClick={() => handlerChangeViewCreatePost({ id: 2, name: "Specific post" })}
            >
              <Text type={isMobileDevice ? 'small' : 'text'}>
                Specific post
              </Text>
            </button>
          </div>
        </div>
      )}
      <div className="content-views">
        {viewForCreatePost.id === 1 && (
          <Fade duration={600} opposite>
            <MonthlyPost closeModal={closeModal} updateStatusView={handlerStatusView} SendSocket={SendSocket} />
          </Fade>
        )}

        {viewForCreatePost.id === 2 && (
          <Fade duration={600} opposite>
            <SpecificPost closeModal={closeModal} updateStatusView={handlerStatusView} SendSocket={SendSocket} />
          </Fade>
        )}
      </div>
    </>
  )
};

ViewsCreatePost.propTypes = {
  closeModal: PropTypes.func.isRequired,
  SendSocket: PropTypes.func.isRequired,
};

export default ViewsCreatePost;
