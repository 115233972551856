import React from 'react';
import moment from 'moment';
import './style.scss';
import Fade from "react-reveal/Fade";
import { Link } from 'react-router-dom';
import {
  useSelector
} from 'react-redux';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import Text from '../../../share-components/text/text';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function InfoAccount() {
  const userData = useSelector((state) => state.userData.value);
  const statusUser = useSelector((state) => state.statusUser.value);
  const activeCompanyProfile = useSelector((state) => state.activeCompanyProfile.value);
  const postsCompany = useSelector((state) => state.postsCompany.value);

  const accountInfo = [
    {
      id: 1,
      name: 'Email account',
      value: userData.email
    },
    {
      id: 2,
      name: 'Register date',
      value: moment(statusUser.dataInitService).format('YYYY-MM-DD') 
    },
  ];

  const currectCompany = [
    {
      id: 1,
      name: 'Name company',
      value: activeCompanyProfile.name
    },
    {
      id: 2,
      name: 'Location',
      value: activeCompanyProfile.location
    },
    {
      id: 6,
      name: 'specific post generated',
      value: parseInt(statusUser.maxGenerateSpecificPost) - parseInt(postsCompany.maxGenerateSpecificPost)
    },
    {
      id: 7,
      name: 'Specific post remaining',
      value: postsCompany.maxGenerateSpecificPost
    },
    {
      id: 9,
      name: 'Content post generated',
      value: parseInt(statusUser.maxGenerateContentPost) - parseInt(postsCompany.maxGenerateContentPost)
    },
    {
      id: 10,
      name: 'Content post remaining',
      value: postsCompany.maxGenerateContentPost
    },
    {
      id: 12,
      name: 'Video post generated',
      value: parseInt(statusUser.maxGenerateVideoPosts) - parseInt(postsCompany.maxGenerateVideoPosts)
    },
    {
      id: 13,
      name: 'Video post remaining',
      value: postsCompany.maxGenerateVideoPosts
    },
  ];

  const handlerBuildInfo = (list) => {
    return <div className="container-list container-cards-menu w-100">
      {list.map((menu, index, array) => (
        <div className={`item-list ${index === 0 ? 'firts-child' : ''} ${index === array.length - 1 ? 'last-child' : ''} ${index !== array.length - 1 ? 'column-divider' : ''}`}>
          <div className='left-container'>
            <Text type="body">{menu.name}</Text>
          </div>
          <div className='right-container'>
            <Text type="body" inyectClass="text-muted">{menu.value}</Text>
          </div>
        </div>
      ))}
    </div>;
  };

  return (
    <div>
      <Fade duration={600} opposite>
        <div className="content-title-embed">
          <div>
            <Link to="/settings" className="link-back">
              <ArrowBackIosNewRoundedIcon className='color-text-primary'/>
              <Text type='body' inyectClass="color-text-primary">
                Settings
              </Text>
            </Link>
          </div>
          <div>
            <Text type="title">
              Account
            </Text>
          </div>
          <div>

          </div>
        </div>
        <div className={`container-company-profile content-view pt-4`}>
          <div className="container-cards-menu mb-0">
            <Text type="small" inyectClass="text-muted">
              Info
            </Text>
          </div>
          {handlerBuildInfo(accountInfo)}

          <div className="container-cards-menu mb-0">
            <Text type="small" inyectClass="text-muted">
              Current company
            </Text>
          </div>
          {handlerBuildInfo(currectCompany)}
        </div>
      </Fade>
    </div>
  );
};

export default InfoAccount;
