import React from 'react';
import './style.scss';
import Fade from "react-reveal/Fade";
import { Link } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import Text from '../../../share-components/text/text';

function Payments() {
  return (
    <div>
      <Fade duration={600} opposite>
        <div className="content-title-embed">
          <div>
            <Link to="/settings" className="link-back">
              <ArrowBackIosNewRoundedIcon className='color-text-primary'/>
              <Text type='body' inyectClass="color-text-primary">
                Settings
              </Text>
            </Link>
          </div>
          <div>
            <Text type="title">
              Payments
            </Text>
          </div>
          <div>

          </div>
        </div>
        <div className={`container-company-profile content-view pt-4`}>

        </div>
      </Fade>
    </div>
  );
};

export default Payments;
