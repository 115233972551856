import React from 'react';
import '../../../../../styles/contentCalendar/components/imageContainer/style.scss';
import PropTypes from 'prop-types';

function ImageContainer({ id, url, selectedFile }) {
  return (
    <div className={`image-container ${selectedFile ? 'backdrop-select' : ''}`}>
      <div
        id={id}
        style={{ backgroundImage: `url(${url})` }}
        className='thumbnail-file'
      />
    </div>
  )
}

ImageContainer.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  selectedFile: PropTypes.bool.isRequired
};

export default ImageContainer;
