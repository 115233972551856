/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const externalURL = createSlice({
  name: 'externalURL',
  initialState: {
    value: {
      from: "",
      url: ""
    },
  },
  reducers: {
    setExternalURL: (state, action) => {
      state.value.from = action.payload.from;
      state.value.url = action.payload.url;
    },
    clearExternalURL: (state, action) => {
      state.value.from = "";
      state.value.url = "";
    },
  }
});

export const {
  setExternalURL, clearExternalURL
} = externalURL.actions;

export default externalURL.reducer;
