/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const guestUsers = createSlice({
  name: 'guestUsers',
  initialState: {
    value: {
      user: "",
      id: "",
      email: "",
      guestUsers: "",
    },
  },
  reducers: {
    setCompanyGuestUsers: (state, action) => {
      state.value.user = action.payload.user;
      state.value.id = action.payload.id;
      state.value.email = action.payload.email;
      state.value.guestUsers = action.payload.guestUsers;
    },
    updateGuestUsers: (state, action) => {
      state.value.guestUsers = action.payload.guestUsers;
    },
  }
});

export const {
  setCompanyGuestUsers, updateGuestUsers
} = guestUsers.actions;

export default guestUsers.reducer;