import UseCheckIsFreeUser from "./checkIsFreeUser/useCheckIsFreeUser";
import { formatObjectForMonthlyPrompt } from "./utilPrompts";

const handlerReplaceIdentationAndLineBreak = (text) => {
  const replaceIdent = text.replace(/^\s+/gm, ' ');
  const replaceLineBreaks = replaceIdent.replace(/\n+/g, ' ');

  return replaceLineBreaks;
};

export const generatePromptMonthlyPost_v2 = (servicePlan, groupSocialNetwork, dataForm, location, socialNetworks, arrayCategories, numberDaysSelected) => {
  const WhatIsYourBusinessType = dataForm[`question${1}`];
  const WhatIsYourBusinessName = dataForm[`question${2}`];
  const ChooseYourIndustryType = dataForm[`question${3}`];
  const DescriptionOfProductsAndServices = dataForm[`question${4}`];
  const SelectYourBrandPersonality = dataForm[`question${5}`];
  const WhoIsYourGenderTarget = dataForm[`question${6}`];
  const WhoIsYourTargetAge = dataForm[`question${7}`].map((ta) => { return ta }).join(' to ');

  const socialNetworksList = socialNetworks.map((sn) => { return sn.name }).join(', ');

  // free plan
  if (UseCheckIsFreeUser(servicePlan)) {
    const numberPosts = 1;

    if (groupSocialNetwork === 1) {
      const generateObjects = `[${Array(numberPosts).fill(formatObjectForMonthlyPrompt).join(',')}]`;

      const prompt = `Generate content following JSON format with ONLY ${numberPosts} SEO-optimized social media post for only this social networks: ${socialNetworksList}.
        Set array inside attribute called 'socialMediaPost' in JSON for ${WhatIsYourBusinessName}, a ${WhatIsYourBusinessType} in the ${ChooseYourIndustryType} industry.
        Emphasize their ${DescriptionOfProductsAndServices} to ${WhoIsYourGenderTarget} aged ${WhoIsYourTargetAge} in ${location}.
        Ensure the content is engaging, platform-specific, and should reflect an ${SelectYourBrandPersonality} style. The posts should:

        Include the type of post, idea, and caption for each post.
        Be visually compelling and the same posts must work for Facebook, Instagram, and Twitter. Twitter posts cannot have more than 280 characters.
        Incorporate storytelling to highlight ${DescriptionOfProductsAndServices}, company culture, and industry insights for the ${SelectYourBrandPersonality} industry.
        Use ${location} specific references to strengthen community ties.
        Vary in type, including high-quality images, BTS videos, polls, infographics, and carousels to drive interactions with ${WhoIsYourGenderTarget} aged ${WhoIsYourTargetAge} in ${location}.
        Maintain a ${SelectYourBrandPersonality} style.
        Include clear CTAs for promotions, engagement, or conversions.
        Use precise language, hashtags, and SEO keywords relevant to ${location} and ${ChooseYourIndustryType} for optimal reach to ${WhoIsYourGenderTarget} aged ${WhoIsYourTargetAge}.

        The mentioned social networks should only be used to give you an idea of what kind of content you should generate and not to generate a quantity of posts for each of the social networks, only generate ${numberPosts} posts.

        Generate content follow the next JSON Object, ONLY include 'socialMediaPost' as UNIQUE attribute and do not include another additional attribute. assign each generated content to the following JSON as a value and return this same JSON with the generated information and dont modify this JSON Object, we want key - value, DONT'T INCLUDE SPECIAL CHARACTERS at the beginning and at the end of the JSON object, DO NOT modify the name of each key that i send you, DO NOT include break lines in each object on JSON array and DO NOT INCLUDE brackets. ${generateObjects}`;

      return handlerReplaceIdentationAndLineBreak(prompt);
    }
  
    if (groupSocialNetwork === 2) {
      const generateObjects = `[${Array(numberPosts).fill(formatObjectForMonthlyPrompt).join(',')}]`;
      const vlogIdeas = arrayCategories.category_2 === undefined ? `The content should include 1 vlog ideas from ${arrayCategories.category_1}` : `The content should include 6 vlog ideas from ${arrayCategories.category_1} and 6 from ${arrayCategories.category_2}`;

      const prompt =  `Generate content following JSON format with ONLY ${numberPosts} SEO-optimized social media post for only this social networks: ${socialNetworksList}. Set array inside attribute called 'socialMediaPost' in JSON for
        ${WhatIsYourBusinessName}, create vlog ideas with voice-over script outlines for, a ${WhatIsYourBusinessType} in the ${ChooseYourIndustryType} industry,
        whose products and services are ${DescriptionOfProductsAndServices}.
        ${vlogIdeas},
        tailored for ${WhoIsYourGenderTarget} aged ${WhoIsYourTargetAge} in ${location}. Each script should outline a 2-minute video for YouTube shorts,
        Instagram reels, and TikTok, ensuring the content is engaging, suitable for each platform, and reflects a ${SelectYourBrandPersonality} style.

        The mentioned social networks should only be used to give you an idea of what kind of content you should generate and not to generate a quantity of posts for each of the social networks, only generate ${numberPosts} posts.

        Generate content follow the next JSON Object, ONLY include 'socialMediaPost' as UNIQUE attribute and do not include another additional attribute. assign each generated content to the following JSON as a value and return this same JSON with the generated information and dont modify this JSON Object, we want key - value, DONT'T INCLUDE SPECIAL CHARACTERS at the beginning and at the end of the JSON object, DO NOT modify the name of each key that i send you, DO NOT include break lines in each object on JSON array and DO NOT INCLUDE brackets. ${generateObjects}`;

      return handlerReplaceIdentationAndLineBreak(prompt);
    }
  }

  // Standard plan
  if (servicePlan === "74a8b99a-e92c-4d60-8966-53d288966d52") {
    const numberPosts = numberDaysSelected > 12 ? 12 : numberDaysSelected;

    if (groupSocialNetwork === 1) {
      const generateObjects = `[${Array(numberPosts).fill(formatObjectForMonthlyPrompt).join(',')}]`;

      const prompt = `Generate an array in the following JSON format with ONLY ${numberPosts} SEO-optimized social media posts for only this social networks: ${socialNetworksList}. Set array inside attribute called 'socialMediaPost' in JSON for
        ${WhatIsYourBusinessName}, a ${WhatIsYourBusinessType} in the ${ChooseYourIndustryType} industry.

        Emphasize their ${DescriptionOfProductsAndServices} to ${WhoIsYourGenderTarget} aged ${WhoIsYourTargetAge} in ${location}.
        Ensure the content is engaging, platform-specific, and should reflect an ${SelectYourBrandPersonality} style. The posts should:

        Include the type of post, idea, and caption for each post.
        Be visually compelling and the same posts must work for Facebook, Instagram, and Twitter. Twitter posts cannot have more than 280 characters.
        Incorporate storytelling to highlight ${DescriptionOfProductsAndServices}, company culture, and industry insights for the ${SelectYourBrandPersonality} industry.
        Use ${location} specific references to strengthen community ties.
        Vary in type, including high-quality images, BTS videos, polls, infographics, and carousels to drive interactions with ${WhoIsYourGenderTarget} aged ${WhoIsYourTargetAge} in ${location}.
        Maintain a ${SelectYourBrandPersonality} style.
        Include clear CTAs for promotions, engagement, or conversions.
        Use precise language, hashtags, and SEO keywords relevant to ${location} and ${ChooseYourIndustryType} for optimal reach to ${WhoIsYourGenderTarget} aged ${WhoIsYourTargetAge}.

        The mentioned social networks should only be used to give you an idea of what kind of content you should generate and not to generate a quantity of posts for each of the social networks, only generate ${numberPosts} posts.

        Generate an array follow the next JSON Object, ONLY include 'socialMediaPost' as UNIQUE attribute and do not include another additional attribute. assign each generated content to the following JSON as a value and return this same JSON with the generated information and dont modify this JSON Object, we want key - value, DONT'T INCLUDE SPECIAL CHARACTERS at the beginning and at the end of the JSON object, DO NOT modify the name of each key that i send you, DO NOT include break lines in each object on JSON array and DO NOT INCLUDE brackets. ${generateObjects}`;

      return handlerReplaceIdentationAndLineBreak(prompt);
    }
  
    if (groupSocialNetwork === 2) {
      const generateObjects = `[${Array(numberPosts).fill(formatObjectForMonthlyPrompt).join(',')}]`;

      const prompt =  `Generate an array in the following JSON format with ONLY ${numberPosts} SEO-optimized social media posts for only this social networks: ${socialNetworksList}. Set array inside attribute called 'socialMediaPost' in JSON for
        ${WhatIsYourBusinessName}, create vlog ideas with voice-over script outlines for , a ${WhatIsYourBusinessType} in the ${ChooseYourIndustryType} industry,
        whose products and services are ${DescriptionOfProductsAndServices}.
        The content should include 6 vlog ideas from ${arrayCategories.category_1} and 6 from ${arrayCategories.category_2},
        tailored for ${WhoIsYourGenderTarget} aged ${WhoIsYourTargetAge} in ${location}. Each script should outline a 2-minute video for YouTube shorts,
        Instagram reels, and TikTok, ensuring the content is engaging, suitable for each platform, and reflects a ${SelectYourBrandPersonality} style.
       
        The mentioned social networks should only be used to give you an idea of what kind of content you should generate and not to generate a quantity of posts for each of the social networks, only generate ${numberPosts} posts.

        Generate an array follow the next JSON Object, ONLY include 'socialMediaPost' as UNIQUE attribute and do not include another additional attribute. assign each generated content to the following JSON as a value and return this same JSON with the generated information and dont modify this JSON Object, we want key - value, DONT'T INCLUDE SPECIAL CHARACTERS at the beginning and at the end of the JSON object, DO NOT modify the name of each key that i send you, DO NOT include break lines in each object on JSON array and DO NOT INCLUDE brackets. ${generateObjects}`;

      return handlerReplaceIdentationAndLineBreak(prompt);
    }
  }

  // Premium plan
  if (servicePlan === "d370579d-dbec-4fd6-9c26-d219fe00da1d") {
    const numberPosts = numberDaysSelected > 10 ? 10 : numberDaysSelected;

    if (groupSocialNetwork === 1) {
      const generateObjects = `[${Array(numberPosts).fill(formatObjectForMonthlyPrompt).join(',')}]`;

      const prompt = `Generate an array in the following JSON format with ONLY ${numberPosts} SEO-optimized social media posts for only this social networks: ${socialNetworksList}. Set array inside attribute called 'socialMediaPost' in JSON for
        ${WhatIsYourBusinessName}, a ${WhatIsYourBusinessType} in the ${ChooseYourIndustryType} industry,
        emphasize their ${DescriptionOfProductsAndServices} to ${WhoIsYourGenderTarget} aged ${WhoIsYourTargetAge} in ${location}, reflecting an ${SelectYourBrandPersonality} brand tone. The calendar should:

        Include the type of post, idea, and caption for each post.
        Be visually compelling and the same posts must work for Facebook, Instagram, and Twitter. Twitter posts cannot have more than 280 characters.
        Integrate the lifestyle allure of ${location}, highlighting how the brand's offerings enrich daily life.
        Feature a curated selection of post types, including interactive elements like quizzes and polls, as well as multimedia like GIFs and animations.
        Maintain an aspirational yet relatable tone, fostering community engagement and featuring CTAs for brand storytelling, exclusive offers, and community events.
        Employ advanced SEO techniques and utilize trending hashtags to boost visibility,
        particularly in ${location} and ${ChooseYourIndustryType} for the target audience ${WhoIsYourGenderTarget} aged ${WhoIsYourTargetAge}.

        The mentioned social networks should only be used to give you an idea of what kind of content you should generate and not to generate a quantity of posts for each of the social networks, only generate ${numberPosts} posts.

        Generate an array follow the next JSON Object, ONLY include 'socialMediaPost' as UNIQUE attribute and do not include another additional attribute. assign each generated content to the following JSON as a value and return this same JSON with the generated information and dont modify this JSON Object, we want key - value, DONT'T INCLUDE SPECIAL CHARACTERS at the beginning and at the end of the JSON object, DO NOT modify the name of each key that i send you, DO NOT include break lines in each object on JSON array and DO NOT INCLUDE brackets. ${generateObjects}`;

      return handlerReplaceIdentationAndLineBreak(prompt);
    }

    if (groupSocialNetwork === 2) {
      const generateObjects = `[${Array(numberPosts).fill(formatObjectForMonthlyPrompt).join(',')}]`;

      const prompt =  `Generate an array in the following JSON format with ONLY ${numberPosts} SEO-optimized social media posts for only this social networks: ${socialNetworksList}.
        Set array inside attribute called 'socialMediaPost' in JSON for ${WhatIsYourBusinessName}, create vlog ideas with voice-over script outlines for , a ${WhatIsYourBusinessType} in the ${ChooseYourIndustryType} industry,
        whose products and services are ${DescriptionOfProductsAndServices}.
        The content should include 5 vlog ideas from ${arrayCategories.category_1} and 5 from ${arrayCategories.category_2},
        tailored for ${WhoIsYourGenderTarget} aged ${WhoIsYourTargetAge} in ${location}. Each script should outline a 2-minute video for YouTube shorts,
        Instagram reels, and TikTok, ensuring the content is engaging, suitable for each platform, and reflects a ${SelectYourBrandPersonality} style.

        The mentioned social networks should only be used to give you an idea of what kind of content you should generate and not to generate a quantity of posts for each of the social networks, only generate ${numberPosts} posts.

        Generate an array follow the next JSON Object, ONLY include 'socialMediaPost' as UNIQUE attribute and do not include another additional attribute. assign each generated content to the following JSON as a value and return this same JSON with the generated information and dont modify this JSON Object, we want key - value, DONT'T INCLUDE SPECIAL CHARACTERS at the beginning and at the end of the JSON object, DO NOT modify the name of each key that i send you, DO NOT include break lines in each object on JSON array and DO NOT INCLUDE brackets. ${generateObjects}`;

      return handlerReplaceIdentationAndLineBreak(prompt);
    }
  }
};
