import React, { useState, useEffect } from 'react';
import '../../../../../styles/contentCalendar/components/detailPost/style.scss';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Fade } from "react-reveal";
import {
  setPublishDate, setPublishHour,
  setAlertNotification, setAlertNotificationDate, setAlertNotificationHour,
  setImageData, setDescription as setDescriptionPost, setHahstags,
  setNewPost as setNewPostSlice
} from '../../../../../../reducers/newPost';
import { formatDateMoment, formatHourMoment, socialNetworksConst } from '../../../../../services/values';
import Text from '../../../../../share-components/text/text';
import '../../../../../share-components/toogle.scss';
import '../../../../../share-components/skeleton.scss';
import ImageList from '../imageList/imageList';
import { updatePostList } from '../../../../../../reducers/postsCompanyAsCollaborator';
import { setShowAlert } from '../../../../../../reducers/showAlert';
import { patchRequestCollaborator } from '../../../../../services/requestLib';
import { resetImageSelectedForPost } from '../../../../../../reducers/imageSelectedForPost';
import UploadFiles from '../uploadFiles/uploadFiles';

function DetailPost({ closeModal, SendSocket }) {
  const currentDate = moment().format(formatDateMoment);
  const userData = useSelector((state) => state.userData.value);

  const activeCompanyProfile = useSelector((state) => state.collaboratorCompanySelected.value);
  const postsCompany = useSelector((state) => state.postsCompanyAsCollaborator.value);
  const activeCollaboratorMode = useSelector((state) => state.activeCollaboratorMode.value);

  // eslint-disable-next-line no-unused-vars
  const [postsCompanyState, setPostsCompanyState] = useState({});
  const postData = useSelector((state) => state.newPost.value);
  const [newPost, setNewPost] = useState({...postData});
  const imageSelectedForPost = useSelector((state) => state.imageSelectedForPost.value);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const handlerSetAlertNotification = (infoPost) => {
    dispatch(setAlertNotification(infoPost));
  };

  const handlerSetNewPost = (infoPost) => {
    dispatch(setNewPostSlice(infoPost));
  };

  const handlerSetAlertNotificationDate = (infoPost) => {
    dispatch(setAlertNotificationDate(infoPost));
  };

  const handlerSetAlertNotificationHour = (infoPost) => {
    dispatch(setAlertNotificationHour(infoPost));
  };

  const handlerSetPublishDate = (infoPost) => {
    dispatch(setPublishDate(infoPost));
  };

  const handlerSetPublishHour = (infoPost) => {
    dispatch(setPublishHour(infoPost));
  };

  const handlerSetImageData = (infoPost) => {
    dispatch(setImageData(infoPost));
  };

  const handlerSetDescription = (infoPost) => {
    dispatch(setDescriptionPost(infoPost));
  };
   
  const handlerSetHahstags = (infoPost) => {
    dispatch(setHahstags(infoPost));
  };

  const handlerUpdatePostList = (infoPostList) => {
    dispatch(updatePostList(infoPostList));
  };

  const handlerSetShowAlert = (infoAlert) => {
    dispatch(setShowAlert(infoAlert));
  };

  const handlerResetImageSelectedForPost = () => {
    dispatch(resetImageSelectedForPost());
  };

  const handlerCloseModal = () => {
    handlerResetImageSelectedForPost();
    closeModal();
  };

  const openAlert = (typeAlert, textAlert) => {
    const object = {
      typeAlert: typeAlert,
      textAlert: textAlert
    };

    handlerSetShowAlert(object);
  };

  const handlerChangeDescriptionOrHashtahs = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "description":
          handlerSetDescription(value);
        break;
      case "hahstags":
          handlerSetHahstags(value);
        break;
      default:
        break;
    }
  };

  const [contentIdea, setContentIdea] = useState("");  
  const [description, setDescription] = useState("");
  // const [hashtags, setHashtags] = useState("");

  useEffect(() => {
    setContentIdea(newPost.contentIdea);
    setDescription(newPost.postText);
    // setHashtags(newPost.hashtags);
  }, [newPost]);

  const [enableAlarm, setEnableAlarm] = useState(false);

  const handlerToogleAlarm = () => {
    if (enableAlarm) {
      setEnableAlarm(false);
      handlerSetAlertNotification(false);
    }
    
    if (!enableAlarm) {
      setEnableAlarm(true);
      handlerSetAlertNotification(true);
    }
  };

  const handlerSetDateTimePublish = (e) => {
    const { name, valueAsNumber } = e.target;

    switch (name) {
      case "date-publish": {
        const formatDate = moment.utc(valueAsNumber).format(formatDateMoment);
        const isValidDate = moment(formatDate).isValid();

        if (isValidDate) {
          handlerSetPublishDate(formatDate);
        } else {
          handlerSetPublishDate("");
        }

        break;
      }
      case "time-publish": {
        const formatHour = moment.utc(valueAsNumber).format(formatHourMoment);

        if (formatHour !== "invalid-date") {
          handlerSetPublishHour(formatHour);
        } else {
          handlerSetPublishHour("");
        }

        break;
      }
      default:
        break;
    }
  };

  const handlerSetDateTimeNotification = (e) => {
    const { name, valueAsNumber } = e.target;

    switch (name) {
      case "date-notification": {
        const formatDate = moment.utc(valueAsNumber).format(formatDateMoment);
        const isValidDate = moment(formatDate).isValid();

        if (isValidDate) {
          handlerSetAlertNotificationDate(formatDate);
        } else {
          handlerSetAlertNotificationDate("");
        }

        break;
      }
      case "time-notification": {
        const formatHour = moment.utc(valueAsNumber).format(formatHourMoment);

        if (formatHour !== "invalid-date") {
          handlerSetAlertNotificationHour(formatHour);
        } else {
          handlerSetAlertNotificationHour("");
        }

        break;
      }
      default:
        break;
    }
  };

  const [openImageList, setOpenImageList] = useState(false);

  const handlerOpenImageList = () => {
    if (openImageList) {
      setOpenImageList(false);
    }
    if (!openImageList) {
      setOpenImageList(true);
    }
  };

  const isFormDataValid = () => {
    const isValid =
      newPost.postText !== "" &&
      newPost.hashtags !== "" &&
      newPost.publishHour !== "" &&
      newPost.publishDate !== "" && !moment.utc(currentDate).isSameOrAfter(newPost.publishDate);

    return isValid;
  };

  const validateDate = (date) => {
    const isValidDate = moment.utc(date).isValid();

    if (isValidDate) {
      if (moment(currentDate).isSameOrAfter(date)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handlerUpdatePostListCompany = async () => {
    const user = {
      email: userData.email,
      password: userData.password,
      token: userData.token,
      typeAccess: 2
    };

    const sendData = {
      id: activeCompanyProfile.idCompany,
      user: activeCompanyProfile.userCompanyOwner,
      postList: postsCompany.postList,
    };

    const responseUpdatePostCompany = await patchRequestCollaborator('company-posts', user, sendData);
    if (responseUpdatePostCompany.status === 200 && responseUpdatePostCompany.data.status === true) {

      SendSocket({
        action: 'send-message',
        data: {
          id: activeCompanyProfile.idCompany,
          user: userData.user,
          message: {
            type: 'UPDATE',
            table: 'company-posts',
            sendFromAdminView: false,
            sendFromCollaboratorView: true,
            companyOwner: activeCompanyProfile.userCompanyOwner,
            idCompanyOwner: activeCompanyProfile.idCompany,
            userSendUpdate: userData.user,
            isAdmin: !activeCollaboratorMode.collaboratorMode,
            data: { 
              user: postsCompany.user,
              id: postsCompany.id,
              email: postsCompany.email,
              maxGenerateContentPost: postsCompany.maxGenerateContentPost,
              maxGenerateSpecificPost: postsCompany.maxGenerateSpecificPost,
              maxGenerateVideoPosts: postsCompany.maxGenerateVideoPosts,
              postList: postsCompany.postList
            }
          }
        }
      });

      handlerCloseModal();
      openAlert("success", "Success");
    } else {
      openAlert("danger", "Error, try again");
    }
  };

  const [confirm, setConfirm] = useState(false);

  const handlerSavePost = () => {
    handlerUpdatePostList(newPost);
    setConfirm(true);
  };

  useEffect(() => {
    if (imageSelectedForPost.uuid !== "") {
      handlerSetImageData(imageSelectedForPost);
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSelectedForPost]);

  useEffect(() => {
    setNewPost({...postData});
  }, [postData]);

  const handlerUpdateCurrentPost = (newData) => {
    const getCurrentPost = newData.postList.find((x) => x.id === newPost.id);
    if (getCurrentPost) {
      setNewPost({...getCurrentPost});
      handlerSetNewPost(getCurrentPost);
    } else {
      handlerSetNewPost(postData);
    }
  };

  useEffect(() => {
    setPostsCompanyState(postsCompany);
    handlerUpdateCurrentPost(postsCompany);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postsCompany]);

  return (
    <div className="content-views">
      <Fade duration={600} opposite>
        <>
          <div className="modal-content d-block">
            <div className="header-detail-post">
              <Text type="subtitle">
                Detail post
              </Text>
            </div>
            <div className="detail-post-container">
              <div className="detail-block">
                <Text type="text">
                  File
                </Text>
              </div>
              <div className="detail-block-img">
                <div>
                  <div className="container-skeleton-or-image">

                    {imageSelectedForPost.uuid !== "" ? (
                      <img
                        src={imageSelectedForPost.urlThumbnail}
                        alt="file selected"
                        className="demo-view-file"
                      />
                    ) : (
                      <>
                        {newPost.urlThumbnailFile ? (
                          <img
                            src={newPost.urlThumbnailFile}
                            alt="file post"
                            className="demo-view-file"
                          />
                        ) : (
                          <div className="skeleton"></div>
                        )}
                      </>
                    )}

                  </div>
                </div>
                <div className="d-flex flex-row gap-10">
                  <button className="btn-select-file" onClick={() => handlerOpenImageList()}>
                    Select file
                  </button>
                  <UploadFiles importButton={"detail post"} SendSocket={SendSocket} />
                </div>
              </div>
              <div className={`block-image-list overflow-scroll ${openImageList ? 'show' : ''}`}>
                <Text type="text">
                  Gallery
                </Text>
                <ImageList isCollapse={openImageList} SendSocket={SendSocket} fromView={'modal-post'} />
              </div>
              <div className="detail-block">
                <Text type="text">
                  Social network
                </Text>
                <div className='d-flex flex-row gap-10'>
                  {newPost.typePost === "Weekly Post" && (
                    <>
                      {newPost.groupPost === "Content post" && (
                        socialNetworksConst.filter((x) => x.group === 1).map((sn) => (
                          <img
                            key={sn.id}
                            className="social-icon-header"
                            src={sn.urlIcon}
                            alt="Weekly posts - Content post"
                          />
                        ))
                      )}

                      {newPost.groupPost === "Video content post" && (
                        socialNetworksConst.filter((x) => x.group === 2).map((sn) => (
                          <img
                            key={sn.id}
                            className="social-icon-header"
                            src={sn.urlIcon}
                            alt="Weekly posts - Video content post"
                          />
                        ))
                      )}
                    </>
                  )}

                  {newPost.typePost === "Specific Post" && (
                    <img
                      className="social-icon-header"
                      src={newPost.imgSocialNetwork}
                      alt="Specific posts"
                    />
                  )}
                </div>
              </div>
              <div className="detail-block">
                <Text type="text">
                  {newPost.typePost === "Weekly Post" && (
                   <>
                    {(newPost.typePost === "Weekly Post" && newPost.groupPost === "Content post") && "Content idea"}
                    {(newPost.typePost === "Weekly Post" && newPost.groupPost === "Video content post") && "Category description"}
                   </>
                  )}

                  {newPost.typePost !== "Weekly Post" && (
                    "Content idea"
                  )}
                </Text>

                <div className="d-flex flex-column" style={{ width: '70%' }}>
                  <textarea
                    className="textarea-detail-post text-area-input"
                    rows={2}
                    value={contentIdea}
                    disabled={true}
                  >
                  </textarea>
                </div>
              </div>
              <div className="detail-block">
                <Text type="text">
                  {newPost.typePost === "Weekly Post" && (
                   <>
                    {(newPost.typePost === "Weekly Post" && newPost.groupPost === "Content post") && "Description"}
                    {(newPost.typePost === "Weekly Post" && newPost.groupPost === "Video content post") && "Content Idea"}
                   </>
                  )}

                  {newPost.typePost !== "Weekly Post" && (
                    "Description"
                  )}
                </Text>
                <div className="d-flex flex-column" style={{ width: '70%' }}>
                  <textarea
                    className={`textarea-detail-post text-area-input ${newPost.postText === "" ? 'invalid-answer' : ''}`}
                    rows={6}
                    name="description"
                    onChange={(e) => handlerChangeDescriptionOrHashtahs(e)}
                    value={description}
                    disabled={confirm}
                  >
                  </textarea>
                  {newPost.postText === "" && (
                    <small className="text-danger mb-1 mt-1">
                      Description's text box should not be empty
                    </small>
                  )}
                </div>
              </div>
              {/*
                <div className="detail-block">
                  <Text type="text">
                    Hashtags
                  </Text>
                  <div className="d-flex flex-column" style={{ width: '70%' }}>
                    <textarea
                      className={`textarea-detail-post text-area-input ${newPost.hashtags === "" ? 'invalid-answer' : ''}`}
                      rows={4}
                      name="hahstags"
                      onChange={(e) => handlerChangeDescriptionOrHashtahs(e)}
                      value={hashtags}
                      disabled={confirm}
                    >
                    </textarea>
                    {newPost.hashtags === "" && (
                      <small className="text-danger mb-1 mt-1">
                        Hashtags's text box should not be empty
                      </small>
                    )}
                  </div>
                </div>
              */}
              <div className="detail-block">
                <Text type="text">
                  Day and time for publish post
                </Text>
                <div className="d-flex flex-column">
                  <div className="d-flex flex-column">
                    <input
                      type='date'
                      className={`mb-1 ${validateDate(newPost.publishDate) ? 'invalid-answer' : ''}`}
                      name="date-publish"
                      onChange={(e) => handlerSetDateTimePublish(e)}
                      disabled={confirm}
                      value={newPost.publishDate}
                    />
                    {validateDate(newPost.publishDate) && (
                      <small className="text-danger mb-1">
                        The publication date must be real date or greater than today.
                      </small>
                    )}
                  </div>
                  <input
                    type='time'
                    className="mb-1"
                    name="time-publish"
                    onChange={(e) => handlerSetDateTimePublish(e)}
                    disabled={confirm}
                    value={newPost.publishHour}
                  />
                </div>  
              </div>
              <div className="detail-block">
                <Text type="text">
                  Enable alarm
                </Text>
                <button
                  className={`btn-toogle ${enableAlarm ? 'active' : ''}`} 
                  onClick={() => handlerToogleAlarm()}
                >
                  <div className="circle-toogle">
                  </div>
                </button>
              </div>
            </div>
            {enableAlarm && (
              <div className="detail-block">
                <Text type="small">
                  Day and time for notification
                </Text>
                <div className="d-flex flex-column">
                  <input
                    type='date'
                    className="mb-1"
                    name="date-notification"
                    onChange={(e) => handlerSetDateTimeNotification(e)}
                  />
                  <input
                    type='time'
                    className="mb-1"
                    name="time-notification"
                    onChange={(e) => handlerSetDateTimeNotification(e)}
                  />
                </div>  
              </div>
            )}
          </div>
          <div className="action-btn-form">
            {!confirm ? (
              <>
                <button
                  className="two-btn-modal"
                  onClick={() => handlerCloseModal()}>
                  Cancel
                </button>
                <button
                  className={`two-btn-modal ${!isFormDataValid() ? 'btn-disabled' : ''}`}
                  onClick={() => handlerSavePost()}
                  disabled={!isFormDataValid()}
                >
                  Confirm
                </button>
              </>
            ) : (
              <button
                className={`one-btn-modal btn-primary`}
                onClick={() => handlerUpdatePostListCompany()}
              >
                Save
              </button>
            )}
          </div>
        </>
      </Fade>
    </div>
  )
};

DetailPost.propTypes = {
  closeModal: PropTypes.func.isRequired,
  SendSocket: PropTypes.func.isRequired,
};

export default DetailPost;
