/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const companyList = createSlice({
  name: 'companyList',
  initialState: {
    value: {
      user: "",
      email: "",
      companyList: []
    },
  },
  reducers: {
    setCompanyList: (state, action) => {
      state.value.user = action.payload.user;
      state.value.email = action.payload.email;
      state.value.companyList = action.payload.companyList;
    },
    setNewCompany: (state, action) => {
      state.value.companyList = action.payload;
    }
  },
});

export const {
  setCompanyList, setNewCompany
} = companyList.actions;

export default companyList.reducer;
