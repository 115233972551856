import React from 'react'
import { Fade } from "react-reveal";
import { PulseLoader } from 'react-spinners';
import Text from '../../text/text';

function Loading() {
  return (
    <Fade duration={600} opposite>
      <div className="d-flex flex-column align-items-center justify-content-center w-100 flex-1">
        <PulseLoader color="#6001d0" />
        <Text type="text" inyectClass="mt-3">
          Loading data
        </Text>
      </div>
    </Fade>
  )
}

export default Loading;
