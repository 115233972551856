import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import {
  useSelector
} from 'react-redux';

function Text({ type, children, inyectClass }) {
  const userAgent = useSelector((state) => state.userAgent.value);
  const getTheme = userAgent.theme;

  return (
    <span
      className={`body-${type} ${getTheme} ${inyectClass || ''}`}
    >
      {children}
    </span>
  )
}

Text.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  inyectClass: PropTypes.node
};

export default Text;
