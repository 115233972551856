export const location = [
  {
    "nombre": "New York",
    "country_code": "US"
  },
  {
    "nombre": "Los Ángeles",
    "country_code": "US"
  },
  {
    "nombre": "Chicago",
    "country_code": "US"
  },
  {
    "nombre": "Houston",
    "country_code": "US"
  },
  {
    "nombre": "Phoenix",
    "country_code": "US"
  },
  {
    "nombre": "Filadelfia",
    "country_code": "US"
  },
  {
    "nombre": "San Antonio",
    "country_code": "US"
  },
  {
    "nombre": "San Diego",
    "country_code": "US"
  },
  {
    "nombre": "Dallas",
    "country_code": "US"
  },
  {
    "nombre": "San José",
    "country_code": "US"
  },
  {
    "nombre": "Austin",
    "country_code": "US"
  },
  {
    "nombre": "Jacksonville",
    "country_code": "US"
  },
  {
    "nombre": "Fort Worth",
    "country_code": "US"
  },
  {
    "nombre": "Columbus",
    "country_code": "US"
  },
  {
    "nombre": "San Francisco",
    "country_code": "US"
  },
  {
    "nombre": "Charlotte",
    "country_code": "US"
  },
  {
    "nombre": "Indianápolis",
    "country_code": "US"
  },
  {
    "nombre": "Seattle",
    "country_code": "US"
  },
  {
    "nombre": "Denver",
    "country_code": "US"
  },
  {
    "nombre": "Washington",
    "country_code": "US"
  },
  {
    "nombre": "Boston",
    "country_code": "US"
  },
  {
    "nombre": "El Paso",
    "country_code": "US"
  },
  {
    "nombre": "Nashville",
    "country_code": "US"
  },
  {
    "nombre": "Detroit",
    "country_code": "US"
  },
  {
    "nombre": "Oklahoma City",
    "country_code": "US"
  },
  {
    "nombre": "Portland",
    "country_code": "US"
  },
  {
    "nombre": "Las Vegas",
    "country_code": "US"
  },
  {
    "nombre": "Memphis",
    "country_code": "US"
  },
  {
    "nombre": "Louisville",
    "country_code": "US"
  },
  {
    "nombre": "Baltimore",
    "country_code": "US"
  },
  {
    "nombre": "Milwaukee",
    "country_code": "US"
  },
  {
    "nombre": "Albuquerque",
    "country_code": "US"
  },
  {
    "nombre": "Tucson",
    "country_code": "US"
  },
  {
    "nombre": "Fresno",
    "country_code": "US"
  },
  {
    "nombre": "Mesa",
    "country_code": "US"
  },
  {
    "nombre": "Sacramento",
    "country_code": "US"
  },
  {
    "nombre": "Atlanta",
    "country_code": "US"
  },
  {
    "nombre": "Kansas City",
    "country_code": "US"
  },
  {
    "nombre": "Colorado Springs",
    "country_code": "US"
  },
  {
    "nombre": "Miami",
    "country_code": "US"
  },
  {
    "nombre": "Raleigh",
    "country_code": "US"
  },
  {
    "nombre": "Omaha",
    "country_code": "US"
  },
  {
    "nombre": "Long Beach",
    "country_code": "US"
  },
  {
    "nombre": "Virginia Beach",
    "country_code": "US"
  },
  {
    "nombre": "Oakland",
    "country_code": "US"
  },
  {
    "nombre": "Minneapolis",
    "country_code": "US"
  },
  {
    "nombre": "Tulsa",
    "country_code": "US"
  },
  {
    "nombre": "Tampa",
    "country_code": "US"
  },
  {
    "nombre": "Arlington",
    "country_code": "US"
  },
  {
    "nombre": "New Orleans",
    "country_code": "US"
  },
  {
    "nombre": "Wichita",
    "country_code": "US"
  },
  {
    "nombre": "Bakersfield",
    "country_code": "US"
  },
  {
    "nombre": "Cleveland",
    "country_code": "US"
  },
  {
    "nombre": "Aurora",
    "country_code": "US"
  },
  {
    "nombre": "Anaheim",
    "country_code": "US"
  },
  {
    "nombre": "Honolulu",
    "country_code": "US"
  },
  {
    "nombre": "Santa Ana",
    "country_code": "US"
  },
  {
    "nombre": "Riverside",
    "country_code": "US"
  },
  {
    "nombre": "Corpus Christi",
    "country_code": "US"
  },
  {
    "nombre": "Lexington",
    "country_code": "US"
  },
  {
    "nombre": "Henderson",
    "country_code": "US"
  },
  {
    "nombre": "Stockton",
    "country_code": "US"
  },
  {
    "nombre": "San Luis",
    "country_code": "US"
  },
  {
    "nombre": "Saint Paul",
    "country_code": "US"
  },
  {
    "nombre": "Cincinnati",
    "country_code": "US"
  },
  {
    "nombre": "Pittsburgh",
    "country_code": "US"
  },
  {
    "nombre": "Greensboro",
    "country_code": "US"
  },
  {
    "nombre": "Lincoln",
    "country_code": "US"
  },
  {
    "nombre": "Anchorage",
    "country_code": "US"
  },
  {
    "nombre": "Plano",
    "country_code": "US"
  },
  {
    "nombre": "Orlando",
    "country_code": "US"
  },
  {
    "nombre": "Irvine",
    "country_code": "US"
  },
  {
    "nombre": "Newark",
    "country_code": "US"
  },
  {
    "nombre": "Toledo",
    "country_code": "US"
  },
  {
    "nombre": "Durham",
    "country_code": "US"
  },
  {
    "nombre": "Chula Vista",
    "country_code": "US"
  },
  {
    "nombre": "Fort Wayne",
    "country_code": "US"
  },
  {
    "nombre": "Jersey City",
    "country_code": "US"
  },
  {
    "nombre": "Saint Petersburg",
    "country_code": "US"
  },
  {
    "nombre": "Laredo",
    "country_code": "US"
  },
  {
    "nombre": "Madison",
    "country_code": "US"
  },
  {
    "nombre": "Chandler",
    "country_code": "US"
  },
  {
    "nombre": "Buffalo",
    "country_code": "US"
  },
  {
    "nombre": "Lubbock",
    "country_code": "US"
  },
  {
    "nombre": "Scottsdale",
    "country_code": "US"
  },
  {
    "nombre": "Reno",
    "country_code": "US"
  },
  {
    "nombre": "Glendale",
    "country_code": "US"
  },
  {
    "nombre": "Gilbert",
    "country_code": "US"
  },
  {
    "nombre": "Winston-Salem",
    "country_code": "US"
  },
  {
    "nombre": "North Las Vegas",
    "country_code": "US"
  },
  {
    "nombre": "Norfolk",
    "country_code": "US"
  },
  {
    "nombre": "Chesapeake",
    "country_code": "US"
  },
  {
    "nombre": "Garland",
    "country_code": "US"
  },
  {
    "nombre": "Irving",
    "country_code": "US"
  },
  {
    "nombre": "Hialeah",
    "country_code": "US"
  },
  {
    "nombre": "Fremont",
    "country_code": "US"
  },
  {
    "nombre": "Boise",
    "country_code": "US"
  },
  {
    "nombre": "Richmond",
    "country_code": "US"
  },
  {
    "nombre": "Baton Rouge",
    "country_code": "US"
  },
  {
    "nombre": "Spokane",
    "country_code": "US"
  },
  {
    "nombre": "Des Moines",
    "country_code": "US"
  },
  {
    "nombre": "Tacoma",
    "country_code": "US"
  },
  {
    "nombre": "San Bernardino",
    "country_code": "US"
  },
  {
    "nombre": "Modesto",
    "country_code": "US"
  },
  {
    "nombre": "Fontana",
    "country_code": "US"
  },
  {
    "nombre": "Santa Clarita",
    "country_code": "US"
  },
  {
    "nombre": "Birmingham",
    "country_code": "US"
  },
  {
    "nombre": "Oxnard",
    "country_code": "US"
  },
  {
    "nombre": "Fayetteville",
    "country_code": "US"
  },
  {
    "nombre": "Moreno Valley",
    "country_code": "US"
  },
  {
    "nombre": "Rochester",
    "country_code": "US"
  },
  {
    "nombre": "Glendale",
    "country_code": "US"
  },
  {
    "nombre": "Huntington Beach",
    "country_code": "US"
  },
  {
    "nombre": "Salt Lake City",
    "country_code": "US"
  },
  {
    "nombre": "Grand Rapids",
    "country_code": "US"
  },
  {
    "nombre": "Amarillo",
    "country_code": "US"
  },
  {
    "nombre": "Yonkers",
    "country_code": "US"
  },
  {
    "nombre": "Aurora",
    "country_code": "US"
  },
  {
    "nombre": "Montgomery",
    "country_code": "US"
  },
  {
    "nombre": "Akron",
    "country_code": "US"
  },
  {
    "nombre": "Little Rock",
    "country_code": "US"
  },
  {
    "nombre": "Huntsville",
    "country_code": "US"
  },
  {
    "nombre": "Augusta",
    "country_code": "US"
  },
  {
    "nombre": "Port St. Lucie",
    "country_code": "US"
  },
  {
    "nombre": "Grand Prairie",
    "country_code": "US"
  },
  {
    "nombre": "Columbus",
    "country_code": "US"
  },
  {
    "nombre": "Tallahassee",
    "country_code": "US"
  },
  {
    "nombre": "Overland Park",
    "country_code": "US"
  },
  {
    "nombre": "Tempe",
    "country_code": "US"
  },
  {
    "nombre": "McKinney",
    "country_code": "US"
  },
  {
    "nombre": "Mobile",
    "country_code": "US"
  },
  {
    "nombre": "Cape Coral",
    "country_code": "US"
  },
  {
    "nombre": "Shreveport",
    "country_code": "US"
  },
  {
    "nombre": "Frisco",
    "country_code": "US"
  },
  {
    "nombre": "Knoxville",
    "country_code": "US"
  },
  {
    "nombre": "Worcester",
    "country_code": "US"
  },
  {
    "nombre": "Brownsville",
    "country_code": "US"
  },
  {
    "nombre": "Vancouver",
    "country_code": "US"
  },
  {
    "nombre": "Fort Lauderdale",
    "country_code": "US"
  },
  {
    "nombre": "Sioux Falls",
    "country_code": "US"
  },
  {
    "nombre": "Ontario",
    "country_code": "US"
  },
  {
    "nombre": "Chattanooga",
    "country_code": "US"
  },
  {
    "nombre": "Providence",
    "country_code": "US"
  },
  {
    "nombre": "Newport News",
    "country_code": "US"
  },
  {
    "nombre": "Rancho Cucamonga",
    "country_code": "US"
  },
  {
    "nombre": "Santa Rosa",
    "country_code": "US"
  },
  {
    "nombre": "Oceanside",
    "country_code": "US"
  },
  {
    "nombre": "Salem",
    "country_code": "US"
  },
  {
    "nombre": "Elk Grove",
    "country_code": "US"
  },
  {
    "nombre": "Garden Grove",
    "country_code": "US"
  },
  {
    "nombre": "Pembroke Pines",
    "country_code": "US"
  },
  {
    "nombre": "Peoria",
    "country_code": "US"
  },
  {
    "nombre": "Eugene",
    "country_code": "US"
  },
  {
    "nombre": "Corona",
    "country_code": "US"
  },
  {
    "nombre": "Cary",
    "country_code": "US"
  },
  {
    "nombre": "Fort Collins",
    "country_code": "US"
  },
  {
    "nombre": "Jackson",
    "country_code": "US"
  },
  {
    "nombre": "Alexandria",
    "country_code": "US"
  },
  {
    "nombre": "Hayward",
    "country_code": "US"
  },
  {
    "nombre": "Lancaster",
    "country_code": "US"
  },
  {
    "nombre": "Lakewood",
    "country_code": "US"
  },
  {
    "nombre": "Clarksville",
    "country_code": "US"
  },
  {
    "nombre": "Palmdale",
    "country_code": "US"
  },
  {
    "nombre": "Salinas",
    "country_code": "US"
  },
  {
    "nombre": "Hollywood",
    "country_code": "US"
  },
  {
    "nombre": "Pasadena",
    "country_code": "US"
  },
  {
    "nombre": "Sunnyvale",
    "country_code": "US"
  },
  {
    "nombre": "Macon",
    "country_code": "US"
  },
  {
    "nombre": "Pomona",
    "country_code": "US"
  },
  {
    "nombre": "Kansas City",
    "country_code": "US"
  },
  {
    "nombre": "Escondido",
    "country_code": "US"
  },
  {
    "nombre": "Killeen",
    "country_code": "US"
  },
  {
    "nombre": "Naperville",
    "country_code": "US"
  },
  {
    "nombre": "Joliet",
    "country_code": "US"
  },
  {
    "nombre": "Bellevue",
    "country_code": "US"
  },
  {
    "nombre": "Rockford",
    "country_code": "US"
  },
  {
    "nombre": "Savannah",
    "country_code": "US"
  },
  {
    "nombre": "Paterson",
    "country_code": "US"
  },
  {
    "nombre": "Torrance",
    "country_code": "US"
  },
  {
    "nombre": "Bridgeport",
    "country_code": "US"
  },
  {
    "nombre": "McAllen",
    "country_code": "US"
  },
  {
    "nombre": "Mesquite",
    "country_code": "US"
  },
  {
    "nombre": "Syracuse",
    "country_code": "US"
  },
  {
    "nombre": "Midland",
    "country_code": "US"
  },
  {
    "nombre": "Pasadena",
    "country_code": "US"
  },
  {
    "nombre": "Dayton",
    "country_code": "US"
  },
  {
    "nombre": "Mira Loma",
    "country_code": "US"
  },
  {
    "nombre": "Olathe",
    "country_code": "US"
  },
  {
    "nombre": "Orange",
    "country_code": "US"
  },
  {
    "nombre": "Thornton",
    "country_code": "US"
  },
  {
    "nombre": "Roseville",
    "country_code": "US"
  },
  {
    "nombre": "Denton",
    "country_code": "US"
  },
  {
    "nombre": "Waco",
    "country_code": "US"
  },
  {
    "nombre": "Surprise",
    "country_code": "US"
  },
  {
    "nombre": "Carrollton",
    "country_code": "US"
  },
  {
    "nombre": "West Valley City",
    "country_code": "US"
  },
  {
    "nombre": "Charleston",
    "country_code": "US"
  },
  {
    "nombre": "Warren",
    "country_code": "US"
  },
  {
    "nombre": "Hampton",
    "country_code": "US"
  },
  {
    "nombre": "Gainesville",
    "country_code": "US"
  },
  {
    "nombre": "Visalia",
    "country_code": "US"
  },
  {
    "nombre": "Coral Springs",
    "country_code": "US"
  },
  {
    "nombre": "Cedar Rapids",
    "country_code": "US"
  },
  {
    "nombre": "Stamford",
    "country_code": "US"
  },
  {
    "nombre": "Concord",
    "country_code": "US"
  },
  {
    "nombre": "Kent",
    "country_code": "US"
  },
  {
    "nombre": "Santa Clara",
    "country_code": "US"
  },
  {
    "nombre": "Elizabeth",
    "country_code": "US"
  },
  {
    "nombre": "Round Rock",
    "country_code": "US"
  },
  {
    "nombre": "Thousand Oaks",
    "country_code": "US"
  },
  {
    "nombre": "Lafayette",
    "country_code": "US"
  },
  {
    "nombre": "Athens",
    "country_code": "US"
  },
  {
    "nombre": "Topeka",
    "country_code": "US"
  },
  {
    "nombre": "Simi Valley",
    "country_code": "US"
  },
  {
    "nombre": "Fargo",
    "country_code": "US"
  },
  {
    "nombre": "Norman",
    "country_code": "US"
  },
  {
    "nombre": "Abilene",
    "country_code": "US"
  },
  {
    "nombre": "Wilmington",
    "country_code": "US"
  },
  {
    "nombre": "Hartford",
    "country_code": "US"
  },
  {
    "nombre": "Victorville",
    "country_code": "US"
  },
  {
    "nombre": "Pearland",
    "country_code": "US"
  },
  {
    "nombre": "Vallejo",
    "country_code": "US"
  },
  {
    "nombre": "Berkeley",
    "country_code": "US"
  },
  {
    "nombre": "Allentown",
    "country_code": "US"
  },
  {
    "nombre": "Richardson",
    "country_code": "US"
  },
  {
    "nombre": "Odessa",
    "country_code": "US"
  },
  {
    "nombre": "Arvada",
    "country_code": "US"
  },
  {
    "nombre": "Cambridge",
    "country_code": "US"
  },
  {
    "nombre": "Sugar Land",
    "country_code": "US"
  },
  {
    "nombre": "Beaumont",
    "country_code": "US"
  },
  {
    "nombre": "Lansing",
    "country_code": "US"
  },
  {
    "nombre": "Evansville",
    "country_code": "US"
  },
  {
    "nombre": "Rochester",
    "country_code": "US"
  },
  {
    "nombre": "Independence",
    "country_code": "US"
  },
  {
    "nombre": "Fairfield",
    "country_code": "US"
  },
  {
    "nombre": "Provo",
    "country_code": "US"
  },
  {
    "nombre": "Clearwater",
    "country_code": "US"
  },
  {
    "nombre": "College Station",
    "country_code": "US"
  },
  {
    "nombre": "West Jordan",
    "country_code": "US"
  },
  {
    "nombre": "Carlsbad",
    "country_code": "US"
  },
  {
    "nombre": "El Monte",
    "country_code": "US"
  },
  {
    "nombre": "Murrieta",
    "country_code": "US"
  },
  {
    "nombre": "Temecula",
    "country_code": "US"
  },
  {
    "nombre": "Palm Bay",
    "country_code": "US"
  },
  {
    "nombre": "Costa Mesa",
    "country_code": "US"
  },
  {
    "nombre": "Westminster",
    "country_code": "US"
  },
  {
    "nombre": "North Charleston",
    "country_code": "US"
  },
  {
    "nombre": "Miami Gardens",
    "country_code": "US"
  },
  {
    "nombre": "Manchester",
    "country_code": "US"
  },
  {
    "nombre": "High Point",
    "country_code": "US"
  },
  {
    "nombre": "Downey",
    "country_code": "US"
  },
  {
    "nombre": "Clovis",
    "country_code": "US"
  },
  {
    "nombre": "Pompano Beach",
    "country_code": "US"
  },
  {
    "nombre": "Pueblo",
    "country_code": "US"
  },
  {
    "nombre": "Elgin",
    "country_code": "US"
  },
  {
    "nombre": "Lowell",
    "country_code": "US"
  },
  {
    "nombre": "Antioch",
    "country_code": "US"
  },
  {
    "nombre": "West Palm Beach",
    "country_code": "US"
  },
  {
    "nombre": "Peoria",
    "country_code": "US"
  },
  {
    "nombre": "Everett",
    "country_code": "US"
  },
  {
    "nombre": "Ventura",
    "country_code": "US"
  },
  {
    "nombre": "Centennial",
    "country_code": "US"
  },
  {
    "nombre": "Lakeland",
    "country_code": "US"
  },
  {
    "nombre": "Gresham",
    "country_code": "US"
  },
  {
    "nombre": "Richmond",
    "country_code": "US"
  },
  {
    "nombre": "Billings",
    "country_code": "US"
  },
  {
    "nombre": "Inglewood",
    "country_code": "US"
  },
  {
    "nombre": "Broken Arrow",
    "country_code": "US"
  },
  {
    "nombre": "Jurupa Valley",
    "country_code": "US"
  },
  {
    "nombre": "Hillsboro",
    "country_code": "US"
  },
  {
    "nombre": "Waterbury",
    "country_code": "US"
  },
  {
    "nombre": "Santa Maria",
    "country_code": "US"
  },
  {
    "nombre": "Boulder",
    "country_code": "US"
  },
  {
    "nombre": "Greeley",
    "country_code": "US"
  },
  {
    "nombre": "Daly City",
    "country_code": "US"
  },
  {
    "nombre": "Meridian",
    "country_code": "US"
  },
  {
    "nombre": "Lewisville",
    "country_code": "US"
  },
  {
    "nombre": "Davie",
    "country_code": "US"
  },
  {
    "nombre": "West Covina",
    "country_code": "US"
  },
  {
    "nombre": "League City",
    "country_code": "US"
  },
  {
    "nombre": "Tyler",
    "country_code": "US"
  },
  {
    "nombre": "Norwalk",
    "country_code": "US"
  },
  {
    "nombre": "San Mateo",
    "country_code": "US"
  },
  {
    "nombre": "Green Bay",
    "country_code": "US"
  },
  {
    "nombre": "Wichita Falls",
    "country_code": "US"
  },
  {
    "nombre": "Sparks",
    "country_code": "US"
  },
  {
    "nombre": "Lakewood",
    "country_code": "US"
  },
  {
    "nombre": "Burbank",
    "country_code": "US"
  },
  {
    "nombre": "Rialto",
    "country_code": "US"
  },
  {
    "nombre": "Allen",
    "country_code": "US"
  },
  {
    "nombre": "El Cajon",
    "country_code": "US"
  },
  {
    "nombre": "Las Cruces",
    "country_code": "US"
  },
  {
    "nombre": "Renton",
    "country_code": "US"
  },
  {
    "nombre": "South Bend",
    "country_code": "US"
  },
  {
    "nombre": "Vista",
    "country_code": "US"
  },
  {
    "nombre": "Tuscaloosa",
    "country_code": "US"
  },
  {
    "nombre": "Clinton",
    "country_code": "US"
  },
  {
    "nombre": "Edison",
    "country_code": "US"
  },
  {
    "nombre": "Woodbridge",
    "country_code": "US"
  },
  {
    "nombre": "San Angelo",
    "country_code": "US"
  },
  {
    "nombre": "Kenosha",
    "country_code": "US"
  },
  {
    "nombre": "Vacaville",
    "country_code": "US"
  },
  {
    "nombre": "Cary",
    "country_code": "US"
  },
  {
    "nombre": "Rockville",
    "country_code": "US"
  },
  {
    "nombre": "Edinburg",
    "country_code": "US"
  },
  {
    "nombre": "Carmel",
    "country_code": "US"
  },
  {
    "nombre": "Spokane Valley",
    "country_code": "US"
  },
  {
    "nombre": "Rio Rancho",
    "country_code": "US"
  },
  {
    "nombre": "Allen",
    "country_code": "US"
  },
  {
    "nombre": "Yuma",
    "country_code": "US"
  },
  {
    "nombre": "Norwalk",
    "country_code": "US"
  },
  {
    "nombre": "Auburn",
    "country_code": "US"
  },
  {
    "nombre": "Germantown",
    "country_code": "US"
  },
  {
    "nombre": "Madera",
    "country_code": "US"
  },
  {
    "nombre": "Sandy",
    "country_code": "US"
  },
  {
    "nombre": "Longmont",
    "country_code": "US"
  },
  {
    "nombre": "Bend",
    "country_code": "US"
  },
  {
    "nombre": "Nashua",
    "country_code": "US"
  },
  {
    "nombre": "Bloomington",
    "country_code": "US"
  },
  {
    "nombre": "Fishers",
    "country_code": "US"
  },
  {
    "nombre": "Clovis",
    "country_code": "US"
  },
  {
    "nombre": "Newport Beach",
    "country_code": "US"
  },
  {
    "nombre": "Plantation",
    "country_code": "US"
  },
  {
    "nombre": "Troy",
    "country_code": "US"
  },
  {
    "nombre": "Chino",
    "country_code": "US"
  },
  {
    "nombre": "Laredo",
    "country_code": "US"
  },
  {
    "nombre": "Vista",
    "country_code": "US"
  },
  {
    "nombre": "Edinburg",
    "country_code": "US"
  },
  {
    "nombre": "Roanoke",
    "country_code": "US"
  },
  {
    "nombre": "Arlington",
    "country_code": "US"
  },
  {
    "nombre": "Cambridge",
    "country_code": "US"
  },
  {
    "nombre": "Billings",
    "country_code": "US"
  },
  {
    "nombre": "Woodbridge",
    "country_code": "US"
  },
  {
    "nombre": "South Bend",
    "country_code": "US"
  },
  {
    "nombre": "Westminster",
    "country_code": "US"
  },
  {
    "nombre": "Lafayette",
    "country_code": "US"
  },
  {
    "nombre": "Hialeah",
    "country_code": "US"
  },
  {
    "nombre": "Alhambra",
    "country_code": "US"
  },
  {
    "nombre": "Norman",
    "country_code": "US"
  },
  {
    "nombre": "Provo",
    "country_code": "US"
  },
  {
    "nombre": "Berkeley",
    "country_code": "US"
  },
  {
    "nombre": "Berkeley",
    "country_code": "US"
  },
  {
    "nombre": "Arlington Heights",
    "country_code": "US"
  },
  {
    "nombre": "Charleston",
    "country_code": "US"
  },
  {
    "nombre": "West Covina",
    "country_code": "US"
  },
  {
    "nombre": "Hillsboro",
    "country_code": "US"
  },
  {
    "nombre": "Clearwater",
    "country_code": "US"
  },
  {
    "nombre": "Greeley",
    "country_code": "US"
  },
  {
    "nombre": "Broken Arrow",
    "country_code": "US"
  },
  {
    "nombre": "Lakewood",
    "country_code": "US"
  },
  {
    "nombre": "Gresham",
    "country_code": "US"
  },
  {
    "nombre": "Rialto",
    "country_code": "US"
  },
  {
    "nombre": "Daly City",
    "country_code": "US"
  },
  {
    "nombre": "Antioch",
    "country_code": "US"
  },
  {
    "nombre": "San Bernardino",
    "country_code": "US"
  },
  {
    "nombre": "Santa Maria",
    "country_code": "US"
  },
  {
    "nombre": "Boulder",
    "country_code": "US"
  },
  {
    "nombre": "Billings",
    "country_code": "US"
  },
  {
    "nombre": "West Valley City",
    "country_code": "US"
  },
  {
    "nombre": "Greeley",
    "country_code": "US"
  },
  {
    "nombre": "Rialto",
    "country_code": "US"
  },
  {
    "nombre": "Antioch",
    "country_code": "US"
  },
  {
    "nombre": "Inglewood",
    "country_code": "US"
  },
  {
    "nombre": "Elgin",
    "country_code": "US"
  },
  {
    "nombre": "West Jordan",
    "country_code": "US"
  },
  {
    "nombre": "Allentown",
    "country_code": "US"
  },
  {
    "nombre": "Manchester",
    "country_code": "US"
  },
  {
    "nombre": "Westminster",
    "country_code": "US"
  },
  {
    "nombre": "Charleston",
    "country_code": "US"
  },
  {
    "nombre": "Murfreesboro",
    "country_code": "US"
  },
  {
    "nombre": "Wilmington",
    "country_code": "US"
  },
  {
    "nombre": "Norwalk",
    "country_code": "US"
  },
  {
    "nombre": "Simi Valley",
    "country_code": "US"
  },
  {
    "nombre": "Pasadena",
    "country_code": "US"
  },
  {
    "nombre": "McAllen",
    "country_code": "US"
  },
  {
    "nombre": "Mesquite",
    "country_code": "US"
  },
  {
    "nombre": "McKinney",
    "country_code": "US"
  },
  {
    "nombre": "Columbia",
    "country_code": "US"
  },
  {
    "nombre": "Ann Arbor",
    "country_code": "US"
  },
  {
    "nombre": "Surprise",
    "country_code": "US"
  },
  {
    "nombre": "Alexandria",
    "country_code": "US"
  },
  {
    "nombre": "Clarksville",
    "country_code": "US"
  },
  {
    "nombre": "Victorville",
    "country_code": "US"
  },
  {
    "nombre": "West Palm Beach",
    "country_code": "US"
  },
  {
    "nombre": "Carrollton",
    "country_code": "US"
  },
  {
    "nombre": "Visalia",
    "country_code": "US"
  },
  {
    "nombre": "Fullerton",
    "country_code": "US"
  },
  {
    "nombre": "Midland",
    "country_code": "US"
  },
  {
    "nombre": "West Valley City",
    "country_code": "US"
  },
  {
    "nombre": "Lowell",
    "country_code": "US"
  },
  {
    "nombre": "Billings",
    "country_code": "US"
  },
  {
    "nombre": "North Charleston",
    "country_code": "US"
  },
  {
    "nombre": "Richardson",
    "country_code": "US"
  },
  {
    "nombre": "Beaumont",
    "country_code": "US"
  },
  {
    "nombre": "Gresham",
    "country_code": "US"
  },
  {
    "nombre": "Cambridge",
    "country_code": "US"
  },
  {
    "nombre": "San Mateo",
    "country_code": "US"
  },
  {
    "nombre": "West Jordan",
    "country_code": "US"
  },
  {
    "nombre": "El Monte",
    "country_code": "US"
  },
  {
    "nombre": "Lowell",
    "country_code": "US"
  },
  {
    "nombre": "Evansville",
    "country_code": "US"
  },
  {
    "nombre": "North Charleston",
    "country_code": "US"
  },
  {
    "nombre": "Cary",
    "country_code": "US"
  },
  {
    "nombre": "Green Bay",
    "country_code": "US"
  },
  {
    "nombre": "Clearwater",
    "country_code": "US"
  },
  {
    "nombre": "Lowell",
    "country_code": "US"
  },
  {
    "nombre": "West Covina",
    "country_code": "US"
  },
  {
    "nombre": "Elgin",
    "country_code": "US"
  },
  {
    "nombre": "South Bend",
    "country_code": "US"
  },
  {
    "nombre": "Boulder",
    "country_code": "US"
  },
  {
    "nombre": "Surprise",
    "country_code": "US"
  },
  {
    "nombre": "Wichita Falls",
    "country_code": "US"
  },
  {
    "nombre": "Lakeland",
    "country_code": "US"
  },
  {
    "nombre": "Downey",
    "country_code": "US"
  },
  {
    "nombre": "San Mateo",
    "country_code": "US"
  },
  {
    "nombre": "High Point",
    "country_code": "US"
  },
  {
    "nombre": "Hillsboro",
    "country_code": "US"
  },
  {
    "nombre": "Victorville",
    "country_code": "US"
  },
  {
    "nombre": "Beaumont",
    "country_code": "US"
  },
  {
    "nombre": "San Angelo",
    "country_code": "US"
  },
  {
    "nombre": "Warren",
    "country_code": "US"
  },
  {
    "nombre": "Inglewood",
    "country_code": "US"
  },
  {
    "nombre": "North Charleston",
    "country_code": "US"
  },
  {
    "nombre": "Abilene",
    "country_code": "US"
  },
  {
    "nombre": "Arvada",
    "country_code": "US"
  },
  {
    "nombre": "Lowell",
    "country_code": "US"
  },
  {
    "nombre": "Gainesville",
    "country_code": "US"
  },
  {
    "nombre": "High Point",
    "country_code": "US"
  },
  {
    "nombre": "Hartford",
    "country_code": "US"
  },
  {
    "nombre": "Clearwater",
    "country_code": "US"
  },
  {
    "nombre": "Beaumont",
    "country_code": "US"
  },
  {
    "nombre": "Clovis",
    "country_code": "US"
  },
  {
    "nombre": "Boulder",
    "country_code": "US"
  },
  {
    "nombre": "Lakewood",
    "country_code": "US"
  },
  {
    "nombre": "Gresham",
    "country_code": "US"
  },
  {
    "nombre": "Billings",
    "country_code": "US"
  },
  {
    "nombre": "West Covina",
    "country_code": "US"
  },
  {
    "nombre": "New Haven",
    "country_code": "US"
  },
  {
    "nombre": "Sandy",
    "country_code": "US"
  },
  {
    "nombre": "Fairfield",
    "country_code": "US"
  },
  {
    "nombre": "Athens",
    "country_code": "US"
  },
  {
    "nombre": "Berkeley",
    "country_code": "US"
  },
  {
    "nombre": "Allen",
    "country_code": "US"
  },
  {
    "nombre": "Sandy",
    "country_code": "US"
  },
  {
    "nombre": "Lafayette",
    "country_code": "US"
  },
  {
    "nombre": "Vallejo",
    "country_code": "US"
  },
  {
    "nombre": "Abilene",
    "country_code": "US"
  },
  {
    "nombre": "Gainesville",
    "country_code": "US"
  },
  {
    "nombre": "Hartford",
    "country_code": "US"
  },
  {
    "nombre": "Athens",
    "country_code": "US"
  },
  {
    "nombre": "Victorville",
    "country_code": "US"
  },
  {
    "nombre": "Davenport",
    "country_code": "US"
  },
  {
    "nombre": "Sandy Springs",
    "country_code": "US"
  },
  {
    "nombre": "San Marcos",
    "country_code": "US"
  },
  {
    "nombre": "Miramar",
    "country_code": "US"
  },
  {
    "nombre": "Hialeah",
    "country_code": "US"
  },
  {
    "nombre": "Hillsboro",
    "country_code": "US"
  },
  {
    "nombre": "Charleston",
    "country_code": "US"
  },
  {
    "nombre": "San Angelo",
    "country_code": "US"
  },
  {
    "nombre": "Sterling Heights",
    "country_code": "US"
  },
  {
    "nombre": "Lakewood",
    "country_code": "US"
  },
  {
    "nombre": "Boulder",
    "country_code": "US"
  },
  {
    "nombre": "Arvada",
    "country_code": "US"
  },
  {
    "nombre": "Daly City",
    "country_code": "US"
  },
  {
    "nombre": "Springfield",
    "country_code": "US"
  },
  {
    "nombre": "Charleston",
    "country_code": "US"
  },
  {
    "nombre": "Allen",
    "country_code": "US"
  },
  {
    "nombre": "Victorville",
    "country_code": "US"
  },
  {
    "nombre": "Abilene",
    "country_code": "US"
  },
  {
    "nombre": "Hartford",
    "country_code": "US"
  },
  {
    "nombre": "Plano",
    "country_code": "US"
  },
  {
    "nombre": "Henderson",
    "country_code": "US"
  },
  {
    "nombre": "Fort Wayne",
    "country_code": "US"
  },
  {
    "nombre": "Jersey City",
    "country_code": "US"
  },
  {
    "nombre": "Toronto",
    "country_code": "CA"
  },
  {
    "nombre": "Montreal",
    "country_code": "CA"
  },
  {
    "nombre": "Vancouver",
    "country_code": "CA"
  },
  {
    "nombre": "Calgary",
    "country_code": "CA"
  },
  {
    "nombre": "Edmonton",
    "country_code": "CA"
  },
  {
    "nombre": "Ottawa",
    "country_code": "CA"
  },
  {
    "nombre": "Québec",
    "country_code": "CA"
  },
  {
    "nombre": "Winnipeg",
    "country_code": "CA"
  },
  {
    "nombre": "Hamilton",
    "country_code": "CA"
  },
  {
    "nombre": "Kitchener",
    "country_code": "CA"
  },
  {
    "nombre": "London",
    "country_code": "CA"
  },
  {
    "nombre": "Victoria",
    "country_code": "CA"
  },
  {
    "nombre": "Halifax",
    "country_code": "CA"
  },
  {
    "nombre": "Oshawa",
    "country_code": "CA"
  },
  {
    "nombre": "Windsor",
    "country_code": "CA"
  },
  {
    "nombre": "Saskatoon",
    "country_code": "CA"
  },
  {
    "nombre": "Regina",
    "country_code": "CA"
  },
  {
    "nombre": "St. John's",
    "country_code": "CA"
  },
  {
    "nombre": "Barrie",
  "country_code": "CA"
  },
  {
    "nombre": "Kelowna",
    "country_code": "CA"
  },
  {
    "nombre": "Guelph",
    "country_code": "CA"
  },
  {
    "nombre": "Kingston",
    "country_code": "CA"
  },
  {
    "nombre": "Moncton",
    "country_code": "CA"
  },
  {
    "nombre": "Saint John",
    "country_code": "CA"
  },
  {
    "nombre": "Thunder Bay",
    "country_code": "CA"
  },
  {
    "nombre": "Peterborough",
    "country_code": "CA"
  },
  {
    "nombre": "Lethbridge",
    "country_code": "CA"
  },
  {
    "nombre": "Nanaimo",
    "country_code": "CA"
  },
  {
    "nombre": "Kamloops",
    "country_code": "CA"
  },
  {
    "nombre": "Belleville",
    "country_code": "CA"
  },
  {
    "nombre": "Chatham-Kent",
    "country_code": "CA"
  },
  {
    "nombre": "Fredericton",
    "country_code": "CA"
  },
  {
    "nombre": "Prince George",
    "country_code": "CA"
  },
  {
    "nombre": "Red Deer",
    "country_code": "CA"
  },
  {
    "nombre": "Medicine Hat",
    "country_code": "CA"
  },
  {
    "nombre": "Drummondville",
    "country_code": "CA"
  },
  {
    "nombre": "Newmarket",
    "country_code": "CA"
  },
  {
    "nombre": "Sherbrooke",
    "country_code": "CA"
  },
  {
    "nombre": "Saint-Hyacinthe",
    "country_code": "CA"
  },
  {
    "nombre": "Milton",
    "country_code": "CA"
  },
  {
    "nombre": "Sorel-Tracy",
    "country_code": "CA"
  },
  {
    "nombre": "Brossard",
    "country_code": "CA"
  },
  {
    "nombre": "Saint-Jean-sur-Richelieu",
    "country_code": "CA"
  },
  {
    "nombre": "Cape Breton",
    "country_code": "CA"
  },
  {
    "nombre": "Laval",
    "country_code": "CA"
  },
  {
    "nombre": "Abbotsford",
    "country_code": "CA"
  },
  {
    "nombre": "Trois-Rivières",
    "country_code": "CA"
  },
  {
    "nombre": "Gatineau",
    "country_code": "CA"
  },
  {
    "nombre": "Kingston",
    "country_code": "CA"
  },
  {
    "nombre": "Vaughan",
    "country_code": "CA"
  },
  {
    "nombre": "Halton Hills",
    "country_code": "CA"
  },
  {
    "nombre": "Clarington",
    "country_code": "CA"
  },
  {
    "nombre": "Pickering",
    "country_code": "CA"
  },
  {
    "nombre": "Brantford",
    "country_code": "CA"
  },
  {
    "nombre": "Niagara Falls",
    "country_code": "CA"
  },
  {
    "nombre": "Repentigny",
    "country_code": "CA"
  },
  {
    "nombre": "Burlington",
    "country_code": "CA"
  },
  {
    "nombre": "Vaudreuil-Dorion",
    "country_code": "CA"
  },
  {
    "nombre": "Terrebonne",
    "country_code": "CA"
  },
  {
    "nombre": "Ciudad de México",
    "country_code": "MX"
  },
  {
    "nombre": "Guadalajara",
    "country_code": "MX"
  },
  {
    "nombre": "Monterrey",
    "country_code": "MX"
  },
  {
    "nombre": "Puebla",
    "country_code": "MX"
  },
  {
    "nombre": "Tijuana",
    "country_code": "MX"
  },
  {
    "nombre": "Querétaro",
    "country_code": "MX"
  },
  {
    "nombre": "Toluca",
    "country_code": "MX"
  },
  {
    "nombre": "León",
    "country_code": "MX"
  },
  {
    "nombre": "Cancún",
    "country_code": "MX"
  },
  {
    "nombre": "Oaxaca de Juárez",
    "country_code": "MX"
  },
  {
    "nombre": "Mérida",
    "country_code": "MX"
  },
  {
    "nombre": "Acapulco",
    "country_code": "MX"
  },
  {
    "nombre": "Veracruz",
    "country_code": "MX"
  },
  {
    "nombre": "Culiacán",
    "country_code": "MX"
  },
  {
    "nombre": "Chihuahua",
    "country_code": "MX"
  },
  {
    "nombre": "Mazatlán",
    "country_code": "MX"
  },
  {
    "nombre": "San Luis Potosí",
    "country_code": "MX"
  },
  {
    "nombre": "Aguascalientes",
    "country_code": "MX"
  },
  {
    "nombre": "Tampico",
    "country_code": "MX"
  },
  {
    "nombre": "Cuernavaca",
    "country_code": "MX"
  },
  {
    "nombre": "Torreón",
    "country_code": "MX"
  },
  {
    "nombre": "Hermosillo",
    "country_code": "MX"
  },
  {
    "nombre": "Saltillo",
    "country_code": "MX"
  },
  {
    "nombre": "Mexicali",
    "country_code": "MX"
  },
  {
    "nombre": "Durango",
    "country_code": "MX"
  },
  {
    "nombre": "Culiacán",
    "country_code": "MX"
  },
  {
    "nombre": "Pachuca",
    "country_code": "MX"
  },
  {
    "nombre": "Morelia",
    "country_code": "MX"
  },
  {
    "nombre": "Campeche",
    "country_code": "MX"
  },
  {
    "nombre": "Colima",
    "country_code": "MX"
  },
  {
    "nombre": "La Paz",
    "country_code": "MX"
  },
  {
    "nombre": "Tabasco",
    "country_code": "MX"
  },
  {
    "nombre": "Zacatecas",
    "country_code": "MX"
  },
  {
    "nombre": "Tuxtla Gutiérrez",
    "country_code": "MX"
  },
  {
    "nombre": "Villahermosa",
    "country_code": "MX"
  },
  {
    "nombre": "Tepic",
    "country_code": "MX"
  },
  {
    "nombre": "Ciudad Obregón",
    "country_code": "MX"
  },
  {
    "nombre": "Tlaxcala",
    "country_code": "MX"
  },
  {
    "nombre": "Chetumal",
    "country_code": "MX"
  },
  {
    "nombre": "Uruapan",
    "country_code": "MX"
  },
  {
    "nombre": "Zamora",
    "country_code": "MX"
  },
  {
    "nombre": "San Cristóbal de las Casas",
    "country_code": "MX"
  },
  {
    "nombre": "Cozumel",
    "country_code": "MX"
  },
  {
    "nombre": "Ciudad del Carmen",
    "country_code": "MX"
  },
  {
    "nombre": "Tecate",
    "country_code": "MX"
  },
  {
    "nombre": "Ciudad Guzmán",
    "country_code": "MX"
  },
  {
    "nombre": "Los Cabos",
    "country_code": "MX"
  },
  {
    "nombre": "Irapuato",
    "country_code": "MX"
  },
  {
    "nombre": "Huatulco",
    "country_code": "MX"
  },
  {
    "nombre": "Ciudad Valles",
    "country_code": "MX"
  },
  {
    "nombre": "Matamoros",
    "country_code": "MX"
  },
  {
    "nombre": "São Paulo",
    "pais": "Brasil",
    "country_code": "BR"
  },
  {
    "nombre": "Río de Janeiro",
    "pais": "Brasil",
    "country_code": "BR"
  },
  {
    "nombre": "Buenos Aires",
    "pais": "Argentina",
    "country_code": "AR"
  },
  {
    "nombre": "Londres",
    "pais": "Reino Unido",
    "country_code": "GB"
  },
  {
    "nombre": "París",
    "pais": "Francia",
    "country_code": "FR"
  },
  {
    "nombre": "Berlín",
    "pais": "Alemania",
    "country_code": "DE"
  },
  {
    "nombre": "Madrid",
    "pais": "España",
    "country_code": "ES"
  },
  {
    "nombre": "Roma",
    "pais": "Italia",
    "country_code": "IT"
  },
  {
    "nombre": "Ámsterdam",
    "pais": "Países Bajos",
    "country_code": "NL"
  },
  {
    "nombre": "Praga",
    "pais": "República Checa",
    "country_code": "CZ"
  },
  {
    "nombre": "Atenas",
    "pais": "Grecia",
    "country_code": "GR"
  },
  {
    "nombre": "Estocolmo",
    "pais": "Suecia",
    "country_code": "SE"
  },
  {
    "nombre": "Moscú",
    "pais": "Rusia",
    "country_code": "RU"
  },
  {
    "nombre": "Tokio",
    "pais": "Japón",
    "country_code": "JP"
  },
  {
    "nombre": "Seúl",
    "pais": "Corea del Sur",
    "country_code": "KR"
  },
  {
    "nombre": "Pekín",
    "pais": "China",
    "country_code": "CN"
  },
  {
    "nombre": "Sídney",
    "pais": "Australia",
    "country_code": "AU"
  },
  {
    "nombre": "Ciudad del Cabo",
    "pais": "Sudáfrica",
    "country_code": "ZA"
  },
  {
    "nombre": "Ciudad de Kuwait",
    "pais": "Kuwait",
    "country_code": "KW"
  },
  {
    "nombre": "Mumbai",
    "pais": "India",
    "country_code": "IN"
  },
  {
    "nombre": "Estambul",
    "pais": "Turquía",
    "country_code": "TR"
  },
  {
    "nombre": "Bangkok",
    "pais": "Tailandia",
    "country_code": "TH"
  },
  {
    "nombre": "Lima",
    "pais": "Perú",
    "country_code": "PE"
  },
  {
    "nombre": "Bogotá",
    "country_code": "CO"
  },
  {
    "nombre": "Medellín",
    "country_code": "CO"
  },
  {
    "nombre": "Cali",
    "country_code": "CO"
  },
  {
    "nombre": "Barranquilla",
    "country_code": "CO"
  },
  {
    "nombre": "Cartagena",
    "country_code": "CO"
  },
  {
    "nombre": "Cúcuta",
    "country_code": "CO"
  },
  {
    "nombre": "Bucaramanga",
    "country_code": "CO"
  },
  {
    "nombre": "Pereira",
    "country_code": "CO"
  },
  {
    "nombre": "Santa Marta",
    "country_code": "CO"
  },
  {
    "nombre": "Ibagué",
    "country_code": "CO"
  },
  {
    "nombre": "Manizales",
    "country_code": "CO"
  },
  {
    "nombre": "Neiva",
    "country_code": "CO"
  },
  {
    "nombre": "Pasto",
    "country_code": "CO"
  },
  {
    "nombre": "Villavicencio",
    "country_code": "CO"
  },
  {
    "nombre": "Valledupar",
    "country_code": "CO"
  },
  {
    "nombre": "Montería",
    "country_code": "CO"
  },
  {
    "nombre": "Popayán",
    "country_code": "CO"
  },
  {
    "nombre": "Buenaventura",
    "country_code": "CO"
  },
  {
    "nombre": "Sincelejo",
    "country_code": "CO"
  },
  {
    "nombre": "Armenia",
    "country_code": "CO"
  },
  {
    "nombre": "Tunja",
    "country_code": "CO"
  },
  {
    "nombre": "Riohacha",
    "country_code": "CO"
  },
  {
    "nombre": "Quibdó",
    "country_code": "CO"
  },
  {
    "nombre": "Arauca",
    "country_code": "CO"
  },
  {
    "nombre": "Yopal",
    "country_code": "CO"
  },
  {
    "nombre": "Florencia",
    "country_code": "CO"
  },
  {
    "nombre": "Mocoa",
    "country_code": "CO"
  },
  {
    "nombre": "San Andrés",
    "country_code": "CO"
  },
  {
    "nombre": "Puerto Inírida",
    "country_code": "CO"
  },
  {
    "nombre": "Mitú",
    "country_code": "CO"
  },
  {
    "nombre": "San José del Guaviare",
    "country_code": "CO"
  },
  {
    "nombre": "Leticia",
    "country_code": "CO"
  },
  {
    "nombre": "Quipama",
    "country_code": "CO"
  },
  {
    "nombre": "Quito",
    "pais": "Ecuador",
    "country_code": "EC"
  },
  {
    "nombre": "La Paz",
    "pais": "Bolivia",
    "country_code": "BO"
  },
  {
    "nombre": "Asunción",
    "pais": "Paraguay",
    "country_code": "PY"
  },
  {
    "nombre": "Montevideo",
    "pais": "Uruguay",
    "country_code": "UY"
  },
  {
    "nombre": "Santiago",
    "pais": "Chile",
    "country_code": "CL"
  },
  {
    "nombre": "Oslo",
    "pais": "Noruega",
    "country_code": "NO"
  },
  {
    "nombre": "Helsinki",
    "pais": "Finlandia",
    "country_code": "FI"
  },
  {
    "nombre": "Copenhague",
    "pais": "Dinamarca",
    "country_code": "DK"
  },
  {
    "nombre": "Varsovia",
    "pais": "Polonia",
    "country_code": "PL"
  },
  {
    "nombre": "Budapest",
    "pais": "Hungría",
    "country_code": "HU"
  },
  {
    "nombre": "Viena",
    "pais": "Austria",
    "country_code": "AT"
  },
  {
    "nombre": "Zúrich",
    "pais": "Suiza",
    "country_code": "CH"
  },
  {
    "nombre": "Ámsterdam",
    "pais": "Países Bajos",
    "country_code": "NL"
  },
  {
    "nombre": "Bruselas",
    "pais": "Bélgica",
    "country_code": "BE"
  },
  {
    "nombre": "Liubliana",
    "pais": "Eslovenia",
    "country_code": "SI"
  },
  {
    "nombre": "Bucarest",
    "pais": "Rumania",
    "country_code": "RO"
  }
];