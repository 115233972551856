import React, { useState, useEffect } from 'react';
import '../../../../../styles/contentCalendar/components/imageList/style.scss';
import PropTypes from 'prop-types';
import { SyncLoader } from 'react-spinners';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { Fade } from 'react-reveal';
import { setShowAlert } from '../../../../../../reducers/showAlert';
import ImageContainer from '../imageContainer/imageContainer';
import { setImageSelectedForPost } from '../../../../../../reducers/imageSelectedForPost';
import { setImageData } from '../../../../../../reducers/newPost';
import DeteleFile from '../deteleFile/deteleFile';
import { useMediaQuery } from '@react-hook/media-query';
import Text from '../../../../../share-components/text/text';

function ImageList({ isCollapse, SendSocket, fromView }) {
  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');
  const companyFiles = useSelector((state) => state.companyFiles.value);
  const [tempFileList, setTempFileList] = useState([...companyFiles.fileList]);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const handlerSetShowAlert = (infoAlert) => {
    dispatch(setShowAlert(infoAlert));
  };
  
  const handlerSetImageData = (infoPost) => {
    dispatch(setImageData(infoPost));
  };
  
  const handlerSetImageSelectedForPost = (infoSelectedFile) => {
    dispatch(setImageSelectedForPost(infoSelectedFile));
  };

  const openAlert = (typeAlert, textAlert) => {
    const object = {
      typeAlert: typeAlert,
      textAlert: textAlert
    };
  
    handlerSetShowAlert(object);
  };

  useEffect(() => {
    setTempFileList([...companyFiles.fileList]);
  }, [companyFiles]);

  const [selectedFileForPost, setSelectedFileForPost] = useState({
    uuid: '',
    uuidThumbnail: '',
    url: '',
    urlThumbnail: '',
    type: ''
  });

  const handlerSelectFile = (uuid) => {
    const findSelectFile = tempFileList.find((x) => x.uuid === uuid);
  
    if (findSelectFile) {
      if (findSelectFile.uuid === selectedFileForPost.uuid) {
        setSelectedFileForPost({
          uuid: '',
          uuidThumbnail: '',
          url: '',
          urlThumbnail: '',
        });
      } else {
        let selectedFile = {
          uuid: findSelectFile.uuid,
          uuidThumbnail: findSelectFile.uuidThumbnail,
          url: findSelectFile.url,
          urlThumbnail: findSelectFile.urlThumbnail,
          type: findSelectFile.type
        };

        handlerSetImageData(selectedFile);
        setSelectedFileForPost(selectedFile);
        handlerSetImageSelectedForPost(selectedFile);

        if (fromView === "modal-post") {
          openAlert("success", "File selected");
        } 
      }
    } else {
      openAlert("danger", "File not found");
    }
  };

  return (
    <div className={`image-list-calendar h-100 ${isCollapse ? 'show' : ''} ${isMobileDevice && 'pl-0 pr-0'} `}>
      {companyFiles.id !== "" ? (
        <Fade duration={300} opposite>
          {companyFiles.fileList.length !== 0 ? (
            <div className="grid-image-list">
              {tempFileList.map((file) => (
                <div key={file.uuid} className='d-flex'>
                  <button
                    id={file.uuid}
                    className={`card-image flex-1`}
                    onClick={() => handlerSelectFile(file.uuid)}
                  >
                    <ImageContainer
                      id={file.uuid}
                      url={file.urlThumbnail}
                      selectedFile={selectedFileForPost.uuid === file.uuid}                    
                    />
                  </button>
                  <DeteleFile
                    file={`${file.uuid}.${file.format}`}
                    thumbnail={`${file.uuidThumbnail}.${file.formatThumbnail}`}
                    SendSocket={SendSocket}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className='d-flex flex-1 justify-content-center align-items-center h-100'>
              <Text type='body' inyectClass={"text-muted"}>
                You don't have files, upload any file
              </Text>
            </div>
          )}
        </Fade>
      ) : (
        <Fade duration={600} opposite>
          <div className="loading-container">
            <div className="d-flex flex-column justify-content-center align-items-center pt-5 pb-5 w-100">
              <div style={{ padding: '50px 0px 20px 0px' }}>
                <SyncLoader color="#6001d0" />
              </div>
              <span>
                Loading files
              </span>
            </div>
          </div>
        </Fade>
      )}
    </div>
  )
}

ImageList.propTypes = {
  isCollapse: PropTypes.bool.isRequired,
  SendSocket: PropTypes.func.isRequired,
  fromView: PropTypes.string.isRequired,
};

export default ImageList;
