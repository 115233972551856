/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const showCompanyCollaborator = createSlice({
  name: 'showCompanyCollaborator',
  initialState: {
    value: {
      show: false
    },
  },
  reducers: {
    setShowModalCollaboratorCompanyList: (state, action) => {
      state.value.show = true;
    },
    setHideModal: (state, action) => {
      state.value.show = false;
    },
  }
});

export const {
  setShowModalCollaboratorCompanyList, setHideModal
} = showCompanyCollaborator.actions;

export default showCompanyCollaborator.reducer;
