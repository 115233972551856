import React, { useState, useEffect } from 'react';
import '../../../styles/style.scss';
import '../../../styles/compranyProfile/style.scss';
import PropTypes from 'prop-types';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { useMediaQuery } from '@react-hook/media-query';
import moment from 'moment';
import { Fade } from 'react-reveal';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { setShowAlert } from '../../../../reducers/showAlert';
import Text from '../../../share-components/text/text';
import ViewsCreatePost from './components/viewsCreatePost/viewsCreatePost';
import DetailPost from './components/detailPost/detailPost';
import { setPostFromCalendar } from '../../../../reducers/newPost';
import GalleryFiles from './components/galleryFiles/galleryFiles';

const localizer = momentLocalizer(moment);

function ContentCalendarCollaborator({ SendSocket }) {
  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');

  const fechaUTC = (datePost) => {
    return new Date(moment.utc(datePost).add(1, 'days'));
  };

  // const userData = useSelector((state) => state.userData.value);
  const userAgent = useSelector((state) => state.userAgent.value);
  const postsCompany = useSelector((state) => state.postsCompanyAsCollaborator.value);
  const [postList, setPostList] = useState(postsCompany.postList.map((item) => {
    return {
      id: item.id,
      title: item.contentIdea,
      start: fechaUTC(item.publishDate),
      end: fechaUTC(item.publishDate),
      socialNetwork: item.socialNetwork,
      groupPost: item.groupPost,
      typePost: item.typePost
    }
  }));

  useEffect(() => {
    setPostList(postsCompany.postList.map((item) => {
      return {
        id: item.id,
        title: item.contentIdea,
        start: fechaUTC(item.publishDate),
        end: fechaUTC(item.publishDate),
        socialNetwork: item.socialNetwork,
        groupPost: item.groupPost,
        typePost: item.typePost
      }
    }));
  }, [postsCompany]);

  const dispatch = useDispatch();

  const handlerSetShowAlert = (infoAlert) => {
    dispatch(setShowAlert(infoAlert));
  };

  const handlerSetPostFromCalendar = (infoPost) => {
    dispatch(setPostFromCalendar(infoPost));
  };

  const openAlert = (typeAlert, textAlert) => {
    const object = {
      typeAlert: typeAlert,
      textAlert: textAlert
    };
  
    handlerSetShowAlert(object);
  };

  //#region CALENDAR
    const [openModalDetailPost, setOpenModalDetailPost] = useState(false);

    const handlerOpenModalDetailPost = () => {
      setOpenModalDetailPost(true);
    };

    const handlerCloseModalDetailPost = () => {
      setOpenModalDetailPost(false);
    };

    const handleSelectEvent = (e) => {
      const findPost = postsCompany.postList.find((x) => x.id === e.id);
      
      if (findPost !== undefined) {
        handlerSetPostFromCalendar(findPost);

        handlerOpenModalDetailPost();
      } else {
        openAlert('danger', 'Post not found, try again')
      }
    };
 //#endregion

  const [openModalForCreatePost, setOpenModalForCreatePost] = useState(false);

  const handlerCloseModalForCreatePost = () => {
    setOpenModalForCreatePost(false);
  };

  const handlerOpenModalForCreatePost = () => {
    setOpenModalForCreatePost(true);
  };

  const handlerRedirectCorrectMethodForCreatePostInEachDevice = () => {
    handlerOpenModalForCreatePost();
  };

  const handlerGetDataEvent = (e) => {
    if (e.typePost === "Weekly Post") {
      if (e.groupPost !== undefined) {
        const classGroup = e.groupPost === "Content post" ? 'text' : 'video';

        return {
          className:  `event-day-for-${classGroup}`,
        };
      }
    }

    if (e.typePost === "Specific Post") {
      if (e.socialNetwork !== undefined) {
        return {
          className:  `event-day-for-${e.socialNetwork.toLowerCase()}`,
        };
      }
    }
  
    return {};
  }

  return (
    <div>
      <div className="content-title">
        <Text type="title">
          Content calendar
        </Text>

        {isMobileDevice && <img src="./assets/logos/symbol/Simbolo/PNG/morado.png" alt="" />}

        <div
          className="container-buttons-cc"
        >
          <GalleryFiles SendSocket={SendSocket} />
          <button
            className="btn-create-post-calendar-action"
            onClick={() => handlerRedirectCorrectMethodForCreatePostInEachDevice()}
          >
            <AddCircleRoundedIcon />
            Create post
          </button>
        </div>
      </div>
      <div className="content-view">
        <Fade duration={600} opposite>
          <div className="calendar-content">
            <div className="container-calendar">
              <Calendar
                localizer={localizer}
                events={postList}
                onSelectEvent={handleSelectEvent}
                eventPropGetter={handlerGetDataEvent}
                startAccessor="start" 
                endAccessor="end"
                views={['month']}
                style={{ flex: '1' }}
                popup
              />

              <div className={`float-button-mobile bg-glass-secondary unselectable ${userAgent.device === 'iOS' ? 'ios-float-button' : ''}`}>
                <div className="container-float-btn">
                  <GalleryFiles SendSocket={SendSocket} />
                  <button
                    onClick={() => handlerRedirectCorrectMethodForCreatePostInEachDevice()}
                  >
                    <AddCircleRoundedIcon />
                    Create post
                  </button>
                </div>
              </div>
            </div>

          </div>
        </Fade>
      </div>

      {/* Modal for create post | web */}
      <Modal
        open={openModalForCreatePost}
        showCloseIcon={false}
        blockScroll={true}
        onClose={() => handlerCloseModalForCreatePost()}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        classNames={{
          overlay: 'customOverlay',
          modal: isMobileDevice ? 'fullscreen-modal' : 'customModal',
        }}
        center
      >
        <div className="modal-body modal-md">
          <ViewsCreatePost closeModal={handlerCloseModalForCreatePost} SendSocket={SendSocket} />
        </div>
      </Modal>

      {/* Modal detail post | click on calendar post */}
      <Modal
        open={openModalDetailPost}
        showCloseIcon={false}
        onClose={() => handlerCloseModalDetailPost()}
        closeOnEsc={false}
        blockScroll={false}
        closeOnOverlayClick={false}
        classNames={{
          overlay: 'customOverlay',
          modal: isMobileDevice ? 'fullscreen-modal' : 'customModal',
        }}
        center
      >
        <div className="modal-body modal-md">
          <DetailPost SendSocket={SendSocket} closeModal={handlerCloseModalDetailPost} />
        </div>
      </Modal>
    </div>
  )
}

ContentCalendarCollaborator.propTypes = {
  SendSocket: PropTypes.func.isRequired,
};

export default ContentCalendarCollaborator;
