/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const activeCollaboratorMode = createSlice({
  name: 'activeCollaboratorMode',
  initialState: {
    value: {
      collaboratorMode: false
    },
  },
  reducers: {
    setEnableMode: (state, action) => {
      state.value.collaboratorMode = true;
    },
    setDisableMode: (state, action) => {
      state.value.collaboratorMode = false;
    },
  }
});

export const {
  setEnableMode, setDisableMode
} = activeCollaboratorMode.actions;

export default activeCollaboratorMode.reducer;
