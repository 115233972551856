import { postRequest } from "../../services/requestLib";
import { setCompanyInfoAsCollaborator } from "../../../reducers/companyInfoAsCollaborator";
import { setGeneratedContentAsCollaborator } from "../../../reducers/generatedContentAsCollaborator";
import { setPostsCompany } from "../../../reducers/postsCompanyAsCollaborator";
import { setCompanyFiles } from "../../../reducers/companyFilesAsCollaborator";

const RequestCompanyCollaborator = async (dispatch, userData, company) => {
  const userInfo = {
    email: userData.email,
    password: userData.password,
    token: userData.token,
    typeAccess: 2,
    data: {
      tokenCompany: company.keyAccess,
      uc: company.userCompanyOwner,
      ic: company.idCompany,
      e: company.emailCompanyOwner,
    }
  };

  try {    
    const requestCompanyInfo = await postRequest('company-info', userInfo);
    if (requestCompanyInfo.status === 200) {
      dispatch(setCompanyInfoAsCollaborator(requestCompanyInfo.data.data));
      dispatch(setGeneratedContentAsCollaborator(requestCompanyInfo.data.data.generatedContent));
    }
  
    const requestPostsCompany = await postRequest('company-posts', userInfo);
    if (requestPostsCompany.status === 200) {
      dispatch(setPostsCompany(requestPostsCompany.data.data));
    }
  
    const requestCompanyFiles = await postRequest('company-file', userInfo);
    if (requestCompanyFiles.status === 200) {
      dispatch(setCompanyFiles(requestCompanyFiles.data.data));
    }
  
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default RequestCompanyCollaborator;
