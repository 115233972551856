export const formatObjectForMonthlyPrompt = '{"contentIdea": "","postText": ""}';

export const formatObjectForSpecificPrompt = '{"contentIdea": "","postText": "","socialNetWork": "","keywords": []}';

export const handlerGetLimitSelectPost = (idPlan) => {
  if (idPlan === "1dff1167-8e08-4020-a91f-2410805aad63") {
    return 1;
  }
  if (idPlan === "74a8b99a-e92c-4d60-8966-53d288966d52") {
    return 12;
  }
  if (idPlan === "d370579d-dbec-4fd6-9c26-d219fe00da1d") {
    return 10;
  }
}