import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from "react-reveal";
import Lottie from "lottie-react";
import Stars from './stars.json';
import Text from '../../text/text';
import { useMediaQuery } from '@react-hook/media-query';

function GeneratingContentCard({ text }) {
  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');

  const styleLottieAnim = {
    height: isMobileDevice ? 120 : 150,
  };

  return (
    <Fade duration={600} opposite>
      <div className="d-flex flex-column justify-content-center align-items-center w-100">
        <Lottie animationData={Stars} loop={true} autoPlay={true} style={styleLottieAnim} />
        <Text type="text">
          {`Generating a new ${text}`}
        </Text>
      </div>
    </Fade>
  )
}

GeneratingContentCard.propTypes = {
  text: PropTypes.string.isRequired
};

export default GeneratingContentCard;
