import React from 'react';
import Lottie from "lottie-react";
import Cloud from "../loading/cloud.json";
import '../loading/style.scss';
import Text from '../text/text';
import { useMediaQuery } from '@react-hook/media-query';

function Loading() {
  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');

  const styleLottieAnim = {
    height: isMobileDevice ? 160 : 200,
  };

  return (
    <div className="main-loader">
      <div className="center-div">
        <Lottie animationData={Cloud} loop={true} autoPlay={true} style={styleLottieAnim} />
        <Text type="subtitle">
          Loading information
        </Text>
      </div>
    </div>
  )
}

export default Loading;

