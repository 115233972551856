import { configureStore } from '@reduxjs/toolkit';
import AnswersForm from '../reducers/answersForm';
import GeneratedContent from '../reducers/generatedContent';
import userData from '../reducers/userData';
import statusUser from '../reducers/statusUser';
import companyInfo from '../reducers/companyInfo';
import companyList from '../reducers/companyList';
import viewCreateCompanyProfile from '../reducers/viewCreateCompanyProfile';
import activeCompanyProfile from '../reducers/activeCompanyProfile';
import alertUserToGenerateContentSuccess from '../reducers/alertUserToGenerateContentSuccess';
import showAlert from '../reducers/showAlert';
import userAgent from '../reducers/userAgent';
import showModalForGoToFormAI from '../reducers/showModalForGoToFormAI';
import showCompanyList from '../reducers/showCompanyList';
import companyFiles from '../reducers/companyFiles';
import newPost from '../reducers/newPost';
import imageSelectedForPost from '../reducers/imageSelectedForPost';
import postsCompany from '../reducers/postsCompany';
import settingsCompany from '../reducers/settingsCompany';
import guestUsers from '../reducers/guestUsers';
import companyCollaborator from '../reducers/companyCollaborator';
import showCompanyCollaborator from '../reducers/modalCompanyCollaborator';
import activeCollaboratorMode from '../reducers/activeCollaboratorMode';
import activeCompanyProfileAsCollaborator from '../reducers/activeCompanyProfileAsCollaborator';
import externalURL from '../reducers/externalURL';

import collaboratorCompanySelected from '../reducers/collaboratorCompanySelected';
import companyInfoAsCollaborator from '../reducers/companyInfoAsCollaborator';
import generatedContentAsCollaborator from '../reducers/generatedContentAsCollaborator';
import companyFilesAsCollaborator from '../reducers/companyFilesAsCollaborator';
import postsCompanyAsCollaborator from '../reducers/postsCompanyAsCollaborator';
import loadingCollaboratorMode from '../reducers/loadingCollaboratorMode';
import loadingCompanyInformation from '../reducers/loadingCompanyInformation';

export default configureStore({
  reducer: {
    answersForm: AnswersForm,
    generatedContent: GeneratedContent,
    userData: userData,
    statusUser: statusUser,
    companyInfo: companyInfo,
    companyList: companyList,
    viewCreateCompanyProfile: viewCreateCompanyProfile,
    activeCompanyProfile: activeCompanyProfile,
    alertUserToGenerateContentSuccess: alertUserToGenerateContentSuccess,
    showAlert: showAlert,
    userAgent: userAgent,
    showModalForGoToFormAI: showModalForGoToFormAI,
    showCompanyList: showCompanyList,
    companyFiles: companyFiles,
    newPost: newPost,
    imageSelectedForPost: imageSelectedForPost,
    postsCompany: postsCompany,
    settingsCompany: settingsCompany,
    guestUsers: guestUsers,
    companyCollaborator: companyCollaborator,
    showCompanyCollaborator: showCompanyCollaborator,
    activeCollaboratorMode: activeCollaboratorMode,
    activeCompanyProfileAsCollaborator: activeCompanyProfileAsCollaborator,
    companyInfoAsCollaborator: companyInfoAsCollaborator,
    generatedContentAsCollaborator: generatedContentAsCollaborator,
    companyFilesAsCollaborator: companyFilesAsCollaborator,
    postsCompanyAsCollaborator: postsCompanyAsCollaborator,
    loadingCollaboratorMode: loadingCollaboratorMode,
    collaboratorCompanySelected: collaboratorCompanySelected,
    loadingCompanyInformation: loadingCompanyInformation,
    externalURL: externalURL
  },
});
