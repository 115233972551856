import moment from 'moment';
import userPool from '../../security/userPool';
import { postRequest } from '../requestLib';
import { formatMoment } from '../values';

const signUp = async (credentials) => {
  return new Promise((resolve, reject) => {
    const newUser = {
      email: credentials.email,
      password: credentials.password,
      allowTerms: credentials.allowTerms,
      servicePlan: credentials.optionOffer
    };

    userPool.signUp(newUser.email, newUser.password, [], null, async (err, data) => {
      if (err) {
        console.log(err);
        resolve({ success: false, data: err, msm: err.message});
      } else {
        try {
          const dataNewUser = {
            email: newUser.email,
            password: newUser.password,
            allowTerms: newUser.allowTerms,
            servicePlan: newUser.servicePlan.uuid,
            dataEndService: moment.utc().format(formatMoment),
            dataInitService: moment.utc().add(1, 'months').format(formatMoment),
          };

          const requestNewUser = await postRequest('create-user', dataNewUser);
          if (requestNewUser.status === 200 && requestNewUser.data.status === true) {
            resolve({ success: true, data: "", msm: "Created user successfully"});
          } else {
            resolve({ success: false, data: "", msm: "Create user failed"});
          }
        } catch (error) {
          console.log(error);
          resolve({ success: false, data: err, msm: err.message});
        }
      }
    });
  });
};

export default signUp;