import axios from 'axios';
// if const
// const urlAmbient = 1; - test url
// const urlAmbient = 2; - pre prod url
// const urlAmbient = 3; - prod url
const urlAmbient = 2;

const returnUrlBackend = (value) => {
  switch (value) {
    case 1:
      return "https://p058ivkzd2.execute-api.us-east-1.amazonaws.com/meantto-test";
    case 2:
      return "https://aijwh8epuc.execute-api.us-east-1.amazonaws.com/pre-prod-meantto";
    case 3:
      return "not fount";
    default:
      return "https://p058ivkzd2.execute-api.us-east-1.amazonaws.com/meantto-test";
  }
}

export const urlBackend =  returnUrlBackend(urlAmbient);

export const postRequest = async (url, data) => {
  const response = await axios.post(`${urlBackend}/${url}`, data);
  return response;
};

export const postRequestMeantto = async (url, dataUser, data) => {
  const dataRequest = {
    email: dataUser.email,
    password: dataUser.password,
    token: dataUser.token,
    data: data
  };

  const response = await axios.post(`${urlBackend}/${url}`, dataRequest);
  return response;
};

export const putRequestMeantto = async (url, dataUser, data) => {
  const dataRequest = {
    email: dataUser.email,
    password: dataUser.password,
    token: dataUser.token,
    data: data
  };

  const response = await axios.put(`${urlBackend}/${url}`, dataRequest);
  return response;
};

export const patchRequestMeantto = async (url, dataUser, data) => {
  const dataRequest = {
    email: dataUser.email,
    password: dataUser.password,
    token: dataUser.token,
    data: data
  };

  const response = await axios.patch(`${urlBackend}/${url}`, dataRequest);
  return response;
};

export const patchRequestCollaborators = async (url, dataUser, data) => {
  const dataRequest = {
    email: dataUser.email,
    user: dataUser.user,
    token: dataUser.token,
    data: data
  };

  const response = await axios.patch(`${urlBackend}/${url}`, dataRequest);
  return response;
};

export const patchRequestCollaborator = async (url, dataUser, data) => {
  const dataRequest = {
    email: dataUser.email,
    password: dataUser.password,
    token: dataUser.token,
    typeAccess: dataUser.typeAccess,
    data: data
  };

  const response = await axios.patch(`${urlBackend}/${url}`, dataRequest);
  return response;
};