import React, { useState, useEffect } from 'react';
import '../../../../../styles/contentCalendar/components/imageList/style.scss';
import PropTypes from 'prop-types';
import { SyncLoader } from 'react-spinners';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { Fade } from 'react-reveal';
import { setShowAlert } from '../../../../../../reducers/showAlert';
import ImageContainer from '../imageContainer/imageContainer';
import { setImageSelectedForPost } from '../../../../../../reducers/imageSelectedForPost';
import { setImageData } from '../../../../../../reducers/newPost';
import DeteleFile from '../deteleFile/deteleFile';
function ImageList({ isCollapse, SendSocket, fromView }) {
  const companyFiles = useSelector((state) => state.companyFilesAsCollaborator.value);
  const [tempFileList, setTempFileList] = useState([...companyFiles.fileList]);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const handlerSetShowAlert = (infoAlert) => {
    dispatch(setShowAlert(infoAlert));
  };
  
  const handlerSetImageData = (infoPost) => {
    dispatch(setImageData(infoPost));
  };
  
  const handlerSetImageSelectedForPost = (infoSelectedFile) => {
    dispatch(setImageSelectedForPost(infoSelectedFile));
  };

  const openAlert = (typeAlert, textAlert) => {
    const object = {
      typeAlert: typeAlert,
      textAlert: textAlert
    };
  
    handlerSetShowAlert(object);
  };

  useEffect(() => {
    setTempFileList([...companyFiles.fileList]);
  }, [companyFiles]);

  const [selectedFileForPost, setSelectedFileForPost] = useState({
    uuid: '',
    uuidThumbnail: '',
    url: '',
    urlThumbnail: '',
  });

  const handlerSelectFile = (uuid) => {
    const findSelectFile = tempFileList.find((x) => x.uuid === uuid);
  
    if (findSelectFile) {
      if (findSelectFile.uuid === selectedFileForPost.uuid) {
        setSelectedFileForPost({
          uuid: '',
          uuidThumbnail: '',
          url: '',
          urlThumbnail: '',
        });
      } else {
        let selectedFile = {
          uuid: findSelectFile.uuid,
          uuidThumbnail: findSelectFile.uuidThumbnail,
          url: findSelectFile.url,
          urlThumbnail: findSelectFile.urlThumbnail,
        };

        handlerSetImageData(selectedFile);
        setSelectedFileForPost(selectedFile);
        handlerSetImageSelectedForPost(selectedFile);
        openAlert("success", "File selected");
      }
    } else {
      openAlert("danger", "File not found");
    }
  };

  return (
    <div className={`image-list-calendar ${isCollapse ? 'show' : ''}`}>
      {companyFiles.fileList.length !== 0 ? (
        <Fade duration={300} opposite>
          <div className="grid-image-list">
            {tempFileList.map((file) => (
              <div key={file.uuid} className='d-flex'>
                <button
                  id={file.uuid}
                  className={`card-image flex-1`}
                  onClick={() => {
                    if (fromView === "modal-post") {
                      handlerSelectFile(file.uuid)
                    }
                  }}
                >
                  <ImageContainer
                    id={file.uuid}
                    url={file.urlThumbnail}
                    selectedFile={selectedFileForPost.uuid === file.uuid}                    
                  />
                </button>
                <DeteleFile
                  file={`${file.uuid}.${file.format}`}
                  thumbnail={`${file.uuidThumbnail}.${file.formatThumbnail}`}
                  SendSocket={SendSocket}
                />
              </div>
            ))}
          </div>
        </Fade>
      ) : (
        <Fade duration={600} opposite>
          <div className="loading-container">
            <div className="d-flex flex-column justify-content-center align-items-center pt-5 pb-5 w-100">
              <div style={{ padding: '50px 0px 20px 0px' }}>
                <SyncLoader color="#6001d0" />
              </div>
              <span>
                Loading files
              </span>
            </div>
          </div>
        </Fade>
      )}
    </div>
  )
}

ImageList.propTypes = {
  isCollapse: PropTypes.bool.isRequired,
  SendSocket: PropTypes.func.isRequired,
  fromView: PropTypes.string.isRequired,
};

export default ImageList;
