export const optionsOffer = [
  {
    "uuid": "1dff1167-8e08-4020-a91f-2410805aad63",
    "access": [
     {
      "access": false,
      "edit": false,
      "generateGPT": false,
      "id": 1,
      "name": "form-ai"
     },
     {
      "access": true,
      "edit": true,
      "generateGPT": true,
      "id": 2,
      "name": "content-calendar"
     },
     {
      "access": false,
      "edit": false,
      "generateGPT": false,
      "id": 3,
      "name": "company-profile"
     },
     {
      "access": false,
      "edit": false,
      "generateGPT": false,
      "id": 4,
      "name": "brand-information"
     }
    ],
    "accessBlogPost": false,
    "canUserBeGuest": false,
    "description": "Access to basic resources and tools.",
    "guestUsersPerCompanyProfile": 0,
    "id": 1,
    "imgUrl": "./assets/icons/free-tier.png",
    "maxCompanyProfiles": 1,
    "maxGenerateBlogPost": 0,
    "maxGenerateContentCompany": 0,
    "maxGenerateContentPost": 1,
    "maxGeneratePost": 1,
    "maxGenerateSpecificPost": 1,
    "maxGenerateVideoPosts": 1,
    "maxSocialNetwork": 1,
    "name": "Free Tier",
    "numberColorPalette": 0,
    "numberHashtags": 0,
    "numberKeywords": 0,
    "numberTaglines": 0,
    "numberTypography": 0,
    "optionList": [
     {
      "id": 1,
      "name": "Basic industry, audience analysis, and 1 Tagline."
     },
     {
      "id": 2,
      "name": "A generated content calendar  for 1 day for a single platform."
     },
     {
      "id": 3,
      "name": "Only 1 Credit to be used with one account."
     }
    ],
    "price": 0,
    "storageForFiles": 262144000,
    "viewFromLogin": true
   },
  {
    "uuid": "74a8b99a-e92c-4d60-8966-53d288966d52",
    "access": [
     {
      "access": true,
      "edit": true,
      "generateGPT": true,
      "id": 1,
      "name": "form-ai"
     },
     {
      "access": true,
      "edit": true,
      "generateGPT": true,
      "id": 2,
      "name": "content-calendar"
     },
     {
      "access": true,
      "edit": true,
      "generateGPT": true,
      "id": 3,
      "name": "company-profile"
     },
     {
      "access": true,
      "edit": true,
      "generateGPT": true,
      "id": 4,
      "name": "brand-information"
     }
    ],
    "accessBlogPost": false,
    "canUserBeGuest": true,
    "description": "Access to more advanced resources and tools.",
    "guestUsersPerCompanyProfile": 2,
    "id": 2,
    "imgUrl": "./assets/icons/standard-tier.png",
    "maxCompanyProfiles": 1,
    "maxGenerateBlogPost": 0,
    "maxGenerateContentCompany": 2,
    "maxGenerateContentPost": 12,
    "maxGeneratePost": 24,
    "maxGenerateSpecificPost": 3,
    "maxGenerateVideoPosts": 12,
    "maxSocialNetwork": 3,
    "name": "Standard Tier",
    "numberColorPalette": 1,
    "numberHashtags": 3,
    "numberKeywords": 10,
    "numberTaglines": 3,
    "numberTypography": 3,
    "optionList": [
     {
      "id": 1,
      "name": "All free tier features."
     },
     {
      "id": 2,
      "name": "A generated content calendar for 5 days for up to 2 platforms"
     },
     {
      "id": 3,
      "name": "Personalized Unique Value Proposition (UVP)"
     },
     {
      "id": 4,
      "name": "Basic marketing campaign outline."
     }
    ],
    "price": 20,
    "storageForFiles": 1073741824,
    "viewFromLogin": true
   },
  {
    "uuid": "d370579d-dbec-4fd6-9c26-d219fe00da1d",
    "access": [
     {
      "access": true,
      "edit": true,
      "generateGPT": true,
      "id": 1,
      "name": "form-ai"
     },
     {
      "access": true,
      "edit": true,
      "generateGPT": true,
      "id": 2,
      "name": "content-calendar"
     },
     {
      "access": true,
      "edit": true,
      "generateGPT": true,
      "id": 3,
      "name": "company-profile"
     },
     {
      "access": true,
      "edit": true,
      "generateGPT": true,
      "id": 4,
      "name": "brand-information"
     }
    ],
    "accessBlogPost": true,
    "canUserBeGuest": true,
    "description": "Full access to all resources and tools.",
    "guestUsersPerCompanyProfile": 4,
    "id": 3,
    "imgUrl": "./assets/icons/premium-tier.png",
    "maxCompanyProfiles": 3,
    "maxGenerateBlogPost": 10,
    "maxGenerateContentCompany": 5,
    "maxGenerateContentPost": 20,
    "maxGeneratePost": 40,
    "maxGenerateSpecificPost": 20,
    "maxGenerateVideoPosts": 20,
    "maxSocialNetwork": 6,
    "name": "Premium Tier",
    "numberColorPalette": 3,
    "numberHashtags": 8,
    "numberKeywords": 30,
    "numberTaglines": 6,
    "numberTypography": 3,
    "optionList": [
     {
      "id": 1,
      "name": "All standard tier features."
     },
     {
      "id": 2,
      "name": "A custom content calendar for 15 days for up to 3 platforms."
     },
     {
      "id": 3,
      "name": "Full brand kit creation (tagline, color palette, typography)."
     },
     {
      "id": 3,
      "name": "Premium customer support."
     }
    ],
    "price": 40,
    "storageForFiles": 3221225472,
    "viewFromLogin": true
   },
   {
    "uuid": "4253ea2a-5b75-45d4-a039-9247ca6e7918",
    "access": [
    ],
    "accessBlogPost": false,
    "canUserBeGuest": false,
    "description": "Only access as Collaborator.",
    "guestUsersPerCompanyProfile": 0,
    "id": 4,
    "imgUrl": "./assets/icons/free-tier.png",
    "maxCompanyProfiles": 0,
    "maxGenerateBlogPost": 0,
    "maxGenerateContentCompany": 0,
    "maxGenerateContentPost": 0,
    "maxGeneratePost": 0,
    "maxGenerateSpecificPost": 0,
    "maxGenerateVideoPosts": 0,
    "maxSocialNetwork": 0,
    "name": "Collaborator Tier",
    "numberColorPalette": 0,
    "numberHashtags": 0,
    "numberKeywords": 0,
    "numberTaglines": 0,
    "numberTypography": 0,
    "optionList": [
     {
      "id": 1,
      "name": "Only access as Collaborator."
     }
    ],
    "price": 0,
    "storageForFiles": 0,
    "viewFromLogin": false
   },
];

/* name bucket s3 */
export const bucketNameS3 = "test-meantto.io-files"; 

/* Thumbnail specs */
export const maxWidthThumbnail = 300;
export const maxHeightThumbnail = 300;
export const maxQualityThumbnail = 1.0;

/* aws const */
export const regionAWS = "us-east-1";

/* Social Networks */
export const socialNetworksConst = [
  {
    id: 1,
    name: 'Facebook',
    urlIcon: './assets/icons/facebook.svg',
    logged: true,
    group: 1
  },
  {
    id: 2,
    name: 'Instagram',
    urlIcon: './assets/icons/instagram.svg',
    logged: false,
    group: 1
  },
  {
    id: 3,
    name: 'Linkedin',
    urlIcon: './assets/icons/linkedin.svg',
    logged: true,
    group: 3
  },
  {
    id: 4,
    name: 'Twitter',
    urlIcon: './assets/icons/twitter.svg',
    logged: true,
    group: 1
  },
  {
    id: 5,
    name: 'Tiktok',
    urlIcon: './assets/icons/tiktok.svg',
    logged: false,
    group: 2
  },
  {
    id: 6,
    name: 'Youtube',
    urlIcon: './assets/icons/youtube.svg',
    logged: true,
    group: 2
  },
  {
    id: 7,
    name: 'Instagram (Reels)',
    urlIcon: './assets/icons/instagram.svg',
    logged: false,
    group: 2
  },
];

/* format for moment js */
export const formatMoment = "YYYY-MM-DDTHH:mm:ss.SSS";
export const formatDateMoment = "YYYY-MM-DD";
export const formatHourMoment = "HH:mm:ss.SSS";
export const formatHourInput = "hh:mm";