import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Link } from 'react-router-dom';
import userPool from './userPool';
import './forgotPassword.css';
import './style.css';
import { postRequest } from '../services/requestLib';
import Password from '../share-components/inputs/password/password';

function ForgotPassword({ from }) {
  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
  const lengthRegex = /.{6,}/;
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const numberRegex = /[0-9]/;

  const [email, setEmail] = useState('');
  const [sendCode, setSendCode] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();

    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    user.forgotPassword({
      onSuccess: function (data) {
        setSendCode(true);
      },
      onFailure: function (err) {
        console.error('Error al solicitar la recuperación de contraseña:', err);
        setSendCode(false);
      },
    });
  };

  const [msmInNewPassword, setMsmInNewPassword] = useState('');
  const [errorInNewPassword, setErrorInNewPassword] = useState(false);

  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('');

  const handlerResetPassword = async (user) => {
    const requestNewUser = await postRequest('reset-password', user);
    if (requestNewUser.status === 200 && requestNewUser.data.status === true) {
      return true;
    } else {
      return false;
    }
  };
  
  const handleConfirmation = (event) => {
    event.preventDefault();

    if (verificationCode.trim() === '') {
      setMsmInNewPassword('Enter code verification');
      setErrorInNewPassword(true);
      return;
    }

    if (newPassword.trim() === '') {
      setMsmInNewPassword('Enter new password');
      setErrorInNewPassword(true);
      return;
    }

    if (newPassword !== newPasswordRepeat) {
      setMsmInNewPassword('Passwords do not match');
      setErrorInNewPassword(true);
      return;
    }

    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    user.confirmPassword(verificationCode, newPassword, {
      onSuccess: function (data) {
        (async () => {
          const user = {
            typeReset: 1,
            email: email,
            newPassword: newPassword
          };
    
          try {
            const resResetPassword = await handlerResetPassword(user);
    
            if (resResetPassword) {
              console.log('Contraseña restablecida con éxito.');
              setPasswordUpdated(true);
            }
          } catch (error) {
            console.error('Error al manejar el restablecimiento de la contraseña:', error);
          }
        })();
      },
      onFailure: function (err) {
        setMsmInNewPassword('Make sure you meet the requirements for your new password.');
        setErrorInNewPassword(true);
        setPasswordUpdated(false);
        console.error('Error al restablecer la contraseña:', err);
      },
    });
  };

  const handlePasswordChange = (newPassword) => {
    setNewPassword(newPassword);
  };

  const handleReEnterPasswordChange = (newPassword) => {
    setNewPasswordRepeat(newPassword);
  };

  const handlerBuildChangePassword = () => {
    return (
      !sendCode ? (
        <form onSubmit={onSubmit} className="d-flex flex-column">
          {from !== 'modal' && (
            <h2 className="title-forgot-password mb-2">
              Forgot your password?
            </h2>
          )}
          <small className="text-forgot-password mb-3">
            Enter your email for send code for reset your password.
          </small>
          <input
            id="email"
            name="email"
            type="text"
            placeholder="email"
            autoComplete='false'
            className="input-field"
            onChange={(event) => setEmail(event.target.value)}
          />
          <button type='submit' className="send-code-btn">
            Send code
          </button>
        </form>
      ) : (
        <div>
          {!passwordUpdated ? (
            <form onSubmit={handleConfirmation} className="d-flex flex-column">
              <h2 className="title-forgot-password">
                New password
              </h2>
              {errorInNewPassword && (
                <div style={{ marginBottom: '10px' }}>
                  <span style={{ color: 'red' }}>
                    {msmInNewPassword}
                  </span>
                </div>
              )}
              <small className="mb-2 mt-2">
                Password have contain:
                <div className="list-requeriments">
                  <ul>
                    <li className={lengthRegex.test(newPassword) && 'li_check' }>
                      Min 6 characters
                    </li>
                    <li className={uppercaseRegex.test(newPassword) && 'li_check' }>
                      A letter mayus
                    </li>
                    <li className={lowercaseRegex.test(newPassword) && 'li_check' }>
                      A letter minus
                    </li>
                    <li className={numberRegex.test(newPassword) && 'li_check' }>
                      A numbers
                    </li>
                    <li className={specialCharRegex.test(newPassword) && 'li_check' }>
                      A special character
                    </li>
                  </ul>
                </div>
              </small>
{/*               <input
                type="text"
                placeholder=""
                className="input-field"
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <input
                type="text"
                placeholder="Re-enter New password"
                className="input-field"
                onChange={(e) => setNewPasswordRepeat(e.target.value)}
              /> */}

              <Password onPasswordChange={handlePasswordChange} isEmpty={newPassword !== ""} placeholder='New password' />
              <Password onPasswordChange={handleReEnterPasswordChange} isEmpty={newPasswordRepeat !== ""} placeholder='Re-enter New password' />

              <div style={{ marginTop: '5px', marginBottom: '10px', height: '1px', backgroundColor: 'grey' }}></div>
              <input
                type="code"
                placeholder="Code verification"
                className="input-field mt-1"
                onChange={(e) => setVerificationCode(e.target.value)}
              />
              <button type='submit' className="send-code-btn">
                Confirm
              </button>
            </form>
          ) : (
            <div className="d-flex flex-column">
              <h2 className="title-forgot-password mb-3">
                Password update successfully!
              </h2>
              <Link
                to={`/sign-in`}
                className="send-code-btn w-100 text-align-center"
              >
                Go to Sign in
              </Link>
            </div>  
          )}
        </div>
      )
    );
  };

  return (from === 'modal' ? (
    handlerBuildChangePassword()
  ) : (
    <div className="container-forgot-password">
      <div className="card-content-forgot-password">
        {handlerBuildChangePassword()}
      </div>
    </div>
  )
  )
};

ForgotPassword.propTypes = {
  SendSocket: PropTypes.func.isRequired,
};

export default ForgotPassword;
