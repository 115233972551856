import React, { useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Fade } from 'react-reveal';
import { Modal } from 'react-responsive-modal';
import { SyncLoader } from 'react-spinners';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { setShowViewCreateCompanyProfile, setHideViewCreateCompanyProfile } from '../../../../reducers/viewCreateCompanyProfile';
import { setNewCompany } from '../../../../reducers/companyList';
import { putRequestMeantto } from '../../../services/requestLib';
import { setActiveCompanyProfile } from '../../../../reducers/activeCompanyProfile';
import { showModal } from '../../../../reducers/showModalForGoToFormAI';
import { setShowCompanyList } from '../../../../reducers/showCompanyList';
import Paper from '../../paper/paper';
import Text from '../../text/text';
import '../../comboBox.scss';
import { location as locationJSON } from '../../../services/location/location';
import { setPostsCompany } from '../../../../reducers/postsCompany';
import { setCompanyInfo } from '../../../../reducers/companyInfo';

const { v4: uuidv4 } = require('uuid');

function CreateFirtsCompany() {
  const userData = useSelector((state) => state.userData.value);
  const statusUser = useSelector((state) => state.statusUser.value);
  const viewCreateCompanyProfile = useSelector((state) => state.viewCreateCompanyProfile.value);
  const companyList = useSelector((state) => state.companyList.value);
  /* eslint-disable no-unused-vars */
  const [tempCompanyList, setTempCompanyList] = useState([...companyList.companyList]);
  const maxGenerateContentCompany = useSelector((state) => state.statusUser.value.maxGenerateContentCompany);
  
  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const handlerSetShowViewCreateCompanyProfile = () => {
    dispatch(setShowViewCreateCompanyProfile());
  };  

  const handlerSetHideViewCreateCompanyProfile = () => {
    dispatch(setHideViewCreateCompanyProfile());
  };

  const handlerSetNewCompany = (newCompany) => {
    dispatch(setNewCompany(newCompany));
  };
  
  const handlerSetActiveCompanyProfile = (infoCompany) => {
    dispatch(setActiveCompanyProfile(infoCompany));
  };

  const handlerSetPostsCompany = (infoCompany) => {
    dispatch(setPostsCompany(infoCompany));
  };

  const handlerShowModalGoToFormAI = () => {
    dispatch(showModal());
  };

  const handlerSetShowCompanyList = () => {
    dispatch(setShowCompanyList());
  };

  const handlerSetCompanyInfo = (infoCompany) => {
    dispatch(setCompanyInfo(infoCompany));
  };

  const [modalCompanyList, setModalCompanyList] = useState(false);

  const handlerShowCreateCompany = () => {
    setModalCompanyList(true);
    handlerSetShowViewCreateCompanyProfile();
  };

  const handlerHideCreateCompany = () => {
    setModalCompanyList(false);
    handlerSetHideViewCreateCompanyProfile();
  };

  const [companyName, setCompanyName] = useState("");
  const [companyNameEmpty, setCompanyNameEmpty] = useState(false);
  
  const handlerChangeName = (e) => {
    const { value } = e.target;
    setCompanyName(value);
    
    if (value === "") {
      setCompanyNameEmpty(true);
    } else {
      setCompanyNameEmpty(false);
    }
  };

  const [location, setLocation] = useState("");
  const [locationFiltered, setLocationFiltered] = useState([]);
  const [ciudadesFiltradas, setCiudadesFiltradas] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(false);
  const [locationEmpty, setLocationEmpty] = useState(false);

  const handlerChangeNameLocation = (e) => {
    const { value } = e.target;
    setSelectedLocation(false);
    setLocationFiltered(value);

    const filteredCities = locationJSON.filter((x) => x.nombre.toLowerCase().includes(value.toLowerCase()));
    setCiudadesFiltradas(filteredCities);

    if (value === "") {
      setCiudadesFiltradas([]);
      setLocationEmpty(true);
    } else {
      setLocationEmpty(false);
    }
  };

  const handlerSelectCity = (e) => {
    setLocation(e);
    setLocationFiltered(e);
    setCiudadesFiltradas([]);
    setSelectedLocation(true);
  };

  const handlerBackToCompanyList = () => {
    handlerHideCreateCompany();
    handlerSetShowCompanyList();
  };
  
  const [viewModal, setViewModal] = useState("create");

  const handlerSuccessCreateCompany = () => {
    setTimeout(() => {
      handlerHideCreateCompany();
      if (maxGenerateContentCompany > 0) {
        handlerShowModalGoToFormAI();
      }
      setViewModal("create");
    }, 3000);
  };

  const handlerRequestCreateNewCompany = async (updatedCompanyList) => {
    setViewModal("loading");
    const dataUser = {
      email: userData.email,
      password: userData.password,
      token: userData.token,
    };

    const sendData = {
      companyList: updatedCompanyList,
      planService: statusUser.servicePlan
    };

    const responseUpdateCompanyInfo = await putRequestMeantto('company-profile', dataUser, sendData);
    if (responseUpdateCompanyInfo.status === 200){
      if (responseUpdateCompanyInfo.data.status === true) {
        setViewModal("success");

        const lastCompany = updatedCompanyList.slice(-1)[0];

        handlerSetNewCompany(updatedCompanyList);
        handlerSetActiveCompanyProfile(lastCompany);
        handlerSetPostsCompany({
          user: statusUser.user,
          id: lastCompany.id,
          email: dataUser.email,
          maxGenerateContentPost: statusUser.maxGenerateContentPost,
          maxGenerateSpecificPost: statusUser.maxGenerateSpecificPost,
          maxGenerateVideoPosts: statusUser.maxGenerateVideoPosts,
          postList: []
        });

        handlerSetCompanyInfo({
          user: statusUser.user,
          email: dataUser.email,
          answerForm: {},
          generatedContent: {},
          counterUseForm: statusUser.maxGenerateContentCompany,
          counterUseGenerateContent: {}
        });

        handlerSuccessCreateCompany();
      } else {
        setViewModal("error");
      }
    } else {
      setViewModal("error");
    }
  };

  const handlerCreateCompany = () => {
    if (companyName !== "" && selectedLocation) {
      const newData = {
        newCompany: {
          active: true,
          id: uuidv4(),
          infoCompanyGenerate: false,
          name: companyName.trim(), 
          location: location.trim(), 
          order: tempCompanyList.length + 1
        }
      };

      let updatedCompanyList = tempCompanyList;
      updatedCompanyList.push(newData.newCompany);

      setCompanyNameEmpty(false);
      handlerRequestCreateNewCompany(updatedCompanyList);
    } else {
      if (companyName === "") {
        setCompanyNameEmpty(true);
      }

      if (!selectedLocation) {
        setLocationEmpty(true);
      }
    }
  };

  useEffect(() => {
    setCompanyName("");
    setLocation("");

    if (viewCreateCompanyProfile.show === true) {
      handlerShowCreateCompany();
      setTempCompanyList([...companyList.companyList]);
    }
    // eslint-disable-next-line
  }, [viewCreateCompanyProfile]);

  return (
    <Modal
      open={modalCompanyList}
      showCloseIcon={false}
      blockScroll={true}
      onClose={() => handlerHideCreateCompany()}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      classNames={{
        overlay: 'customOverlay',
        modal: 'customModal',
      }}
      styles={{
        modal: {
          overflow: 'visible',
        },
      }}
      center
      >
      <div className="modal-body-no-responsive">
        <div className="modal-content">
          {viewModal === "create" && (
            <div className='d-flex flex-column' style={{ height: '90%' }}>
              <h3 className="w-100 text-center">
                Please enter the name of your company
              </h3>
              <div className=" mb-1 mt-3 pl-2 pr-2">
                <Paper type="info" inyectClass="pt-1 pb-1 pl-2 pr-2 paper-rounded">
                  <Text type="small">
                    Verify that the name is correctly written.
                  </Text>
                    <br />
                  <Text type="small">
                    once the profile is created, the company name cannot be edited.
                  </Text>
                </Paper>
              </div>  
              <div className="d-flex flex-column mt-3">
                <input
                  id="companyName"
                  name="companyName"
                  type="text"
                  className={`input-field field-form ${companyNameEmpty && 'invalid-answer mb-1'}`}
                  onChange={(e) => handlerChangeName(e)}
                  value={companyName}
                  placeholder='Company name'
                  />
                {companyNameEmpty && (
                  <small style={{ color: 'red' }}>
                    Company name input is empty
                  </small>
                )}
              </div>
              <div className="d-flex flex-column">
                <div className="combo-box">
                  <input
                    id="location"
                    name="location"
                    type="text"
                    className={`input-field field-form ${locationEmpty && 'invalid-answer mb-1'}`}
                    onChange={(e) => handlerChangeNameLocation(e)}
                    value={locationFiltered}
                    placeholder='Location'
                  />
                  {ciudadesFiltradas.length > 0 && (
                    <Fade duration={600} opposite>
                      <div className="combo-box-container bg-glass">
                        {ciudadesFiltradas.map((city, i) => (
                          <button
                            key={i}
                            onClick={() => handlerSelectCity(`${city.nombre}, ${city.country_code}`)}
                          >
                            {city.nombre}, {city.country_code}
                          </button>
                        ))}
                      </div>
                    </Fade>
                  )}
                </div>  
                {locationEmpty && (
                  <small style={{ color: 'red' }}>
                    Location input is empty
                  </small>
                )}
              </div>
            </div>
          )}
          {viewModal === "loading" && (     
            <div className='d-flex flex-column justify-content-center align-items-center pb-2 pt-2' style={{ height: '100%' }}>
              <SyncLoader color="#6001d0" className="mt-2" />
              <span className="w-100 text-center mt-3">
                Creating your company
              </span>
            </div>
          )}
          {viewModal === "error" && (
            <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '100%' }}>
              <h4 className="w-100 text-center">
                Something went wrong. Please try again
              </h4>
            </div>
          )}
          {viewModal === "success" && (
            <div className='d-flex flex-column justify-content-center align-items-center pt-2 pb-2' style={{ height: '100%' }}>
              <h4 className="w-100 text-center">
                Your company has been successfully created.
              </h4>
              <br />
              <span>
                Now, complete this form for start to generate your content
              </span>
            </div>
          )}
        </div>
        <div className="action-btn-form">
          {(viewModal !== "loading" || viewModal !== "success") && (
            <>
              {companyList.companyList.length === 0 ? (
                <>
                  {viewModal === "create" && (
                    <button
                      className={`one-btn-modal btn-primary ${companyNameEmpty && 'disabled '}`}
                      onClick={() => handlerCreateCompany()}
                      disabled={companyNameEmpty}
                    >
                      Create company
                    </button>
                  )}
                  {viewModal === "error" && (
                    <button
                      className={`one-btn-modal btn-primary ${companyNameEmpty && 'disabled '}`}
                      onClick={() => handlerCreateCompany()}
                      disabled={companyNameEmpty}
                    >
                      try again
                    </button>
                  )}
                </>
              ) : (
                (viewModal !== "success" && viewModal !== "loading") && (
                  <>  
                    <button
                      className="two-btn-modal"
                      onClick={() => handlerBackToCompanyList()}>
                        Back
                    </button>
                    <button
                      className={`two-btn-modal btn-primary ${(companyName === '' || location === '' || !selectedLocation) ? 'disabled' : ''}`}
                      onClick={() => handlerCreateCompany()}
                      disabled={companyName === '' || location === '' || !selectedLocation}
                    >
                      Create company
                    </button>
                  </>
                )
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default CreateFirtsCompany;
