export const version_app = "24.8.21";

export function detectDeviceType() {
  const userAgent = window.navigator.userAgent;
  if (/Android/i.test(userAgent)) {
    return 'Android';
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return 'iOS';
  } else {
    return 'Otro';
  }
}

export function emailValidator(email) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}