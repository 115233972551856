/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const loadingCompanyInformation = createSlice({
  name: 'loadingCompanyInformation',
  initialState: {
    value: {
      loading: false
    },
  },
  reducers: {
    setLoadingCompanyInformation: (state, action) => {
      state.value.show = true;
    },
    hideLoadingCompanyInformation: (state, action) => {
      state.value.show = false;
    },
  }
});

export const {
  setLoadingCompanyInformation, hideLoadingCompanyInformation
} = loadingCompanyInformation.actions;

export default loadingCompanyInformation.reducer;
