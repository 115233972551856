/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const generatedContentAsCollaborator = createSlice({
  name: 'generatedContentAsCollaborator',
  initialState: {
    value: {
      "Brand Name": "",
      "Brand Mission": "",
      "Brand Vision": "",
      "Value Statement": "",
      "Unique Value Proposition (UVP)": "",
      "Target Audience": "",
      "Market Gap": "",
      "Ultimate Benefit": "",
      "Taglines": [],
      "Color Palette": [],
      "Typography": [],
    },
  },
  reducers: {
    setGeneratedContentAsCollaborator: (state, action) => {
      // Mapea las claves del JSON a las claves deseadas en el estado Redux
      if (action.payload["Brand Name"]) {
        state.value["Brand Name"] = action.payload["Brand Name"] || "";
      }
      state.value["Brand Mission"] = action.payload["Brand Mission"] || "";
      state.value["Brand Vision"] = action.payload["Brand Vision"] || "";
      state.value["Value Statement"] = action.payload["Value Statement"] || "";
      state.value["Unique Value Proposition (UVP)"] = action.payload["Unique Value Proposition (UVP)"] || "";
      state.value["Target Audience"] = action.payload["Target Audience"] || "";
      state.value["Market Gap"] = action.payload["Market Gap"] || "";
      state.value["Ultimate Benefit"] = action.payload["Ultimate Benefit"] || "";
      state.value["Taglines"]  = action.payload["Taglines"] || "";
      state.value["Color Palette"] = action.payload["Color Palette"] || "";
      state.value["Typography"]  = action.payload["Typography"] || "";
    },
    cleanGeneratedContentAsCollaborator: (state, action) => {
      state.value["Brand Name"] = "";
      state.value["Brand Mission"] = "";
      state.value["Brand Vision"] = "";
      state.value["Value Statement"] = "";
      state.value["Unique Value Proposition (UVP)"] = "";
      state.value["Target Audience"] = "";
      state.value["Market Gap"] = "";
      state.value["Ultimate Benefit"] = "";
      state.value["Taglines"]  = [];
      state.value["Color Palette"] = [];
      state.value["Typography"]  = [];
    },
  }
});

export const {
  setGeneratedContentAsCollaborator, cleanGeneratedContentAsCollaborator
} = generatedContentAsCollaborator.actions;

export default generatedContentAsCollaborator.reducer;