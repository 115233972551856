import React, { useEffect, useState } from 'react';
import { Fade } from "react-reveal";
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import './style.scss';
import Lottie from "lottie-react";
import Team from "./team.json";
import HighFive from "./high-five.json";
import base64 from 'base-64';
import { useMediaQuery } from '@react-hook/media-query';
import Text from '../share-components/text/text';
import { patchRequestCollaborators, postRequest } from '../services/requestLib';
import { setShowAlert } from '../../reducers/showAlert';
import { setCompanyCollaborator } from '../../reducers/companyCollaborator';

function JoinToMeantto() {
  const history = useHistory();
  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');
  const userData = useSelector((state) => state.userData.value);
  const companyCollaborator = useSelector((state) => state.companyCollaborator.value);

  const styleLottieAnim = {
    height: isMobileDevice ? 200 : 250,
  };

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const handlerSetCompanyCollaborator = (infoCompanyCollaborator) => {
    dispatch(setCompanyCollaborator(infoCompanyCollaborator));
  };

  const handlerSetShowAlert = (infoAlert) => {
    dispatch(setShowAlert(infoAlert));
  };

  const location = useLocation();
  const { pathname } = location;
  const pathParts = pathname.split('/');
  const base64Segment = JSON.parse(base64.decode(pathParts[pathParts.length - 1]));

  const openAlert = (typeAlert, textAlert) => {
    const object = {
      typeAlert: typeAlert,
      textAlert: textAlert
    };

    handlerSetShowAlert(object);
  }; 

  const requestCompanyCollaborator = async () => {
    const userInfo = {
      email: userData.email,
      password: userData.password,
      token: userData.token
    };

    const requestCompanyCollaborator = await postRequest('company-collaborator', userInfo);
    if (requestCompanyCollaborator.status === 200) {
      handlerSetCompanyCollaborator(requestCompanyCollaborator.data.data);
    }
  };

  const checkIfAlreadyAcceptedInvitation = () => {
    const filteredCompanyList = companyCollaborator.companyList.filter((x) => x.idCompany === base64Segment.iC);

    if (filteredCompanyList.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const [successAcceptedInvitation, setSuccessAcceptedInvitation] = useState(false);

  const handlerAcceptInvitation = async () => {
    const validateAlreadyExist = checkIfAlreadyAcceptedInvitation();

    if (validateAlreadyExist) {
      history.push('/');
      openAlert("info", "You are already a collaborator in this company.");
    } else {
      const userInfo = {
        email: userData.email,
        user: userData.user,
        token: userData.token,
      };
      
      const requestEnterCompanyAsCollaborator = await patchRequestCollaborators('company-collaborator', userInfo, base64Segment);
      if (requestEnterCompanyAsCollaborator.status === 200) {
        if (requestEnterCompanyAsCollaborator.data.status === true) {
          openAlert("success", "Success");
          requestCompanyCollaborator();
          setSuccessAcceptedInvitation(true);

          setTimeout(() => {
            history.push('/');
            openAlert("info", "Now you can access the companies to which you have been invited.");
          }, 1500);
        }
  
        if (requestEnterCompanyAsCollaborator.data.status === false) {
          openAlert("danger", requestEnterCompanyAsCollaborator.data.message);
        }
      }
    }
  };

  useEffect(() => {
    if (base64Segment.e === userData.email) {
      history.push('/');
      openAlert("info", "You cannot invite yourself to your own company.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    base64Segment.e !== undefined && (
      <div className="main-guest-users-container">
        <div className="container-message">
          {(successAcceptedInvitation === false) && (
            <Fade duration={600} opposite>
              <Lottie animationData={Team} loop={true} autoPlay={true} style={styleLottieAnim} />
              <Text type="subtitle">
                {base64Segment.e} invited you to his company.
              </Text>
              <button className='btn btn-primary' onClick={() => handlerAcceptInvitation()}>
                Accept invitation
              </button>
            </Fade>
          )}

          {(successAcceptedInvitation === true) && (
            <Fade duration={600} opposite>
              <Lottie animationData={HighFive} loop={true} autoPlay={true} style={styleLottieAnim} />
              <Text type="title">
                Great, you are new teammate
              </Text>
            </Fade>
          )}
        </div>
      </div>
    )
  )
}

export default JoinToMeantto;
