import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { setHideModal } from '../../../../reducers/alertUserToGenerateContentSuccess';

function GenerateContentSuccess() {
  const history = useHistory();
  const alertUserToGenerateContentSuccess = useSelector((state) => state.alertUserToGenerateContentSuccess.value);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const handlerSetHideModal = () => {
    dispatch(setHideModal());
  };

  const [modalFirtsSteps, setModalFirtsSteps] = useState(false);

  const handlerShowFirtsStepsToUser = () => {
    setModalFirtsSteps(true);
  };

  const handlerHideFirtsStepsToUser = () => {
    setModalFirtsSteps(false);
    handlerSetHideModal();
    history.push('/company-profile');
  };

  useEffect(() => {
    if (alertUserToGenerateContentSuccess.show === true) {
      handlerShowFirtsStepsToUser();
    }
    // eslint-disable-next-line
  }, [alertUserToGenerateContentSuccess]);

  return (
    <Modal
      open={modalFirtsSteps}
      showCloseIcon={false}
      blockScroll={true}
      onClose={() => handlerHideFirtsStepsToUser()}
      classNames={{
        overlay: 'customOverlay',
        modal: 'customModal',
      }}
      center
      >
      <div className="modal-body">
        <div className="modal-content">
          <div className='d-flex flex-column align-items-center justify-content-center h-100'>
            <span className="title-alert-modal mb-2">
              Your AI-Generated Content is Ready!
            </span>
            <span className="text-alert-modal">
              We're excited to let you know that your content is ready to view.
              <br /><br />
              To view, please go to the 'Company Profile' section.
            </span>
          </div>
        </div>
        <div className="action-btn-form">
          <button
            onClick={() => handlerHideFirtsStepsToUser()}>
              Go to Company Profile
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default GenerateContentSuccess;
