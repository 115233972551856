import React from 'react';
import './style.scss';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { useMediaQuery } from '@react-hook/media-query';

function UpgradeYourPlan() {
  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');

  return (
    <button
      type='button'
      className="a-link border-none"
      onClick={() => console.log("Upgrade your plan")}
    >
      <div className="menu-link bg-upgrade-plan">
        <KeyboardDoubleArrowUpIcon className='text-primary'/>
        <span>
          {isMobileDevice ? 'Upgrade' : 'Upgrade your plan'}
        </span>
      </div>
    </button>
  )
}

export default UpgradeYourPlan;