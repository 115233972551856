import React, { useState } from 'react';
import PropTypes from 'prop-types'; 
import { useHistory } from 'react-router-dom';
import './login.css';
import login from '../services/login/login';
import Password from '../share-components/inputs/password/password';

function FormLogin({ url }) {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [msmRequest, setMsmRequest] = useState('');  
  
  const onSubmit = async (event) => {
    setMsmRequest("");
    event.preventDefault();
  
    if (email !== '' && password !== '') {
      const { success, msm } = await login({
        email: email,
        password: password
      });
  
      if (success) {
        history.push(`/${url}`);
        window.location.reload();
      } else {
        setMsmRequest(msm);
      }
    } else {
      setMsmRequest("Email and Password inputs are empty");
    }
  };

  const handlePasswordChange = (newPassword) => {
    setPassword(newPassword);
  };

  return (
    <form onSubmit={onSubmit} className="pt-5 d-flex flex-column">
      <input
        id="email"
        name="email"
        type="text"
        placeholder="email"
        autoComplete='false'
        className={`input-field ${msmRequest !== "" && 'invalid-answer'}`}
        onChange={(event) => setEmail(event.target.value)}
      />
      <Password onPasswordChange={handlePasswordChange} isEmpty={msmRequest !== ""} placeholder='password' />

      {msmRequest !== "" && (
        <p className='text-danger'>
          {msmRequest}
        </p>
      )}

      <button type="submit" className="signin-btn mt-3">
        Sign in
      </button>
    </form>
  )
}

FormLogin.propTypes = {
  url: PropTypes.string
};

export default FormLogin;
