import React from 'react';
import PropTypes from 'prop-types';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Fade from "react-reveal/Fade";
import '../../../../settings/style.scss';
import './style.scss';
import { socialNetworksConst } from '../../../../services/values';
import Text from '../../../text/text';

function SocialNetworks({ backView }) {
  const handlerBackView = () => {
    backView();
  };

  return (
    <Fade duration={600} opposite>
      <div className="menu-main-container pr-2 pl-2">
        <div className="header-submenu">
          <Text type="text">
            Social networks
          </Text>
        </div>
        <div className="content-submenu">
          <div className='mt-3 mb-3'>
            <div className="container-list">
              {socialNetworksConst.map((social, index, array) => (
                <div className={`item-list ${index === 0 ? 'firts-child' : ''} ${index === array.length - 1 ? 'last-child' : ''} ${index !== array.length - 1 ? 'column-divider' : ''}`} key={social.id}>
                  <div className="left-container">
                    <img src={social.urlIcon} alt="" style={{ height: '26px', width: 'auto' }} />
                    {social.name}
                  </div>

                  <div className="right-container">
                    <NavigateNextIcon />
                    {social.logged && (<small className="text-muted">Logged</small>)}
                  </div>  
                </div>
              ))}
            </div>

            <div className="mb-1 pl-3 pr-3">
              <small className="text-small-description">
                The limit of social media accounts you can link to Meantto is based on the number of social media accounts allowed according to your subscription plan on our platform.
              </small>
            </div>
          </div>
        </div>
      </div>
      <div className="action-btn-form">
        <button
          className={`one-btn-modal`}
          onClick={() => handlerBackView()}
        >
          Back
        </button>
      </div>
    </Fade>
  )
}

SocialNetworks.propTypes = {
  backView: PropTypes.func.isRequired,
};

export default SocialNetworks;
