import React, { useEffect, useState } from 'react';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import MoonLoader from "react-spinners/MoonLoader";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { setHideAlert } from '../../../reducers/showAlert';
import './style.scss';

function Alert() {
  
  const [stateTypeAlert, setStateTypeAlert] = useState("");
  const [stateTextAlert, setStateTextAlert] = useState("");
  const [stateAlert, setStateAlert] = useState(false);
  
  const showAlertRedux = useSelector((state) => state.showAlert.value);

  const dispatch = useDispatch();

  const handlerSetHideAlert = () => {
    dispatch(setHideAlert());
  };

  const handlerHideAll = () => {
    setStateAlert(false);
    setStateTypeAlert("");
    setStateTextAlert("");
  };

  const handlerOpenAlert = () => {
    setStateTypeAlert(showAlertRedux.typeAlert);
    setStateTextAlert(showAlertRedux.textAlert);
    setStateAlert(true);
    setTimeout(() => {
      handlerSetHideAlert();
      handlerHideAll();
    }, 3000);
  };

  useEffect(() => {
    if (showAlertRedux.show === true) {
      handlerOpenAlert();
    }
    // eslint-disable-next-line
  }, [showAlertRedux]);

  return (
    <div className={`alert ${stateAlert ? 'show': ''} type-alert-${stateTypeAlert} unselectable`}>
      <div className="alert-body">

        {stateTypeAlert === "success" && <CheckRoundedIcon />}
        {stateTypeAlert === "danger" && <ErrorOutlineRoundedIcon />}
        {stateTypeAlert === "warning" && <PriorityHighRoundedIcon />}
        {stateTypeAlert === "copy" && <ContentCopyRoundedIcon />}
        {stateTypeAlert === "info" && <InfoRoundedIcon />}
        {stateTypeAlert === "loading" && <MoonLoader color="#000" size={22} />}

        <div className="text-alert unselectable">
          {stateTextAlert}
        </div>
      </div>
    </div>
  )
}

export default Alert;