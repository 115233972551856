import React, { useState, useEffect } from 'react';
import {
  useDispatch
} from 'react-redux';
import { Fade } from "react-reveal";
import base64 from 'base-64';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@react-hook/media-query';
import './style.scss';
import Lottie from "lottie-react";
import Team from "./team.json";
// import HighFive from "./high-five.json";
import Text from '../share-components/text/text';
import { setShowAlert } from '../../reducers/showAlert';
import FormLogin from '../security/formLogin';
import FormSignUp from '../security/formSignUp';
import { setExternalURL } from '../../reducers/externalURL';

function CollaboratorWithoutAccount() {
  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');
  const history = useHistory();

  const styleLottieAnim = {
    height: isMobileDevice ? 200 : 250,
  };

  const location = useLocation();

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const handlerSetShowAlert = (infoAlert) => {
    dispatch(setShowAlert(infoAlert));
  };

  const handlerSetExternalURL = (urlInfo) => {
    dispatch(setExternalURL(urlInfo));
  };

  const openAlert = (typeAlert, textAlert) => {
    const object = {
      typeAlert: typeAlert,
      textAlert: textAlert
    };

    handlerSetShowAlert(object);
  }; 

  const [typeView, setTypeView] = useState(1);

  const handlerShowFormLogin = (id) => {
    setTypeView(id);
  };

  const [urlEncode, setUrlEncode] = useState("");
  const [objectURL, setObjectURL] = useState({});

  useEffect(() => {
    try {
      const { pathname } = location;
      const pathParts = pathname.split('/');
      const base64Segment = JSON.parse(base64.decode(pathParts[pathParts.length - 1]));

      if (base64Segment === null || typeof base64Segment !== 'object' || Array.isArray(base64Segment)) {
        history.push('/');
        openAlert("info", "Invalid URL, please try again.");
      } else {
        const object = {
          from: 'invitation',
          url: pathname.substring(1)
        };
        handlerSetExternalURL(object);
        setObjectURL(base64Segment);
        setUrlEncode(pathParts[pathParts.length - 1]);
      }
    } catch (error) {
      history.push('/');
      openAlert("info", "Invalid URL, please try again.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="form-login-invitation">
      <img
        src="./assets/logos/imagotipo/Imagotipo/Horizontal/PNG/morado.png"
        className='logo-invitation'
        alt='logo - Meantto'
      />

      {typeView === 1 && (
        <div className="container-message">
          <Fade duration={600} opposite>
            <Lottie animationData={Team} loop={true} autoPlay={true} style={styleLottieAnim} />
            <Text type="subtitle">
              {objectURL.e} invited you to his company.
            </Text>
            <Text type="text">
              If you have an account, log in to accept the invitation, and if not, create an account on MeantTo.
            </Text>

            <div className="div-container-buttons mt-4">
              <button className='btn btn-primary' onClick={() => handlerShowFormLogin(2)}>
                Sign in
              </button>

              <button className='btn btn-secundary' onClick={() => handlerShowFormLogin(3)}>
                Create an account
              </button>
            </div>
          </Fade>
        </div>
      )}

      {typeView === 2 && (
        <Fade duration={600} opposite>
          <div className="pt-5 ml-2 mr-2">
            <FormLogin url={urlEncode} />
            <button className='btn back-button mt-2' onClick={() => handlerShowFormLogin(1)}>
              Back
            </button>
          </div>
        </Fade>
      )}

      {typeView === 3 && (
        <Fade duration={600} opposite>
          <div className="pt-5 ml-2 mr-2">
            <FormSignUp viewFromLogin={false} />
            <button className='btn back-button mt-2' onClick={() => handlerShowFormLogin(1)}>
              Back
            </button>
          </div>
        </Fade>
      )}
    </div>
  )
}

export default CollaboratorWithoutAccount;
