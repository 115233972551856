import React, { memo } from 'react';
import '../../../styles/style.scss';
import '../../../styles/compranyProfile/style.scss';
import { useSelector } from 'react-redux';
import { Fade } from "react-reveal";
import Information from './information/information';
import '../../../share-components/modal.scss';
import Text from '../../../share-components/text/text';

function CompanyProfile() {
  const collaboratorCompanySelected = useSelector((state) => state.collaboratorCompanySelected.value); 
  return (
    <div>
      <div className="content-title">
        <Text type="title">
          Company profile
        </Text>

        <img src="./assets/logos/symbol/Simbolo/PNG/morado.png" alt="" />
      </div>
      <h1>
        {collaboratorCompanySelected.name}{' '}
        <Text type="small" inyectClass="text-muted unselectable">
          by {collaboratorCompanySelected.emailCompanyOwner}
        </Text>
      </h1>
      <div className="content-view">
        <div className="container-company-profile">
          <Fade duration={600} opposite>
            <Information />
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default memo(CompanyProfile);
