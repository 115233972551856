import { useState, useEffect } from 'react';

const useTheme = () => {
  const [userTheme, setUserTheme] = useState(() => {
    const storedTheme = localStorage.getItem('theme');
    return storedTheme || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
  });

  const toggleTheme = () => {
    const newTheme = userTheme === 'light' ? 'dark' : 'light';
    setUserTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    window.dispatchEvent(new Event('themeChange'));
  };

  const handleSystemThemeChange = (event) => {
    const newTheme = event.matches ? 'dark' : 'light';
    setUserTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const initialTheme = mediaQuery.matches ? 'dark' : 'light';
    handleSystemThemeChange({ matches: initialTheme === 'dark' }); // Simular el evento de cambio inicial

    mediaQuery.addEventListener('change', handleSystemThemeChange);

    return () => {
      mediaQuery.removeEventListener('change', handleSystemThemeChange);
    };
  }, []);

  return { userTheme, toggleTheme };
};

export default useTheme;
