import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@react-hook/media-query';
import './style.scss';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { setShowCompanyList, setHideCompanyList } from '../../../reducers/showCompanyList';
import { setShowViewCreateCompanyProfile } from '../../../reducers/viewCreateCompanyProfile';
import { setActiveCompanyProfile } from '../../../reducers/activeCompanyProfile';
import Text from '../text/text';

function ModalChangeCompany() {
  const history = useHistory();
  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');
  const showCompanyList = useSelector((state) => state.showCompanyList.value);
  const activeCompanyProfile = useSelector((state) => state.activeCompanyProfile.value);
  const statusUser = useSelector((state) => state.statusUser.value);
  const companyList = useSelector((state) => state.companyList.value);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const handlerSetShowCompanyList = () => {
    dispatch(setShowCompanyList());
  };  

  const handlerSetHideCompanyList = () => {
    dispatch(setHideCompanyList());
  };

  const handlerSetShowViewCreateCompanyProfile = () => {
    dispatch(setShowViewCreateCompanyProfile());
  };

  const handlerSetActiveCompanyProfile = (infoCompany) => {
    dispatch(setActiveCompanyProfile(infoCompany));
  };

  const [modalCompanyList, setModalCompanyList] = useState(false);

  const handlerShowCreateCompany = () => {
    setModalCompanyList(true);
    handlerSetShowCompanyList();
  };

  const handlerHideCreateCompany = () => {
    setModalCompanyList(false);
    handlerSetHideCompanyList();
  };

  useEffect(() => {
    if (showCompanyList.show === true) {
      handlerShowCreateCompany();
    }
    // eslint-disable-next-line
  }, [showCompanyList]);

  const handlerCreateANewCompany = () => {
    handlerHideCreateCompany();
    handlerSetShowViewCreateCompanyProfile();
  };

  const handlerChangeCompanyProfile = async (id) => {
    try {
      const selectCompany = companyList.companyList.filter((x) => x.id === id)[0];
      handlerSetActiveCompanyProfile(selectCompany);
      handlerHideCreateCompany();

      if (selectCompany.infoCompanyGenerate === false) {
        history.push('/form-ai');
      } else {
        history.push('/');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      open={modalCompanyList}
      showCloseIcon={false}
      blockScroll={true}
      onClose={() => handlerHideCreateCompany()}
      classNames={{
        overlay: 'customOverlay',
        modal: isMobileDevice ? 'fullscreen-modal' : 'customModal',
      }}
      center
    >
      <div className="modal-body modal-sm">
        <div className="modal-content">
          <div className='d-flex flex-column' style={{ height: '90%' }}>
            <h3 className="w-100 text-center">
              Select company profile
            </h3>

            {companyList.companyList.length > 0 ? (
              <div className="container-list mt-3 mb-1">
                {companyList.companyList.map((companyItem, index, array) => (
                  <button
                    key={companyItem.id}
                    className={`item-list w-100 ${index === 0 ? 'firts-child' : ''} ${index === array.length - 1 ? 'last-child' : ''} ${array.length === 1 ? 'only-child' : ''} ${index !== array.length - 1 ? 'column-divider' : ''}`}
                    onClick={() => handlerChangeCompanyProfile(companyItem.id)}
                  >
                    <div
                      className="w-100 btn-company-profile"
                    >
                      <span>
                        {companyItem.name}
                      </span>  
                      {activeCompanyProfile.id === companyItem.id && (
                        <CheckRoundedIcon className="text-success" />
                      )}
                    </div>  
                  </button>
                ))}
            </div>
            ) : (
              <Text type={"body"} inyectClass={"text-muted"}>
                For companies to appear, you must have received an invitation
              </Text>
            )}
          </div>
        </div>
        <div className="action-btn-form">
          {companyList.companyList.length < statusUser.maxCompanyProfiles ? (
            <>
              <button
                className="two-btn-modal"
                onClick={() => handlerHideCreateCompany()}>
                  Close
              </button>
              <button
                className="two-btn-modal"
                onClick={() => handlerCreateANewCompany()}>
                  Create company
              </button>
            </>
          ) : (
            <button
              className="one-btn-modal"
              onClick={() => handlerHideCreateCompany()}>
                Close
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ModalChangeCompany;
