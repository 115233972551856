import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Fade } from "react-reveal";
import { SyncLoader } from 'react-spinners';
import './login.css';
import signUp from '../services/signup/signup';
import { optionsOffer } from '../services/values';
import Text from '../share-components/text/text';
import Password from '../share-components/inputs/password/password';

function FormSignUp({ viewFromLogin }) {
  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
  const lengthRegex = /.{6,}/;
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const numberRegex = /[0-9]/;

  const listServicePlanFiltered = viewFromLogin ? optionsOffer.filter((x) => x.viewFromLogin === true) : optionsOffer;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [allowTerms, setAllowTerms] = useState(false);
  const [stepSignUp, setStepSignUp] = useState(1);
  const [optionOffer, setOptionOffer] = useState(viewFromLogin ?
    {
      id: 3,
      name: 'Premium Tier',
      uuid: 'd370579d-dbec-4fd6-9c26-d219fe00da1d'
    } : {
      id: 4,
      name: 'Collaborator Tier',
      uuid: '4253ea2a-5b75-45d4-a039-9247ca6e7918'
    }
  );

  const [userCreatedSuccessfully, setUserCreatedSuccessfully] = useState(false);
  const [creatingUser, setCreatingUser] = useState(false);
  const [msmRequest, setMsmRequest] = useState('');  
  
  const onSubmit = async (event) => {
    setCreatingUser(true);
    setMsmRequest("");
    event.preventDefault();
  
    if (email !== '' && password !== '') {
      const { success, msm } = await signUp({
        email: email,
        password: password,
        allowTerms: allowTerms,
        optionOffer: optionOffer
      });
  
      if (success) {
        setCreatingUser(false);
        setUserCreatedSuccessfully(true);

        setEmail("");
        setPassword("");
        setAllowTerms(false);
      } else {
        setMsmRequest(msm);
        setCreatingUser(false);
        setUserCreatedSuccessfully(false);
      }
    } else {
      setCreatingUser(false);
      setUserCreatedSuccessfully(false);  
      setMsmRequest("Email and Password inputs are empty");
    }
  };

  const handlePasswordChange = (newPassword) => {
    setPassword(newPassword);
  };

  const selectOptionOffer = (e) => {
    const { id, name, uuid } = e;

    setOptionOffer({
      id: id,
      name: name,
      uuid: uuid
    });
  };

  const optionOfferToForm = () => {
    setStepSignUp(2);
  };

  const formToOptionOffer = () => {
    setStepSignUp(1);
  };

  return (
    <>
      {stepSignUp === 1 && (
        <div className="signup-option-offer-container d-flex flex-column">
          {listServicePlanFiltered.map((card) => (
            <button
              type='button'
              id={card.id}
              key={card.id}
              className={`card-option-offer mb-1 ${optionOffer.id === card.id ? 'offer-active' : ''}`}
              onClick={() => selectOptionOffer({id: card.id, name: card.name, uuid: card.uuid})}
            >
              <div className="d-flex flex-row">
                <div className="img-card d-flex align-items-center">
                  <img className="img-card-option-offer" src={card.imgUrl} alt={`${card.name} - Meantto`} />
                </div>
                <div className="text-card d-flex flex-column flex-start">
                  <Text type="subtitle" inyectClass='title-card-option-offer'>
                    {card.name}
                  </Text>
                  <span className="description-card-option-offer">{card.description}</span>
                </div>
              </div>
              <div className={`${optionOffer.id !== card.id ? 'list-options-closed' : 'list-options'}`}>
                <ul>
                  {card.optionList.map((option) => (
                    <li key={option.id}>
                      <span className="text-more-optiones">
                        {option.name}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </button>
          ))}
          <div>
            <button className="signin-btn mt-3 w-100" onClick={() => optionOfferToForm()}>
              Next
            </button>
          </div>
        </div>
      )}

      {stepSignUp === 2 && (
        <div className="signup-form-container">

          {creatingUser === true ? (
            <Fade duration={1000} opposite>
              <div className="d-flex flex-column justify-content-center align-items-center pt-5 pb-5">
                <div style={{ padding: '50px 0px 20px 0px' }}>
                  <SyncLoader color="#6001d0" />
                </div>
                <span>
                  Creating user
                </span>
              </div>
            </Fade>
          ) : (
            <>
              {userCreatedSuccessfully === true ? (
                <Fade duration={1000} opposite>
                  <div className="div-register-user-success d-flex flex-column">
                    <p>Thank you for registering!</p>
                    <br />
                    <p>
                      Your account has been successfully created. To access your account, please check your email for a verification link.
                      Click on the link provided in the email to verify your account.
                    </p>
                    <br />
                    <p>
                      Once you've verified your email, you'll be able to log in and start using our services.
                    </p>
                    <br />
                    <Link 
                      to={`/sign-in`}
                      className="signin-btn mt-3 w-100 text-align-center"
                    >
                      Sign in
                    </Link>
                  </div>
                </Fade>
              ) : (
                <>
                  <div className="w-100 mb-3">
                    <button className="signup-back-btn" onClick={() => formToOptionOffer()}>
                      Back
                    </button>
                  </div>
                  <small className="ml-2">
                    Password have contain:
                    <div className="list-requeriments">
                      <ul>
                        <li className={lengthRegex.test(password) && 'li_check' }>
                          Min 6 characters
                        </li>
                        <li className={uppercaseRegex.test(password) && 'li_check' }>
                          A letter mayus
                        </li>
                        <li className={lowercaseRegex.test(password) && 'li_check' }>
                          A letter minus
                        </li>
                        <li className={numberRegex.test(password) && 'li_check' }>
                          A numbers
                        </li>
                        <li className={specialCharRegex.test(password) && 'li_check' }>
                          A special character
                        </li>
                      </ul>
                    </div>
                  </small>
                  <form onSubmit={onSubmit} className="pt-3 d-flex flex-column">
                    <input
                      id="email"
                      name="email"
                      type="text"
                      placeholder="email"
                      autoComplete='false'
                      className={`input-field ${email === "" ? 'invalid-answer' : ''}`}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    <Password onPasswordChange={handlePasswordChange} isEmpty={password === ""} placeholder='password' />

                    <div className="w-100 d-flex flex-row gap-10">
                      <input
                        id="allowTerms"
                        name="allowTerms"
                        type="checkbox"
                        style={{ display: 'block' }}
                        onChange={(event) => setAllowTerms(!allowTerms)}
                      />
                      <label  htmlFor="allowTerms">
                        I have read and agreed to the <a href="/terms-conditions" style={{ color: 'blue' }}>Terms and conditions</a>
                      </label>
                    </div>
                    {allowTerms ? (
                      <button type="submit" className={`signin-btn mt-3`}>
                        Sign up
                      </button>
                    ) : (
                      <div className={`signin-btn mt-3 btn-sign-up-disabled`} style={{ textAlign: 'center' }} disabled={!allowTerms}>
                        Sign up
                      </div>
                    )}
                  </form>
                  <Text type="body" inyectClass='text-danger'>
                    {msmRequest}
                  </Text>
                </>
              )}
            </>
          )}

        </div>
      )}

      {(creatingUser === false && userCreatedSuccessfully === false) && (
        <div className="d-flex justify-content-center mt-2">
          <Link
            id="sign-in"
            to={`/sign-in`}
          >
            Do you already have an account?
          </Link>
        </div>
      )}
    </>
  )
};

FormSignUp.propTypes = {
  viewFromLogin: PropTypes.bool.isRequired
};

export default FormSignUp;
