import React, { useState } from 'react';
import '../../../../../styles/contentCalendar/components/galleryFiles/style.scss';
import PropTypes from 'prop-types';
import { Fade } from 'react-reveal';
import BurstModeIcon from '@mui/icons-material/BurstMode';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@react-hook/media-query';
import Text from '../../../../../share-components/text/text';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import ImageList from '../imageList/imageList';
import { resetImageSelectedForPost } from '../../../../../../reducers/imageSelectedForPost';
import UploadFiles from '../uploadFiles/uploadFiles';

function GalleryFiles({ SendSocket }) {
  const allowedVideoFormats = ['video/mp4'];
  const allowedImageFormats = ['image/jpeg', 'image/png'];
  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');
  const imageSelectedForPost = useSelector((state) => state.imageSelectedForPost.value);

  const dispatch = useDispatch();

  const handlerResetImageSelected = () => {
    dispatch(resetImageSelectedForPost());
  };

  const [modalGallery, setModalGallery] = useState(false);

  const handlerOpenModalGallery = () => {
    setModalGallery(true);
  };
 
  const closeModalUploadFiles = () => {
    setModalGallery(false);
    handlerResetImageSelected();
  };

  return (
    <>    
      <button
        type='button'
        className="btn-upload-filea-calendar-action mr-2"
        onClick={() => handlerOpenModalGallery()}
      >
        <BurstModeIcon />
        Gallery
      </button>

      <Modal
        open={modalGallery}
        showCloseIcon={false}
        blockScroll={true}
        onClose={() => closeModalUploadFiles()}
        classNames={{
          overlay: 'customOverlay',
          modal: isMobileDevice ? 'fullscreen-modal' : 'customModal',
        }}
        center
      >
        <div className="modal-body">
          <div className="modal-content">
            <div className='w-100 d-flex justify-content-between'>
              <Text type="subtitle">
                Gallery
              </Text>
            </div>

            {imageSelectedForPost.url ? (
              <Fade duration={1000} opposite>
                <div className='d-flex flex-1 align-items-center justify-content-center'>
                  <div className='container-image-xl'>
                    {allowedImageFormats.includes(imageSelectedForPost?.type) && (
                      <Fade duration={600} opposite>
                        <img src={imageSelectedForPost?.url} alt="Preview" style={{ maxWidth: 'auto', maxHeight: '100%' }} />
                      </Fade>
                    )}

                    {allowedVideoFormats.includes(imageSelectedForPost?.type) && (
                      <Fade duration={600} opposite>
                        <video controls width="100%" height="100%">
                          <source src={imageSelectedForPost?.url} type={imageSelectedForPost?.type} />
                          Tu navegador no soporta el tag de video.
                        </video>
                      </Fade>
                    )}

                    <button
                      className='btn bg-transparent btn-close-img-xl'
                      onClick={() => handlerResetImageSelected()}
                    >
                      <CloseIcon className='text-white'/>
                    </button>
                  </div>
                </div>
              </Fade>
            ) : (
              <div className='container-gallery'>
                <div className='float-button-gallery'>
                  <UploadFiles importButton={"gallery modal"} SendSocket={SendSocket} />
                </div>
                <div className='d-flex flex-1 overflow-scroll'>
                  <ImageList isCollapse={true} SendSocket={SendSocket} fromView={'modal-file'} />
                </div>
              </div>
            )}
          </div>

          <div className="action-btn-form">
            <Fade duration={1000} opposite>
              <button
                className="one-btn-modal"
                onClick={() => closeModalUploadFiles()}>
                Close
              </button>
            </Fade>
          </div>

        </div>
      </Modal>
    </>
  )
};

GalleryFiles.propTypes = {
  SendSocket: PropTypes.func.isRequired,
};

export default GalleryFiles;
