export const valuesForTooltip = [
  {
    name: "Brand Mission",
    description: "The mission statement outlines the organization's purpose and goals, providing a roadmap for achieving them.",
  },
  {
    name: "Brand Vision",
    description: "The vision statement describes the ideal state that the organization wants to achieve or aspires to go.",
  },
  {
    name: "Target Audience",
    description: "A target audience is the specific group most likely to be interested in your product or service. Defining this group ensures efficient use of marketing resources to reach potential customers effectively.",
  },
  {
    name: "Market Gap",
    description: "Identifying the market gap means recognizing an unmet need or demand within the market. It highlights the opportunity for your business to address a problem or fulfill a requirement that other competitors might be missing.",
  },
  {
    name: "Unique Value Proposition (UVP)",
    description: "A UVP (unique value proposition) highlights the distinct benefit of your product or service, emphasizing what sets it apart from competitors. It communicates how customers will benefit and addresses their specific needs.",
  },
  {
    name: "Ultimate Benefit",
    description: "The ultimate benefit communicates the core advantage that customers gain from your products or services. It's the primary solution to the problem your customers are trying to solve.",
  },
  {
    name: "Value Statement",
    description: "A value statement outlines a company's core beliefs, shaping its culture and operations.",
  },
];