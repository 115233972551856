import React from 'react';
import './style.css';

function Error404() {
  return (
    <div> error404 </div>
  )
}

export default Error404;
