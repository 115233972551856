/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const companyInfo = createSlice({
  name: 'companyInfo',
  initialState: {
    value: {
      user: "",
      email: "",
      answerForm: {},
      generatedContent: {},
      counterUseForm: 0,
      counterUseGenerateContent: {},
      logo: {}
    },
  },
  reducers: {
    setCompanyInfo: (state, action) => {
      state.value.user = action.payload.user;
      state.value.email = action.payload.email;
      state.value.answerForm = action.payload.answerForm;
      state.value.generatedContent = action.payload.generatedContent;
      state.value.counterUseForm = action.payload.counterUseForm;
      state.value.counterUseGenerateContent = action.payload.counterUseGenerateContent;
      state.value.logo = action.payload.logo;
    },
    setRestCounterGenerateContent: (state, action) => {
      state.value.counterUseGenerateContent[action.payload] = (parseInt(state.value.counterUseGenerateContent[action.payload]) - 1);
    },
    updateCounterUseGenerateContent: (state, action) => {
      state.value.counterUseGenerateContent = action.payload;
    },
    updateCounterUseForm: (state, action) => {
      state.value.counterUseForm = action.payload;
    },
    updateCompanyLogo: (state, action) => {
      state.value.logo = action.payload;
    },
    resetCompanyInfo: (state, action) => {
      state.value.user = "";
      state.value.email = "";
      state.value.answerForm = {};
      state.value.generatedContent = {};
      state.value.counterUseForm = 0;
      state.value.counterUseGenerateContent = {};
      state.value.logo = {};
    },
  }
});

export const {
  setCompanyInfo, setRestCounterGenerateContent, updateCounterUseGenerateContent, updateCounterUseForm, updateCompanyLogo, resetCompanyInfo
} = companyInfo.actions;

export default companyInfo.reducer;
