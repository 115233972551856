/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const generatedContent = createSlice({
  name: 'generatedContent',
  initialState: {
    value: {
      "Brand Name": "",
      "Brand Mission": "",
      "Brand Vision": "",
      "Value Statement": "",
      "Unique Value Proposition (UVP)": "",
      "Target Audience": "",
      "Market Gap": "",
      "Ultimate Benefit": "",
      "Taglines": [],
      "Color Palette": [],
      "Typography": [],
    },
  },
  reducers: {
    setNameCompany: (state, action) => {
      // Mapea las claves del JSON a las claves deseadas en el estado Redux
      state.value["Brand Name"] = action.payload["Brand Name"] || "";
    },
    setGeneratedContent: (state, action) => {
      // Mapea las claves del JSON a las claves deseadas en el estado Redux
      if (action.payload["Brand Name"]) {
        state.value["Brand Name"] = action.payload["Brand Name"] || "";
      }
      state.value["Brand Mission"] = action.payload["Brand Mission"] || "";
      state.value["Brand Vision"] = action.payload["Brand Vision"] || "";
      state.value["Value Statement"] = action.payload["Value Statement"] || "";
      state.value["Unique Value Proposition (UVP)"] = action.payload["Unique Value Proposition (UVP)"] || "";
      state.value["Target Audience"] = action.payload["Target Audience"] || "";
      state.value["Market Gap"] = action.payload["Market Gap"] || "";
      state.value["Ultimate Benefit"] = action.payload["Ultimate Benefit"] || "";
      state.value["Taglines"]  = action.payload["Taglines"] || "";
      state.value["Color Palette"] = action.payload["Color Palette"] || "";
      state.value["Typography"]  = action.payload["Typography"] || "";
    },
    setTextContentCompany: (state, action) => {
      state.value[action.payload.title] = action.payload.content
    },
    setUpdateDataFromUser: (state, action) => {
      // Mapea las claves del JSON a las claves deseadas en el estado Redux
      if (action.payload["Brand Name"]) {
        state.value.brandName = action.payload["Brand Name"] || "";
      }
      state.value["Brand Mission"] = action.payload["Brand Mission"] || "";
      state.value["Brand Vision"] = action.payload["Brand Vision"] || "";
      state.value["Value Statement"] = action.payload["Value Statement"] || "";
      state.value["Unique Value Proposition (UVP)"] = action.payload["Unique Value Proposition (UVP)"] || "";
      state.value["Target Audience"] = action.payload["Target Audience"] || "";
      state.value["Market Gap"] = action.payload["Market Gap"] || "";
      state.value["Ultimate Benefit"] = action.payload["Ultimate Benefit"] || "";
      state.value["Taglines"]  = action.payload["Taglines"] || "";
      state.value["Color Palette"] = action.payload["Color Palette"] || "";
      state.value["Typography"]  = action.payload["Typography"] || "";
    },
    setReGenerateContent: (state, action) => {
      state.value[action.payload.titleCard] = action.payload.content || "";
    },
    setNewPaletteColor: (state, action) => {
      state.value["Color Palette"] = action.payload["Color Palette"] || "";
    },
    setNewTypography: (state, action) => {
      state.value["Typography"]  = action.payload["Typography"] || "";
    },
  }
});

export const {
  setGeneratedContent, setTextContentCompany, setNameCompany, setUpdateDataFromUser, setNewPaletteColor, setNewTypography, setReGenerateContent
} = generatedContent.actions;

export default generatedContent.reducer;