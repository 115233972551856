/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const loadingCollaboratorMode = createSlice({
  name: 'loadingCollaboratorMode',
  initialState: {
    value: {
      loading: false
    },
  },
  reducers: {
    setLoadingCollaboratorMode: (state, action) => {
      state.value.collaboratorMode = true;
    },
    setNotLoadingCollaboratorMode: (state, action) => {
      state.value.collaboratorMode = false;
    },
  }
});

export const {
  setLoadingCollaboratorMode, setNotLoadingCollaboratorMode
} = loadingCollaboratorMode.actions;

export default loadingCollaboratorMode.reducer;
