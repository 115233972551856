import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useMediaQuery } from '@react-hook/media-query';
import { Tooltip } from 'react-tooltip';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { Fade } from "react-reveal";
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import { putRequestMeantto } from '../../../../services/requestLib';
import { setNewPaletteColor, setNewTypography, setUpdateDataFromUser } from '../../../../../reducers/generatedContent';
import { generateNewColorPalette, generateNewTaglines, generateNewTypography } from '../../../../services/generatePrompt2';
import { generateContentMeantto } from '../../../../services/generatePrompt';
import { setShowAlert } from '../../../../../reducers/showAlert';
import { setRestCounterGenerateContent } from '../../../../../reducers/companyInfo';
import Paper from '../../../../share-components/paper/paper';
import Loading from '../../../../share-components/lottie/loadingInfoCard/loading';
import GeneratingContentCard from '../../../../share-components/lottie/generatingContentCard/generatingContentCard';
import UploadFiles from '../../contentCalendar/components/uploadFiles/uploadFiles';
import Text from '../../../../share-components/text/text';

function Information({ SendSocket }) {
  const esPantallaMediana = useMediaQuery('(min-width: 1px) and (max-width: 991px)');
  const activeCollaboratorMode = useSelector((state) => state.activeCollaboratorMode.value);
  const generatedContentBrand = useSelector((state) => state.generatedContent.value);
  const companyInfo = useSelector((state) => state.companyInfo.value);
  const userData = useSelector((state) => state.userData.value);
  const answersForm = useSelector((state) => state.answersForm.value);
  const activeCompanyProfile = useSelector((state) => state.activeCompanyProfile.value);

  const dispatch = useDispatch();

  const handlerSetUpdateDataFromUser = (newInfo) => {
    dispatch(setUpdateDataFromUser(newInfo));
  };

  const handlerSetNewPaletteColor = (newInfo) => {
    dispatch(setNewPaletteColor(newInfo));
  };
  
  const handlerSetNewTypography = (newInfo) => {
    dispatch(setNewTypography(newInfo));
  };

  const handlerSetShowAlert = (infoAlert) => {
    dispatch(setShowAlert(infoAlert));
  };

  const handlerSetRestCounterGenerateContent = (titleCard) => {
    dispatch(setRestCounterGenerateContent(titleCard));
  };

  const [tempBrandKit, setTempBrandKit] = useState({...generatedContentBrand});
  const [updatingBrandKit, setUpdatingBrandKit] = useState(false);

  const openAlert = (typeAlert, textAlert) => {
    const object = {
      typeAlert: typeAlert,
      textAlert: textAlert
    };

    handlerSetShowAlert(object);
  };

  useEffect(() => {
    setTempBrandKit({...generatedContentBrand});
  }, [generatedContentBrand]);

  const handlerSendMsm = (newData) => {
    SendSocket({
      action: 'send-message',
      data: {
        id: activeCompanyProfile.id,
        user: userData.user,
        message: {
          type: 'UPDATE',
          table: 'company-info',
          sendFromAdminView: true,
          sendFromCollaboratorView: false,
          companyOwner: userData.user,
          idCompanyOwner: activeCompanyProfile.id,
          userSendUpdate: userData.user,
          isAdmin: !activeCollaboratorMode.collaboratorMode,
          data: {
            user: companyInfo.user,
            email: companyInfo.email,
            answerForm: companyInfo.answerForm,
            generatedContent: newData,
            counterUseForm: companyInfo.counterUseForm,
            counterUseGenerateContent: companyInfo.counterUseGenerateContent,
            logo: companyInfo.logo,
          }
        }
      }
    });
  };

  const handlerUpdateCounterGenerateContent = (titleCard) => {
    const newJson = {
      ...companyInfo.counterUseGenerateContent,
      [titleCard]: companyInfo.counterUseGenerateContent[titleCard] - 1
    };
    return newJson;
  };

  const updateCompanyInfo = async (oldData, newData, updateCounter, updateCounterForm) => {
    setUpdatingBrandKit(true);
    handlerSetUpdateDataFromUser(newData);

    const dataUser = {
      email: userData.email,
      password: userData.password,
      token: userData.token,
    };
    
    const sendData = {
      id: activeCompanyProfile.id,
      generatedContent: newData,
      counterUseGenerateContent: updateCounter,
      counterUseForm: updateCounterForm
    };

    const responseUpdateCompanyInfo = await putRequestMeantto('company-info', dataUser, sendData);
    if (responseUpdateCompanyInfo.status === 200){
      if (responseUpdateCompanyInfo.data.status === true) {
        setUpdatingBrandKit(false);
        handlerSetUpdateDataFromUser(newData);

        handlerSendMsm(newData);

        openAlert("success", "Success");
      } else {
        handlerSetUpdateDataFromUser(oldData);
        openAlert("danger", "Error, try again");
      }
    } else {
      handlerSetUpdateDataFromUser(oldData);
      openAlert("danger", "Error, try again");
    }
  };

  /* Color palette */
  const colorPaletteList = generatedContentBrand['Color Palette'];

  const handlerSelectColorPalette = (e) => {
    const titleCard = 'Color Palette';
    openAlert("loading", "Making changes");
    const updatedColorPaletteList = colorPaletteList.map(item => {
      if (item.id === e) {
        return {
          ...item,
          active: true
        };
      } else {
        return {
          ...item,
          active: false
        };
      }
    });

    let companyInfo_UpdateColorPalette = tempBrandKit;
    companyInfo_UpdateColorPalette[titleCard] = updatedColorPaletteList;

    updateCompanyInfo(tempBrandKit, companyInfo_UpdateColorPalette, companyInfo.counterUseGenerateContent, companyInfo.counterUseForm);
  };

  /* typography */
  const typographyList = generatedContentBrand['Typography'];

  const handlerSelectTypography = (e) => {
    const titleCard = 'Typography';
    openAlert("loading", "Making changes");
    const updatedTypographyList = typographyList.map(item => {
      if (item.id === e) {
        return {
          ...item,
          active: true
        };
      } else {
        return {
          ...item,
          active: false
        };
      }
    });

    let companyInfo_UpdateTypography = tempBrandKit;
    companyInfo_UpdateTypography[titleCard] = updatedTypographyList;

    updateCompanyInfo(tempBrandKit, companyInfo_UpdateTypography, companyInfo.counterUseGenerateContent, companyInfo.counterUseForm);
  };

  const [generatingNewPaletteColor, setGeneratingNewPaletteColor] = useState(false);

  const returnAnswersFormUpdated = (title, newDataGenerated) => {
    let companyInfo_UpdateNewData = tempBrandKit;
    companyInfo_UpdateNewData[title] = newDataGenerated;

    return companyInfo_UpdateNewData;
  };

  const reGenerateANewPaletteColor = async () => {
    setGeneratingNewPaletteColor(true);
    const generatePromptForNewPaletteColor = generateNewColorPalette(answersForm);
    const responseMeanttoGenerateNewPaletteColor = await generateContentMeantto(userData.email, activeCompanyProfile.id, "brand information",generatePromptForNewPaletteColor);

    if (responseMeanttoGenerateNewPaletteColor.status === 200) {
      const data = responseMeanttoGenerateNewPaletteColor.data;

      if (data.body !== "") {
        const replaceQuotes = JSON.parse(data.body);
        handlerSetNewPaletteColor(replaceQuotes);

        const newData = returnAnswersFormUpdated('Color Palette', replaceQuotes['Color Palette']);
        const updateCounter = handlerUpdateCounterGenerateContent('Color Palette');
        updateCompanyInfo(tempBrandKit, newData, updateCounter, companyInfo.counterUseForm);
        handlerSetRestCounterGenerateContent('Color Palette');

        handlerSendMsm();

        setGeneratingNewPaletteColor(false);
        openAlert("success", "Success");
      } else {
        setGeneratingNewPaletteColor(false);
        openAlert("danger", "Error, try again");
      }
    } else {
      openAlert("danger", "Error, try again");
    }
  };
  
  const [generatingNewTypography, setGeneratingNewTypography] = useState(false);

  const reGenerateANewTypography = async () => {
    setGeneratingNewTypography(true);
    const generatePromptForNewTypography = generateNewTypography(answersForm);
    const responseMeanttoGenerateNewTypography = await generateContentMeantto(userData.email, activeCompanyProfile.id, "brand information",generatePromptForNewTypography);

    if (responseMeanttoGenerateNewTypography.status === 200) {
      const data = responseMeanttoGenerateNewTypography.data;

      if (data.body !== "") {
        const replaceQuotes = JSON.parse(data.body);
        handlerSetNewTypography(replaceQuotes);

        const newData = returnAnswersFormUpdated('Typography', replaceQuotes['Typography']);
        const updateCounter = handlerUpdateCounterGenerateContent('Typography');
        updateCompanyInfo(tempBrandKit, newData, updateCounter, companyInfo.counterUseForm);
        handlerSetRestCounterGenerateContent('Typography');

        handlerSendMsm();

        setGeneratingNewTypography(false);
        openAlert("success", "Success");
      } else {
        setGeneratingNewTypography(false);
        openAlert("danger", "Error, try again");
      }
    } else {
      openAlert("danger", "Error, try again");
    }
  };

  /* Taglines */
  const taglinesList = generatedContentBrand['Taglines'];

  const handlerSelectTaglines = (e) => {
    const titleCard = 'Taglines'; 
    openAlert("loading", "Making changes");
    const updatedTaglinesList = taglinesList.map(item => {
      if (item.id === e) {
        return {
          ...item,
          active: true
        };
      } else {
        return {
          ...item,
          active: false
        };
      }
    });

    let companyInfo_UpdateTaglines = tempBrandKit;
    companyInfo_UpdateTaglines[titleCard] = updatedTaglinesList;

    updateCompanyInfo(tempBrandKit, companyInfo_UpdateTaglines, companyInfo.counterUseGenerateContent, companyInfo.counterUseForm);
  };

  const [generatingNewTagline, setGeneratingNewTagline] = useState(false);

  const reGenerateANewTagline = async () => {
    setGeneratingNewTagline(true);
    const generatePromptForNewTaglines = generateNewTaglines(answersForm);
    const responseMeanttoGenerateNewTaglines = await generateContentMeantto(userData.email, activeCompanyProfile.id, "brand information",generatePromptForNewTaglines);

    if (responseMeanttoGenerateNewTaglines.status === 200) {
      const data = responseMeanttoGenerateNewTaglines.data;

      if (data.body !== "") {
        const replaceQuotes = JSON.parse(data.body);
        handlerSetNewTypography(replaceQuotes);

        const newData = returnAnswersFormUpdated('Taglines', replaceQuotes['Taglines']);
        const updateCounter = handlerUpdateCounterGenerateContent('Taglines');
        updateCompanyInfo(tempBrandKit, newData, updateCounter, companyInfo.counterUseForm);
        handlerSetRestCounterGenerateContent('Taglines');

        handlerSendMsm();

        setGeneratingNewTagline(false);
        openAlert("success", "Success");
      } else {
        setGeneratingNewTagline(false);
        openAlert("danger", "Error, try again");
      }
    } else {
      openAlert("danger", "Error, try again");
    }
  };

  const calculateLuminance = (hex) => {
    // Convierte el valor hexadecimal a los componentes RGB
    const r = parseInt(hex.slice(1, 3), 16) / 255;
    const g = parseInt(hex.slice(3, 5), 16) / 255;
    const b = parseInt(hex.slice(5, 7), 16) / 255;
    // Calcula el valor de luminancia
    return 0.299 * r + 0.587 * g + 0.114 * b;
  };

  const copyTextToClipboard = (text) => {
    const input = document.createElement('textarea');
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);

    openAlert("copy", "Copy to clipboard");
  };

  return (
    <div className="container-brand">
      <Paper type="card-content" inyectClass="card-brand-profile">
        <div className="title-card">
          <span
            className="title-card-company"
          >
            Logo
            <InfoOutlinedIcon
              className="text-muted btn-tooltip"
              data-tooltip-id="tooltip-logo"
              data-tooltip-content="logo"
            />
          </span>
          <UploadFiles importButton={"logo"} SendSocket={SendSocket} />
        </div>
        <div className="content-brand-card logo-container">
          {companyInfo.logo.url !== undefined ? (
            <Fade duration={1000} opposite>
              <div className="selected-container">
                <div className="logo-div">
                  <img
                    src={companyInfo.logo.url}
                    alt="Company logo - Meantto"
                    style={{ borderRadius: '6px' }}
                  />
                </div>
              </div>
            </Fade>
          ) : (
            <div className="flex-1 flex-column">
              <Text type="text" inyectClass="text-muted unselectable">
                Upload your logo
              </Text>
            </div>
          )}
        </div>
      </Paper>

      <Paper type="card-content" inyectClass="card-brand-profile">
        <div className="title-card">
          <span
            className="title-card-company"
          >
            Taglines
            <InfoOutlinedIcon
              className="text-muted btn-tooltip"
              data-tooltip-id="tooltip-taglines"
              data-tooltip-html={`Taglines are catchy and memorable phrases used in marketing to convey the essence of your brand or a specific campaign. They are often short and impactful, leaving a lasting impression on your audience.<br/><br/>Remaining attempts: ${companyInfo?.counterUseGenerateContent?.['Taglines']}`}
            />
          </span>
          {!generatingNewTagline && (
            <>
              {companyInfo?.counterUseGenerateContent?.['Taglines'] > 0 && (
                <button
                  type="button"
                  onClick={() => reGenerateANewTagline()}
                >
                  <CachedRoundedIcon className="icon-primary"/>
                </button>
              )}
            </>
          )}
        </div>
        <div className="content-brand-card taglines-container">
          {taglinesList.length > 0 ? (
            <>
              {generatingNewTagline ? (
                <GeneratingContentCard text="taglines" />
              ) : (
                <Fade duration={1000} opposite>
                  <div className="selected-container">
                    {taglinesList.filter((x) => x.active === true)?.map((typoItem) => (
                      <span key={typoItem.id} className="selected-typography">
                        {typoItem.content}
                      </span>
                    ))}
                  </div>
                  <div className="options-container tagline-options-container">
                    {taglinesList.map((typoItem) => (
                      <div
                        key={typoItem.id}
                        className="option-tagline"
                        onClick={() => {
                          if (updatingBrandKit === false) {
                            handlerSelectTaglines(typoItem.id)
                          }
                        }}
                      >
                        <div className={`indicator-option indicator-option-tagline ${typoItem.active === true && 'brand-selected'}`} />
                        <span>
                          {typoItem.content}
                        </span>
                      </div>
                    ))}
                  </div>
                </Fade>
              )}
            </>
          ) : (
            <Loading />
          )}
        </div>
      </Paper>

      <Paper type="card-content" inyectClass="card-brand-profile">
        <div className="title-card">
          <span
            className="title-card-company"
          >
            Color palette
            <InfoOutlinedIcon
              className="text-muted btn-tooltip"
              data-tooltip-id="tooltip-color-palette"
              data-tooltip-html={`Your color palette consists of the specific colors chosen to represent your brand. Colors evoke emotions and can influence how people perceive your brand, making the selection of colors a crucial branding decision.<br/><br/>Remaining attempts: ${companyInfo?.counterUseGenerateContent?.['Color Palette']}`}
            />
          </span>
          {!generatingNewPaletteColor && (
            <>
              {companyInfo?.counterUseGenerateContent?.['Color Palette'] > 0 && (
                <button
                  type="button"
                  onClick={() => reGenerateANewPaletteColor()}
                >
                  <CachedRoundedIcon className="icon-primary"/>
                </button>
              )}
            </>
          )}
        </div>
        <div className="content-brand-card palette-color-container">
          {colorPaletteList.length > 0 ? (
            <>
              {generatingNewPaletteColor ? (
                <GeneratingContentCard text="palette color" />
              ) : (
                <Fade duration={1000} opposite>
                  <div className="selected-container">
                    <div className="color-div">
                      <div className="color-list">
                        {colorPaletteList.filter((x) => x.active === true).length > 0 && (
                          <>
                            {colorPaletteList.filter((x) => x.active === true)[0].content.map((colorItem) => (
                              <div
                                key={colorItem}
                                className="color-item-selected unselectable"
                                style={{ backgroundColor: colorItem, cursor: 'pointer' }}
                                onClick={() => copyTextToClipboard(colorItem)}
                              >
                                <span style={{ color: calculateLuminance(colorItem) > 0.5 ? "#000" : "#fff", }}>
                                  {colorItem}
                                </span>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="options-container">
                    {colorPaletteList.map((palette) => (
                      <div
                        key={palette.id}
                        className="option-palette-color"
                        onClick={() => {
                          if (updatingBrandKit === false) {
                            handlerSelectColorPalette(palette.id)
                          }
                        }}
                      >
                        <div className="color-list">
                          {palette.content.map((colorItem) => (
                            <div
                              key={colorItem}
                              className="color-item"
                              style={{ backgroundColor: colorItem }}
                            />
                          ))}
                        </div>
                        <div className={`indicator-option ${palette.active === true && 'brand-selected'}`} />
                      </div>  
                    ))}
                  </div>
                </Fade>
              )}
            </>
          ) : (
            <Loading />
          )}
        </div>
      </Paper>

      <Paper type="card-content" inyectClass="card-brand-profile">
        <div className="title-card">
          <span
            className="title-card-company"
          >
            Typography
            <InfoOutlinedIcon
              className="text-muted btn-tooltip"
              data-tooltip-id="tooltip-typography"
              data-tooltip-html={`Taglines.<br/><br/>Remaining attempts: ${companyInfo?.counterUseGenerateContent?.['Typography']}`}
            />
          </span>
          {!generatingNewTypography && (
            <>
              {companyInfo?.counterUseGenerateContent?.['Typography'] > 0 && (
                <button
                  type="button"
                  onClick={() => reGenerateANewTypography()}
                >
                  <CachedRoundedIcon className="icon-primary"/>
                </button>
              )}
            </>
          )}
        </div>
        <div className="content-brand-card typography-container">
          {typographyList.length > 0 ? (
            <>
              {generatingNewTypography ? (
                <GeneratingContentCard text="typography" />
              ) : (
                <Fade duration={1000} opposite>
                  <div className="selected-container">
                    {typographyList.filter((x) => x.active === true)?.map((typoItem) => (
                      <span key={typoItem.id} className="selected-typography">
                        {typoItem.content}
                      </span>
                    ))}
                  </div>
                  <div className="options-container">
                    {typographyList.map((typoItem) => (
                      <div
                        key={typoItem.id}
                        className="option-typography"
                        onClick={() => {
                          if (updatingBrandKit === false) {
                            handlerSelectTypography(typoItem.id)
                          }
                        }}
                      >
                        <span>
                          {typoItem.content}
                        </span>
                        <div className={`indicator-option ${typoItem.active === true && 'brand-selected'}`} />
                      </div>
                    ))}
                  </div>
                </Fade>
              )}
            </>
          ) : (
            <Loading />
          )}
        </div>
      </Paper>

      <Tooltip id="tooltip-logo" place={esPantallaMediana ? 'bottom-start' : 'right'} className="custom-tooltip"/>
      <Tooltip id="tooltip-taglines" place={esPantallaMediana ? 'bottom-start' : 'right'} className="custom-tooltip"/>
      <Tooltip id="tooltip-color-palette" place={esPantallaMediana ? 'bottom-start' : 'right'} className="custom-tooltip"/>
      <Tooltip id="tooltip-typography" place={esPantallaMediana ? 'bottom-start' : 'right'} className="custom-tooltip"/>
    </div>
  )
};

Information.propTypes = {
  SendSocket: PropTypes.func.isRequired,
};

export default memo(Information);
