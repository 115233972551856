import React, { memo } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { Fade } from "react-reveal";
import { setShowAlert } from '../../../../../reducers/showAlert';
import Paper from '../../../../share-components/paper/paper';
import Loading from '../../../../share-components/lottie/loadingInfoCard/loading';
// import UploadFiles from '../../contentCalendar/components/uploadFiles/uploadFiles';
import Text from '../../../../share-components/text/text';

function Information() {
  const companyInfo = useSelector((state) => state.companyInfoAsCollaborator.value);
  const generatedContentBrand = useSelector((state) => state.generatedContentAsCollaborator.value);

  const dispatch = useDispatch();

  const handlerSetShowAlert = (infoAlert) => {
    dispatch(setShowAlert(infoAlert));
  };

  const openAlert = (typeAlert, textAlert) => {
    const object = {
      typeAlert: typeAlert,
      textAlert: textAlert
    };

    handlerSetShowAlert(object);
  };

  /* Taglines */
  const taglinesList = generatedContentBrand['Taglines'];

  /* Color palette */
  const colorPaletteList = generatedContentBrand['Color Palette'];

  /* typography */
  const typographyList = generatedContentBrand['Typography'];

  const calculateLuminance = (hex) => {
    // Convierte el valor hexadecimal a los componentes RGB
    const r = parseInt(hex.slice(1, 3), 16) / 255;
    const g = parseInt(hex.slice(3, 5), 16) / 255;
    const b = parseInt(hex.slice(5, 7), 16) / 255;
    // Calcula el valor de luminancia
    return 0.299 * r + 0.587 * g + 0.114 * b;
  };

  const copyTextToClipboard = (text) => {
    const input = document.createElement('textarea');
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);

    openAlert("copy", "Copy to clipboard");
  };

  return (
    <div className="container-brand">
      <Paper type="card-content" inyectClass="card-brand-profile">
        <div className="title-card">
          <span
            className="title-card-company"
          >
            Logo
            <InfoOutlinedIcon
              className="text-muted btn-tooltip"
              data-tooltip-id="tooltip-logo"
              data-tooltip-content="logo"
            />
          </span>
          {/*
            <UploadFiles importButton={"logo"}/>
          */}
        </div>
        <div className="content-brand-card logo-container">
          {companyInfo.logo.url !== undefined ? (
            <Fade duration={1000} opposite>
              <div className="selected-container">
                <div className="logo-div">
                  <img
                    src={companyInfo.logo.url}
                    alt="Company logo - Meantto"
                    style={{ borderRadius: '6px' }}
                  />
                </div>
              </div>
            </Fade>
          ) : (
            <div className="flex-1 flex-column">
              <Text type="text" inyectClass="text-muted">
                Upload your logo
              </Text>
            </div>
          )}
        </div>
      </Paper>

      <Paper type="card-content" inyectClass="card-brand-profile">
        <div className="title-card">
          <span
            className="title-card-company"
          >
            Taglines
            <InfoOutlinedIcon
              className="text-muted btn-tooltip"
              data-tooltip-id="tooltip-taglines"
              data-tooltip-html={`Taglines are catchy and memorable phrases used in marketing to convey the essence of your brand or a specific campaign. They are often short and impactful, leaving a lasting impression on your audience.<br/><br/>Remaining attempts: ${companyInfo?.counterUseGenerateContent?.['Taglines']}`}
            />
          </span>
        </div>
        <div className="content-brand-card taglines-container">
          {taglinesList.length > 0 ? (
            <Fade duration={1000} opposite>
              <div className="selected-container">
                {taglinesList.filter((x) => x.active === true)?.map((typoItem) => (
                  <span key={typoItem.id} className="selected-typography">
                    {typoItem.content}
                  </span>
                ))}
              </div>
              <div className="options-container tagline-options-container">
                {taglinesList.map((typoItem) => (
                  <div
                    key={typoItem.id}
                    className="option-tagline unselectable"
                  >
                    <div className={`indicator-option indicator-option-tagline ${typoItem.active === true && 'brand-selected'}`} />
                    <span>
                      {typoItem.content}
                    </span>
                  </div>
                ))}
              </div>
            </Fade>
          ) : (
            <Loading />
          )}
        </div>
      </Paper>

      <Paper type="card-content" inyectClass="card-brand-profile">
        <div className="title-card">
          <span
            className="title-card-company"
          >
            Color palette
            <InfoOutlinedIcon
              className="text-muted btn-tooltip"
              data-tooltip-id="tooltip-color-palette"
              data-tooltip-html={`Your color palette consists of the specific colors chosen to represent your brand. Colors evoke emotions and can influence how people perceive your brand, making the selection of colors a crucial branding decision.<br/><br/>Remaining attempts: ${companyInfo?.counterUseGenerateContent?.['Color Palette']}`}
            />
          </span>
        </div>
        <div className="content-brand-card palette-color-container">
          {colorPaletteList.length > 0 ? (
            <Fade duration={1000} opposite>
              <div className="selected-container unselectable">
                <div className="color-div">
                  <div className="color-list">
                    {colorPaletteList.filter((x) => x.active === true).length > 0 && (
                      <>
                        {colorPaletteList.filter((x) => x.active === true)[0].content.map((colorItem) => (
                          <div
                            key={colorItem}
                            className="color-item-selected unselectable"
                            style={{ backgroundColor: colorItem, cursor: 'pointer' }}
                            onClick={() => copyTextToClipboard(colorItem)}
                          >
                            <span style={{ color: calculateLuminance(colorItem) > 0.5 ? "#000" : "#fff", }}>
                              {colorItem}
                            </span>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="options-container">
                {colorPaletteList.map((palette) => (
                  <div
                    key={palette.id}
                    className="option-palette-color unselectable"
                  >
                    <div className="color-list">
                      {palette.content.map((colorItem) => (
                        <div
                          key={colorItem}
                          className="color-item"
                          style={{ backgroundColor: colorItem }}
                        />
                      ))}
                    </div>
                    <div className={`indicator-option ${palette.active === true && 'brand-selected'}`} />
                  </div>  
                ))}
              </div>
            </Fade>
          ) : (
            <Loading />
          )}
        </div>
      </Paper>

      <Paper type="card-content" inyectClass="card-brand-profile">
        <div className="title-card">
          <span
            className="title-card-company"
          >
            Typography
            <InfoOutlinedIcon
              className="text-muted btn-tooltip"
              data-tooltip-id="tooltip-typography"
              data-tooltip-html={`Taglines.<br/><br/>Remaining attempts: ${companyInfo?.counterUseGenerateContent?.['Typography']}`}
            />
          </span>
        </div>
        <div className="content-brand-card typography-container">
          {typographyList.length > 0 ? (
            <Fade duration={1000} opposite>
              <div className="selected-container">
                {typographyList.filter((x) => x.active === true)?.map((typoItem) => (
                  <span key={typoItem.id} className="selected-typography">
                    {typoItem.content}
                  </span>
                ))}
              </div>
              <div className="options-container">
                {typographyList.map((typoItem) => (
                  <div
                    key={typoItem.id}
                    className="option-typography unselectable"
                  >
                    <span>
                      {typoItem.content}
                    </span>
                    <div className={`indicator-option ${typoItem.active === true && 'brand-selected'}`} />
                  </div>
                ))}
              </div>
            </Fade>
          ) : (
            <Loading />
          )}
        </div>
      </Paper>
    </div>
)
};

export default memo(Information);
