import React, { useEffect, useState } from 'react';
import './style.scss';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@react-hook/media-query';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import GroupIcon from '@mui/icons-material/Group';
import ShareIcon from '@mui/icons-material/Share';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { setHideModal } from '../../../reducers/settingsCompany';
import Text from '../../share-components/text/text';
import WidgetManageFiles from './components/widgetManageFiles/widgetManageFiles';
import SocialNetworks from './components/socialNetworks/socialNetworks';
import GuestUsers from './components/guestUsers/guestUsers';
import UseCheckIsFreeUser from '../../services/checkIsFreeUser/useCheckIsFreeUser';
import { IsCollaboratorPlan } from '../../services/isCollaboratorPlan/IsCollaboratorPlan';

function SettingsCompany() {
  const isMobileDevice = useMediaQuery('(min-width: 1px) and (max-width: 991px)');
  const userData = useSelector((state) => state.userData.value);
  const settingsCompany = useSelector((state) => state.settingsCompany.value);
  const activeCompanyProfile = useSelector((state) => state.activeCompanyProfile.value);
  const companyInfo = useSelector((state) => state.companyInfo.value);
  const isFreeUserPlan = UseCheckIsFreeUser(userData.servicePlan);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const handlerSetHideModal = () => {
    dispatch(setHideModal());
  };

  const [openModal, setOpenModal] = useState(false);

  const handlerOpenModal = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    if (settingsCompany.show === true) {
      handlerOpenModal();
    }
  }, [settingsCompany]);

  const [showView, setShowView] = useState(0);

  const backView = () => {
    setShowView(0);
  };

  const changeViewCompanySettings = (id) => {
    setShowView(id);
  };

  const handlerCloseModal = () => {
    setOpenModal(false);
    handlerSetHideModal();
    backView();
  };

  const handlerCheckIsMenuMustShow = () => {
    if (IsCollaboratorPlan(userData.servicePlan)) {
      return false;
    }

    if (isFreeUserPlan) {
      return false;
    }

    return true;
  };

  const menuList = [
    {
      id: 1,
      title: "Guest users",
      text: "Add team members for work in your company profile",
      icon: <GroupIcon />,
      show: handlerCheckIsMenuMustShow()
    },
    {
      id: 2,
      title: "Social networks",
      text: "Link your social networks",
      icon: <ShareIcon />,
      show: handlerCheckIsMenuMustShow()
    },
  ];

  const buildButtonMenu = (menu) => {
    return menu.show && (
      <button
        type='button'
        className='btn button-list-settings-company'
        key={menu.id}
        onClick={() => changeViewCompanySettings(menu.id)}
      >
        <div className="container-button">
          {menu.icon}
          <Text type="text">
            {menu.title}
          </Text>
        </div>
      </button>
    );
  };

  return (
    <Modal
      open={openModal}
      showCloseIcon={false}
      onClose={() => handlerCloseModal()}
      closeOnEsc={false}
      blockScroll={false}
      closeOnOverlayClick={false}
      classNames={{
        overlay: 'customOverlay',
        modal: isMobileDevice ? 'fullscreen-modal' : 'customModal',
      }}
      center
    >
      <div className="modal-body modal-sm">
        <div className="body-settings-company">
          <div className='header-main-content'>
            <Text type="subtitle">
              Company settings
            </Text>
            <button
              className='bg-transparent border-none btn p-0'
              onClick={() => handlerCloseModal()}
            >
              <CloseIcon />
            </button>
          </div>

          {showView === 0 && (
            <div className='main-body-settings-company'>
              <div className='main-menu-settings-company'>
                <div className="account-company pr-2 pl-2">
                  <img
                    src={companyInfo?.logo?.urlThumbnail || ''}
                    alt=""
                    style={{ height: '36px', width: 'auto', borderRadius: '6px' }}
                  />
                  <Text type="text">
                    {activeCompanyProfile.name}
                  </Text>
                </div>
              </div>
              <WidgetManageFiles />
              <div className="list-menu-settings-company">
                {menuList.map((menuItem) => (
                  buildButtonMenu(menuItem)
                ))}
              </div>  
            </div>
          )}

          {showView === 1 && (
            <GuestUsers backView={backView} />
          )}

          {showView === 2 && (
            <SocialNetworks backView={backView} />
          )}

        </div>
      </div>
    </Modal>
  )
}

export default SettingsCompany;