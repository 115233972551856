const calculateTotalStorage = (fileList) => {
  const total = fileList.reduce((acc, file) => {
    return acc + file.size;
  }, 0);

  return total;
};

export const validateStorage = (fileList, maxStorageCompany, sizeNewFile) => {
  const totalStorageUsage = calculateTotalStorage(fileList);
  const calculateNewStorageUsageWithNewFile = totalStorageUsage + sizeNewFile;

  if (calculateNewStorageUsageWithNewFile > maxStorageCompany) {
    return true;
  } else {
    return false;
  }
};