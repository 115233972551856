/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const stateUser = createSlice({
  name: 'stateUser',
  initialState: {
    value: {
      user: "",
      email: "",
      active: false,
      dataEndService: "",
      dataInitService: "",
      maxCompanyProfiles: 0,
      maxGenerateContentCompany: 0,
      accessBlogPost: false,
      canUserBeGuest: false,
      maxGenerateBlogPost: 0,
      maxGenerateContentPost: 0,
      maxGenerateVideoPosts: 0,
      maxGenerateSpecificPost: 0,
      guestUsersPerCompanyProfile: 0,
      serviceHistory: [],
      servicePlan: "",
      access: []
    },
  },
  reducers: {
    setStateUser: (state, action) => {
      state.value.user = action.payload.user;
      state.value.email = action.payload.email;
      state.value.active = action.payload.active;
      state.value.dataEndService = action.payload.dataEndService;
      state.value.dataInitService = action.payload.dataInitService;
      state.value.maxCompanyProfiles = parseInt(action.payload.maxCompanyProfiles);
      state.value.maxGenerateContentCompany = parseInt(action.payload.maxGenerateContentCompany);
      state.value.accessBlogPost = action.payload.accessBlogPost;
      state.value.canUserBeGuest = action.payload.canUserBeGuest;
      state.value.maxGenerateBlogPost = parseInt(action.payload.maxGenerateBlogPost);
      state.value.maxGenerateContentPost = parseInt(action.payload.maxGenerateContentPost);
      state.value.maxGenerateSpecificPost = parseInt(action.payload.maxGenerateSpecificPost);
      state.value.maxGenerateVideoPosts = parseInt(action.payload.maxGenerateVideoPosts);
      state.value.guestUsersPerCompanyProfile = parseInt(action.payload.guestUsersPerCompanyProfile);
      state.value.serviceHistory = action.payload.serviceHistory;
      state.value.servicePlan = action.payload.servicePlan;
      state.value.access = action.payload.access;
    },
    setRestMaxContentGenerateCompany: (state, action) => {
      state.value.maxGenerateContentCompany = (state.value.maxGenerateContentCompany - 1);
    },
    setRestMaxContentGenerateBrandKit: (state, action) => {
      state.value.maxContentGenerateBrandKit = (state.value.maxContentGenerateBrandKit - 1);
    },
  }
});

export const { setStateUser, setRestMaxContentGenerateCompany, setRestMaxContentGenerateBrandKit } = stateUser.actions;

export default stateUser.reducer;
